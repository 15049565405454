export const GFTI = [
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67259,
   "Closing_Rank": 73418
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 73632,
   "Closing_Rank": 73632
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12126,
   "Closing_Rank": 14062
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23145,
   "Closing_Rank": 27706
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28093,
   "Closing_Rank": 28093
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10347,
   "Closing_Rank": 12598
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5529,
   "Closing_Rank": 5656
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 129172,
   "Closing_Rank": 251170
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 134705,
   "Closing_Rank": 134705
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41077,
   "Closing_Rank": 42335
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 88617,
   "Closing_Rank": 126820
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 97172,
   "Closing_Rank": 97172
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21045,
   "Closing_Rank": 26400
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20184,
   "Closing_Rank": 20184
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6721,
   "Closing_Rank": 7509
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43020,
   "Closing_Rank": 50836
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45992,
   "Closing_Rank": 45992
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3213,
   "Closing_Rank": 3213
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8558,
   "Closing_Rank": 8667
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17774,
   "Closing_Rank": 19161
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16318,
   "Closing_Rank": 16318
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8748,
   "Closing_Rank": 10422
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 948,
   "Closing_Rank": 3587
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 71669,
   "Closing_Rank": 97431
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 89527,
   "Closing_Rank": 89527
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3505,
   "Closing_Rank": 3505
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18955,
   "Closing_Rank": 20222
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40817,
   "Closing_Rank": 54011
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 68589,
   "Closing_Rank": 68589
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11271,
   "Closing_Rank": 16105
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16723,
   "Closing_Rank": 16723
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4280,
   "Closing_Rank": 4500
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51079,
   "Closing_Rank": 58657
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56760,
   "Closing_Rank": 56760
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9699,
   "Closing_Rank": 9849
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19277,
   "Closing_Rank": 20863
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23076,
   "Closing_Rank": 23076
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10231,
   "Closing_Rank": 10900
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3662,
   "Closing_Rank": 4260
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 95469,
   "Closing_Rank": 134701
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 114979,
   "Closing_Rank": 114979
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26117,
   "Closing_Rank": 27284
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55437,
   "Closing_Rank": 72701
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 79757,
   "Closing_Rank": 79757
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16927,
   "Closing_Rank": 19772
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17430,
   "Closing_Rank": 17430
 },
 {
   "Institute": "Assam University Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5944,
   "Closing_Rank": 6665
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 944,
   "Closing_Rank": 1667
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 665,
   "Closing_Rank": 678
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 597,
   "Closing_Rank": 1085
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 351,
   "Closing_Rank": 431
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 169,
   "Closing_Rank": 229
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1948,
   "Closing_Rank": 9499
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3588,
   "Closing_Rank": 3677
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15530,
   "Closing_Rank": 19251
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24376,
   "Closing_Rank": 26589
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1263,
   "Closing_Rank": 1263
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3116,
   "Closing_Rank": 3699
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8515,
   "Closing_Rank": 9552
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3996,
   "Closing_Rank": 5803
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2982,
   "Closing_Rank": 3401
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30408,
   "Closing_Rank": 35711
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42917,
   "Closing_Rank": 50188
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8190,
   "Closing_Rank": 8301
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7782,
   "Closing_Rank": 11559
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7462,
   "Closing_Rank": 8510
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55039,
   "Closing_Rank": 57068
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58078,
   "Closing_Rank": 58078
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4107,
   "Closing_Rank": 4107
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9618,
   "Closing_Rank": 9637
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18082,
   "Closing_Rank": 19723
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9545,
   "Closing_Rank": 11859
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5217,
   "Closing_Rank": 5217
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 82385,
   "Closing_Rank": 91376
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 75883,
   "Closing_Rank": 103780
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17978,
   "Closing_Rank": 21535
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18066,
   "Closing_Rank": 21374
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8782,
   "Closing_Rank": 23727
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39389,
   "Closing_Rank": 49909
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44873,
   "Closing_Rank": 55026
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3436,
   "Closing_Rank": 3436
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8164,
   "Closing_Rank": 8624
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15871,
   "Closing_Rank": 19536
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5195,
   "Closing_Rank": 11282
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5115,
   "Closing_Rank": 5664
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55520,
   "Closing_Rank": 82308
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 74966,
   "Closing_Rank": 88813
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14447,
   "Closing_Rank": 17013
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19733,
   "Closing_Rank": 23663
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10592,
   "Closing_Rank": 20337
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24167,
   "Closing_Rank": 53237
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 57562,
   "Closing_Rank": 58887
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4021,
   "Closing_Rank": 4021
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8744,
   "Closing_Rank": 8927
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17793,
   "Closing_Rank": 19326
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8960,
   "Closing_Rank": 10729
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3225,
   "Closing_Rank": 4233
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66886,
   "Closing_Rank": 75669
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 87312,
   "Closing_Rank": 103051
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13333,
   "Closing_Rank": 13358
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18397,
   "Closing_Rank": 19045
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5617,
   "Closing_Rank": 13668
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10209,
   "Closing_Rank": 18030
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16893,
   "Closing_Rank": 23503
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 880,
   "Closing_Rank": 1050
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2812,
   "Closing_Rank": 3374
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5218,
   "Closing_Rank": 8471
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1119,
   "Closing_Rank": 1119
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3233,
   "Closing_Rank": 5582
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1587,
   "Closing_Rank": 2666
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18357,
   "Closing_Rank": 30267
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27403,
   "Closing_Rank": 41767
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5048,
   "Closing_Rank": 7644
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6039,
   "Closing_Rank": 11295
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2682,
   "Closing_Rank": 7292
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24787,
   "Closing_Rank": 31361
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38006,
   "Closing_Rank": 38276
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2617,
   "Closing_Rank": 2617
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5890,
   "Closing_Rank": 6123
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13841,
   "Closing_Rank": 14718
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9347,
   "Closing_Rank": 9786
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4639,
   "Closing_Rank": 4968
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49318,
   "Closing_Rank": 53882
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43789,
   "Closing_Rank": 65415
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9993,
   "Closing_Rank": 10104
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13787,
   "Closing_Rank": 17040
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9444,
   "Closing_Rank": 15107
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19878,
   "Closing_Rank": 27924
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25185,
   "Closing_Rank": 34915
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1265,
   "Closing_Rank": 1347
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4589,
   "Closing_Rank": 5474
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9020,
   "Closing_Rank": 13424
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5697,
   "Closing_Rank": 9079
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3732,
   "Closing_Rank": 4734
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31805,
   "Closing_Rank": 50935
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47572,
   "Closing_Rank": 59919
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8406,
   "Closing_Rank": 9640
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10893,
   "Closing_Rank": 16922
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5264,
   "Closing_Rank": 11639
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52599,
   "Closing_Rank": 59904
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 62176,
   "Closing_Rank": 62176
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3005,
   "Closing_Rank": 3005
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9919,
   "Closing_Rank": 10078
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19816,
   "Closing_Rank": 20906
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11315,
   "Closing_Rank": 12189
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4694,
   "Closing_Rank": 4694
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96783,
   "Closing_Rank": 106423
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 122392,
   "Closing_Rank": 122465
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20503,
   "Closing_Rank": 24026
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28013,
   "Closing_Rank": 28023
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24601,
   "Closing_Rank": 32498
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22223,
   "Closing_Rank": 31152
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34930,
   "Closing_Rank": 38704
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5400,
   "Closing_Rank": 6806
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11706,
   "Closing_Rank": 14854
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8737,
   "Closing_Rank": 10330
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4151,
   "Closing_Rank": 5214
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49445,
   "Closing_Rank": 65629
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51690,
   "Closing_Rank": 65932
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10745,
   "Closing_Rank": 12558
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12272,
   "Closing_Rank": 18705
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11504,
   "Closing_Rank": 18958
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31389,
   "Closing_Rank": 43489
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43915,
   "Closing_Rank": 53467
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7332,
   "Closing_Rank": 8057
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13840,
   "Closing_Rank": 18290
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9380,
   "Closing_Rank": 10594
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5119,
   "Closing_Rank": 5597
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54254,
   "Closing_Rank": 66709
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 78404,
   "Closing_Rank": 87225
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10935,
   "Closing_Rank": 13321
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15605,
   "Closing_Rank": 19036
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11043,
   "Closing_Rank": 21703
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47152,
   "Closing_Rank": 55470
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59109,
   "Closing_Rank": 59109
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9268,
   "Closing_Rank": 9690
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19924,
   "Closing_Rank": 20661
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4837,
   "Closing_Rank": 10899
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4389,
   "Closing_Rank": 4389
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70351,
   "Closing_Rank": 102547
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 99214,
   "Closing_Rank": 110795
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23484,
   "Closing_Rank": 24483
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25447,
   "Closing_Rank": 25447
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46644,
   "Closing_Rank": 55546
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56611,
   "Closing_Rank": 64083
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3666,
   "Closing_Rank": 3666
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9139,
   "Closing_Rank": 9529
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18730,
   "Closing_Rank": 20374
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11402,
   "Closing_Rank": 12042
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5219,
   "Closing_Rank": 6182
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67695,
   "Closing_Rank": 94141
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 89481,
   "Closing_Rank": 104699
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19217,
   "Closing_Rank": 19800
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22024,
   "Closing_Rank": 28086
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36220,
   "Closing_Rank": 41337
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45399,
   "Closing_Rank": 45399
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6937,
   "Closing_Rank": 6951
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13669,
   "Closing_Rank": 16456
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9405,
   "Closing_Rank": 10942
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3795,
   "Closing_Rank": 3795
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65278,
   "Closing_Rank": 70085
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 80691,
   "Closing_Rank": 90331
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16109,
   "Closing_Rank": 16723
 },
 {
   "Institute": "Birla Institute of Technology Mesra  Ranchi",
   "Academic_Program_Name": "Quantitative Economics  Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25108,
   "Closing_Rank": 25108
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40539,
   "Closing_Rank": 72273
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3608,
   "Closing_Rank": 3608
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11838,
   "Closing_Rank": 12821
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22330,
   "Closing_Rank": 26209
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8113,
   "Closing_Rank": 12824
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3158,
   "Closing_Rank": 6348
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56727,
   "Closing_Rank": 78861
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12952,
   "Closing_Rank": 14043
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25335,
   "Closing_Rank": 30414
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8929,
   "Closing_Rank": 13249
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4434,
   "Closing_Rank": 6422
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56633,
   "Closing_Rank": 82972
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3736,
   "Closing_Rank": 3736
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13708,
   "Closing_Rank": 14472
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26521,
   "Closing_Rank": 29739
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9357,
   "Closing_Rank": 14342
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6557,
   "Closing_Rank": 6933
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66298,
   "Closing_Rank": 97236
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16347,
   "Closing_Rank": 17039
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31165,
   "Closing_Rank": 36292
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13360,
   "Closing_Rank": 16043
 },
 {
   "Institute": "Gurukula Kangri Vishwavidyalaya Haridwar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6414,
   "Closing_Rank": 7283
 },
 {
   "Institute": "Indian Institute of Carpet Technology  Bhadohi",
   "Academic_Program_Name": "Carpet  and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 72683,
   "Closing_Rank": 108746
 },
 {
   "Institute": "Indian Institute of Carpet Technology  Bhadohi",
   "Academic_Program_Name": "Carpet  and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18011,
   "Closing_Rank": 18096
 },
 {
   "Institute": "Indian Institute of Carpet Technology  Bhadohi",
   "Academic_Program_Name": "Carpet  and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37277,
   "Closing_Rank": 37808
 },
 {
   "Institute": "Indian Institute of Carpet Technology  Bhadohi",
   "Academic_Program_Name": "Carpet  and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13436,
   "Closing_Rank": 16280
 },
 {
   "Institute": "Indian Institute of Carpet Technology  Bhadohi",
   "Academic_Program_Name": "Carpet  and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6282,
   "Closing_Rank": 6282
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54783,
   "Closing_Rank": 71301
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2842,
   "Closing_Rank": 2842
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11709,
   "Closing_Rank": 11853
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22365,
   "Closing_Rank": 24082
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10582,
   "Closing_Rank": 12726
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4077,
   "Closing_Rank": 4607
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27039,
   "Closing_Rank": 40078
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6644,
   "Closing_Rank": 7227
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13553,
   "Closing_Rank": 18104
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7676,
   "Closing_Rank": 9797
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3028,
   "Closing_Rank": 4045
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41366,
   "Closing_Rank": 52503
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2328,
   "Closing_Rank": 2328
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8806,
   "Closing_Rank": 9424
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18654,
   "Closing_Rank": 21526
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10132,
   "Closing_Rank": 11659
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5403,
   "Closing_Rank": 5778
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47266,
   "Closing_Rank": 60156
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2621,
   "Closing_Rank": 2621
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10216,
   "Closing_Rank": 10892
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18231,
   "Closing_Rank": 23192
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11503,
   "Closing_Rank": 12948
 },
 {
   "Institute": "Institute of Infrastructure Technology Research and Management-Ahmedabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5180,
   "Closing_Rank": 5847
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59865,
   "Closing_Rank": 98991
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16483,
   "Closing_Rank": 17382
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31859,
   "Closing_Rank": 36869
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14204,
   "Closing_Rank": 16112
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6547,
   "Closing_Rank": 7674
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64699,
   "Closing_Rank": 100745
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16734,
   "Closing_Rank": 17639
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32308,
   "Closing_Rank": 35758
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12461,
   "Closing_Rank": 14462
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4771,
   "Closing_Rank": 6656
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47648,
   "Closing_Rank": 65174
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2292,
   "Closing_Rank": 3282
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10801,
   "Closing_Rank": 11383
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20008,
   "Closing_Rank": 23226
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9513,
   "Closing_Rank": 12256
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4010,
   "Closing_Rank": 5891
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56318,
   "Closing_Rank": 90411
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14998,
   "Closing_Rank": 15540
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28671,
   "Closing_Rank": 31338
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13383,
   "Closing_Rank": 14823
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6827,
   "Closing_Rank": 7555
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58573,
   "Closing_Rank": 79290
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3374,
   "Closing_Rank": 3679
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13014,
   "Closing_Rank": 13718
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24778,
   "Closing_Rank": 28610
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11053,
   "Closing_Rank": 13468
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4714,
   "Closing_Rank": 6361
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63551,
   "Closing_Rank": 110158
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18359,
   "Closing_Rank": 18823
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35776,
   "Closing_Rank": 38480
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15486,
   "Closing_Rank": 16913
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6817,
   "Closing_Rank": 7944
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54203,
   "Closing_Rank": 77321
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12837,
   "Closing_Rank": 13323
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23967,
   "Closing_Rank": 28430
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12514,
   "Closing_Rank": 14118
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5378,
   "Closing_Rank": 6869
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62854,
   "Closing_Rank": 96985
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16096,
   "Closing_Rank": 16709
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31094,
   "Closing_Rank": 35162
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12875,
   "Closing_Rank": 15461
 },
 {
   "Institute": "School of Studies of Engineering and Technology Guru Ghasidas Vishwavidyalaya Bilaspur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7019,
   "Closing_Rank": 7416
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24207,
   "Closing_Rank": 43997
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1794,
   "Closing_Rank": 1794
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7440,
   "Closing_Rank": 8061
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13596,
   "Closing_Rank": 18590
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1532,
   "Closing_Rank": 1532
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3137,
   "Closing_Rank": 8509
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4805,
   "Closing_Rank": 5022
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46854,
   "Closing_Rank": 56755
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2106,
   "Closing_Rank": 2106
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9383,
   "Closing_Rank": 9750
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19121,
   "Closing_Rank": 21419
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8969,
   "Closing_Rank": 10721
 },
 {
   "Institute": "J.K. Institute of Applied Physics  Technology Department of Electronics  Communication University of Allahabad- Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1913,
   "Closing_Rank": 5357
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35716,
   "Closing_Rank": 54579
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2085,
   "Closing_Rank": 3234
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9278,
   "Closing_Rank": 10029
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17855,
   "Closing_Rank": 21666
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10043,
   "Closing_Rank": 11161
 },
 {
   "Institute": "National Institute of Electronics and Information Technology Aurangabad (Maharashtra)",
   "Academic_Program_Name": "Electronic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5002,
   "Closing_Rank": 5561
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42537,
   "Closing_Rank": 50906
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46291,
   "Closing_Rank": 51025
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1351,
   "Closing_Rank": 1351
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8634,
   "Closing_Rank": 9053
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8506,
   "Closing_Rank": 8506
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15888,
   "Closing_Rank": 20121
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19856,
   "Closing_Rank": 22226
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8939,
   "Closing_Rank": 10904
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10772,
   "Closing_Rank": 10884
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3597,
   "Closing_Rank": 4701
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4547,
   "Closing_Rank": 4547
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52106,
   "Closing_Rank": 63321
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 64662,
   "Closing_Rank": 73509
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10286,
   "Closing_Rank": 11578
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12629,
   "Closing_Rank": 12629
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19629,
   "Closing_Rank": 22700
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24484,
   "Closing_Rank": 29050
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8805,
   "Closing_Rank": 13189
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12541,
   "Closing_Rank": 13278
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4342,
   "Closing_Rank": 5454
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5363,
   "Closing_Rank": 5363
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53712,
   "Closing_Rank": 76992
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 67067,
   "Closing_Rank": 80113
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13202,
   "Closing_Rank": 14360
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13284,
   "Closing_Rank": 13284
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24645,
   "Closing_Rank": 31555
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27188,
   "Closing_Rank": 31896
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9839,
   "Closing_Rank": 14440
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13813,
   "Closing_Rank": 13971
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5195,
   "Closing_Rank": 6951
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7368,
   "Closing_Rank": 7368
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47915,
   "Closing_Rank": 77460
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 71986,
   "Closing_Rank": 79577
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12910,
   "Closing_Rank": 14739
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14256,
   "Closing_Rank": 14256
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24087,
   "Closing_Rank": 31537
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28428,
   "Closing_Rank": 33284
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10898,
   "Closing_Rank": 14601
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13215,
   "Closing_Rank": 14899
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5917,
   "Closing_Rank": 6362
 },
 {
   "Institute": "National Institute of Advanced Manufacturing Technology Ranchi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5616,
   "Closing_Rank": 5616
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70784,
   "Closing_Rank": 92779
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15592,
   "Closing_Rank": 16301
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30065,
   "Closing_Rank": 34720
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15137,
   "Closing_Rank": 16343
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6301,
   "Closing_Rank": 7594
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64811,
   "Closing_Rank": 98731
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16641,
   "Closing_Rank": 17395
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31784,
   "Closing_Rank": 36031
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14821,
   "Closing_Rank": 14977
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6963,
   "Closing_Rank": 7434
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58667,
   "Closing_Rank": 81582
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14487,
   "Closing_Rank": 14487
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28332,
   "Closing_Rank": 28899
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15336,
   "Closing_Rank": 15336
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4575,
   "Closing_Rank": 4575
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43050,
   "Closing_Rank": 58940
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1622,
   "Closing_Rank": 1622
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9822,
   "Closing_Rank": 10476
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17681,
   "Closing_Rank": 21600
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10550,
   "Closing_Rank": 11695
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4864,
   "Closing_Rank": 6080
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50522,
   "Closing_Rank": 79475
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13150,
   "Closing_Rank": 13209
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24832,
   "Closing_Rank": 27272
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12043,
   "Closing_Rank": 14217
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5460,
   "Closing_Rank": 6463
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50193,
   "Closing_Rank": 63568
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2918,
   "Closing_Rank": 2918
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11018,
   "Closing_Rank": 11595
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21143,
   "Closing_Rank": 24424
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11694,
   "Closing_Rank": 12534
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4818,
   "Closing_Rank": 5200
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75867,
   "Closing_Rank": 110525
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18527,
   "Closing_Rank": 18856
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37116,
   "Closing_Rank": 38887
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16288,
   "Closing_Rank": 16773
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7423,
   "Closing_Rank": 8087
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65002,
   "Closing_Rank": 89448
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15608,
   "Closing_Rank": 17293
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30469,
   "Closing_Rank": 34915
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13456,
   "Closing_Rank": 16253
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7942,
   "Closing_Rank": 8088
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63813,
   "Closing_Rank": 90733
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15321,
   "Closing_Rank": 15464
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33145,
   "Closing_Rank": 36305
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14584,
   "Closing_Rank": 15839
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7819,
   "Closing_Rank": 7819
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64651,
   "Closing_Rank": 94248
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15776,
   "Closing_Rank": 16509
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30196,
   "Closing_Rank": 35033
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10598,
   "Closing_Rank": 15829
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6186,
   "Closing_Rank": 7307
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69891,
   "Closing_Rank": 85605
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17164,
   "Closing_Rank": 17352
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28766,
   "Closing_Rank": 36808
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13701,
   "Closing_Rank": 15420
 },
 {
   "Institute": "Sant Longowal Institute of Engineering and Technology",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6758,
   "Closing_Rank": 7403
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1696,
   "Closing_Rank": 1863
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 769,
   "Closing_Rank": 799
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 332,
   "Closing_Rank": 453
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 241,
   "Closing_Rank": 245
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85431,
   "Closing_Rank": 97624
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16241,
   "Closing_Rank": 16406
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14313,
   "Closing_Rank": 15550
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4221,
   "Closing_Rank": 6172
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41249,
   "Closing_Rank": 68816
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11343,
   "Closing_Rank": 12743
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9992,
   "Closing_Rank": 11551
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5652,
   "Closing_Rank": 6637
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70045,
   "Closing_Rank": 84299
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14553,
   "Closing_Rank": 15028
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12783,
   "Closing_Rank": 14314
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5513,
   "Closing_Rank": 6756
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67991,
   "Closing_Rank": 79337
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14179,
   "Closing_Rank": 14423
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14018,
   "Closing_Rank": 14436
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6535,
   "Closing_Rank": 7405
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60076,
   "Closing_Rank": 71045
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12773,
   "Closing_Rank": 12822
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12069,
   "Closing_Rank": 12636
 },
 {
   "Institute": "Mizoram University Aizawl",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7257,
   "Closing_Rank": 7364
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Bachelor of Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Design)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 88329,
   "Closing_Rank": 90552
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Bachelor of Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Design)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34528,
   "Closing_Rank": 34528
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Bachelor of Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Design)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15416,
   "Closing_Rank": 15416
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60430,
   "Closing_Rank": 96305
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16425,
   "Closing_Rank": 17752
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31444,
   "Closing_Rank": 33852
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12164,
   "Closing_Rank": 13855
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5102,
   "Closing_Rank": 5299
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44577,
   "Closing_Rank": 56762
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9977,
   "Closing_Rank": 10102
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17030,
   "Closing_Rank": 19796
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7317,
   "Closing_Rank": 8680
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3737,
   "Closing_Rank": 3737
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59765,
   "Closing_Rank": 72639
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14298,
   "Closing_Rank": 14341
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24635,
   "Closing_Rank": 25801
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10409,
   "Closing_Rank": 10867
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5879,
   "Closing_Rank": 5879
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62983,
   "Closing_Rank": 69923
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11913,
   "Closing_Rank": 12809
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22655,
   "Closing_Rank": 24113
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8740,
   "Closing_Rank": 9926
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4773,
   "Closing_Rank": 5346
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 61553,
   "Closing_Rank": 109043
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18467,
   "Closing_Rank": 18487
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37557,
   "Closing_Rank": 38826
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13424,
   "Closing_Rank": 15840
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5951,
   "Closing_Rank": 6458
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69214,
   "Closing_Rank": 81609
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14911,
   "Closing_Rank": 15256
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25520,
   "Closing_Rank": 29747
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12037,
   "Closing_Rank": 13314
 },
 {
   "Institute": "School of Engineering Tezpur University Napaam Tezpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5422,
   "Closing_Rank": 5688
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59,
   "Closing_Rank": 754
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 144,
   "Closing_Rank": 144
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 129,
   "Closing_Rank": 613
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 287,
   "Closing_Rank": 702
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36,
   "Closing_Rank": 312
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16,
   "Closing_Rank": 166
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 109,
   "Closing_Rank": 1093
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 184,
   "Closing_Rank": 314
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 527,
   "Closing_Rank": 725
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 174,
   "Closing_Rank": 359
 },
 {
   "Institute": "School of Planning  Architecture Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 74,
   "Closing_Rank": 189
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3,
   "Closing_Rank": 238
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1,
   "Closing_Rank": 47
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27,
   "Closing_Rank": 339
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 76,
   "Closing_Rank": 380
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9,
   "Closing_Rank": 172
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12,
   "Closing_Rank": 48
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57,
   "Closing_Rank": 435
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12,
   "Closing_Rank": 12
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 194,
   "Closing_Rank": 256
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 156,
   "Closing_Rank": 594
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24,
   "Closing_Rank": 153
 },
 {
   "Institute": "School of Planning  Architecture New Delhi",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19,
   "Closing_Rank": 120
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60,
   "Closing_Rank": 798
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93,
   "Closing_Rank": 118
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 148,
   "Closing_Rank": 711
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 271,
   "Closing_Rank": 682
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68,
   "Closing_Rank": 375
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 88,
   "Closing_Rank": 196
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 226,
   "Closing_Rank": 1039
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 113,
   "Closing_Rank": 113
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 223,
   "Closing_Rank": 330
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 347,
   "Closing_Rank": 704
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 82,
   "Closing_Rank": 357
 },
 {
   "Institute": "School of Planning  Architecture: Vijayawada",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 129,
   "Closing_Rank": 224
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1780,
   "Closing_Rank": 1857
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 800,
   "Closing_Rank": 800
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1095,
   "Closing_Rank": 1113
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 458,
   "Closing_Rank": 458
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 202,
   "Closing_Rank": 202
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55211,
   "Closing_Rank": 66299
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10982,
   "Closing_Rank": 11677
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22795,
   "Closing_Rank": 25775
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12589,
   "Closing_Rank": 13163
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6506,
   "Closing_Rank": 6812
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60123,
   "Closing_Rank": 88532
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15414,
   "Closing_Rank": 16680
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29341,
   "Closing_Rank": 33127
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13891,
   "Closing_Rank": 15430
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6246,
   "Closing_Rank": 7299
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 74669,
   "Closing_Rank": 96085
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16829,
   "Closing_Rank": 17015
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30925,
   "Closing_Rank": 33348
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14924,
   "Closing_Rank": 15733
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6724,
   "Closing_Rank": 6799
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40771,
   "Closing_Rank": 61593
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10284,
   "Closing_Rank": 10737
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19589,
   "Closing_Rank": 22726
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9602,
   "Closing_Rank": 11893
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4703,
   "Closing_Rank": 5787
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58793,
   "Closing_Rank": 81774
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13637,
   "Closing_Rank": 13922
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27375,
   "Closing_Rank": 29406
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13624,
   "Closing_Rank": 14602
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6696,
   "Closing_Rank": 6958
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58693,
   "Closing_Rank": 75947
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12747,
   "Closing_Rank": 13599
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24225,
   "Closing_Rank": 27267
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11333,
   "Closing_Rank": 14144
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6407,
   "Closing_Rank": 7227
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67920,
   "Closing_Rank": 89898
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15344,
   "Closing_Rank": 15517
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29944,
   "Closing_Rank": 32534
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12901,
   "Closing_Rank": 14896
 },
 {
   "Institute": "Shri Mata Vaishno Devi University Katra Jammu  Kashmir",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7446,
   "Closing_Rank": 7583
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15769,
   "Closing_Rank": 18080
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3076,
   "Closing_Rank": 3489
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6083,
   "Closing_Rank": 8384
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4402,
   "Closing_Rank": 5728
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56,
   "Closing_Rank": 56
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2500,
   "Closing_Rank": 2821
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19420,
   "Closing_Rank": 22017
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 832,
   "Closing_Rank": 832
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3491,
   "Closing_Rank": 3700
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8768,
   "Closing_Rank": 9569
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3440,
   "Closing_Rank": 6158
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3195,
   "Closing_Rank": 3578
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22711,
   "Closing_Rank": 31363
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2319,
   "Closing_Rank": 2319
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5232,
   "Closing_Rank": 5969
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9866,
   "Closing_Rank": 13017
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7362,
   "Closing_Rank": 9285
 },
 {
   "Institute": "International Institute of Information Technology Naya Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3144,
   "Closing_Rank": 4239
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15170,
   "Closing_Rank": 33350
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 741,
   "Closing_Rank": 741
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5824,
   "Closing_Rank": 6581
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9332,
   "Closing_Rank": 14417
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 563,
   "Closing_Rank": 563
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3887,
   "Closing_Rank": 6804
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 780,
   "Closing_Rank": 3103
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47922,
   "Closing_Rank": 67449
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 438,
   "Closing_Rank": 438
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11697,
   "Closing_Rank": 12482
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20811,
   "Closing_Rank": 24094
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 810,
   "Closing_Rank": 810
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9638,
   "Closing_Rank": 12127
 },
 {
   "Institute": "University of Hyderabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5048,
   "Closing_Rank": 5347
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23988,
   "Closing_Rank": 49123
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15318,
   "Closing_Rank": 15318
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9875,
   "Closing_Rank": 11723
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18381,
   "Closing_Rank": 30665
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6643,
   "Closing_Rank": 7119
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6221,
   "Closing_Rank": 6562
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3072,
   "Closing_Rank": 3072
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38672,
   "Closing_Rank": 80056
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21639,
   "Closing_Rank": 67000
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15769,
   "Closing_Rank": 25411
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33309,
   "Closing_Rank": 51670
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8416,
   "Closing_Rank": 9136
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6872,
   "Closing_Rank": 10485
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2729,
   "Closing_Rank": 3040
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5942,
   "Closing_Rank": 17512
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 228,
   "Closing_Rank": 757
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5473,
   "Closing_Rank": 9313
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 156,
   "Closing_Rank": 156
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1567,
   "Closing_Rank": 4240
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3809,
   "Closing_Rank": 10498
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 415,
   "Closing_Rank": 740
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1879,
   "Closing_Rank": 2267
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 811,
   "Closing_Rank": 3754
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 664,
   "Closing_Rank": 1189
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12134,
   "Closing_Rank": 18408
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9620,
   "Closing_Rank": 10083
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1833,
   "Closing_Rank": 2079
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7865,
   "Closing_Rank": 11603
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 275,
   "Closing_Rank": 275
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2234,
   "Closing_Rank": 2234
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1743,
   "Closing_Rank": 2973
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2065,
   "Closing_Rank": 2065
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18527,
   "Closing_Rank": 21573
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3420,
   "Closing_Rank": 10314
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3422,
   "Closing_Rank": 5305
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11394,
   "Closing_Rank": 13915
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 772,
   "Closing_Rank": 772
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2401,
   "Closing_Rank": 2514
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2939,
   "Closing_Rank": 3827
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2270,
   "Closing_Rank": 2270
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32087,
   "Closing_Rank": 43039
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17723,
   "Closing_Rank": 21553
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10351,
   "Closing_Rank": 13386
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21231,
   "Closing_Rank": 27230
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2214,
   "Closing_Rank": 3465
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4545,
   "Closing_Rank": 4915
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3968,
   "Closing_Rank": 6556
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2698,
   "Closing_Rank": 3621
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16734,
   "Closing_Rank": 30744
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12535,
   "Closing_Rank": 15050
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5600,
   "Closing_Rank": 9451
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14780,
   "Closing_Rank": 19785
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1294,
   "Closing_Rank": 1406
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3082,
   "Closing_Rank": 3467
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3890,
   "Closing_Rank": 5385
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2286,
   "Closing_Rank": 3038
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24461,
   "Closing_Rank": 32004
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16160,
   "Closing_Rank": 16160
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9876,
   "Closing_Rank": 10080
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18655,
   "Closing_Rank": 21167
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3291,
   "Closing_Rank": 3598
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5578,
   "Closing_Rank": 5653
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Electronics Engineering (VLSI Design and Technology) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3170,
   "Closing_Rank": 3170
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18756,
   "Closing_Rank": 24451
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10478,
   "Closing_Rank": 10478
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8200,
   "Closing_Rank": 9452
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14098,
   "Closing_Rank": 15800
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2852,
   "Closing_Rank": 2863
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4160,
   "Closing_Rank": 5548
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2478,
   "Closing_Rank": 2478
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38352,
   "Closing_Rank": 58061
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21404,
   "Closing_Rank": 40381
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10343,
   "Closing_Rank": 19994
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26661,
   "Closing_Rank": 41055
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3446,
   "Closing_Rank": 3569
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6780,
   "Closing_Rank": 7476
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7029,
   "Closing_Rank": 10105
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3313,
   "Closing_Rank": 4054
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53076,
   "Closing_Rank": 85162
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15363,
   "Closing_Rank": 27530
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31093,
   "Closing_Rank": 54239
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9044,
   "Closing_Rank": 9337
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9605,
   "Closing_Rank": 11574
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4852,
   "Closing_Rank": 4852
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43358,
   "Closing_Rank": 68080
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20065,
   "Closing_Rank": 24200
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44682,
   "Closing_Rank": 48373
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3617,
   "Closing_Rank": 3617
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7872,
   "Closing_Rank": 8347
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10728,
   "Closing_Rank": 11311
 },
 {
   "Institute": "Punjab Engineering College Chandigarh",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4700,
   "Closing_Rank": 4700
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9716,
   "Closing_Rank": 27936
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 561,
   "Closing_Rank": 561
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4747,
   "Closing_Rank": 5606
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7591,
   "Closing_Rank": 11255
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 354,
   "Closing_Rank": 354
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3375,
   "Closing_Rank": 5058
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1859,
   "Closing_Rank": 2863
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28151,
   "Closing_Rank": 35486
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 947,
   "Closing_Rank": 947
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5801,
   "Closing_Rank": 6953
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11884,
   "Closing_Rank": 14360
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1241,
   "Closing_Rank": 1241
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5673,
   "Closing_Rank": 7300
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 215,
   "Closing_Rank": 215
 },
 {
   "Institute": "Jawaharlal Nehru University Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2774,
   "Closing_Rank": 3349
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30873,
   "Closing_Rank": 33451
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1199,
   "Closing_Rank": 1199
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5571,
   "Closing_Rank": 6228
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13423,
   "Closing_Rank": 15505
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8029,
   "Closing_Rank": 8942
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3687,
   "Closing_Rank": 3820
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17692,
   "Closing_Rank": 31001
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 739,
   "Closing_Rank": 1145
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5103,
   "Closing_Rank": 5401
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9232,
   "Closing_Rank": 13241
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1142,
   "Closing_Rank": 1142
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5599,
   "Closing_Rank": 7847
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2488,
   "Closing_Rank": 3504
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33820,
   "Closing_Rank": 41584
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2172,
   "Closing_Rank": 2172
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6824,
   "Closing_Rank": 7145
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16077,
   "Closing_Rank": 17238
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9492,
   "Closing_Rank": 10152
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3944,
   "Closing_Rank": 4791
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35866,
   "Closing_Rank": 39955
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2996,
   "Closing_Rank": 2996
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6995,
   "Closing_Rank": 7049
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15003,
   "Closing_Rank": 16850
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8321,
   "Closing_Rank": 9386
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2106,
   "Closing_Rank": 4587
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20238,
   "Closing_Rank": 33712
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1456,
   "Closing_Rank": 1456
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5923,
   "Closing_Rank": 6164
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13269,
   "Closing_Rank": 15432
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7999,
   "Closing_Rank": 8719
 },
 {
   "Institute": "International Institute of Information Technology Bhubaneswar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4106,
   "Closing_Rank": 4399
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63302,
   "Closing_Rank": 63539
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 90158,
   "Closing_Rank": 90158
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22824,
   "Closing_Rank": 22824
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14040,
   "Closing_Rank": 14040
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55057,
   "Closing_Rank": 58033
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60710,
   "Closing_Rank": 60710
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3060,
   "Closing_Rank": 3060
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9586,
   "Closing_Rank": 9586
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19530,
   "Closing_Rank": 19530
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10963,
   "Closing_Rank": 10963
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57634,
   "Closing_Rank": 60026
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 75040,
   "Closing_Rank": 75040
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23251,
   "Closing_Rank": 23251
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 80446,
   "Closing_Rank": 80446
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 104796,
   "Closing_Rank": 104796
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33291,
   "Closing_Rank": 33291
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15171,
   "Closing_Rank": 15171
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75537,
   "Closing_Rank": 75537
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24946,
   "Closing_Rank": 24946
 },
 {
   "Institute": "Central institute of Technology Kokrajar Assam",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5109,
   "Closing_Rank": 5109
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57098,
   "Closing_Rank": 57233
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11389,
   "Closing_Rank": 11389
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20050,
   "Closing_Rank": 20050
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11604,
   "Closing_Rank": 11604
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62229,
   "Closing_Rank": 78491
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4119,
   "Closing_Rank": 4119
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14047,
   "Closing_Rank": 15048
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24859,
   "Closing_Rank": 28744
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10506,
   "Closing_Rank": 12740
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4456,
   "Closing_Rank": 5462
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 262380,
   "Closing_Rank": 534773
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 228701,
   "Closing_Rank": 257334
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66435,
   "Closing_Rank": 69036
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37156,
   "Closing_Rank": 48768
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7927,
   "Closing_Rank": 8485
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 382,
   "Closing_Rank": 382
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15963,
   "Closing_Rank": 19141
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7142,
   "Closing_Rank": 10210
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5064,
   "Closing_Rank": 5319
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59941,
   "Closing_Rank": 168596
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62774,
   "Closing_Rank": 72069
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40774,
   "Closing_Rank": 41371
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56315,
   "Closing_Rank": 56968
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9407,
   "Closing_Rank": 9407
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18630,
   "Closing_Rank": 18997
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9832,
   "Closing_Rank": 9832
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33884,
   "Closing_Rank": 56014
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2139,
   "Closing_Rank": 2139
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9283,
   "Closing_Rank": 9711
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16907,
   "Closing_Rank": 20173
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1405,
   "Closing_Rank": 1405
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10260,
   "Closing_Rank": 10928
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4976,
   "Closing_Rank": 5515
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 149482,
   "Closing_Rank": 227780
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 86234,
   "Closing_Rank": 94433
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32271,
   "Closing_Rank": 32378
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48436,
   "Closing_Rank": 51227
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3601,
   "Closing_Rank": 3641
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19916,
   "Closing_Rank": 19916
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11035,
   "Closing_Rank": 11035
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4424,
   "Closing_Rank": 4424
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41776,
   "Closing_Rank": 44373
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1892,
   "Closing_Rank": 2834
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18739,
   "Closing_Rank": 18862
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9091,
   "Closing_Rank": 9091
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50026,
   "Closing_Rank": 69273
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11366,
   "Closing_Rank": 12777
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21953,
   "Closing_Rank": 24238
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8205,
   "Closing_Rank": 12529
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5567,
   "Closing_Rank": 5827
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 265489,
   "Closing_Rank": 398302
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 149397,
   "Closing_Rank": 158370
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38771,
   "Closing_Rank": 58123
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47030,
   "Closing_Rank": 60203
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9897,
   "Closing_Rank": 9897
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20298,
   "Closing_Rank": 20959
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11207,
   "Closing_Rank": 12359
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4524,
   "Closing_Rank": 5828
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 258325,
   "Closing_Rank": 298097
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 109476,
   "Closing_Rank": 116897
 },
 {
   "Institute": "Puducherry Technological University Puducherry",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32756,
   "Closing_Rank": 32756
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 119059,
   "Closing_Rank": 372966
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69667,
   "Closing_Rank": 79675
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 230452,
   "Closing_Rank": 291815
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29434,
   "Closing_Rank": 37297
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8859,
   "Closing_Rank": 8859
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65242,
   "Closing_Rank": 96049
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16786,
   "Closing_Rank": 17479
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31391,
   "Closing_Rank": 33500
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12627,
   "Closing_Rank": 14233
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Civil and Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6336,
   "Closing_Rank": 7195
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 94996,
   "Closing_Rank": 148356
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27123,
   "Closing_Rank": 32399
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50536,
   "Closing_Rank": 99021
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17962,
   "Closing_Rank": 19292
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5700,
   "Closing_Rank": 7564
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43934,
   "Closing_Rank": 54826
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9000,
   "Closing_Rank": 9047
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19212,
   "Closing_Rank": 19872
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6726,
   "Closing_Rank": 11389
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5088,
   "Closing_Rank": 5088
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 157168,
   "Closing_Rank": 292228
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52127,
   "Closing_Rank": 52710
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 143072,
   "Closing_Rank": 169022
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19945,
   "Closing_Rank": 21892
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6280,
   "Closing_Rank": 7868
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65050,
   "Closing_Rank": 82676
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15047,
   "Closing_Rank": 15047
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26526,
   "Closing_Rank": 27391
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13559,
   "Closing_Rank": 14808
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6208,
   "Closing_Rank": 6208
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 449634,
   "Closing_Rank": 527382
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 86290,
   "Closing_Rank": 86290
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 259142,
   "Closing_Rank": 298461
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40012,
   "Closing_Rank": 41655
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11115,
   "Closing_Rank": 11115
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85992,
   "Closing_Rank": 105533
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17893,
   "Closing_Rank": 17913
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34960,
   "Closing_Rank": 36947
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16644,
   "Closing_Rank": 16811
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7768,
   "Closing_Rank": 7768
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 193992,
   "Closing_Rank": 356960
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70954,
   "Closing_Rank": 72108
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 177860,
   "Closing_Rank": 225386
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23520,
   "Closing_Rank": 37145
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9445,
   "Closing_Rank": 9445
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66653,
   "Closing_Rank": 83761
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14070,
   "Closing_Rank": 14070
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29877,
   "Closing_Rank": 30768
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9378,
   "Closing_Rank": 14713
 },
 {
   "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology Malda West Bengal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7471,
   "Closing_Rank": 7471
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57727,
   "Closing_Rank": 76993
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13667,
   "Closing_Rank": 14017
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26413,
   "Closing_Rank": 30875
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12611,
   "Closing_Rank": 13878
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5351,
   "Closing_Rank": 5959
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32073,
   "Closing_Rank": 49021
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3108,
   "Closing_Rank": 3108
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8344,
   "Closing_Rank": 8880
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16926,
   "Closing_Rank": 19382
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7961,
   "Closing_Rank": 10339
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3522,
   "Closing_Rank": 4438
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51339,
   "Closing_Rank": 57631
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9624,
   "Closing_Rank": 9953
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18698,
   "Closing_Rank": 21072
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10216,
   "Closing_Rank": 11135
 },
 {
   "Institute": "Central University of Rajasthan Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4627,
   "Closing_Rank": 5121
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58177,
   "Closing_Rank": 106292
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1226,
   "Closing_Rank": 1226
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18005,
   "Closing_Rank": 18574
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35066,
   "Closing_Rank": 38117
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10023,
   "Closing_Rank": 16247
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Kundli",
   "Academic_Program_Name": "Food Technology and Management ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4463,
   "Closing_Rank": 7767
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56416,
   "Closing_Rank": 95412
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2490,
   "Closing_Rank": 3466
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17909,
   "Closing_Rank": 18352
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30626,
   "Closing_Rank": 36784
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1554,
   "Closing_Rank": 1554
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10537,
   "Closing_Rank": 15077
 },
 {
   "Institute": "National Institute of Food Technology Entrepreneurship and Management Thanjavur",
   "Academic_Program_Name": "Food Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4897,
   "Closing_Rank": 7326
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12242,
   "Closing_Rank": 12242
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Agricultural Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29236,
   "Closing_Rank": 29236
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12430,
   "Closing_Rank": 12430
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25403,
   "Closing_Rank": 25403
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50301,
   "Closing_Rank": 55764
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10196,
   "Closing_Rank": 11068
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11271,
   "Closing_Rank": 11271
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5570,
   "Closing_Rank": 5570
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10624,
   "Closing_Rank": 10624
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12569,
   "Closing_Rank": 12569
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65512,
   "Closing_Rank": 65512
 },
 {
   "Institute": "North Eastern Regional Institute of Science and Technology Nirjuli-791109 (Itanagar)Arunachal Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12191,
   "Closing_Rank": 12191
 },
 {
   "Institute": "Indian Institute of Handloom Technology(IIHT) Varanasi",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58882,
   "Closing_Rank": 102843
 },
 {
   "Institute": "Indian Institute of Handloom Technology(IIHT) Varanasi",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17586,
   "Closing_Rank": 17998
 },
 {
   "Institute": "Indian Institute of Handloom Technology(IIHT) Varanasi",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36160,
   "Closing_Rank": 37637
 },
 {
   "Institute": "Indian Institute of Handloom Technology(IIHT) Varanasi",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12951,
   "Closing_Rank": 15494
 },
 {
   "Institute": "Indian Institute of Handloom Technology(IIHT) Varanasi",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6859,
   "Closing_Rank": 7346
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 80755,
   "Closing_Rank": 97484
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17530,
   "Closing_Rank": 17795
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31676,
   "Closing_Rank": 35867
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11171,
   "Closing_Rank": 15786
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6680,
   "Closing_Rank": 6690
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18805,
   "Closing_Rank": 62904
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10925,
   "Closing_Rank": 11105
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19204,
   "Closing_Rank": 23266
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6976,
   "Closing_Rank": 11875
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4779,
   "Closing_Rank": 6026
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52422,
   "Closing_Rank": 65702
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2796,
   "Closing_Rank": 2796
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10928,
   "Closing_Rank": 11625
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20195,
   "Closing_Rank": 23292
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10568,
   "Closing_Rank": 12172
 },
 {
   "Institute": "Chhattisgarh Swami Vivekanada Technical University Bhilai (CSVTU Bhilai)",
   "Academic_Program_Name": "Computer Science Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5027,
   "Closing_Rank": 5645
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28446,
   "Closing_Rank": 56446
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4284,
   "Closing_Rank": 4284
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10228,
   "Closing_Rank": 10766
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18261,
   "Closing_Rank": 21850
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10189,
   "Closing_Rank": 11628
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4076,
   "Closing_Rank": 6441
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64392,
   "Closing_Rank": 76973
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18731,
   "Closing_Rank": 20688
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25460,
   "Closing_Rank": 38259
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13218,
   "Closing_Rank": 16608
 },
 {
   "Institute": "Institute of Chemical Technology Mumbai: Indian Oil Odisha Campus Bhubaneswar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Masters in Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8103,
   "Closing_Rank": 9482
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63911,
   "Closing_Rank": 94373
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17158,
   "Closing_Rank": 17296
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30977,
   "Closing_Rank": 36592
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13151,
   "Closing_Rank": 15601
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6583,
   "Closing_Rank": 6702
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64491,
   "Closing_Rank": 79951
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14601,
   "Closing_Rank": 14610
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26731,
   "Closing_Rank": 29541
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9608,
   "Closing_Rank": 12505
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5107,
   "Closing_Rank": 5671
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43720,
   "Closing_Rank": 86765
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14382,
   "Closing_Rank": 14421
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31158,
   "Closing_Rank": 32653
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12587,
   "Closing_Rank": 13847
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5831,
   "Closing_Rank": 6123
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42925,
   "Closing_Rank": 74395
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3696,
   "Closing_Rank": 3696
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13345,
   "Closing_Rank": 13564
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23081,
   "Closing_Rank": 25462
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12184,
   "Closing_Rank": 12454
 },
 {
   "Institute": "North-Eastern Hill University Shillong",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4114,
   "Closing_Rank": 5296
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 61741,
   "Closing_Rank": 69094
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11329,
   "Closing_Rank": 11580
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21657,
   "Closing_Rank": 24411
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10270,
   "Closing_Rank": 12049
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6209,
   "Closing_Rank": 6353
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44623,
   "Closing_Rank": 73171
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3483,
   "Closing_Rank": 3483
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11979,
   "Closing_Rank": 12181
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23108,
   "Closing_Rank": 26252
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11703,
   "Closing_Rank": 12554
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6365,
   "Closing_Rank": 6767
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58184,
   "Closing_Rank": 78192
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4198,
   "Closing_Rank": 4198
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13394,
   "Closing_Rank": 13873
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24494,
   "Closing_Rank": 27925
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12903,
   "Closing_Rank": 14012
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6709,
   "Closing_Rank": 6997
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering (Avionics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52728,
   "Closing_Rank": 74325
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering (Avionics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13713,
   "Closing_Rank": 13984
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering (Avionics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23605,
   "Closing_Rank": 29380
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering (Avionics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13275,
   "Closing_Rank": 14234
 },
 {
   "Institute": "Central University of Jammu",
   "Academic_Program_Name": "Electronics and Communication Engineering (Avionics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3998,
   "Closing_Rank": 7234
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Aeronautical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39303,
   "Closing_Rank": 76456
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Aeronautical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12767,
   "Closing_Rank": 14227
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Aeronautical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25144,
   "Closing_Rank": 29074
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Aeronautical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5347,
   "Closing_Rank": 11160
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Aeronautical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1212,
   "Closing_Rank": 5909
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46633,
   "Closing_Rank": 63665
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10695,
   "Closing_Rank": 11273
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19493,
   "Closing_Rank": 23270
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9093,
   "Closing_Rank": 12158
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5126,
   "Closing_Rank": 6189
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Dairy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 72856,
   "Closing_Rank": 110311
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Dairy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18351,
   "Closing_Rank": 18770
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Dairy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36997,
   "Closing_Rank": 38701
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Dairy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14051,
   "Closing_Rank": 17043
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Dairy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7660,
   "Closing_Rank": 8143
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60269,
   "Closing_Rank": 78073
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13604,
   "Closing_Rank": 14307
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24350,
   "Closing_Rank": 29078
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12157,
   "Closing_Rank": 14526
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5701,
   "Closing_Rank": 7408
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Fashion and Apparel Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85229,
   "Closing_Rank": 108526
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Fashion and Apparel Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18081,
   "Closing_Rank": 18741
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Fashion and Apparel Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35846,
   "Closing_Rank": 38340
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Fashion and Apparel Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14378,
   "Closing_Rank": 16809
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Fashion and Apparel Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7141,
   "Closing_Rank": 8091
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96129,
   "Closing_Rank": 113756
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18883,
   "Closing_Rank": 18947
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38922,
   "Closing_Rank": 39359
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15709,
   "Closing_Rank": 17076
 },
 {
   "Institute": "Institute of Engineering and Technology Dr. H. S. Gour University. Sagar (A Central University)",
   "Academic_Program_Name": "Food Engineering  and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8011,
   "Closing_Rank": 8180
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58463,
   "Closing_Rank": 88343
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15255,
   "Closing_Rank": 15805
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28091,
   "Closing_Rank": 33859
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12456,
   "Closing_Rank": 14885
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2646,
   "Closing_Rank": 5619
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40878,
   "Closing_Rank": 55902
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3190,
   "Closing_Rank": 4178
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9174,
   "Closing_Rank": 10225
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19348,
   "Closing_Rank": 21527
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4663,
   "Closing_Rank": 10498
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4448,
   "Closing_Rank": 5557
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56176,
   "Closing_Rank": 73646
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12099,
   "Closing_Rank": 13203
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22852,
   "Closing_Rank": 28416
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6934,
   "Closing_Rank": 12098
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6411,
   "Closing_Rank": 6848
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Printing and Packaging Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 80388,
   "Closing_Rank": 96179
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Printing and Packaging Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16025,
   "Closing_Rank": 16194
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Printing and Packaging Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32775,
   "Closing_Rank": 35334
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Printing and Packaging Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13981,
   "Closing_Rank": 14952
 },
 {
   "Institute": "Central University of Haryana",
   "Academic_Program_Name": "Printing and Packaging Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5687,
   "Closing_Rank": 6912
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23803,
   "Closing_Rank": 50774
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42369,
   "Closing_Rank": 54665
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1952,
   "Closing_Rank": 1952
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8477,
   "Closing_Rank": 9228
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17583,
   "Closing_Rank": 20157
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9979,
   "Closing_Rank": 11489
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4713,
   "Closing_Rank": 5718
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60083,
   "Closing_Rank": 99112
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 61976,
   "Closing_Rank": 93450
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17031,
   "Closing_Rank": 18701
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16536,
   "Closing_Rank": 22212
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9407,
   "Closing_Rank": 24343
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50942,
   "Closing_Rank": 58085
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 62816,
   "Closing_Rank": 64024
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2943,
   "Closing_Rank": 2943
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9671,
   "Closing_Rank": 10185
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20279,
   "Closing_Rank": 22016
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11483,
   "Closing_Rank": 12363
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5632,
   "Closing_Rank": 5632
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70579,
   "Closing_Rank": 120113
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 122457,
   "Closing_Rank": 122457
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22105,
   "Closing_Rank": 23182
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27755,
   "Closing_Rank": 30403
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20103,
   "Closing_Rank": 30030
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51183,
   "Closing_Rank": 60736
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51280,
   "Closing_Rank": 65927
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10067,
   "Closing_Rank": 10333
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18772,
   "Closing_Rank": 21673
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10604,
   "Closing_Rank": 12312
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5001,
   "Closing_Rank": 6447
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 102962,
   "Closing_Rank": 121375
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 101967,
   "Closing_Rank": 122144
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21069,
   "Closing_Rank": 23793
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13729,
   "Closing_Rank": 28693
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16593,
   "Closing_Rank": 36343
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63517,
   "Closing_Rank": 68851
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 68585,
   "Closing_Rank": 73040
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12826,
   "Closing_Rank": 12942
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22055,
   "Closing_Rank": 23395
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13846,
   "Closing_Rank": 13950
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6290,
   "Closing_Rank": 6290
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 87367,
   "Closing_Rank": 121367
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 122820,
   "Closing_Rank": 122820
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24142,
   "Closing_Rank": 24504
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28100,
   "Closing_Rank": 28487
 },
 {
   "Institute": "Birla Institute of Technology Deoghar Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14926,
   "Closing_Rank": 14926
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40482,
   "Closing_Rank": 50586
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42581,
   "Closing_Rank": 49902
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1648,
   "Closing_Rank": 1648
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8872,
   "Closing_Rank": 9177
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17848,
   "Closing_Rank": 19893
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9006,
   "Closing_Rank": 11337
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5366,
   "Closing_Rank": 5609
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52299,
   "Closing_Rank": 70616
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 67662,
   "Closing_Rank": 80565
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11863,
   "Closing_Rank": 13593
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13786,
   "Closing_Rank": 17330
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8801,
   "Closing_Rank": 8801
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47109,
   "Closing_Rank": 73960
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60046,
   "Closing_Rank": 73723
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12239,
   "Closing_Rank": 12661
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23815,
   "Closing_Rank": 26923
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8047,
   "Closing_Rank": 13453
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4617,
   "Closing_Rank": 5253
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93990,
   "Closing_Rank": 108249
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 89801,
   "Closing_Rank": 105566
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17967,
   "Closing_Rank": 18207
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13872,
   "Closing_Rank": 21816
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10783,
   "Closing_Rank": 10783
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28630,
   "Closing_Rank": 45956
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45659,
   "Closing_Rank": 48437
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2839,
   "Closing_Rank": 2839
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7758,
   "Closing_Rank": 8096
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14084,
   "Closing_Rank": 18466
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1474,
   "Closing_Rank": 1474
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9010,
   "Closing_Rank": 10744
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5188,
   "Closing_Rank": 5405
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46620,
   "Closing_Rank": 61818
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63823,
   "Closing_Rank": 72038
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10605,
   "Closing_Rank": 11201
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12187,
   "Closing_Rank": 13611
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7165,
   "Closing_Rank": 7165
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57312,
   "Closing_Rank": 61655
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60252,
   "Closing_Rank": 63125
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10360,
   "Closing_Rank": 10828
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20509,
   "Closing_Rank": 23214
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11714,
   "Closing_Rank": 13302
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5864,
   "Closing_Rank": 6107
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63936,
   "Closing_Rank": 92622
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 86322,
   "Closing_Rank": 91840
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15530,
   "Closing_Rank": 16205
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14903,
   "Closing_Rank": 22079
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9936,
   "Closing_Rank": 9936
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44807,
   "Closing_Rank": 56509
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53408,
   "Closing_Rank": 58096
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9440,
   "Closing_Rank": 9910
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20329,
   "Closing_Rank": 21241
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11756,
   "Closing_Rank": 12263
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5432,
   "Closing_Rank": 5829
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 64972,
   "Closing_Rank": 84176
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 77418,
   "Closing_Rank": 83253
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13920,
   "Closing_Rank": 14580
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15851,
   "Closing_Rank": 19977
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8260,
   "Closing_Rank": 8260
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55880,
   "Closing_Rank": 68874
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 73325,
   "Closing_Rank": 74275
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11401,
   "Closing_Rank": 12083
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22163,
   "Closing_Rank": 23525
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13531,
   "Closing_Rank": 13884
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6065,
   "Closing_Rank": 6485
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70794,
   "Closing_Rank": 105046
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 94955,
   "Closing_Rank": 96140
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17412,
   "Closing_Rank": 17663
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20328,
   "Closing_Rank": 23126
 },
 {
   "Institute": "Birla Institute of Technology Patna Off-Campus",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10369,
   "Closing_Rank": 10369
 },
 {
   "Institute": "Indian Institute of Handloom Technology Salem",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69188,
   "Closing_Rank": 105409
 },
 {
   "Institute": "Indian Institute of Handloom Technology Salem",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17757,
   "Closing_Rank": 17931
 },
 {
   "Institute": "Indian Institute of Handloom Technology Salem",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35167,
   "Closing_Rank": 36609
 },
 {
   "Institute": "Indian Institute of Handloom Technology Salem",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11064,
   "Closing_Rank": 14193
 },
 {
   "Institute": "Indian Institute of Handloom Technology Salem",
   "Academic_Program_Name": "Handloom and Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4879,
   "Closing_Rank": 6904
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38282,
   "Closing_Rank": 56220
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41082,
   "Closing_Rank": 46146
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9220,
   "Closing_Rank": 10177
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11534,
   "Closing_Rank": 11534
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17644,
   "Closing_Rank": 21729
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20627,
   "Closing_Rank": 20973
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10456,
   "Closing_Rank": 12397
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11688,
   "Closing_Rank": 11688
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3971,
   "Closing_Rank": 5756
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5723,
   "Closing_Rank": 5723
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24887,
   "Closing_Rank": 66836
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 68084,
   "Closing_Rank": 68766
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12184,
   "Closing_Rank": 12644
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14951,
   "Closing_Rank": 14951
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21913,
   "Closing_Rank": 28475
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25640,
   "Closing_Rank": 25640
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12034,
   "Closing_Rank": 12703
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9796,
   "Closing_Rank": 9796
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Aviation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5584,
   "Closing_Rank": 6478
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58777,
   "Closing_Rank": 86943
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 79828,
   "Closing_Rank": 83617
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14662,
   "Closing_Rank": 15637
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15870,
   "Closing_Rank": 15870
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27793,
   "Closing_Rank": 30639
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27903,
   "Closing_Rank": 29789
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8714,
   "Closing_Rank": 16061
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15079,
   "Closing_Rank": 15079
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6195,
   "Closing_Rank": 7150
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Civil Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4867,
   "Closing_Rank": 4867
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46856,
   "Closing_Rank": 69506
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 64097,
   "Closing_Rank": 68995
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12029,
   "Closing_Rank": 13457
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13005,
   "Closing_Rank": 13005
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23545,
   "Closing_Rank": 27538
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27016,
   "Closing_Rank": 27016
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9464,
   "Closing_Rank": 13582
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13922,
   "Closing_Rank": 13922
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electrical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3754,
   "Closing_Rank": 5672
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48658,
   "Closing_Rank": 63121
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56628,
   "Closing_Rank": 56628
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11552,
   "Closing_Rank": 11955
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19156,
   "Closing_Rank": 24323
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23816,
   "Closing_Rank": 23816
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9111,
   "Closing_Rank": 12852
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11712,
   "Closing_Rank": 11712
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Electronics  Communication Engineering (Rail Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5819,
   "Closing_Rank": 6158
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40082,
   "Closing_Rank": 74076
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59637,
   "Closing_Rank": 64579
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12113,
   "Closing_Rank": 13674
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13343,
   "Closing_Rank": 13343
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23138,
   "Closing_Rank": 28510
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22964,
   "Closing_Rank": 25695
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6237,
   "Closing_Rank": 13332
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13035,
   "Closing_Rank": 13035
 },
 {
   "Institute": "Gati Shakti Vishwavidyalaya Vadodara",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering ( Rail  Engineering) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4674,
   "Closing_Rank": 6102
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 63781,
   "Closing_Rank": 110858
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18411,
   "Closing_Rank": 18582
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37359,
   "Closing_Rank": 38628
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11599,
   "Closing_Rank": 16937
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6543,
   "Closing_Rank": 8041
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Computer Science  Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45526,
   "Closing_Rank": 79221
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Computer Science  Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13391,
   "Closing_Rank": 13688
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Computer Science  Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25235,
   "Closing_Rank": 29829
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Computer Science  Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12812,
   "Closing_Rank": 13932
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Computer Science  Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4613,
   "Closing_Rank": 6539
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 76789,
   "Closing_Rank": 92728
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4335,
   "Closing_Rank": 4335
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15576,
   "Closing_Rank": 16278
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31589,
   "Closing_Rank": 36796
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13538,
   "Closing_Rank": 16326
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech. - M. Tech in Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6685,
   "Closing_Rank": 7896
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech.- M. Tech in Metallurgical  Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 91148,
   "Closing_Rank": 111567
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech.- M. Tech in Metallurgical  Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18667,
   "Closing_Rank": 18876
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech.- M. Tech in Metallurgical  Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37570,
   "Closing_Rank": 39002
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech.- M. Tech in Metallurgical  Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16473,
   "Closing_Rank": 17172
 },
 {
   "Institute": "CU Jharkhand",
   "Academic_Program_Name": "Integrated B. Tech.- M. Tech in Metallurgical  Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6278,
   "Closing_Rank": 8144
 }
]
