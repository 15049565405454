export const IIIT =[
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3710,
   "Closing_Rank": 8044
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10050,
   "Closing_Rank": 11529
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 212,
   "Closing_Rank": 212
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1127,
   "Closing_Rank": 1365
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2013,
   "Closing_Rank": 2013
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2658,
   "Closing_Rank": 3625
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5669,
   "Closing_Rank": 6214
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 195,
   "Closing_Rank": 195
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1737,
   "Closing_Rank": 2195
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3246,
   "Closing_Rank": 3313
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67,
   "Closing_Rank": 67
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 746,
   "Closing_Rank": 1343
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1493,
   "Closing_Rank": 1493
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75,
   "Closing_Rank": 75
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10447,
   "Closing_Rank": 17195
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24344,
   "Closing_Rank": 25669
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1334,
   "Closing_Rank": 1334
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2740,
   "Closing_Rank": 2803
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4312,
   "Closing_Rank": 4312
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5855,
   "Closing_Rank": 6543
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10596,
   "Closing_Rank": 13502
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 973,
   "Closing_Rank": 973
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3776,
   "Closing_Rank": 5088
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4852,
   "Closing_Rank": 4852
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1508,
   "Closing_Rank": 1671
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9708,
   "Closing_Rank": 13577
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18625,
   "Closing_Rank": 22022
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 776,
   "Closing_Rank": 776
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 555,
   "Closing_Rank": 555
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2069,
   "Closing_Rank": 2370
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3731,
   "Closing_Rank": 3809
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4349,
   "Closing_Rank": 6241
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7705,
   "Closing_Rank": 10475
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 557,
   "Closing_Rank": 557
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2426,
   "Closing_Rank": 4293
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4280,
   "Closing_Rank": 5371
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1116,
   "Closing_Rank": 1981
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and M. Tech (IT) ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2568,
   "Closing_Rank": 2568
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13833,
   "Closing_Rank": 16856
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22303,
   "Closing_Rank": 24511
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1098,
   "Closing_Rank": 1098
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2615,
   "Closing_Rank": 2790
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4010,
   "Closing_Rank": 4010
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 219,
   "Closing_Rank": 219
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5953,
   "Closing_Rank": 7482
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10304,
   "Closing_Rank": 10838
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 746,
   "Closing_Rank": 746
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3595,
   "Closing_Rank": 4746
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5701,
   "Closing_Rank": 5701
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2239,
   "Closing_Rank": 2526
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Integrated B. Tech.(IT) and MBA ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and MBA)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2793,
   "Closing_Rank": 2793
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8643,
   "Closing_Rank": 11180
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16057,
   "Closing_Rank": 18094
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 719,
   "Closing_Rank": 719
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1724,
   "Closing_Rank": 1908
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3232,
   "Closing_Rank": 3232
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4634,
   "Closing_Rank": 4863
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7390,
   "Closing_Rank": 9429
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2706,
   "Closing_Rank": 3476
 },
 {
   "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology &amp; Management Gwalior",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1394,
   "Closing_Rank": 1494
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14413,
   "Closing_Rank": 27124
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29212,
   "Closing_Rank": 34478
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1997,
   "Closing_Rank": 2185
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4354,
   "Closing_Rank": 4855
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6523,
   "Closing_Rank": 6523
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8497,
   "Closing_Rank": 11440
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14792,
   "Closing_Rank": 15390
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1715,
   "Closing_Rank": 1715
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5471,
   "Closing_Rank": 7643
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10037,
   "Closing_Rank": 10142
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2830,
   "Closing_Rank": 3559
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4802,
   "Closing_Rank": 4802
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14863,
   "Closing_Rank": 24416
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25882,
   "Closing_Rank": 34423
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1261,
   "Closing_Rank": 1619
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2035,
   "Closing_Rank": 2035
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3896,
   "Closing_Rank": 4630
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5759,
   "Closing_Rank": 6604
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 229,
   "Closing_Rank": 229
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7053,
   "Closing_Rank": 10658
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13265,
   "Closing_Rank": 14625
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 671,
   "Closing_Rank": 1525
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3959,
   "Closing_Rank": 7363
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6501,
   "Closing_Rank": 8483
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2621,
   "Closing_Rank": 3475
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3681,
   "Closing_Rank": 3832
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25813,
   "Closing_Rank": 34167
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34794,
   "Closing_Rank": 36589
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 295,
   "Closing_Rank": 295
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5548,
   "Closing_Rank": 6098
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6775,
   "Closing_Rank": 6833
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12480,
   "Closing_Rank": 14779
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17748,
   "Closing_Rank": 18565
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1891,
   "Closing_Rank": 1891
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6950,
   "Closing_Rank": 8653
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9767,
   "Closing_Rank": 10738
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1655,
   "Closing_Rank": 3604
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT)Kota Rajasthan",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4075,
   "Closing_Rank": 4075
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8898,
   "Closing_Rank": 20821
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26461,
   "Closing_Rank": 30584
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 915,
   "Closing_Rank": 1082
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2453,
   "Closing_Rank": 2453
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3243,
   "Closing_Rank": 3551
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5082,
   "Closing_Rank": 5385
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5512,
   "Closing_Rank": 8445
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10414,
   "Closing_Rank": 13481
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1398,
   "Closing_Rank": 1399
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3874,
   "Closing_Rank": 5209
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4417,
   "Closing_Rank": 6963
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 141,
   "Closing_Rank": 141
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1335,
   "Closing_Rank": 2701
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3079,
   "Closing_Rank": 3264
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17226,
   "Closing_Rank": 30437
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33374,
   "Closing_Rank": 38176
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1332,
   "Closing_Rank": 2723
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4982,
   "Closing_Rank": 5528
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6177,
   "Closing_Rank": 6177
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8786,
   "Closing_Rank": 13518
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16034,
   "Closing_Rank": 17942
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2945,
   "Closing_Rank": 7079
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5484,
   "Closing_Rank": 8445
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3015,
   "Closing_Rank": 3531
 },
 {
   "Institute": "Indian Institute of Information Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3982,
   "Closing_Rank": 3982
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28211,
   "Closing_Rank": 39991
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35632,
   "Closing_Rank": 38130
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 293,
   "Closing_Rank": 1271
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6424,
   "Closing_Rank": 6978
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7343,
   "Closing_Rank": 7343
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13588,
   "Closing_Rank": 16367
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16308,
   "Closing_Rank": 18234
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 736,
   "Closing_Rank": 1432
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3847,
   "Closing_Rank": 9255
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6254,
   "Closing_Rank": 8992
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 263,
   "Closing_Rank": 263
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3499,
   "Closing_Rank": 4176
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4314,
   "Closing_Rank": 4314
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40334,
   "Closing_Rank": 43031
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40847,
   "Closing_Rank": 42026
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3291,
   "Closing_Rank": 3291
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7084,
   "Closing_Rank": 7241
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7628,
   "Closing_Rank": 7628
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16578,
   "Closing_Rank": 17077
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15369,
   "Closing_Rank": 15369
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6259,
   "Closing_Rank": 9708
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10443,
   "Closing_Rank": 10443
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kalyani West Bengal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4204,
   "Closing_Rank": 4901
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18772,
   "Closing_Rank": 28994
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 629,
   "Closing_Rank": 1922
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4618,
   "Closing_Rank": 5038
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8650,
   "Closing_Rank": 12954
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1074,
   "Closing_Rank": 1382
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4009,
   "Closing_Rank": 8043
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3181,
   "Closing_Rank": 4306
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25414,
   "Closing_Rank": 31601
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2644,
   "Closing_Rank": 2644
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5041,
   "Closing_Rank": 5264
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12987,
   "Closing_Rank": 14380
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1279,
   "Closing_Rank": 1279
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7401,
   "Closing_Rank": 9056
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "CSE ( Data Science &amp; Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4101,
   "Closing_Rank": 4443
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26120,
   "Closing_Rank": 32173
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1547,
   "Closing_Rank": 1547
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5249,
   "Closing_Rank": 5444
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13134,
   "Closing_Rank": 14394
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7943,
   "Closing_Rank": 8919
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad Sonepat Haryana",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3806,
   "Closing_Rank": 4544
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17649,
   "Closing_Rank": 28335
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28756,
   "Closing_Rank": 28910
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 709,
   "Closing_Rank": 1505
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4629,
   "Closing_Rank": 5056
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5436,
   "Closing_Rank": 5436
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8416,
   "Closing_Rank": 12090
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 966,
   "Closing_Rank": 966
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3954,
   "Closing_Rank": 6890
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3614,
   "Closing_Rank": 3981
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24797,
   "Closing_Rank": 31872
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 484,
   "Closing_Rank": 484
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5543,
   "Closing_Rank": 5586
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10451,
   "Closing_Rank": 13111
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6991,
   "Closing_Rank": 8712
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4542,
   "Closing_Rank": 4580
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27980,
   "Closing_Rank": 30485
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5311,
   "Closing_Rank": 5484
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11265,
   "Closing_Rank": 13073
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7494,
   "Closing_Rank": 7990
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4490,
   "Closing_Rank": 4605
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28230,
   "Closing_Rank": 37935
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37389,
   "Closing_Rank": 37389
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3688,
   "Closing_Rank": 3688
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6161,
   "Closing_Rank": 6728
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12339,
   "Closing_Rank": 16185
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17889,
   "Closing_Rank": 17889
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7166,
   "Closing_Rank": 9737
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10873,
   "Closing_Rank": 10873
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3118,
   "Closing_Rank": 4987
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29569,
   "Closing_Rank": 33023
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30920,
   "Closing_Rank": 30920
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1717,
   "Closing_Rank": 1717
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5420,
   "Closing_Rank": 6044
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12245,
   "Closing_Rank": 14170
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17018,
   "Closing_Rank": 17018
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5026,
   "Closing_Rank": 9195
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3694,
   "Closing_Rank": 4937
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Una Himachal Pradesh",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4365,
   "Closing_Rank": 4365
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12583,
   "Closing_Rank": 28509
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25567,
   "Closing_Rank": 32036
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 710,
   "Closing_Rank": 710
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4532,
   "Closing_Rank": 4983
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8346,
   "Closing_Rank": 11996
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12461,
   "Closing_Rank": 12461
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5423,
   "Closing_Rank": 7771
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2718,
   "Closing_Rank": 3902
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15850,
   "Closing_Rank": 26444
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11742,
   "Closing_Rank": 30676
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1162,
   "Closing_Rank": 1335
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4199,
   "Closing_Rank": 4857
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5250,
   "Closing_Rank": 5599
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 295,
   "Closing_Rank": 295
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7089,
   "Closing_Rank": 11112
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10622,
   "Closing_Rank": 12540
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 555,
   "Closing_Rank": 882
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4696,
   "Closing_Rank": 8121
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7118,
   "Closing_Rank": 7928
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 184,
   "Closing_Rank": 184
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2438,
   "Closing_Rank": 3930
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2895,
   "Closing_Rank": 2895
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27898,
   "Closing_Rank": 36490
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31852,
   "Closing_Rank": 38333
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1774,
   "Closing_Rank": 1774
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6078,
   "Closing_Rank": 6535
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11733,
   "Closing_Rank": 14065
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12833,
   "Closing_Rank": 13601
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1333,
   "Closing_Rank": 1333
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6520,
   "Closing_Rank": 9656
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10281,
   "Closing_Rank": 10281
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Sri City Chittoor",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3861,
   "Closing_Rank": 4141
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10274,
   "Closing_Rank": 21838
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23161,
   "Closing_Rank": 30490
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1383,
   "Closing_Rank": 1946
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 681,
   "Closing_Rank": 681
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3655,
   "Closing_Rank": 4092
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5021,
   "Closing_Rank": 6025
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 390,
   "Closing_Rank": 390
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6971,
   "Closing_Rank": 9909
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8473,
   "Closing_Rank": 15791
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1113,
   "Closing_Rank": 1205
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3805,
   "Closing_Rank": 7532
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5936,
   "Closing_Rank": 7556
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1345,
   "Closing_Rank": 3451
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2494,
   "Closing_Rank": 2926
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21896,
   "Closing_Rank": 23624
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31434,
   "Closing_Rank": 33467
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2100,
   "Closing_Rank": 2100
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4101,
   "Closing_Rank": 4255
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6420,
   "Closing_Rank": 6420
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8800,
   "Closing_Rank": 11316
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15814,
   "Closing_Rank": 16080
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7544,
   "Closing_Rank": 8179
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6596,
   "Closing_Rank": 6596
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Vadodara Gujrat",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2882,
   "Closing_Rank": 3507
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5569,
   "Closing_Rank": 7758
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10761,
   "Closing_Rank": 14319
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 337,
   "Closing_Rank": 344
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 487,
   "Closing_Rank": 487
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1063,
   "Closing_Rank": 1376
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2165,
   "Closing_Rank": 2348
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 92,
   "Closing_Rank": 92
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2428,
   "Closing_Rank": 3558
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6185,
   "Closing_Rank": 7281
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 193,
   "Closing_Rank": 193
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1144,
   "Closing_Rank": 2402
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2564,
   "Closing_Rank": 4416
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 88,
   "Closing_Rank": 88
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 910,
   "Closing_Rank": 1310
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2680,
   "Closing_Rank": 2784
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1581,
   "Closing_Rank": 5202
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4142,
   "Closing_Rank": 9457
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 229,
   "Closing_Rank": 284
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1011,
   "Closing_Rank": 1011
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 724,
   "Closing_Rank": 986
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1389,
   "Closing_Rank": 1801
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52,
   "Closing_Rank": 52
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1119,
   "Closing_Rank": 2509
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2473,
   "Closing_Rank": 5104
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 174,
   "Closing_Rank": 261
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 502,
   "Closing_Rank": 502
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 609,
   "Closing_Rank": 1562
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1036,
   "Closing_Rank": 3003
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27,
   "Closing_Rank": 44
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 441,
   "Closing_Rank": 888
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 962,
   "Closing_Rank": 1879
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4252,
   "Closing_Rank": 5392
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9159,
   "Closing_Rank": 9754
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 280,
   "Closing_Rank": 280
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 887,
   "Closing_Rank": 994
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2009,
   "Closing_Rank": 2009
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1950,
   "Closing_Rank": 2639
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5184,
   "Closing_Rank": 5423
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 278,
   "Closing_Rank": 278
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1023,
   "Closing_Rank": 1695
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 836,
   "Closing_Rank": 836
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 916,
   "Closing_Rank": 952
 },
 {
   "Institute": "Indian Institute of Information Technology Allahabad",
   "Academic_Program_Name": "Information Technology-Business Informatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1893,
   "Closing_Rank": 1893
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24530,
   "Closing_Rank": 39290
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6555,
   "Closing_Rank": 7490
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11596,
   "Closing_Rank": 15798
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1244,
   "Closing_Rank": 1244
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6697,
   "Closing_Rank": 8025
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2782,
   "Closing_Rank": 3122
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25389,
   "Closing_Rank": 32117
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33257,
   "Closing_Rank": 40124
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2109,
   "Closing_Rank": 2109
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5569,
   "Closing_Rank": 5662
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10937,
   "Closing_Rank": 11387
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14391,
   "Closing_Rank": 14391
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5458,
   "Closing_Rank": 6619
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8319,
   "Closing_Rank": 8319
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2960,
   "Closing_Rank": 2960
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20405,
   "Closing_Rank": 27848
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27612,
   "Closing_Rank": 33349
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2146,
   "Closing_Rank": 2146
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5284,
   "Closing_Rank": 6451
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6754,
   "Closing_Rank": 6754
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9175,
   "Closing_Rank": 11284
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10683,
   "Closing_Rank": 10683
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5224,
   "Closing_Rank": 6008
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2651,
   "Closing_Rank": 2651
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3518,
   "Closing_Rank": 3518
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7422,
   "Closing_Rank": 18654
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11864,
   "Closing_Rank": 19604
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 655,
   "Closing_Rank": 655
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 571,
   "Closing_Rank": 571
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2862,
   "Closing_Rank": 3058
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3113,
   "Closing_Rank": 3215
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 173,
   "Closing_Rank": 173
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5428,
   "Closing_Rank": 7575
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5188,
   "Closing_Rank": 9420
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 265,
   "Closing_Rank": 265
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2162,
   "Closing_Rank": 4366
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3179,
   "Closing_Rank": 5547
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1476,
   "Closing_Rank": 2146
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1976,
   "Closing_Rank": 1976
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19308,
   "Closing_Rank": 22849
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21711,
   "Closing_Rank": 26500
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 866,
   "Closing_Rank": 866
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3639,
   "Closing_Rank": 4185
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5384,
   "Closing_Rank": 5384
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6828,
   "Closing_Rank": 8562
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12015,
   "Closing_Rank": 13194
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 594,
   "Closing_Rank": 594
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3794,
   "Closing_Rank": 5281
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5681,
   "Closing_Rank": 6597
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 302,
   "Closing_Rank": 302
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2219,
   "Closing_Rank": 2375
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2683,
   "Closing_Rank": 2683
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11923,
   "Closing_Rank": 18702
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16255,
   "Closing_Rank": 22163
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 436,
   "Closing_Rank": 436
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3090,
   "Closing_Rank": 3188
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3765,
   "Closing_Rank": 3765
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6221,
   "Closing_Rank": 7850
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10721,
   "Closing_Rank": 11166
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 385,
   "Closing_Rank": 385
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4092,
   "Closing_Rank": 4700
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5862,
   "Closing_Rank": 5862
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1662,
   "Closing_Rank": 2148
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Computer Science and Engineering with Major in Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2009,
   "Closing_Rank": 2009
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19426,
   "Closing_Rank": 27634
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23341,
   "Closing_Rank": 27644
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 859,
   "Closing_Rank": 1007
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 654,
   "Closing_Rank": 654
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4433,
   "Closing_Rank": 5126
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4954,
   "Closing_Rank": 5652
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 186,
   "Closing_Rank": 186
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7612,
   "Closing_Rank": 10157
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10230,
   "Closing_Rank": 13591
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 615,
   "Closing_Rank": 615
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4952,
   "Closing_Rank": 6140
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4928,
   "Closing_Rank": 6911
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1836,
   "Closing_Rank": 2609
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2528,
   "Closing_Rank": 2575
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19452,
   "Closing_Rank": 43513
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45347,
   "Closing_Rank": 47534
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2030,
   "Closing_Rank": 2030
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2249,
   "Closing_Rank": 2249
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7361,
   "Closing_Rank": 7902
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9275,
   "Closing_Rank": 9672
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12644,
   "Closing_Rank": 16443
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15779,
   "Closing_Rank": 19279
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1186,
   "Closing_Rank": 1186
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6455,
   "Closing_Rank": 8018
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9024,
   "Closing_Rank": 10505
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2977,
   "Closing_Rank": 3320
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3247,
   "Closing_Rank": 3247
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31783,
   "Closing_Rank": 48128
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44987,
   "Closing_Rank": 56354
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1828,
   "Closing_Rank": 1828
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8145,
   "Closing_Rank": 8656
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10224,
   "Closing_Rank": 10224
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14059,
   "Closing_Rank": 17165
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18808,
   "Closing_Rank": 19374
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1173,
   "Closing_Rank": 1173
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8144,
   "Closing_Rank": 9063
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9853,
   "Closing_Rank": 9853
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3599,
   "Closing_Rank": 3652
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kancheepuram",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4853,
   "Closing_Rank": 4853
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8150,
   "Closing_Rank": 14757
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16151,
   "Closing_Rank": 23022
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 425,
   "Closing_Rank": 974
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1402,
   "Closing_Rank": 1402
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2212,
   "Closing_Rank": 2443
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3726,
   "Closing_Rank": 4150
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 179,
   "Closing_Rank": 179
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3671,
   "Closing_Rank": 5785
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7265,
   "Closing_Rank": 9914
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 380,
   "Closing_Rank": 497
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 728,
   "Closing_Rank": 728
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 863,
   "Closing_Rank": 3905
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3656,
   "Closing_Rank": 5201
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 107,
   "Closing_Rank": 107
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1222,
   "Closing_Rank": 2003
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2205,
   "Closing_Rank": 2610
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12242,
   "Closing_Rank": 19106
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18506,
   "Closing_Rank": 27458
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1190,
   "Closing_Rank": 1279
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1925,
   "Closing_Rank": 1925
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2975,
   "Closing_Rank": 3231
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4403,
   "Closing_Rank": 4699
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 284,
   "Closing_Rank": 284
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5831,
   "Closing_Rank": 7514
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10550,
   "Closing_Rank": 12756
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 768,
   "Closing_Rank": 768
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3737,
   "Closing_Rank": 4945
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5425,
   "Closing_Rank": 7356
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1994,
   "Closing_Rank": 2464
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2894,
   "Closing_Rank": 3108
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28690,
   "Closing_Rank": 41353
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43819,
   "Closing_Rank": 50222
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2412,
   "Closing_Rank": 2412
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6646,
   "Closing_Rank": 7237
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8257,
   "Closing_Rank": 8257
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11867,
   "Closing_Rank": 16157
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20153,
   "Closing_Rank": 20662
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5818,
   "Closing_Rank": 7511
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5674,
   "Closing_Rank": 9237
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2739,
   "Closing_Rank": 2964
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4155,
   "Closing_Rank": 4155
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41026,
   "Closing_Rank": 49439
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43821,
   "Closing_Rank": 55789
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3322,
   "Closing_Rank": 3322
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8065,
   "Closing_Rank": 8414
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10027,
   "Closing_Rank": 10027
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16022,
   "Closing_Rank": 18176
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21409,
   "Closing_Rank": 22444
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7825,
   "Closing_Rank": 9556
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11116,
   "Closing_Rank": 11422
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3466,
   "Closing_Rank": 3818
 },
 {
   "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology Design &amp; Manufacture Jabalpur",
   "Academic_Program_Name": "Smart Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4862,
   "Closing_Rank": 4862
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40493,
   "Closing_Rank": 50509
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44722,
   "Closing_Rank": 52943
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8163,
   "Closing_Rank": 8512
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9021,
   "Closing_Rank": 9469
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15819,
   "Closing_Rank": 18624
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20233,
   "Closing_Rank": 21886
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9487,
   "Closing_Rank": 10707
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10674,
   "Closing_Rank": 11636
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4051,
   "Closing_Rank": 4697
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5014,
   "Closing_Rank": 5132
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37547,
   "Closing_Rank": 47179
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7705,
   "Closing_Rank": 8361
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16738,
   "Closing_Rank": 18235
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9058,
   "Closing_Rank": 10255
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4416,
   "Closing_Rank": 4768
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46236,
   "Closing_Rank": 55833
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 57045,
   "Closing_Rank": 67091
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9143,
   "Closing_Rank": 9513
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10977,
   "Closing_Rank": 11091
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17234,
   "Closing_Rank": 19882
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22255,
   "Closing_Rank": 23856
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10383,
   "Closing_Rank": 11358
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10921,
   "Closing_Rank": 12681
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4614,
   "Closing_Rank": 5242
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5118,
   "Closing_Rank": 5504
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45745,
   "Closing_Rank": 56550
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9255,
   "Closing_Rank": 9658
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18010,
   "Closing_Rank": 20128
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9682,
   "Closing_Rank": 11176
 },
 {
   "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4799,
   "Closing_Rank": 5349
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10666,
   "Closing_Rank": 29303
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15479,
   "Closing_Rank": 32237
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 827,
   "Closing_Rank": 827
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1710,
   "Closing_Rank": 1710
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4678,
   "Closing_Rank": 5173
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6191,
   "Closing_Rank": 6443
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7941,
   "Closing_Rank": 11994
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13035,
   "Closing_Rank": 15906
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1360,
   "Closing_Rank": 5985
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6241,
   "Closing_Rank": 8427
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1400,
   "Closing_Rank": 2170
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3086,
   "Closing_Rank": 3293
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11247,
   "Closing_Rank": 36702
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38757,
   "Closing_Rank": 41732
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1699,
   "Closing_Rank": 1699
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6227,
   "Closing_Rank": 6710
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7134,
   "Closing_Rank": 7703
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10675,
   "Closing_Rank": 15120
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16099,
   "Closing_Rank": 17553
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1104,
   "Closing_Rank": 1104
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1178,
   "Closing_Rank": 1178
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5644,
   "Closing_Rank": 7890
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8674,
   "Closing_Rank": 9822
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2387,
   "Closing_Rank": 3408
 },
 {
   "Institute": "Indian Institute of Information Technology Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1183,
   "Closing_Rank": 3853
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7341,
   "Closing_Rank": 9824
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10857,
   "Closing_Rank": 13117
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 482,
   "Closing_Rank": 482
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1459,
   "Closing_Rank": 1639
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2778,
   "Closing_Rank": 4262
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6458,
   "Closing_Rank": 7118
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 403,
   "Closing_Rank": 403
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2211,
   "Closing_Rank": 3139
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4672,
   "Closing_Rank": 5463
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1202,
   "Closing_Rank": 1776
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2783,
   "Closing_Rank": 2783
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6127,
   "Closing_Rank": 9384
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9235,
   "Closing_Rank": 14286
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 230,
   "Closing_Rank": 230
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1422,
   "Closing_Rank": 1575
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2265,
   "Closing_Rank": 2265
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2935,
   "Closing_Rank": 4064
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7134,
   "Closing_Rank": 8046
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 264,
   "Closing_Rank": 264
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1362,
   "Closing_Rank": 3240
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5200,
   "Closing_Rank": 5200
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 170,
   "Closing_Rank": 170
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 922,
   "Closing_Rank": 1751
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1822,
   "Closing_Rank": 1822
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9303,
   "Closing_Rank": 11380
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17888,
   "Closing_Rank": 18827
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 528,
   "Closing_Rank": 528
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1666,
   "Closing_Rank": 1857
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2928,
   "Closing_Rank": 2928
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3472,
   "Closing_Rank": 4884
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8180,
   "Closing_Rank": 8268
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 437,
   "Closing_Rank": 437
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1035,
   "Closing_Rank": 1035
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2599,
   "Closing_Rank": 3888
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5190,
   "Closing_Rank": 6157
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2110,
   "Closing_Rank": 2255
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Computer Science and Business ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3442,
   "Closing_Rank": 3442
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5814,
   "Closing_Rank": 11494
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16257,
   "Closing_Rank": 18522
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 648,
   "Closing_Rank": 648
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1927,
   "Closing_Rank": 1927
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1786,
   "Closing_Rank": 2055
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3019,
   "Closing_Rank": 3019
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4116,
   "Closing_Rank": 4959
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8960,
   "Closing_Rank": 9152
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 630,
   "Closing_Rank": 630
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3523,
   "Closing_Rank": 4019
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5519,
   "Closing_Rank": 6111
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2061,
   "Closing_Rank": 2178
 },
 {
   "Institute": "Indian Institute of Information Technology Lucknow",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3716,
   "Closing_Rank": 3716
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24106,
   "Closing_Rank": 37419
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20028,
   "Closing_Rank": 39855
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 601,
   "Closing_Rank": 1246
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6032,
   "Closing_Rank": 6763
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6713,
   "Closing_Rank": 7570
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 308,
   "Closing_Rank": 308
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11172,
   "Closing_Rank": 15972
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14252,
   "Closing_Rank": 17907
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 519,
   "Closing_Rank": 785
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2716,
   "Closing_Rank": 9390
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6796,
   "Closing_Rank": 9769
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 99,
   "Closing_Rank": 99
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2789,
   "Closing_Rank": 3974
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1812,
   "Closing_Rank": 2087
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28213,
   "Closing_Rank": 39077
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37154,
   "Closing_Rank": 41400
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 448,
   "Closing_Rank": 990
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6641,
   "Closing_Rank": 6996
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6913,
   "Closing_Rank": 7668
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 223,
   "Closing_Rank": 373
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12624,
   "Closing_Rank": 16643
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17328,
   "Closing_Rank": 18574
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1135,
   "Closing_Rank": 1135
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8720,
   "Closing_Rank": 9861
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8124,
   "Closing_Rank": 10212
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4160,
   "Closing_Rank": 4447
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3514,
   "Closing_Rank": 3514
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11215,
   "Closing_Rank": 41143
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41496,
   "Closing_Rank": 43215
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1253,
   "Closing_Rank": 1378
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7001,
   "Closing_Rank": 7224
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6983,
   "Closing_Rank": 7285
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14136,
   "Closing_Rank": 16841
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18551,
   "Closing_Rank": 18957
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 635,
   "Closing_Rank": 635
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6091,
   "Closing_Rank": 10252
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10261,
   "Closing_Rank": 10883
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 144,
   "Closing_Rank": 144
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2967,
   "Closing_Rank": 4685
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3771,
   "Closing_Rank": 3771
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Dharwad ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 92,
   "Closing_Rank": 92
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20771,
   "Closing_Rank": 36999
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35334,
   "Closing_Rank": 38027
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 578,
   "Closing_Rank": 578
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5965,
   "Closing_Rank": 6561
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6571,
   "Closing_Rank": 6571
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12134,
   "Closing_Rank": 14516
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13672,
   "Closing_Rank": 13940
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 412,
   "Closing_Rank": 412
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4717,
   "Closing_Rank": 6190
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6661,
   "Closing_Rank": 6661
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2155,
   "Closing_Rank": 2934
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3498,
   "Closing_Rank": 3498
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21428,
   "Closing_Rank": 32761
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24883,
   "Closing_Rank": 33001
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 491,
   "Closing_Rank": 491
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5379,
   "Closing_Rank": 5862
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5702,
   "Closing_Rank": 5702
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9114,
   "Closing_Rank": 13128
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11429,
   "Closing_Rank": 12828
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 317,
   "Closing_Rank": 317
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4291,
   "Closing_Rank": 5596
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5148,
   "Closing_Rank": 5148
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2046,
   "Closing_Rank": 2305
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2129,
   "Closing_Rank": 2129
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31320,
   "Closing_Rank": 37958
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6205,
   "Closing_Rank": 6378
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13283,
   "Closing_Rank": 15367
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 546,
   "Closing_Rank": 546
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6318,
   "Closing_Rank": 6939
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1814,
   "Closing_Rank": 1814
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40614,
   "Closing_Rank": 42606
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2076,
   "Closing_Rank": 2076
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6896,
   "Closing_Rank": 7207
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14604,
   "Closing_Rank": 16891
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7122,
   "Closing_Rank": 7918
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3357,
   "Closing_Rank": 3398
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33048,
   "Closing_Rank": 41985
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35056,
   "Closing_Rank": 37555
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 926,
   "Closing_Rank": 926
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6814,
   "Closing_Rank": 7053
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7043,
   "Closing_Rank": 7043
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13258,
   "Closing_Rank": 16831
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13679,
   "Closing_Rank": 13679
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7044,
   "Closing_Rank": 7695
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7776,
   "Closing_Rank": 7776
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2713,
   "Closing_Rank": 3301
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39952,
   "Closing_Rank": 50444
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8584,
   "Closing_Rank": 8770
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14877,
   "Closing_Rank": 18228
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6428,
   "Closing_Rank": 9460
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3582,
   "Closing_Rank": 3824
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42489,
   "Closing_Rank": 49886
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50261,
   "Closing_Rank": 52652
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2884,
   "Closing_Rank": 2884
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8147,
   "Closing_Rank": 8530
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17006,
   "Closing_Rank": 18149
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19787,
   "Closing_Rank": 19787
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1477,
   "Closing_Rank": 1477
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7583,
   "Closing_Rank": 8903
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10907,
   "Closing_Rank": 10907
 },
 {
   "Institute": "Indian Institute of Information Technology Design &amp; Manufacturing Kurnool Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering with specialization in Design and Manufacturing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3549,
   "Closing_Rank": 3817
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19546,
   "Closing_Rank": 35996
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19945,
   "Closing_Rank": 40151
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1349,
   "Closing_Rank": 2524
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1475,
   "Closing_Rank": 1475
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5795,
   "Closing_Rank": 6586
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6528,
   "Closing_Rank": 7599
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 378,
   "Closing_Rank": 378
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10312,
   "Closing_Rank": 14629
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12122,
   "Closing_Rank": 15875
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1207,
   "Closing_Rank": 1509
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1136,
   "Closing_Rank": 1136
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5635,
   "Closing_Rank": 9967
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6953,
   "Closing_Rank": 10470
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2355,
   "Closing_Rank": 4217
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3526,
   "Closing_Rank": 4454
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12989,
   "Closing_Rank": 32727
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18954,
   "Closing_Rank": 33437
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 961,
   "Closing_Rank": 961
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5287,
   "Closing_Rank": 5717
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6782,
   "Closing_Rank": 6782
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9265,
   "Closing_Rank": 12084
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11290,
   "Closing_Rank": 12777
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 488,
   "Closing_Rank": 488
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3545,
   "Closing_Rank": 8046
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9185,
   "Closing_Rank": 9891
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2806,
   "Closing_Rank": 3731
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4576,
   "Closing_Rank": 4576
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25771,
   "Closing_Rank": 36976
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38818,
   "Closing_Rank": 41306
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1597,
   "Closing_Rank": 1597
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6057,
   "Closing_Rank": 6670
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7650,
   "Closing_Rank": 7650
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12242,
   "Closing_Rank": 14983
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15371,
   "Closing_Rank": 15932
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 931,
   "Closing_Rank": 931
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6998,
   "Closing_Rank": 9327
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9433,
   "Closing_Rank": 10065
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3378,
   "Closing_Rank": 4219
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Computer Science and Engineering with specialization in Cyber Security",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4845,
   "Closing_Rank": 4845
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19045,
   "Closing_Rank": 39875
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34914,
   "Closing_Rank": 40028
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2395,
   "Closing_Rank": 2395
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6530,
   "Closing_Rank": 6912
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7621,
   "Closing_Rank": 7621
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11792,
   "Closing_Rank": 15467
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15340,
   "Closing_Rank": 16560
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1696,
   "Closing_Rank": 1696
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9377,
   "Closing_Rank": 10131
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9468,
   "Closing_Rank": 10207
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3809,
   "Closing_Rank": 4152
 },
 {
   "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4659,
   "Closing_Rank": 4659
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20517,
   "Closing_Rank": 32429
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1044,
   "Closing_Rank": 1538
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5259,
   "Closing_Rank": 5894
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 255,
   "Closing_Rank": 255
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9170,
   "Closing_Rank": 13690
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1377,
   "Closing_Rank": 1588
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6142,
   "Closing_Rank": 8439
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2581,
   "Closing_Rank": 4172
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25552,
   "Closing_Rank": 31336
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1514,
   "Closing_Rank": 1514
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5092,
   "Closing_Rank": 5465
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8903,
   "Closing_Rank": 13354
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7471,
   "Closing_Rank": 8131
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4267,
   "Closing_Rank": 4382
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30138,
   "Closing_Rank": 40194
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2141,
   "Closing_Rank": 2651
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6770,
   "Closing_Rank": 7045
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11678,
   "Closing_Rank": 16809
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1355,
   "Closing_Rank": 1355
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9064,
   "Closing_Rank": 10111
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4567,
   "Closing_Rank": 4995
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35309,
   "Closing_Rank": 39295
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3250,
   "Closing_Rank": 3250
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6893,
   "Closing_Rank": 7047
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15263,
   "Closing_Rank": 16460
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1662,
   "Closing_Rank": 1662
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7220,
   "Closing_Rank": 9990
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
   "Academic_Program_Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things)",
   "duration": "4 Years",
   "Degree_Type": " B. Tech / B. Tech (Hons.))",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3404,
   "Closing_Rank": 4691
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17234,
   "Closing_Rank": 27356
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 830,
   "Closing_Rank": 914
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4340,
   "Closing_Rank": 5189
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 165,
   "Closing_Rank": 165
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7541,
   "Closing_Rank": 11755
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 469,
   "Closing_Rank": 620
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3999,
   "Closing_Rank": 8228
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 211,
   "Closing_Rank": 211
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2917,
   "Closing_Rank": 3711
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18517,
   "Closing_Rank": 27105
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 986,
   "Closing_Rank": 986
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4412,
   "Closing_Rank": 4984
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7990,
   "Closing_Rank": 11474
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 510,
   "Closing_Rank": 510
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4086,
   "Closing_Rank": 7396
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2412,
   "Closing_Rank": 3381
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20112,
   "Closing_Rank": 28763
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1008,
   "Closing_Rank": 1008
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5271,
   "Closing_Rank": 5560
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8121,
   "Closing_Rank": 12500
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 741,
   "Closing_Rank": 741
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4075,
   "Closing_Rank": 8418
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Data Science and Analytics) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2759,
   "Closing_Rank": 3395
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23181,
   "Closing_Rank": 30403
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1022,
   "Closing_Rank": 1070
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5014,
   "Closing_Rank": 5672
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10565,
   "Closing_Rank": 13132
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 987,
   "Closing_Rank": 987
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6209,
   "Closing_Rank": 8541
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3927,
   "Closing_Rank": 4174
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27541,
   "Closing_Rank": 37530
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 559,
   "Closing_Rank": 2341
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6084,
   "Closing_Rank": 6658
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 383,
   "Closing_Rank": 383
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10606,
   "Closing_Rank": 15792
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1694,
   "Closing_Rank": 1808
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8190,
   "Closing_Rank": 9718
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3708,
   "Closing_Rank": 4706
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22824,
   "Closing_Rank": 35608
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1558,
   "Closing_Rank": 1558
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6196,
   "Closing_Rank": 6585
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12080,
   "Closing_Rank": 15472
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1123,
   "Closing_Rank": 1607
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4463,
   "Closing_Rank": 9588
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering (Internet of Things) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 536,
   "Closing_Rank": 4628
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9557,
   "Closing_Rank": 18252
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18487,
   "Closing_Rank": 26196
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 460,
   "Closing_Rank": 856
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 396,
   "Closing_Rank": 396
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2859,
   "Closing_Rank": 3401
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4219,
   "Closing_Rank": 4864
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 247,
   "Closing_Rank": 247
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4909,
   "Closing_Rank": 8377
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8888,
   "Closing_Rank": 12521
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 665,
   "Closing_Rank": 775
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 454,
   "Closing_Rank": 454
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2845,
   "Closing_Rank": 5476
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4061,
   "Closing_Rank": 6155
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93,
   "Closing_Rank": 129
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2019,
   "Closing_Rank": 3030
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1906,
   "Closing_Rank": 3331
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17293,
   "Closing_Rank": 23592
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26570,
   "Closing_Rank": 30741
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 973,
   "Closing_Rank": 1068
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3844,
   "Closing_Rank": 4365
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5155,
   "Closing_Rank": 5572
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8044,
   "Closing_Rank": 11092
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13133,
   "Closing_Rank": 14755
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 925,
   "Closing_Rank": 925
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5558,
   "Closing_Rank": 7873
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8403,
   "Closing_Rank": 8827
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2587,
   "Closing_Rank": 3346
 },
 {
   "Institute": "Indian Institute of Information Technology (IIIT) Pune",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3394,
   "Closing_Rank": 3394
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24375,
   "Closing_Rank": 39481
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32777,
   "Closing_Rank": 41440
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6352,
   "Closing_Rank": 6738
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7432,
   "Closing_Rank": 7698
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11807,
   "Closing_Rank": 15743
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13124,
   "Closing_Rank": 17805
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5416,
   "Closing_Rank": 9116
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8195,
   "Closing_Rank": 9129
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3542,
   "Closing_Rank": 4956
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4662,
   "Closing_Rank": 4662
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37978,
   "Closing_Rank": 42488
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42934,
   "Closing_Rank": 43392
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7080,
   "Closing_Rank": 7193
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8638,
   "Closing_Rank": 8638
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16016,
   "Closing_Rank": 17203
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16492,
   "Closing_Rank": 16492
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9438,
   "Closing_Rank": 10168
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10406,
   "Closing_Rank": 11219
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4516,
   "Closing_Rank": 5101
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5089,
   "Closing_Rank": 5089
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22083,
   "Closing_Rank": 39975
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6423,
   "Closing_Rank": 6423
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7770,
   "Closing_Rank": 7770
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15752,
   "Closing_Rank": 16801
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18225,
   "Closing_Rank": 18225
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6856,
   "Closing_Rank": 10243
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4652,
   "Closing_Rank": 5028
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5003,
   "Closing_Rank": 5003
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42025,
   "Closing_Rank": 47663
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45233,
   "Closing_Rank": 48683
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7780,
   "Closing_Rank": 7926
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8329,
   "Closing_Rank": 8329
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17081,
   "Closing_Rank": 18110
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20225,
   "Closing_Rank": 21609
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10318,
   "Closing_Rank": 10529
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11213,
   "Closing_Rank": 11213
 },
 {
   "Institute": "Indian Institute of Information Technology Bhagalpur",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4027,
   "Closing_Rank": 5194
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15159,
   "Closing_Rank": 23999
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1111,
   "Closing_Rank": 1581
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3789,
   "Closing_Rank": 4572
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7232,
   "Closing_Rank": 10997
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 725,
   "Closing_Rank": 755
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4374,
   "Closing_Rank": 6860
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3159,
   "Closing_Rank": 3841
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11406,
   "Closing_Rank": 23637
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1920,
   "Closing_Rank": 1920
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3704,
   "Closing_Rank": 3784
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 269,
   "Closing_Rank": 269
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7573,
   "Closing_Rank": 10710
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5420,
   "Closing_Rank": 5954
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Artificial Intelligence) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3068,
   "Closing_Rank": 3097
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Physical System) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24564,
   "Closing_Rank": 30659
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Physical System) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4947,
   "Closing_Rank": 5121
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Physical System) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11484,
   "Closing_Rank": 12626
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Physical System) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5646,
   "Closing_Rank": 7342
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Physical System) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3915,
   "Closing_Rank": 3996
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16823,
   "Closing_Rank": 27858
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4527,
   "Closing_Rank": 4877
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8315,
   "Closing_Rank": 11432
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 983,
   "Closing_Rank": 983
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6416,
   "Closing_Rank": 6900
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Cyber Security) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3759,
   "Closing_Rank": 3997
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12196,
   "Closing_Rank": 24335
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 903,
   "Closing_Rank": 903
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3876,
   "Closing_Rank": 4749
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7241,
   "Closing_Rank": 10496
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3604,
   "Closing_Rank": 6122
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering (Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2612,
   "Closing_Rank": 2816
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20535,
   "Closing_Rank": 35355
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2648,
   "Closing_Rank": 2844
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5721,
   "Closing_Rank": 6367
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10489,
   "Closing_Rank": 14641
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7232,
   "Closing_Rank": 9202
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2766,
   "Closing_Rank": 4749
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15057,
   "Closing_Rank": 30065
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1531,
   "Closing_Rank": 1726
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4800,
   "Closing_Rank": 5347
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9161,
   "Closing_Rank": 12751
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 999,
   "Closing_Rank": 1648
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6198,
   "Closing_Rank": 8371
 },
 {
   "Institute": "Indian Institute of Information Technology Bhopal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2688,
   "Closing_Rank": 4354
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12117,
   "Closing_Rank": 25108
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31653,
   "Closing_Rank": 33933
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 971,
   "Closing_Rank": 1644
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4192,
   "Closing_Rank": 4627
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5445,
   "Closing_Rank": 5947
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8876,
   "Closing_Rank": 11302
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13447,
   "Closing_Rank": 15434
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 846,
   "Closing_Rank": 846
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3175,
   "Closing_Rank": 7837
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8026,
   "Closing_Rank": 8505
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2114,
   "Closing_Rank": 3112
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3605,
   "Closing_Rank": 3605
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22702,
   "Closing_Rank": 34263
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32016,
   "Closing_Rank": 35609
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2722,
   "Closing_Rank": 2811
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5827,
   "Closing_Rank": 6391
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7177,
   "Closing_Rank": 7363
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13335,
   "Closing_Rank": 15703
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15751,
   "Closing_Rank": 16798
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1608,
   "Closing_Rank": 1608
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5294,
   "Closing_Rank": 8904
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9166,
   "Closing_Rank": 9986
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2721,
   "Closing_Rank": 3755
 },
 {
   "Institute": "Indian Institute of Information Technology Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4558,
   "Closing_Rank": 4558
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23898,
   "Closing_Rank": 39356
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30606,
   "Closing_Rank": 44100
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6602,
   "Closing_Rank": 6856
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7848,
   "Closing_Rank": 7848
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12044,
   "Closing_Rank": 16297
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18037,
   "Closing_Rank": 19570
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4576,
   "Closing_Rank": 9014
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9794,
   "Closing_Rank": 11310
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3557,
   "Closing_Rank": 4278
 },
 {
   "Institute": "Indian Institute of Information Technology Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4931,
   "Closing_Rank": 4931
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15185,
   "Closing_Rank": 37788
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40191,
   "Closing_Rank": 42319
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1719,
   "Closing_Rank": 1738
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6174,
   "Closing_Rank": 6747
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6840,
   "Closing_Rank": 7471
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11531,
   "Closing_Rank": 15884
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15983,
   "Closing_Rank": 17427
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1388,
   "Closing_Rank": 1388
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6707,
   "Closing_Rank": 9414
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6690,
   "Closing_Rank": 6902
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3306,
   "Closing_Rank": 3896
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3727,
   "Closing_Rank": 3727
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21174,
   "Closing_Rank": 34282
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31894,
   "Closing_Rank": 38721
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1090,
   "Closing_Rank": 1090
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5514,
   "Closing_Rank": 5934
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6233,
   "Closing_Rank": 6273
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 230,
   "Closing_Rank": 230
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11332,
   "Closing_Rank": 14422
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12962,
   "Closing_Rank": 15948
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 506,
   "Closing_Rank": 506
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4887,
   "Closing_Rank": 9130
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8342,
   "Closing_Rank": 8823
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1954,
   "Closing_Rank": 3696
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2997,
   "Closing_Rank": 2997
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25874,
   "Closing_Rank": 39100
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34619,
   "Closing_Rank": 41131
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1431,
   "Closing_Rank": 1431
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6727,
   "Closing_Rank": 6986
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7666,
   "Closing_Rank": 7666
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12307,
   "Closing_Rank": 16256
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16693,
   "Closing_Rank": 18537
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8333,
   "Closing_Rank": 10071
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10660,
   "Closing_Rank": 11471
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3798,
   "Closing_Rank": 4460
 },
 {
   "Institute": "Indian institute of information technology Raichur Karnataka",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4854,
   "Closing_Rank": 4854
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17310,
   "Closing_Rank": 32396
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34583,
   "Closing_Rank": 38839
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1924,
   "Closing_Rank": 2211
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2221,
   "Closing_Rank": 2221
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5191,
   "Closing_Rank": 5983
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6850,
   "Closing_Rank": 7291
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11851,
   "Closing_Rank": 15207
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16116,
   "Closing_Rank": 18963
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1077,
   "Closing_Rank": 1077
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4212,
   "Closing_Rank": 9163
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8542,
   "Closing_Rank": 11061
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3469,
   "Closing_Rank": 4629
 },
 {
   "Institute": "Indian Institute of Information Technology Vadodara International Campus Diu (IIITVICD)",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4099,
   "Closing_Rank": 4099
 }
]
