import React from 'react'

export default function Footer() {
  return (
    <div id='footer'>
       <p>This is an Open Source Project, contribution will be appreciated :D <a id='githubRepo' href='https://github.com/vinayakmaheshwari/ORCR' target='_blank' rel="noreferrer">Github Repo</a></p>
       <p id='madeBy'>Made with &hearts; by <a href='https://github.com/vinayakmaheshwari/' target='_blank' rel="noreferrer">Vinayak</a></p>
      
    </div>
  )
}
