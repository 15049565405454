export const advClgs = [
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9106",
   "Closing_Rank": "14782"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18286",
   "Closing_Rank": "23024"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2062",
   "Closing_Rank": "2267"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3638",
   "Closing_Rank": "4265"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4115",
   "Closing_Rank": "5231"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7450",
   "Closing_Rank": "8720"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2381",
   "Closing_Rank": "2587"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4031",
   "Closing_Rank": "4293"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "753",
   "Closing_Rank": "977"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1718",
   "Closing_Rank": "1750"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2389",
   "Closing_Rank": "3685"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4833",
   "Closing_Rank": "7557"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "197",
   "Closing_Rank": "197"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "400",
   "Closing_Rank": "466"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1025",
   "Closing_Rank": "1025"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "774",
   "Closing_Rank": "1237"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2107",
   "Closing_Rank": "3291"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "31",
   "Closing_Rank": "31"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "382",
   "Closing_Rank": "596"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1279",
   "Closing_Rank": "1313"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "30P",
   "Closing_Rank": "30P"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "241",
   "Closing_Rank": "356"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "546",
   "Closing_Rank": "649"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5381",
   "Closing_Rank": "7661"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12140",
   "Closing_Rank": "14183"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "262P",
   "Closing_Rank": "262P"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1013",
   "Closing_Rank": "1261"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2194",
   "Closing_Rank": "2249"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2167",
   "Closing_Rank": "2876"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5899",
   "Closing_Rank": "6611"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1105",
   "Closing_Rank": "1762"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2081",
   "Closing_Rank": "2081"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "667",
   "Closing_Rank": "792"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1179",
   "Closing_Rank": "1179"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3944",
   "Closing_Rank": "5157"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8798",
   "Closing_Rank": "10904"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "59",
   "Closing_Rank": "59"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "601",
   "Closing_Rank": "738"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1941",
   "Closing_Rank": "1941"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1423",
   "Closing_Rank": "2026"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3327",
   "Closing_Rank": "3729"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "914",
   "Closing_Rank": "1239"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1773",
   "Closing_Rank": "1915"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "384",
   "Closing_Rank": "538"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "910",
   "Closing_Rank": "910"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10756",
   "Closing_Rank": "11307"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18825",
   "Closing_Rank": "18825"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1846",
   "Closing_Rank": "1978"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3136",
   "Closing_Rank": "4378"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9267",
   "Closing_Rank": "9267"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2045",
   "Closing_Rank": "2226"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4555",
   "Closing_Rank": "4555"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1333",
   "Closing_Rank": "1333"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7881",
   "Closing_Rank": "10233"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16104",
   "Closing_Rank": "19505"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "172",
   "Closing_Rank": "172"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1400",
   "Closing_Rank": "1639"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3122",
   "Closing_Rank": "3236"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3078",
   "Closing_Rank": "3885"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7053",
   "Closing_Rank": "7801"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1907",
   "Closing_Rank": "2293"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2465",
   "Closing_Rank": "3941"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "918",
   "Closing_Rank": "1041"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1735",
   "Closing_Rank": "1735"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11823",
   "Closing_Rank": "14898"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20901",
   "Closing_Rank": "21693"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2301",
   "Closing_Rank": "2373"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4185",
   "Closing_Rank": "4185"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4730",
   "Closing_Rank": "5608"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9228",
   "Closing_Rank": "9228"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2332",
   "Closing_Rank": "2774"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4396",
   "Closing_Rank": "4396"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1102",
   "Closing_Rank": "1223"
 },
 {
   "Institute": "Indian Institute  of Technology Bhubaneswar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "330P",
   "Closing_Rank": "602P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "817",
   "Closing_Rank": "2394"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2494",
   "Closing_Rank": "6360"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "44",
   "Closing_Rank": "44"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "41P",
   "Closing_Rank": "41P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "327",
   "Closing_Rank": "618"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1050",
   "Closing_Rank": "1409"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "447",
   "Closing_Rank": "1577"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1935",
   "Closing_Rank": "3516"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "62",
   "Closing_Rank": "62"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "136",
   "Closing_Rank": "678"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "803",
   "Closing_Rank": "1493"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "184",
   "Closing_Rank": "501"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "424",
   "Closing_Rank": "424"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "631",
   "Closing_Rank": "1191"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4101",
   "Closing_Rank": "4101"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "139",
   "Closing_Rank": "139"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "360",
   "Closing_Rank": "360"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1490",
   "Closing_Rank": "1490"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1106",
   "Closing_Rank": "1291"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3479",
   "Closing_Rank": "3479"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "521",
   "Closing_Rank": "545"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1438",
   "Closing_Rank": "1438"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "BS in Mathematics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "247",
   "Closing_Rank": "247"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "650",
   "Closing_Rank": "2545"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4559",
   "Closing_Rank": "6650"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "130",
   "Closing_Rank": "153"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "265P",
   "Closing_Rank": "265P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "390",
   "Closing_Rank": "485"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "927",
   "Closing_Rank": "1438"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "44P",
   "Closing_Rank": "44P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "953",
   "Closing_Rank": "1338"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2481",
   "Closing_Rank": "3197"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "64",
   "Closing_Rank": "64"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "492",
   "Closing_Rank": "783"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1123",
   "Closing_Rank": "1498"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "301",
   "Closing_Rank": "413"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "344",
   "Closing_Rank": "742"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5629",
   "Closing_Rank": "7430"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9692",
   "Closing_Rank": "11262"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "193",
   "Closing_Rank": "193"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1175",
   "Closing_Rank": "1338"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2637",
   "Closing_Rank": "2637"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2780",
   "Closing_Rank": "3506"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7579",
   "Closing_Rank": "7754"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1688",
   "Closing_Rank": "2025"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3101",
   "Closing_Rank": "3101"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1007",
   "Closing_Rank": "1036"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1498",
   "Closing_Rank": "1498"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2244",
   "Closing_Rank": "4046"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6334",
   "Closing_Rank": "8833"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "142",
   "Closing_Rank": "151"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "148P",
   "Closing_Rank": "148P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "559",
   "Closing_Rank": "769"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1725",
   "Closing_Rank": "2029"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "36P",
   "Closing_Rank": "36P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1076",
   "Closing_Rank": "1979"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3419",
   "Closing_Rank": "4709"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "50P",
   "Closing_Rank": "53P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "580",
   "Closing_Rank": "1002"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "770",
   "Closing_Rank": "1986"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "41",
   "Closing_Rank": "331"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "601",
   "Closing_Rank": "623"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "68"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7",
   "Closing_Rank": "421"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "3"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7",
   "Closing_Rank": "7"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7",
   "Closing_Rank": "23"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "43",
   "Closing_Rank": "79"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3",
   "Closing_Rank": "3"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5",
   "Closing_Rank": "50"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "66",
   "Closing_Rank": "274"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "2"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20",
   "Closing_Rank": "20"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "32"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10",
   "Closing_Rank": "71"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "1"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "13"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16",
   "Closing_Rank": "40"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1",
   "Closing_Rank": "1"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1562",
   "Closing_Rank": "2408"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3878",
   "Closing_Rank": "4408"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "144",
   "Closing_Rank": "144"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "398",
   "Closing_Rank": "525"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "920",
   "Closing_Rank": "920"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "618",
   "Closing_Rank": "1255"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1491",
   "Closing_Rank": "2705"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "280",
   "Closing_Rank": "722"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "373",
   "Closing_Rank": "373"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "130",
   "Closing_Rank": "222"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "714",
   "Closing_Rank": "714"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15",
   "Closing_Rank": "496"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "642",
   "Closing_Rank": "1339"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14",
   "Closing_Rank": "30"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "77",
   "Closing_Rank": "77"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "57",
   "Closing_Rank": "116"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "208",
   "Closing_Rank": "455"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "21",
   "Closing_Rank": "21"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "131",
   "Closing_Rank": "325"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "608",
   "Closing_Rank": "830"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "23",
   "Closing_Rank": "23"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "34",
   "Closing_Rank": "171"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "196",
   "Closing_Rank": "368"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10P",
   "Closing_Rank": "10P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "36",
   "Closing_Rank": "79"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "84",
   "Closing_Rank": "176"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "536",
   "Closing_Rank": "973"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1731",
   "Closing_Rank": "2424"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "52",
   "Closing_Rank": "52"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "245P",
   "Closing_Rank": "245P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "183",
   "Closing_Rank": "199"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "558",
   "Closing_Rank": "753"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "379",
   "Closing_Rank": "571"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1006",
   "Closing_Rank": "1877"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "42",
   "Closing_Rank": "42"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "197",
   "Closing_Rank": "274"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "584",
   "Closing_Rank": "1054"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "131",
   "Closing_Rank": "221"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "363",
   "Closing_Rank": "363"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1905",
   "Closing_Rank": "2689"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3767",
   "Closing_Rank": "5537"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "184",
   "Closing_Rank": "184"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "490",
   "Closing_Rank": "568"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1691",
   "Closing_Rank": "1691"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1300",
   "Closing_Rank": "1650"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3388",
   "Closing_Rank": "3824"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "61P",
   "Closing_Rank": "61P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "706",
   "Closing_Rank": "990"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1673",
   "Closing_Rank": "1673"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "552",
   "Closing_Rank": "669"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1473",
   "Closing_Rank": "1473"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "197",
   "Closing_Rank": "1719"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1680",
   "Closing_Rank": "3629"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "156",
   "Closing_Rank": "156"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "442",
   "Closing_Rank": "567"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1410",
   "Closing_Rank": "1410"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "970",
   "Closing_Rank": "1578"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2329",
   "Closing_Rank": "2854"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "106P",
   "Closing_Rank": "106P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "217",
   "Closing_Rank": "779"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1772",
   "Closing_Rank": "2347"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11P",
   "Closing_Rank": "11P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15",
   "Closing_Rank": "682"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1047",
   "Closing_Rank": "1047"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3424",
   "Closing_Rank": "4718"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8821",
   "Closing_Rank": "9292"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "219P",
   "Closing_Rank": "219P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "935",
   "Closing_Rank": "1060"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2019",
   "Closing_Rank": "2019"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1601",
   "Closing_Rank": "2716"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5701",
   "Closing_Rank": "5973"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "622",
   "Closing_Rank": "1267"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1874",
   "Closing_Rank": "1874"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "680",
   "Closing_Rank": "830"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Environmental Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1587",
   "Closing_Rank": "1587"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1042",
   "Closing_Rank": "1726"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3258",
   "Closing_Rank": "4762"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "63",
   "Closing_Rank": "63"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "279",
   "Closing_Rank": "303"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "706",
   "Closing_Rank": "706"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "401",
   "Closing_Rank": "1196"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2881",
   "Closing_Rank": "3817"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "588",
   "Closing_Rank": "999"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1052",
   "Closing_Rank": "1052"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "323",
   "Closing_Rank": "351"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Industrial Engineering and Operations Research ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1404",
   "Closing_Rank": "1404"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "666",
   "Closing_Rank": "1685"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1662",
   "Closing_Rank": "4444"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "78",
   "Closing_Rank": "152"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "164",
   "Closing_Rank": "367"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "968",
   "Closing_Rank": "1319"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "32P",
   "Closing_Rank": "32P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "462",
   "Closing_Rank": "958"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1265",
   "Closing_Rank": "2722"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "19P",
   "Closing_Rank": "23P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "191",
   "Closing_Rank": "476"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "142",
   "Closing_Rank": "1066"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "61",
   "Closing_Rank": "309"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "466",
   "Closing_Rank": "516"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2690",
   "Closing_Rank": "4193"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6870",
   "Closing_Rank": "8491"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "249P",
   "Closing_Rank": "263P"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "624",
   "Closing_Rank": "907"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1585",
   "Closing_Rank": "1723"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1439",
   "Closing_Rank": "2207"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3446",
   "Closing_Rank": "5594"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "901",
   "Closing_Rank": "1369"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1500",
   "Closing_Rank": "2276"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "514",
   "Closing_Rank": "641"
 },
 {
   "Institute": "Indian Institute  of Technology Bombay",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1154",
   "Closing_Rank": "1251"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7482",
   "Closing_Rank": "10842"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14742",
   "Closing_Rank": "18004"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1590",
   "Closing_Rank": "1854"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3230",
   "Closing_Rank": "3230"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3195",
   "Closing_Rank": "4693"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7596",
   "Closing_Rank": "8700"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2375",
   "Closing_Rank": "2569"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4116",
   "Closing_Rank": "4116"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in General Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1394",
   "Closing_Rank": "1400"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12859",
   "Closing_Rank": "14440"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21058",
   "Closing_Rank": "23252"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2113",
   "Closing_Rank": "2174"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3969",
   "Closing_Rank": "3969"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5151",
   "Closing_Rank": "5543"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8998",
   "Closing_Rank": "8998"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2787",
   "Closing_Rank": "3050"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4427",
   "Closing_Rank": "4427"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1416",
   "Closing_Rank": "1453"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3634",
   "Closing_Rank": "4089"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7453",
   "Closing_Rank": "7886"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "671",
   "Closing_Rank": "679"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1517",
   "Closing_Rank": "1517"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1441",
   "Closing_Rank": "1767"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3788",
   "Closing_Rank": "4641"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "118P",
   "Closing_Rank": "118P"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "904",
   "Closing_Rank": "1086"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1940",
   "Closing_Rank": "1940"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "621",
   "Closing_Rank": "678"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4694",
   "Closing_Rank": "6643"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11035",
   "Closing_Rank": "12194"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "969",
   "Closing_Rank": "1093"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2281",
   "Closing_Rank": "2281"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2377",
   "Closing_Rank": "2943"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6343",
   "Closing_Rank": "6353"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1531",
   "Closing_Rank": "1751"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3907",
   "Closing_Rank": "3907"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "B.Tech in Microelectronics &amp; VLSI ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1084",
   "Closing_Rank": "1096"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11233",
   "Closing_Rank": "14239"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20007",
   "Closing_Rank": "21077"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2124",
   "Closing_Rank": "2215"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4493",
   "Closing_Rank": "4493"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5184",
   "Closing_Rank": "5698"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2614",
   "Closing_Rank": "3144"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4590",
   "Closing_Rank": "4590"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1331",
   "Closing_Rank": "1502"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12900",
   "Closing_Rank": "16430"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "23662",
   "Closing_Rank": "23698"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2353",
   "Closing_Rank": "2487"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4093",
   "Closing_Rank": "4093"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5803",
   "Closing_Rank": "6034"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3170",
   "Closing_Rank": "3198"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4482",
   "Closing_Rank": "4482"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "BS in Chemical Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1493",
   "Closing_Rank": "1529"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9968",
   "Closing_Rank": "12562"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20688",
   "Closing_Rank": "22150"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1796",
   "Closing_Rank": "1953"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3738",
   "Closing_Rank": "3738"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4238",
   "Closing_Rank": "4726"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8450",
   "Closing_Rank": "9050"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2311",
   "Closing_Rank": "2546"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4406",
   "Closing_Rank": "4406"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "717",
   "Closing_Rank": "795"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1526",
   "Closing_Rank": "1526"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1803",
   "Closing_Rank": "3118"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6049",
   "Closing_Rank": "6725"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "178",
   "Closing_Rank": "178"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "258P",
   "Closing_Rank": "258P"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "330",
   "Closing_Rank": "489"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "825",
   "Closing_Rank": "1058"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "25P",
   "Closing_Rank": "25P"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "765",
   "Closing_Rank": "1274"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2698",
   "Closing_Rank": "3578"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "104P",
   "Closing_Rank": "104P"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "542",
   "Closing_Rank": "761"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1061",
   "Closing_Rank": "1732"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "293",
   "Closing_Rank": "371"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "870",
   "Closing_Rank": "870"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3222",
   "Closing_Rank": "3918"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7110",
   "Closing_Rank": "9188"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "189P",
   "Closing_Rank": "189P"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "529",
   "Closing_Rank": "595"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1401",
   "Closing_Rank": "1401"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1284",
   "Closing_Rank": "1679"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3683",
   "Closing_Rank": "4638"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "731",
   "Closing_Rank": "977"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1897",
   "Closing_Rank": "1897"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "378",
   "Closing_Rank": "615"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "983",
   "Closing_Rank": "983"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4772",
   "Closing_Rank": "6909"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11796",
   "Closing_Rank": "12842"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "855",
   "Closing_Rank": "1188"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2285",
   "Closing_Rank": "2285"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2320",
   "Closing_Rank": "2932"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6754",
   "Closing_Rank": "7019"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1216",
   "Closing_Rank": "1650"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2943",
   "Closing_Rank": "3151"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "772",
   "Closing_Rank": "833"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1632",
   "Closing_Rank": "1632"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9089",
   "Closing_Rank": "10475"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19986",
   "Closing_Rank": "20153"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1885",
   "Closing_Rank": "1958"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3601",
   "Closing_Rank": "3601"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4320",
   "Closing_Rank": "5006"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8869",
   "Closing_Rank": "8869"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2298",
   "Closing_Rank": "2631"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4734",
   "Closing_Rank": "4734"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1037",
   "Closing_Rank": "1342"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6947",
   "Closing_Rank": "9734"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18047",
   "Closing_Rank": "19060"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1470",
   "Closing_Rank": "1676"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3723",
   "Closing_Rank": "3723"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3063",
   "Closing_Rank": "4112"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8440",
   "Closing_Rank": "8967"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1831",
   "Closing_Rank": "2133"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3471",
   "Closing_Rank": "3923"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "677",
   "Closing_Rank": "974"
 },
 {
   "Institute": "Indian Institute  of Technology Mandi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "397P",
   "Closing_Rank": "397P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3677",
   "Closing_Rank": "4849"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8216",
   "Closing_Rank": "9733"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "229P",
   "Closing_Rank": "229P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "961",
   "Closing_Rank": "1040"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2000",
   "Closing_Rank": "2341"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1986",
   "Closing_Rank": "2749"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5747",
   "Closing_Rank": "7010"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1100",
   "Closing_Rank": "1548"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2663",
   "Closing_Rank": "2673"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "496",
   "Closing_Rank": "819"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1310",
   "Closing_Rank": "1310"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1689",
   "Closing_Rank": "2221"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3550",
   "Closing_Rank": "5060"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "140",
   "Closing_Rank": "140"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "283",
   "Closing_Rank": "474"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1308",
   "Closing_Rank": "1358"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "960",
   "Closing_Rank": "1303"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2912",
   "Closing_Rank": "3412"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "126",
   "Closing_Rank": "655"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "974",
   "Closing_Rank": "1348"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "423",
   "Closing_Rank": "481"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "771",
   "Closing_Rank": "771"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2629",
   "Closing_Rank": "3646"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6656",
   "Closing_Rank": "6847"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "494",
   "Closing_Rank": "594"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2232",
   "Closing_Rank": "2232"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1424",
   "Closing_Rank": "2054"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3654",
   "Closing_Rank": "4525"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "60",
   "Closing_Rank": "60"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "764",
   "Closing_Rank": "887"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1576",
   "Closing_Rank": "2263"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "233",
   "Closing_Rank": "572"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "604",
   "Closing_Rank": "604"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2666",
   "Closing_Rank": "4169"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6862",
   "Closing_Rank": "9424"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "159",
   "Closing_Rank": "159"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "580",
   "Closing_Rank": "778"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1688",
   "Closing_Rank": "1972"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1056",
   "Closing_Rank": "2063"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4144",
   "Closing_Rank": "5280"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "124P",
   "Closing_Rank": "124P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "396",
   "Closing_Rank": "852"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1095",
   "Closing_Rank": "1937"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "18",
   "Closing_Rank": "188"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "235",
   "Closing_Rank": "312"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "27",
   "Closing_Rank": "116"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "39",
   "Closing_Rank": "556"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5",
   "Closing_Rank": "5"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "68",
   "Closing_Rank": "68"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "24",
   "Closing_Rank": "35"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "108",
   "Closing_Rank": "145"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "39",
   "Closing_Rank": "89"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "319",
   "Closing_Rank": "394"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3",
   "Closing_Rank": "3"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "23",
   "Closing_Rank": "50"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "76",
   "Closing_Rank": "102"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2",
   "Closing_Rank": "2"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14",
   "Closing_Rank": "23"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "51",
   "Closing_Rank": "52"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3",
   "Closing_Rank": "3"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "128",
   "Closing_Rank": "204"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "958",
   "Closing_Rank": "1061"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10",
   "Closing_Rank": "10"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "40",
   "Closing_Rank": "54"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "238",
   "Closing_Rank": "238"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "120",
   "Closing_Rank": "169"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "417",
   "Closing_Rank": "587"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "46",
   "Closing_Rank": "80"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "169",
   "Closing_Rank": "169"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "25",
   "Closing_Rank": "42"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "171",
   "Closing_Rank": "171"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "382",
   "Closing_Rank": "625"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1411",
   "Closing_Rank": "1826"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "34",
   "Closing_Rank": "54"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "94P",
   "Closing_Rank": "94P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "118",
   "Closing_Rank": "156"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "478",
   "Closing_Rank": "554"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "27",
   "Closing_Rank": "27"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "237",
   "Closing_Rank": "422"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "966",
   "Closing_Rank": "1549"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "18",
   "Closing_Rank": "18"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "56",
   "Closing_Rank": "230"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "240",
   "Closing_Rank": "547"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "24",
   "Closing_Rank": "95"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "190",
   "Closing_Rank": "205"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6P",
   "Closing_Rank": "6P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "646",
   "Closing_Rank": "810"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2019",
   "Closing_Rank": "2462"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "70",
   "Closing_Rank": "70"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "162",
   "Closing_Rank": "162"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "162",
   "Closing_Rank": "214"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "700",
   "Closing_Rank": "700"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "428",
   "Closing_Rank": "510"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1595",
   "Closing_Rank": "1837"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "28",
   "Closing_Rank": "28"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "249",
   "Closing_Rank": "288"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "739",
   "Closing_Rank": "829"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "99",
   "Closing_Rank": "159"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering (Power and Automation) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "345",
   "Closing_Rank": "345"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1898",
   "Closing_Rank": "2707"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5632",
   "Closing_Rank": "6523"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "115P",
   "Closing_Rank": "115P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "503",
   "Closing_Rank": "565"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1137",
   "Closing_Rank": "1137"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1362",
   "Closing_Rank": "1957"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4393",
   "Closing_Rank": "5132"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "875",
   "Closing_Rank": "1092"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2231",
   "Closing_Rank": "2231"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "586",
   "Closing_Rank": "645"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1481",
   "Closing_Rank": "1481"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "890",
   "Closing_Rank": "1194"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2128",
   "Closing_Rank": "3381"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "260",
   "Closing_Rank": "307"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1000",
   "Closing_Rank": "1000"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "190",
   "Closing_Rank": "640"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1854",
   "Closing_Rank": "2151"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "43",
   "Closing_Rank": "43"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "256",
   "Closing_Rank": "407"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "840",
   "Closing_Rank": "840"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "214",
   "Closing_Rank": "245"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "562",
   "Closing_Rank": "562"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1845",
   "Closing_Rank": "2560"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4087",
   "Closing_Rank": "7030"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "112P",
   "Closing_Rank": "112P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "600",
   "Closing_Rank": "715"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2137",
   "Closing_Rank": "2137"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1411",
   "Closing_Rank": "1917"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3523",
   "Closing_Rank": "4329"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "749",
   "Closing_Rank": "1117"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2413",
   "Closing_Rank": "2766"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "535",
   "Closing_Rank": "746"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1016",
   "Closing_Rank": "1016"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2422",
   "Closing_Rank": "3797"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8280",
   "Closing_Rank": "9743"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "386P",
   "Closing_Rank": "386P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "598",
   "Closing_Rank": "928"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2233",
   "Closing_Rank": "2233"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2190",
   "Closing_Rank": "2504"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5821",
   "Closing_Rank": "6222"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1345",
   "Closing_Rank": "1408"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2545",
   "Closing_Rank": "2545"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "797",
   "Closing_Rank": "813"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1341",
   "Closing_Rank": "1341"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "118",
   "Closing_Rank": "332"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "824",
   "Closing_Rank": "1240"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "25",
   "Closing_Rank": "25"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "90",
   "Closing_Rank": "90"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "38",
   "Closing_Rank": "74"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "374",
   "Closing_Rank": "417"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12",
   "Closing_Rank": "12"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "93",
   "Closing_Rank": "218"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "589",
   "Closing_Rank": "757"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11",
   "Closing_Rank": "11"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "85",
   "Closing_Rank": "164"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "214",
   "Closing_Rank": "470"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3",
   "Closing_Rank": "3"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "50",
   "Closing_Rank": "97"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "55",
   "Closing_Rank": "55"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "339",
   "Closing_Rank": "417"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1433",
   "Closing_Rank": "1644"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "78",
   "Closing_Rank": "84"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "645",
   "Closing_Rank": "645"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "250",
   "Closing_Rank": "298"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "779",
   "Closing_Rank": "1071"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "165",
   "Closing_Rank": "185"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "667",
   "Closing_Rank": "667"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1P",
   "Closing_Rank": "1P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "117",
   "Closing_Rank": "170"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1225",
   "Closing_Rank": "1782"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3748",
   "Closing_Rank": "5487"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "35",
   "Closing_Rank": "35"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "316",
   "Closing_Rank": "420"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "985",
   "Closing_Rank": "1114"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11P",
   "Closing_Rank": "11P"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "597",
   "Closing_Rank": "1010"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2267",
   "Closing_Rank": "3475"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "41",
   "Closing_Rank": "41"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "116",
   "Closing_Rank": "500"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1140",
   "Closing_Rank": "1329"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "208",
   "Closing_Rank": "310"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "408",
   "Closing_Rank": "408"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2452",
   "Closing_Rank": "3089"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7335",
   "Closing_Rank": "9018"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "537",
   "Closing_Rank": "722"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1596",
   "Closing_Rank": "1734"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "879",
   "Closing_Rank": "2191"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5326",
   "Closing_Rank": "6191"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "825",
   "Closing_Rank": "1335"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1875",
   "Closing_Rank": "2391"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "688",
   "Closing_Rank": "810"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1159",
   "Closing_Rank": "1159"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4352",
   "Closing_Rank": "6102"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9820",
   "Closing_Rank": "12551"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "956",
   "Closing_Rank": "1215"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2604",
   "Closing_Rank": "2605"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2665",
   "Closing_Rank": "3185"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5730",
   "Closing_Rank": "7737"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1579",
   "Closing_Rank": "1780"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2677",
   "Closing_Rank": "2889"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "836",
   "Closing_Rank": "991"
 },
 {
   "Institute": "Indian Institute  of Technology Delhi",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1386",
   "Closing_Rank": "1538"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7109",
   "Closing_Rank": "7913"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15679",
   "Closing_Rank": "16378"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "164P",
   "Closing_Rank": "164P"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1326",
   "Closing_Rank": "1402"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2385",
   "Closing_Rank": "2385"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3015",
   "Closing_Rank": "3568"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6714",
   "Closing_Rank": "6965"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1513",
   "Closing_Rank": "1913"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2786",
   "Closing_Rank": "2786"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "848",
   "Closing_Rank": "922"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1642",
   "Closing_Rank": "1642"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8296",
   "Closing_Rank": "9773"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16460",
   "Closing_Rank": "18217"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1299",
   "Closing_Rank": "1474"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2775",
   "Closing_Rank": "2775"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3390",
   "Closing_Rank": "3974"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6590",
   "Closing_Rank": "7849"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1600",
   "Closing_Rank": "1881"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3425",
   "Closing_Rank": "3731"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "566",
   "Closing_Rank": "631"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1363",
   "Closing_Rank": "1363"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "823",
   "Closing_Rank": "1389"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3499",
   "Closing_Rank": "4698"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "47",
   "Closing_Rank": "57"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "163",
   "Closing_Rank": "218"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "716",
   "Closing_Rank": "749"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14",
   "Closing_Rank": "22"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "301",
   "Closing_Rank": "649"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1053",
   "Closing_Rank": "1468"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "16",
   "Closing_Rank": "16"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "245",
   "Closing_Rank": "329"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "604",
   "Closing_Rank": "728"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12P",
   "Closing_Rank": "12P"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "113",
   "Closing_Rank": "168"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "154",
   "Closing_Rank": "154"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2359",
   "Closing_Rank": "3818"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5167",
   "Closing_Rank": "8297"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "53P",
   "Closing_Rank": "270P"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "451",
   "Closing_Rank": "744"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1460",
   "Closing_Rank": "1579"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "38P",
   "Closing_Rank": "38P"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1193",
   "Closing_Rank": "1648"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2688",
   "Closing_Rank": "4056"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "660",
   "Closing_Rank": "997"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1470",
   "Closing_Rank": "1704"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "350",
   "Closing_Rank": "467"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "656",
   "Closing_Rank": "656"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5765",
   "Closing_Rank": "7612"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13790",
   "Closing_Rank": "15115"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1302",
   "Closing_Rank": "1511"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3128",
   "Closing_Rank": "3618"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8144",
   "Closing_Rank": "8144"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2066",
   "Closing_Rank": "2104"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4096",
   "Closing_Rank": "4096"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1173",
   "Closing_Rank": "1173"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1591",
   "Closing_Rank": "2180"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4905",
   "Closing_Rank": "5064"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "83",
   "Closing_Rank": "83"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "232",
   "Closing_Rank": "261"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "901",
   "Closing_Rank": "901"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "635",
   "Closing_Rank": "1064"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1807",
   "Closing_Rank": "2373"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "121P",
   "Closing_Rank": "121P"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "575",
   "Closing_Rank": "719"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1041",
   "Closing_Rank": "1041"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "260",
   "Closing_Rank": "287"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "563",
   "Closing_Rank": "563"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4946",
   "Closing_Rank": "6812"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12137",
   "Closing_Rank": "14123"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "824",
   "Closing_Rank": "1173"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2103",
   "Closing_Rank": "2509"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2198",
   "Closing_Rank": "2993"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5542",
   "Closing_Rank": "6402"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1223",
   "Closing_Rank": "1702"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3160",
   "Closing_Rank": "3569"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "573",
   "Closing_Rank": "747"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1636",
   "Closing_Rank": "1636"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8299",
   "Closing_Rank": "10894"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18413",
   "Closing_Rank": "19770"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1577",
   "Closing_Rank": "1821"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2989",
   "Closing_Rank": "2989"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3582",
   "Closing_Rank": "4524"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8322",
   "Closing_Rank": "8653"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2163",
   "Closing_Rank": "2597"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3223",
   "Closing_Rank": "4011"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1175",
   "Closing_Rank": "1225"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Metallurgical Engineering and Materials Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1734",
   "Closing_Rank": "1734"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5666",
   "Closing_Rank": "6640"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10683",
   "Closing_Rank": "10970"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1077",
   "Closing_Rank": "1307"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1916",
   "Closing_Rank": "3159"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5795",
   "Closing_Rank": "5795"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1425",
   "Closing_Rank": "1668"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1731",
   "Closing_Rank": "1731"
 },
 {
   "Institute": "Indian Institute  of Technology Indore",
   "Academic_Program_Name": "Space Sciences and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "719",
   "Closing_Rank": "719"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2716",
   "Closing_Rank": "4745"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10505",
   "Closing_Rank": "11444"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "269P",
   "Closing_Rank": "269P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "628",
   "Closing_Rank": "989"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2021",
   "Closing_Rank": "2021"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1741",
   "Closing_Rank": "2345"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4427",
   "Closing_Rank": "5661"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "502",
   "Closing_Rank": "1302"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2141",
   "Closing_Rank": "2606"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "557",
   "Closing_Rank": "799"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1219",
   "Closing_Rank": "1219"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5515",
   "Closing_Rank": "10770"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15669",
   "Closing_Rank": "19903"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1529",
   "Closing_Rank": "1744"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3247",
   "Closing_Rank": "3462"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3191",
   "Closing_Rank": "4488"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8281",
   "Closing_Rank": "8557"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2067",
   "Closing_Rank": "2523"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3377",
   "Closing_Rank": "4242"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1053",
   "Closing_Rank": "1129"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Agricultural and Food Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "343P",
   "Closing_Rank": "343P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10069",
   "Closing_Rank": "12893"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20374",
   "Closing_Rank": "22024"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1913",
   "Closing_Rank": "2100"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4291",
   "Closing_Rank": "4291"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4616",
   "Closing_Rank": "5493"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2982",
   "Closing_Rank": "3092"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4560",
   "Closing_Rank": "4575"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1387",
   "Closing_Rank": "1462"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "768P",
   "Closing_Rank": "768P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "16634",
   "Closing_Rank": "20427"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20942",
   "Closing_Rank": "24653"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3043",
   "Closing_Rank": "3311"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4976",
   "Closing_Rank": "4976"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6513",
   "Closing_Rank": "7772"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3797",
   "Closing_Rank": "4204"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4054",
   "Closing_Rank": "4054"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1094",
   "Closing_Rank": "1755"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1656P",
   "Closing_Rank": "1656P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "467",
   "Closing_Rank": "898"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2604",
   "Closing_Rank": "2630"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "196",
   "Closing_Rank": "104P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "120",
   "Closing_Rank": "138"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "355",
   "Closing_Rank": "355"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "308",
   "Closing_Rank": "412"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "924",
   "Closing_Rank": "1109"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10",
   "Closing_Rank": "10"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "166",
   "Closing_Rank": "243"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "780",
   "Closing_Rank": "782"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "123",
   "Closing_Rank": "135"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "256",
   "Closing_Rank": "256"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5505",
   "Closing_Rank": "7972"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14147",
   "Closing_Rank": "15493"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1371",
   "Closing_Rank": "1415"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3189",
   "Closing_Rank": "3189"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3326",
   "Closing_Rank": "3797"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6514",
   "Closing_Rank": "7866"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2034",
   "Closing_Rank": "2294"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1404",
   "Closing_Rank": "2475"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "806",
   "Closing_Rank": "1002"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1731",
   "Closing_Rank": "1731"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3905",
   "Closing_Rank": "4848"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8646",
   "Closing_Rank": "11646"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "247P",
   "Closing_Rank": "247P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "710",
   "Closing_Rank": "911"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1858",
   "Closing_Rank": "2117"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1779",
   "Closing_Rank": "2388"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4348",
   "Closing_Rank": "5232"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "497",
   "Closing_Rank": "1297"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1292",
   "Closing_Rank": "2540"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "683",
   "Closing_Rank": "740"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "375",
   "Closing_Rank": "938"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7869",
   "Closing_Rank": "12488"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18426",
   "Closing_Rank": "19077"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1742",
   "Closing_Rank": "1934"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4190",
   "Closing_Rank": "4190"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4714",
   "Closing_Rank": "5375"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8798",
   "Closing_Rank": "9099"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1740",
   "Closing_Rank": "3023"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3919",
   "Closing_Rank": "3919"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1413",
   "Closing_Rank": "1496"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "576P",
   "Closing_Rank": "576P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4902",
   "Closing_Rank": "6992"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12769",
   "Closing_Rank": "15388"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "149",
   "Closing_Rank": "149"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1032",
   "Closing_Rank": "1214"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2357",
   "Closing_Rank": "2478"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2031",
   "Closing_Rank": "2991"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5456",
   "Closing_Rank": "6337"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1232",
   "Closing_Rank": "1578"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1170",
   "Closing_Rank": "1474"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "405",
   "Closing_Rank": "541"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "960",
   "Closing_Rank": "1158"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "226",
   "Closing_Rank": "415"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1172",
   "Closing_Rank": "1661"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "20",
   "Closing_Rank": "22"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "66",
   "Closing_Rank": "66"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "53",
   "Closing_Rank": "67"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "171",
   "Closing_Rank": "306"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7",
   "Closing_Rank": "9"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "91",
   "Closing_Rank": "209"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "396",
   "Closing_Rank": "601"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7",
   "Closing_Rank": "7"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "17",
   "Closing_Rank": "137"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "151",
   "Closing_Rank": "414"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6",
   "Closing_Rank": "6"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "33",
   "Closing_Rank": "67"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "183",
   "Closing_Rank": "206"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5P",
   "Closing_Rank": "5P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3176",
   "Closing_Rank": "5263"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6763",
   "Closing_Rank": "10746"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "315P",
   "Closing_Rank": "315P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "698",
   "Closing_Rank": "986"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1971",
   "Closing_Rank": "1971"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1941",
   "Closing_Rank": "2736"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5105",
   "Closing_Rank": "7383"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1632",
   "Closing_Rank": "2027"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3690",
   "Closing_Rank": "3724"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "637",
   "Closing_Rank": "818"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1444",
   "Closing_Rank": "1444"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1460",
   "Closing_Rank": "2016"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4312",
   "Closing_Rank": "5599"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "112",
   "Closing_Rank": "19P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "268",
   "Closing_Rank": "349"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "847",
   "Closing_Rank": "974"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "719",
   "Closing_Rank": "944"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1657",
   "Closing_Rank": "2057"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "29P",
   "Closing_Rank": "29P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "415",
   "Closing_Rank": "544"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1244",
   "Closing_Rank": "1307"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "225",
   "Closing_Rank": "257"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "551",
   "Closing_Rank": "618"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "516",
   "Closing_Rank": "1448"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1815",
   "Closing_Rank": "3816"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "21",
   "Closing_Rank": "72"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "297P",
   "Closing_Rank": "297P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "185",
   "Closing_Rank": "228"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "519",
   "Closing_Rank": "802"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "30",
   "Closing_Rank": "30"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "234",
   "Closing_Rank": "687"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "977",
   "Closing_Rank": "1672"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "32",
   "Closing_Rank": "25P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "157",
   "Closing_Rank": "390"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "664",
   "Closing_Rank": "1135"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9",
   "Closing_Rank": "9"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "110",
   "Closing_Rank": "219"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Electronics and Electrical Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "372",
   "Closing_Rank": "448"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9996",
   "Closing_Rank": "12413"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19316",
   "Closing_Rank": "20680"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1877",
   "Closing_Rank": "2058"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4289",
   "Closing_Rank": "4289"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4299",
   "Closing_Rank": "5104"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9049",
   "Closing_Rank": "9049"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2708",
   "Closing_Rank": "3004"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4168",
   "Closing_Rank": "4168"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1483",
   "Closing_Rank": "1497"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Exploration Geophysics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "457P",
   "Closing_Rank": "457P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2915",
   "Closing_Rank": "4364"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7214",
   "Closing_Rank": "10726"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "155",
   "Closing_Rank": "155"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "518",
   "Closing_Rank": "772"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1665",
   "Closing_Rank": "1665"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1112",
   "Closing_Rank": "2307"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3669",
   "Closing_Rank": "5032"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1171",
   "Closing_Rank": "1653"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2378",
   "Closing_Rank": "2552"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "567",
   "Closing_Rank": "840"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Industrial and Systems Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1200",
   "Closing_Rank": "1200"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2022",
   "Closing_Rank": "2481"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6373",
   "Closing_Rank": "6582"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "167",
   "Closing_Rank": "167"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "403",
   "Closing_Rank": "510"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1663",
   "Closing_Rank": "1663"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1103",
   "Closing_Rank": "1302"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3385",
   "Closing_Rank": "3448"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "609",
   "Closing_Rank": "895"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1880",
   "Closing_Rank": "1880"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "505",
   "Closing_Rank": "583"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1540",
   "Closing_Rank": "1540"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4649",
   "Closing_Rank": "6067"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12350",
   "Closing_Rank": "12933"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "934",
   "Closing_Rank": "1328"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2407",
   "Closing_Rank": "2407"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2322",
   "Closing_Rank": "3292"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5944",
   "Closing_Rank": "6497"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1820",
   "Closing_Rank": "2076"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3147",
   "Closing_Rank": "3665"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "952",
   "Closing_Rank": "1034"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Manufacturing Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1677",
   "Closing_Rank": "1677"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "971",
   "Closing_Rank": "1329"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3514",
   "Closing_Rank": "3957"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "53",
   "Closing_Rank": "53"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "151",
   "Closing_Rank": "254"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "757",
   "Closing_Rank": "757"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "504",
   "Closing_Rank": "740"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1478",
   "Closing_Rank": "1883"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4P",
   "Closing_Rank": "4P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "229",
   "Closing_Rank": "509"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1149",
   "Closing_Rank": "1405"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "161",
   "Closing_Rank": "267"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "415",
   "Closing_Rank": "415"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2160",
   "Closing_Rank": "3613"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6783",
   "Closing_Rank": "10732"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "188P",
   "Closing_Rank": "356P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "584",
   "Closing_Rank": "703"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1435",
   "Closing_Rank": "1864"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1137",
   "Closing_Rank": "1721"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3638",
   "Closing_Rank": "4493"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "607",
   "Closing_Rank": "966"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1567",
   "Closing_Rank": "2369"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "381",
   "Closing_Rank": "540"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "708",
   "Closing_Rank": "888"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5472",
   "Closing_Rank": "7104"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12914",
   "Closing_Rank": "15082"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "388P",
   "Closing_Rank": "388P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1325",
   "Closing_Rank": "1359"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2697",
   "Closing_Rank": "2801"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2394",
   "Closing_Rank": "3378"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6844",
   "Closing_Rank": "7423"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1523",
   "Closing_Rank": "2109"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2762",
   "Closing_Rank": "2994"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "839",
   "Closing_Rank": "930"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1622",
   "Closing_Rank": "1622"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7431",
   "Closing_Rank": "10218"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16126",
   "Closing_Rank": "19143"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1420",
   "Closing_Rank": "1572"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3206",
   "Closing_Rank": "3429"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3031",
   "Closing_Rank": "4015"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8100",
   "Closing_Rank": "8519"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1678",
   "Closing_Rank": "2285"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3003",
   "Closing_Rank": "4060"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "933",
   "Closing_Rank": "1085"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "69P",
   "Closing_Rank": "184P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7876",
   "Closing_Rank": "9098"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16598",
   "Closing_Rank": "17790"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1441",
   "Closing_Rank": "1695"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3390",
   "Closing_Rank": "3741"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3313",
   "Closing_Rank": "4208"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7362",
   "Closing_Rank": "8540"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2174",
   "Closing_Rank": "2357"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3618",
   "Closing_Rank": "3976"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1128",
   "Closing_Rank": "1192"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Ocean Engineering and Naval Architecture ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "294P",
   "Closing_Rank": "294P"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2957",
   "Closing_Rank": "9672"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14639",
   "Closing_Rank": "17600"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1478",
   "Closing_Rank": "1701"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3668",
   "Closing_Rank": "3668"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3604",
   "Closing_Rank": "4608"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7850",
   "Closing_Rank": "8776"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2394",
   "Closing_Rank": "2664"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3740",
   "Closing_Rank": "3740"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1126",
   "Closing_Rank": "1336"
 },
 {
   "Institute": "Indian Institute  of Technology Kharagpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "641P",
   "Closing_Rank": "641P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "685",
   "Closing_Rank": "875"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2214",
   "Closing_Rank": "2840"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "114",
   "Closing_Rank": "127"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "372",
   "Closing_Rank": "372"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "23",
   "Closing_Rank": "23"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "295",
   "Closing_Rank": "399"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "562",
   "Closing_Rank": "798"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "19",
   "Closing_Rank": "19"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "20",
   "Closing_Rank": "251"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "334",
   "Closing_Rank": "334"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "88",
   "Closing_Rank": "89"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6579",
   "Closing_Rank": "8493"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15656",
   "Closing_Rank": "15954"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1502",
   "Closing_Rank": "1636"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3613",
   "Closing_Rank": "4087"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4554",
   "Closing_Rank": "5782"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1969",
   "Closing_Rank": "2563"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3820",
   "Closing_Rank": "3820"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biomedical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1040",
   "Closing_Rank": "1197"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6015",
   "Closing_Rank": "8524"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14833",
   "Closing_Rank": "15214"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1551",
   "Closing_Rank": "1632"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2904",
   "Closing_Rank": "2904"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3056",
   "Closing_Rank": "4058"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6673",
   "Closing_Rank": "6673"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2300",
   "Closing_Rank": "2324"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3479",
   "Closing_Rank": "3479"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Biotechnology and Bioinformatics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1074",
   "Closing_Rank": "1099"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4229",
   "Closing_Rank": "5757"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10591",
   "Closing_Rank": "11897"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "200P",
   "Closing_Rank": "361P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "889",
   "Closing_Rank": "1021"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2086",
   "Closing_Rank": "2086"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2152",
   "Closing_Rank": "2755"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4306",
   "Closing_Rank": "4578"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1331",
   "Closing_Rank": "1588"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2215",
   "Closing_Rank": "2215"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "646",
   "Closing_Rank": "783"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1328",
   "Closing_Rank": "1328"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7053",
   "Closing_Rank": "7898"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12882",
   "Closing_Rank": "16049"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "75P",
   "Closing_Rank": "75P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1044",
   "Closing_Rank": "1331"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2260",
   "Closing_Rank": "2260"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2830",
   "Closing_Rank": "3334"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5762",
   "Closing_Rank": "6223"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1229",
   "Closing_Rank": "1789"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1301",
   "Closing_Rank": "2852"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "640",
   "Closing_Rank": "697"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1302",
   "Closing_Rank": "1302"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1046",
   "Closing_Rank": "1790"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4462",
   "Closing_Rank": "5158"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "272",
   "Closing_Rank": "272"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "26P",
   "Closing_Rank": "26P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "638",
   "Closing_Rank": "991"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1547",
   "Closing_Rank": "1547"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "529",
   "Closing_Rank": "657"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "878",
   "Closing_Rank": "878"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "305",
   "Closing_Rank": "441"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computational Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "348",
   "Closing_Rank": "348"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "431",
   "Closing_Rank": "656"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1709",
   "Closing_Rank": "1809"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "32",
   "Closing_Rank": "32"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "48",
   "Closing_Rank": "48"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "91",
   "Closing_Rank": "112"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "356",
   "Closing_Rank": "356"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "123",
   "Closing_Rank": "302"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "605",
   "Closing_Rank": "865"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "93",
   "Closing_Rank": "182"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "363",
   "Closing_Rank": "386"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5",
   "Closing_Rank": "5"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "53",
   "Closing_Rank": "82"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "107",
   "Closing_Rank": "107"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1022",
   "Closing_Rank": "2105"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3084",
   "Closing_Rank": "4344"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "133",
   "Closing_Rank": "133"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "290",
   "Closing_Rank": "387"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "723",
   "Closing_Rank": "723"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "606",
   "Closing_Rank": "1021"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1260",
   "Closing_Rank": "2009"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "58",
   "Closing_Rank": "58"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "464",
   "Closing_Rank": "614"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "873",
   "Closing_Rank": "1218"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "276",
   "Closing_Rank": "292"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "545",
   "Closing_Rank": "545"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1570",
   "Closing_Rank": "2147"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4056",
   "Closing_Rank": "4434"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "321",
   "Closing_Rank": "350"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "868",
   "Closing_Rank": "868"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "557",
   "Closing_Rank": "920"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2076",
   "Closing_Rank": "2076"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "401",
   "Closing_Rank": "463"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "528",
   "Closing_Rank": "528"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Electrical Engineering (IC Design and Technology",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "277",
   "Closing_Rank": "373"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3442",
   "Closing_Rank": "4932"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11250",
   "Closing_Rank": "12055"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "91P",
   "Closing_Rank": "91P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1004",
   "Closing_Rank": "1180"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1997",
   "Closing_Rank": "1997"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2435",
   "Closing_Rank": "2856"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3086",
   "Closing_Rank": "6654"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1690",
   "Closing_Rank": "1818"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3009",
   "Closing_Rank": "3009"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1024",
   "Closing_Rank": "1066"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1822",
   "Closing_Rank": "3835"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4470",
   "Closing_Rank": "5748"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "536",
   "Closing_Rank": "654"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1830",
   "Closing_Rank": "1830"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1166",
   "Closing_Rank": "1984"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2543",
   "Closing_Rank": "4046"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "863",
   "Closing_Rank": "1125"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1512",
   "Closing_Rank": "1512"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "498",
   "Closing_Rank": "592"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Engineering Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1028",
   "Closing_Rank": "1028"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8178",
   "Closing_Rank": "9551"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15890",
   "Closing_Rank": "16446"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1755",
   "Closing_Rank": "1799"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3018",
   "Closing_Rank": "3018"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2923",
   "Closing_Rank": "4097"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7981",
   "Closing_Rank": "7981"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2199",
   "Closing_Rank": "2370"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3889",
   "Closing_Rank": "3889"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1177",
   "Closing_Rank": "1240"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5105",
   "Closing_Rank": "8197"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13015",
   "Closing_Rank": "15999"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "186P",
   "Closing_Rank": "186P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1344",
   "Closing_Rank": "1422"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2499",
   "Closing_Rank": "2499"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2847",
   "Closing_Rank": "3481"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7549",
   "Closing_Rank": "7564"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1841",
   "Closing_Rank": "2196"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3360",
   "Closing_Rank": "3360"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "817",
   "Closing_Rank": "945"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "660",
   "Closing_Rank": "982"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2885",
   "Closing_Rank": "3625"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "147",
   "Closing_Rank": "158"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "502",
   "Closing_Rank": "502"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "419",
   "Closing_Rank": "480"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "918",
   "Closing_Rank": "918"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "22",
   "Closing_Rank": "22"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "238",
   "Closing_Rank": "301"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "519",
   "Closing_Rank": "519"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "178",
   "Closing_Rank": "178"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3751",
   "Closing_Rank": "4469"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7527",
   "Closing_Rank": "10758"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "142P",
   "Closing_Rank": "142P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "689",
   "Closing_Rank": "786"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1868",
   "Closing_Rank": "1868"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1765",
   "Closing_Rank": "2121"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2745",
   "Closing_Rank": "4746"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "132P",
   "Closing_Rank": "132P"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "836",
   "Closing_Rank": "1116"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2205",
   "Closing_Rank": "2270"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "387",
   "Closing_Rank": "570"
 },
 {
   "Institute": "Indian Institute  of Technology Hyderabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "881",
   "Closing_Rank": "881"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3048",
   "Closing_Rank": "4182"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7749",
   "Closing_Rank": "8194"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "111P",
   "Closing_Rank": "111P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "509",
   "Closing_Rank": "555"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1537",
   "Closing_Rank": "1587"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1341",
   "Closing_Rank": "1722"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4169",
   "Closing_Rank": "4502"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "797",
   "Closing_Rank": "1081"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1528",
   "Closing_Rank": "2082"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "450",
   "Closing_Rank": "589"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "863",
   "Closing_Rank": "863"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12293",
   "Closing_Rank": "14666"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18682",
   "Closing_Rank": "20850"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2098",
   "Closing_Rank": "2295"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3966",
   "Closing_Rank": "4419"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4754",
   "Closing_Rank": "5752"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9141",
   "Closing_Rank": "9141"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3063",
   "Closing_Rank": "3167"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4617",
   "Closing_Rank": "4686"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1250",
   "Closing_Rank": "1452"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Bio Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1049P",
   "Closing_Rank": "1049P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8295",
   "Closing_Rank": "11036"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15996",
   "Closing_Rank": "19661"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1625",
   "Closing_Rank": "1782"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3246",
   "Closing_Rank": "3246"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4259",
   "Closing_Rank": "4613"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8291",
   "Closing_Rank": "8539"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2292",
   "Closing_Rank": "2688"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4029",
   "Closing_Rank": "4029"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "995",
   "Closing_Rank": "1120"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "707P",
   "Closing_Rank": "707P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12654",
   "Closing_Rank": "16381"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "22452",
   "Closing_Rank": "23748"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2437",
   "Closing_Rank": "2491"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5713",
   "Closing_Rank": "6013"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3184",
   "Closing_Rank": "3228"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4853",
   "Closing_Rank": "4853"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1553",
   "Closing_Rank": "1553"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Chemistry with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1059P",
   "Closing_Rank": "1059P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11244",
   "Closing_Rank": "13316"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19028",
   "Closing_Rank": "21003"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1905",
   "Closing_Rank": "2031"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4153",
   "Closing_Rank": "4153"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4108",
   "Closing_Rank": "4937"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9273",
   "Closing_Rank": "9273"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2495",
   "Closing_Rank": "2619"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4172",
   "Closing_Rank": "4201"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "901",
   "Closing_Rank": "980"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1799",
   "Closing_Rank": "1799"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2062",
   "Closing_Rank": "3061"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5655",
   "Closing_Rank": "7457"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "76",
   "Closing_Rank": "76"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "348",
   "Closing_Rank": "416"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1117",
   "Closing_Rank": "1150"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "29",
   "Closing_Rank": "29"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "735",
   "Closing_Rank": "1404"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2023",
   "Closing_Rank": "3361"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "503",
   "Closing_Rank": "789"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1247",
   "Closing_Rank": "1421"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "244",
   "Closing_Rank": "396"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "327",
   "Closing_Rank": "327"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4693",
   "Closing_Rank": "6346"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9003",
   "Closing_Rank": "11925"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "360P",
   "Closing_Rank": "360P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "869",
   "Closing_Rank": "1149"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1718",
   "Closing_Rank": "1718"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2316",
   "Closing_Rank": "2972"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6084",
   "Closing_Rank": "6785"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1379",
   "Closing_Rank": "1835"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2929",
   "Closing_Rank": "3114"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "791",
   "Closing_Rank": "875"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1505",
   "Closing_Rank": "1560"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11381",
   "Closing_Rank": "13339"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19921",
   "Closing_Rank": "22782"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1954",
   "Closing_Rank": "2211"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4299",
   "Closing_Rank": "4299"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4948",
   "Closing_Rank": "5688"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8892",
   "Closing_Rank": "9279"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2790",
   "Closing_Rank": "2950"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4235",
   "Closing_Rank": "4235"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1346",
   "Closing_Rank": "1419"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1026P",
   "Closing_Rank": "1026P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6984",
   "Closing_Rank": "9488"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14797",
   "Closing_Rank": "18249"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1355",
   "Closing_Rank": "1616"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3141",
   "Closing_Rank": "3315"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3320",
   "Closing_Rank": "4105"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8042",
   "Closing_Rank": "8755"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2006",
   "Closing_Rank": "2330"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3733",
   "Closing_Rank": "4132"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "968",
   "Closing_Rank": "1071"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "198P",
   "Closing_Rank": "332P"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10714",
   "Closing_Rank": "14641"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "23402",
   "Closing_Rank": "23673"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2395",
   "Closing_Rank": "2420"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4654",
   "Closing_Rank": "5792"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2817",
   "Closing_Rank": "3182"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4697",
   "Closing_Rank": "4697"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1510",
   "Closing_Rank": "1510"
 },
 {
   "Institute": "Indian Institute  of Technology Jodhpur",
   "Academic_Program_Name": "Physics with Specialization ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1032P",
   "Closing_Rank": "1032P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2713",
   "Closing_Rank": "3638"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6950",
   "Closing_Rank": "10186"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "157P",
   "Closing_Rank": "157P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "345P",
   "Closing_Rank": "345P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "629",
   "Closing_Rank": "875"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1917",
   "Closing_Rank": "1917"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "863",
   "Closing_Rank": "1980"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3457",
   "Closing_Rank": "4326"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "705",
   "Closing_Rank": "987"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1805",
   "Closing_Rank": "2655"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "146",
   "Closing_Rank": "681"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "768",
   "Closing_Rank": "768"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5259",
   "Closing_Rank": "6952"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10725",
   "Closing_Rank": "12032"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1273",
   "Closing_Rank": "1341"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2519",
   "Closing_Rank": "2519"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2995",
   "Closing_Rank": "3476"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6561",
   "Closing_Rank": "7371"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1623",
   "Closing_Rank": "2071"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3355",
   "Closing_Rank": "3462"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "829",
   "Closing_Rank": "1168"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Biological Sciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1790",
   "Closing_Rank": "1790"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2227",
   "Closing_Rank": "4015"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7129",
   "Closing_Rank": "9528"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "29P",
   "Closing_Rank": "199P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "543",
   "Closing_Rank": "707"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1482",
   "Closing_Rank": "1661"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1402",
   "Closing_Rank": "2058"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3295",
   "Closing_Rank": "4628"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "855",
   "Closing_Rank": "1185"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1635",
   "Closing_Rank": "2062"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "547",
   "Closing_Rank": "684"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1043",
   "Closing_Rank": "1062"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7317",
   "Closing_Rank": "10498"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15079",
   "Closing_Rank": "16196"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1472",
   "Closing_Rank": "1650"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3744",
   "Closing_Rank": "3744"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3173",
   "Closing_Rank": "4766"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7860",
   "Closing_Rank": "8578"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2460",
   "Closing_Rank": "2667"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4596",
   "Closing_Rank": "4614"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1348",
   "Closing_Rank": "1434"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "250P",
   "Closing_Rank": "250P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4213",
   "Closing_Rank": "5948"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4232",
   "Closing_Rank": "13819"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "391P",
   "Closing_Rank": "395P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "806",
   "Closing_Rank": "1020"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2054",
   "Closing_Rank": "2293"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2168",
   "Closing_Rank": "2764"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5332",
   "Closing_Rank": "6704"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "982",
   "Closing_Rank": "1509"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2680",
   "Closing_Rank": "2969"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "290",
   "Closing_Rank": "482"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "668",
   "Closing_Rank": "837"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "120",
   "Closing_Rank": "252"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "781",
   "Closing_Rank": "1117"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6",
   "Closing_Rank": "11"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "88",
   "Closing_Rank": "117"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "28",
   "Closing_Rank": "51"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "150",
   "Closing_Rank": "294"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4",
   "Closing_Rank": "4"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "61",
   "Closing_Rank": "132"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "410",
   "Closing_Rank": "538"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6",
   "Closing_Rank": "6"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "55",
   "Closing_Rank": "96"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "133",
   "Closing_Rank": "397"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4",
   "Closing_Rank": "4"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8",
   "Closing_Rank": "8"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "30",
   "Closing_Rank": "54"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "127",
   "Closing_Rank": "141"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7210",
   "Closing_Rank": "10058"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16346",
   "Closing_Rank": "17618"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1534",
   "Closing_Rank": "1627"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2942",
   "Closing_Rank": "2942"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3667",
   "Closing_Rank": "4562"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7778",
   "Closing_Rank": "8477"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1636",
   "Closing_Rank": "2567"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4511",
   "Closing_Rank": "4511"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1280",
   "Closing_Rank": "1369"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Earth Sciences ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "702P",
   "Closing_Rank": "702P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2137",
   "Closing_Rank": "3106"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5790",
   "Closing_Rank": "6326"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "169",
   "Closing_Rank": "169"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "569",
   "Closing_Rank": "690"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1548",
   "Closing_Rank": "1548"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1258",
   "Closing_Rank": "2051"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3436",
   "Closing_Rank": "4211"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "806",
   "Closing_Rank": "1430"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2897",
   "Closing_Rank": "3401"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "521",
   "Closing_Rank": "725"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1645",
   "Closing_Rank": "1645"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "743",
   "Closing_Rank": "1290"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2616",
   "Closing_Rank": "3166"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "107",
   "Closing_Rank": "128"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "190P",
   "Closing_Rank": "190P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "193",
   "Closing_Rank": "259"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "702",
   "Closing_Rank": "859"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3P",
   "Closing_Rank": "3P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "432",
   "Closing_Rank": "743"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1397",
   "Closing_Rank": "2426"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "53",
   "Closing_Rank": "61"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "281",
   "Closing_Rank": "452"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "769",
   "Closing_Rank": "1152"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "98",
   "Closing_Rank": "203"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "414",
   "Closing_Rank": "518"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4831",
   "Closing_Rank": "6008"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11438",
   "Closing_Rank": "13464"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "955",
   "Closing_Rank": "1258"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2264",
   "Closing_Rank": "2558"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2603",
   "Closing_Rank": "3126"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6390",
   "Closing_Rank": "7187"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1544",
   "Closing_Rank": "1853"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3140",
   "Closing_Rank": "3639"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "859",
   "Closing_Rank": "1080"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1412",
   "Closing_Rank": "1412"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "756",
   "Closing_Rank": "990"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2288",
   "Closing_Rank": "3445"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "100",
   "Closing_Rank": "100"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12P",
   "Closing_Rank": "12P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "111",
   "Closing_Rank": "209"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "607",
   "Closing_Rank": "607"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1P",
   "Closing_Rank": "1P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "352",
   "Closing_Rank": "673"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1295",
   "Closing_Rank": "2234"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "212",
   "Closing_Rank": "372"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1148",
   "Closing_Rank": "1341"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "182",
   "Closing_Rank": "343"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mathematics and Scientific Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "632",
   "Closing_Rank": "632"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1889",
   "Closing_Rank": "2776"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6470",
   "Closing_Rank": "8298"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "186",
   "Closing_Rank": "34P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "386",
   "Closing_Rank": "506"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1499",
   "Closing_Rank": "1686"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1046",
   "Closing_Rank": "1392"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3631",
   "Closing_Rank": "4202"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "135P",
   "Closing_Rank": "135P"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "586",
   "Closing_Rank": "834"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1541",
   "Closing_Rank": "2026"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "426",
   "Closing_Rank": "509"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "582",
   "Closing_Rank": "776"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3377",
   "Closing_Rank": "5508"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7470",
   "Closing_Rank": "16084"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "95",
   "Closing_Rank": "95"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1135",
   "Closing_Rank": "1436"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2897",
   "Closing_Rank": "2897"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2664",
   "Closing_Rank": "3602"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6209",
   "Closing_Rank": "7080"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1980",
   "Closing_Rank": "2363"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3812",
   "Closing_Rank": "3812"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1176",
   "Closing_Rank": "1277"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1765",
   "Closing_Rank": "1765"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "699",
   "Closing_Rank": "1030"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1938",
   "Closing_Rank": "3674"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "105",
   "Closing_Rank": "105"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "160",
   "Closing_Rank": "207"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "800",
   "Closing_Rank": "800"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "486",
   "Closing_Rank": "714"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1611",
   "Closing_Rank": "1999"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "402",
   "Closing_Rank": "534"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1035",
   "Closing_Rank": "1035"
 },
 {
   "Institute": "Indian Institute  of Technology Kanpur",
   "Academic_Program_Name": "Statistics and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "220",
   "Closing_Rank": "224"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1469",
   "Closing_Rank": "2815"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5475",
   "Closing_Rank": "6845"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "119P",
   "Closing_Rank": "119P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "760",
   "Closing_Rank": "837"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1232",
   "Closing_Rank": "1232"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "832",
   "Closing_Rank": "1655"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1452",
   "Closing_Rank": "3432"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "63P",
   "Closing_Rank": "63P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "299",
   "Closing_Rank": "900"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1522",
   "Closing_Rank": "1699"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "173",
   "Closing_Rank": "478"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "661",
   "Closing_Rank": "661"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2199",
   "Closing_Rank": "2984"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2184",
   "Closing_Rank": "2184"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "883",
   "Closing_Rank": "883"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1395",
   "Closing_Rank": "1455"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3953",
   "Closing_Rank": "3953"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "995",
   "Closing_Rank": "995"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "503",
   "Closing_Rank": "503"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "132",
   "Closing_Rank": "419"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1079",
   "Closing_Rank": "1379"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "43",
   "Closing_Rank": "43"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "80",
   "Closing_Rank": "104"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "298",
   "Closing_Rank": "298"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "137",
   "Closing_Rank": "207"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "306",
   "Closing_Rank": "503"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "30",
   "Closing_Rank": "30"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "119",
   "Closing_Rank": "198"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "237",
   "Closing_Rank": "237"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "38",
   "Closing_Rank": "83"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Artificial Intelligence and Data Analytics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "218",
   "Closing_Rank": "218"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3135",
   "Closing_Rank": "5755"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5326",
   "Closing_Rank": "9871"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1142",
   "Closing_Rank": "1384"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2205",
   "Closing_Rank": "2205"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2456",
   "Closing_Rank": "3364"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5526",
   "Closing_Rank": "6929"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1727",
   "Closing_Rank": "2266"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2942",
   "Closing_Rank": "2942"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1057",
   "Closing_Rank": "1090"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1566",
   "Closing_Rank": "1566"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8849",
   "Closing_Rank": "10682"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12473",
   "Closing_Rank": "14739"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1561",
   "Closing_Rank": "1743"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2996",
   "Closing_Rank": "2996"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4045",
   "Closing_Rank": "4682"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6950",
   "Closing_Rank": "7692"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2517",
   "Closing_Rank": "2778"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4048",
   "Closing_Rank": "4048"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1325",
   "Closing_Rank": "1359"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Biological Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1691",
   "Closing_Rank": "1691"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2024",
   "Closing_Rank": "3972"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6727",
   "Closing_Rank": "9068"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "274P",
   "Closing_Rank": "383P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "643",
   "Closing_Rank": "784"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1306",
   "Closing_Rank": "1843"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "820",
   "Closing_Rank": "2042"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2636",
   "Closing_Rank": "3772"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "724",
   "Closing_Rank": "1237"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1434",
   "Closing_Rank": "2010"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "291",
   "Closing_Rank": "617"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "851",
   "Closing_Rank": "895"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3305",
   "Closing_Rank": "5688"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7794",
   "Closing_Rank": "12586"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "147",
   "Closing_Rank": "282P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "951",
   "Closing_Rank": "1111"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1884",
   "Closing_Rank": "1945"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1586",
   "Closing_Rank": "2592"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3477",
   "Closing_Rank": "5366"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1044",
   "Closing_Rank": "1324"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1802",
   "Closing_Rank": "2445"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "401",
   "Closing_Rank": "522"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "648",
   "Closing_Rank": "652"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "76",
   "Closing_Rank": "159"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "558",
   "Closing_Rank": "757"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4",
   "Closing_Rank": "4"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "17",
   "Closing_Rank": "42"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "109",
   "Closing_Rank": "129"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "18",
   "Closing_Rank": "82"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "288",
   "Closing_Rank": "344"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4",
   "Closing_Rank": "4"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "36",
   "Closing_Rank": "72"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12",
   "Closing_Rank": "106"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8P",
   "Closing_Rank": "8P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5",
   "Closing_Rank": "29"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "68",
   "Closing_Rank": "68"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "299",
   "Closing_Rank": "838"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1304",
   "Closing_Rank": "2677"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "87",
   "Closing_Rank": "94"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "69P",
   "Closing_Rank": "69P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "105",
   "Closing_Rank": "178"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "468",
   "Closing_Rank": "539"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14P",
   "Closing_Rank": "14P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "122",
   "Closing_Rank": "407"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "641",
   "Closing_Rank": "1063"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "48",
   "Closing_Rank": "51"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "114",
   "Closing_Rank": "378"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "121",
   "Closing_Rank": "563"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "39",
   "Closing_Rank": "149"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "297",
   "Closing_Rank": "336"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1828",
   "Closing_Rank": "3656"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6825",
   "Closing_Rank": "8939"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "359P",
   "Closing_Rank": "359P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "790",
   "Closing_Rank": "1235"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2744",
   "Closing_Rank": "2744"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "933",
   "Closing_Rank": "1772"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2860",
   "Closing_Rank": "3896"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "778",
   "Closing_Rank": "1571"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2110",
   "Closing_Rank": "2280"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "998",
   "Closing_Rank": "1133"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "979",
   "Closing_Rank": "979"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "931",
   "Closing_Rank": "1858"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3376",
   "Closing_Rank": "4636"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "370P",
   "Closing_Rank": "370P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "342",
   "Closing_Rank": "803"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2082",
   "Closing_Rank": "2082"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "385",
   "Closing_Rank": "1192"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2319",
   "Closing_Rank": "3046"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1003",
   "Closing_Rank": "1326"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "685",
   "Closing_Rank": "685"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "908",
   "Closing_Rank": "1022"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1429",
   "Closing_Rank": "1429"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "458",
   "Closing_Rank": "2310"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2868",
   "Closing_Rank": "6678"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "120",
   "Closing_Rank": "156P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "359",
   "Closing_Rank": "577"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "845",
   "Closing_Rank": "1385"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "509",
   "Closing_Rank": "1199"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2292",
   "Closing_Rank": "3442"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "93P",
   "Closing_Rank": "93P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "375",
   "Closing_Rank": "792"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "647",
   "Closing_Rank": "1467"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "150",
   "Closing_Rank": "369"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "458",
   "Closing_Rank": "715"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3770",
   "Closing_Rank": "4748"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9735",
   "Closing_Rank": "10699"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "340P",
   "Closing_Rank": "340P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1019",
   "Closing_Rank": "1263"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2109",
   "Closing_Rank": "2109"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2010",
   "Closing_Rank": "2463"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4498",
   "Closing_Rank": "5297"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1328",
   "Closing_Rank": "1679"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1564",
   "Closing_Rank": "2722"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "629",
   "Closing_Rank": "784"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1339",
   "Closing_Rank": "1339"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4274",
   "Closing_Rank": "7404"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12471",
   "Closing_Rank": "13618"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "272P",
   "Closing_Rank": "272P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1084",
   "Closing_Rank": "1568"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2710",
   "Closing_Rank": "2807"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2615",
   "Closing_Rank": "3210"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5654",
   "Closing_Rank": "6778"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "133P",
   "Closing_Rank": "133P"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1355",
   "Closing_Rank": "2224"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2720",
   "Closing_Rank": "3007"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "770",
   "Closing_Rank": "1056"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Naval Architecture and Ocean Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "139",
   "Closing_Rank": "139"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1292",
   "Closing_Rank": "2742"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7069",
   "Closing_Rank": "7069"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "942",
   "Closing_Rank": "942"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1804",
   "Closing_Rank": "2369"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5459",
   "Closing_Rank": "5459"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1784",
   "Closing_Rank": "1784"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3152",
   "Closing_Rank": "3152"
 },
 {
   "Institute": "Indian Institute  of Technology Madras",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1149",
   "Closing_Rank": "1149"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1804",
   "Closing_Rank": "2309"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4703",
   "Closing_Rank": "4824"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "108",
   "Closing_Rank": "108"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "305",
   "Closing_Rank": "361"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1182",
   "Closing_Rank": "1182"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "908",
   "Closing_Rank": "1115"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2317",
   "Closing_Rank": "2534"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "512",
   "Closing_Rank": "649"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "989",
   "Closing_Rank": "989"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "125",
   "Closing_Rank": "249"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6353",
   "Closing_Rank": "8269"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14225",
   "Closing_Rank": "15791"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1281",
   "Closing_Rank": "1553"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3039",
   "Closing_Rank": "3039"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3134",
   "Closing_Rank": "3800"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6706",
   "Closing_Rank": "7934"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1460",
   "Closing_Rank": "2125"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3126",
   "Closing_Rank": "3126"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1075",
   "Closing_Rank": "1146"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "159P",
   "Closing_Rank": "159P"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8338",
   "Closing_Rank": "10234"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16078",
   "Closing_Rank": "17839"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1563",
   "Closing_Rank": "1761"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2965",
   "Closing_Rank": "2965"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3093",
   "Closing_Rank": "4148"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8117",
   "Closing_Rank": "8173"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2065",
   "Closing_Rank": "2356"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3416",
   "Closing_Rank": "3416"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "485",
   "Closing_Rank": "651"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1199",
   "Closing_Rank": "1199"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "811",
   "Closing_Rank": "1708"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2427",
   "Closing_Rank": "4592"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "71",
   "Closing_Rank": "71"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "222",
   "Closing_Rank": "264"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "770",
   "Closing_Rank": "770"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "411",
   "Closing_Rank": "750"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1602",
   "Closing_Rank": "1714"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "56",
   "Closing_Rank": "56"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "259",
   "Closing_Rank": "338"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "828",
   "Closing_Rank": "828"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "21P",
   "Closing_Rank": "21P"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "155",
   "Closing_Rank": "207"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "374",
   "Closing_Rank": "374"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1836",
   "Closing_Rank": "2234"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5172",
   "Closing_Rank": "5172"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "394",
   "Closing_Rank": "394"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1163",
   "Closing_Rank": "1163"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "923",
   "Closing_Rank": "1048"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2950",
   "Closing_Rank": "2950"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "105P",
   "Closing_Rank": "105P"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "418",
   "Closing_Rank": "490"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "367",
   "Closing_Rank": "367"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "644",
   "Closing_Rank": "644"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2835",
   "Closing_Rank": "4055"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6395",
   "Closing_Rank": "8494"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "767",
   "Closing_Rank": "842"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1697",
   "Closing_Rank": "1697"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1415",
   "Closing_Rank": "1930"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4489",
   "Closing_Rank": "4896"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1028",
   "Closing_Rank": "1354"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1984",
   "Closing_Rank": "1984"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "500",
   "Closing_Rank": "534"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "955",
   "Closing_Rank": "955"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3932",
   "Closing_Rank": "4588"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8611",
   "Closing_Rank": "8611"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "904",
   "Closing_Rank": "904"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1791",
   "Closing_Rank": "1791"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1806",
   "Closing_Rank": "2274"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5783",
   "Closing_Rank": "5783"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1468",
   "Closing_Rank": "1633"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "710",
   "Closing_Rank": "710"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1021",
   "Closing_Rank": "1021"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2458",
   "Closing_Rank": "4349"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7247",
   "Closing_Rank": "7281"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "527",
   "Closing_Rank": "747"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1282",
   "Closing_Rank": "1282"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1347",
   "Closing_Rank": "2660"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4751",
   "Closing_Rank": "5545"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1015",
   "Closing_Rank": "1558"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2918",
   "Closing_Rank": "2918"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Integrated Circuit Design &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1014",
   "Closing_Rank": "1069"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8632",
   "Closing_Rank": "11298"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16409",
   "Closing_Rank": "17233"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1825",
   "Closing_Rank": "1891"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3708",
   "Closing_Rank": "3708"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4192",
   "Closing_Rank": "4653"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8171",
   "Closing_Rank": "8171"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2374",
   "Closing_Rank": "2741"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2821",
   "Closing_Rank": "2821"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1227",
   "Closing_Rank": "1269"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "806P",
   "Closing_Rank": "806P"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4859",
   "Closing_Rank": "6344"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10210",
   "Closing_Rank": "13488"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "953",
   "Closing_Rank": "1217"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2705",
   "Closing_Rank": "2705"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2646",
   "Closing_Rank": "3075"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6809",
   "Closing_Rank": "7095"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "666",
   "Closing_Rank": "1725"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3892",
   "Closing_Rank": "3892"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "759",
   "Closing_Rank": "780"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1407",
   "Closing_Rank": "1407"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6581",
   "Closing_Rank": "7422"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13636",
   "Closing_Rank": "14114"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1350",
   "Closing_Rank": "1489"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2795",
   "Closing_Rank": "2795"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3201",
   "Closing_Rank": "3498"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7139",
   "Closing_Rank": "7139"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1998",
   "Closing_Rank": "2060"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3637",
   "Closing_Rank": "3637"
 },
 {
   "Institute": "Indian Institute  of Technology Gandhinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "944",
   "Closing_Rank": "944"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3557",
   "Closing_Rank": "4346"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6711",
   "Closing_Rank": "9272"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "538",
   "Closing_Rank": "611"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1322",
   "Closing_Rank": "1322"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1209",
   "Closing_Rank": "1661"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3196",
   "Closing_Rank": "4132"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "44P",
   "Closing_Rank": "44P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "773",
   "Closing_Rank": "1012"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1056",
   "Closing_Rank": "1056"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "469",
   "Closing_Rank": "575"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1125",
   "Closing_Rank": "1125"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14906",
   "Closing_Rank": "15274"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24612",
   "Closing_Rank": "24612"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2382",
   "Closing_Rank": "2382"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5378",
   "Closing_Rank": "5517"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2753",
   "Closing_Rank": "2753"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Geotechnical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1188",
   "Closing_Rank": "1188"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Structural Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13323",
   "Closing_Rank": "14055"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Structural Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18498",
   "Closing_Rank": "18498"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Structural Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2375",
   "Closing_Rank": "2375"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Structural Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4591",
   "Closing_Rank": "5199"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech in CE. - M. Tech.  in Structural Engineering ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2526",
   "Closing_Rank": "2613"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3466",
   "Closing_Rank": "4429"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9776",
   "Closing_Rank": "9776"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "667",
   "Closing_Rank": "667"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1277",
   "Closing_Rank": "1541"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "921",
   "Closing_Rank": "921"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1917",
   "Closing_Rank": "1917"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (CSE) and M.Tech in CSE ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "729",
   "Closing_Rank": "729"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6068",
   "Closing_Rank": "6253"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14138",
   "Closing_Rank": "14138"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "991",
   "Closing_Rank": "991"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2211",
   "Closing_Rank": "2215"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6902",
   "Closing_Rank": "6902"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1281",
   "Closing_Rank": "1281"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ECE) -M. Tech. in VLSI ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1005",
   "Closing_Rank": "1005"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7254",
   "Closing_Rank": "7605"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16498",
   "Closing_Rank": "16498"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1192",
   "Closing_Rank": "1192"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3036",
   "Closing_Rank": "3084"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7167",
   "Closing_Rank": "7167"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (EEE)-M. Tech. in (Power &amp;. Control) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1705",
   "Closing_Rank": "1890"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5147",
   "Closing_Rank": "5683"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11004",
   "Closing_Rank": "11004"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "714",
   "Closing_Rank": "714"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2014",
   "Closing_Rank": "2200"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4930",
   "Closing_Rank": "4930"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1303",
   "Closing_Rank": "1303"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (Mathematics &amp; Computing) M. Tech. in (Mathematics &amp; Computing) ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "721",
   "Closing_Rank": "721"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8327",
   "Closing_Rank": "9835"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18529",
   "Closing_Rank": "18529"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1907",
   "Closing_Rank": "1907"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3449",
   "Closing_Rank": "3916"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8810",
   "Closing_Rank": "8810"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2303",
   "Closing_Rank": "2303"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B. Tech. (ME) - M. Tech. in Mechatronics ",
   "duration": "5 Years",
   "Degree_Type": " B.Tech. + M.Tech./MS (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1244",
   "Closing_Rank": "1244"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4371",
   "Closing_Rank": "4864"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1961",
   "Closing_Rank": "1961"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3612",
   "Closing_Rank": "3612"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1160",
   "Closing_Rank": "1160"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12577",
   "Closing_Rank": "13111"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4544",
   "Closing_Rank": "4544"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2697",
   "Closing_Rank": "2697"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12162",
   "Closing_Rank": "14176"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20511",
   "Closing_Rank": "20511"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2461",
   "Closing_Rank": "2461"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5396",
   "Closing_Rank": "5396"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14220",
   "Closing_Rank": "15129"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2292",
   "Closing_Rank": "2292"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4971",
   "Closing_Rank": "4971"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2668",
   "Closing_Rank": "2668"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3427",
   "Closing_Rank": "3427"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11166",
   "Closing_Rank": "11166"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1183",
   "Closing_Rank": "1183"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "941",
   "Closing_Rank": "941"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "581",
   "Closing_Rank": "581"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5875",
   "Closing_Rank": "7575"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16808",
   "Closing_Rank": "16808"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1337",
   "Closing_Rank": "1337"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2622",
   "Closing_Rank": "2622"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1826",
   "Closing_Rank": "1826"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6895",
   "Closing_Rank": "7070"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18135",
   "Closing_Rank": "18135"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1186",
   "Closing_Rank": "1186"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3094",
   "Closing_Rank": "3094"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10491",
   "Closing_Rank": "12375"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2199",
   "Closing_Rank": "2199"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4878",
   "Closing_Rank": "4878"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2684",
   "Closing_Rank": "2684"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4990",
   "Closing_Rank": "5445"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2229",
   "Closing_Rank": "2229"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6341",
   "Closing_Rank": "6341"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1309",
   "Closing_Rank": "1309"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7840",
   "Closing_Rank": "9445"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14756",
   "Closing_Rank": "19507"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1595",
   "Closing_Rank": "1703"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2889",
   "Closing_Rank": "3250"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7457",
   "Closing_Rank": "7457"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2122",
   "Closing_Rank": "2351"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1194",
   "Closing_Rank": "1194"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15433",
   "Closing_Rank": "15438"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "22211",
   "Closing_Rank": "22211"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4728",
   "Closing_Rank": "4728"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2341",
   "Closing_Rank": "2341"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Technology and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1273",
   "Closing_Rank": "1273"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6287",
   "Closing_Rank": "6660"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12637",
   "Closing_Rank": "12637"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "926",
   "Closing_Rank": "926"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2372",
   "Closing_Rank": "2383"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1670",
   "Closing_Rank": "1670"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2945",
   "Closing_Rank": "2945"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "882",
   "Closing_Rank": "882"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "BS in Economics with MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6885",
   "Closing_Rank": "9961"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "BS in Economics with MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3944",
   "Closing_Rank": "3944"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "BS in Economics with MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3890",
   "Closing_Rank": "3890"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "BS in Economics with MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2678",
   "Closing_Rank": "2678"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "BS in Economics with MBA (IIM Bodh Gaya) ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and MBA (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1329",
   "Closing_Rank": "1329"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10776",
   "Closing_Rank": "12519"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20166",
   "Closing_Rank": "21996"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1726",
   "Closing_Rank": "1940"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3395",
   "Closing_Rank": "3395"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4236",
   "Closing_Rank": "4836"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8339",
   "Closing_Rank": "8805"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2195",
   "Closing_Rank": "2592"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4041",
   "Closing_Rank": "4097"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1135",
   "Closing_Rank": "1245"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1722",
   "Closing_Rank": "1722"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11793",
   "Closing_Rank": "14062"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "22292",
   "Closing_Rank": "22410"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1985",
   "Closing_Rank": "2201"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3779",
   "Closing_Rank": "3779"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5025",
   "Closing_Rank": "5437"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9036",
   "Closing_Rank": "9036"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2713",
   "Closing_Rank": "3075"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3964",
   "Closing_Rank": "3964"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1239",
   "Closing_Rank": "1252"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1016P",
   "Closing_Rank": "1016P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12287",
   "Closing_Rank": "14579"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "22663",
   "Closing_Rank": "23883"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2040",
   "Closing_Rank": "2127"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3967",
   "Closing_Rank": "3967"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4184",
   "Closing_Rank": "5194"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8731",
   "Closing_Rank": "9291"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2430",
   "Closing_Rank": "2648"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3991",
   "Closing_Rank": "4292"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "989",
   "Closing_Rank": "1110"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "141P",
   "Closing_Rank": "141P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1760",
   "Closing_Rank": "3144"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4695",
   "Closing_Rank": "7192"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "173",
   "Closing_Rank": "36P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "124",
   "Closing_Rank": "124"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "380",
   "Closing_Rank": "445"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "994",
   "Closing_Rank": "1301"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "770",
   "Closing_Rank": "1135"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2522",
   "Closing_Rank": "2975"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "100P",
   "Closing_Rank": "100P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "499",
   "Closing_Rank": "681"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "837",
   "Closing_Rank": "1887"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "306",
   "Closing_Rank": "404"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "444",
   "Closing_Rank": "444"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8762",
   "Closing_Rank": "11437"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18633",
   "Closing_Rank": "21392"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2055",
   "Closing_Rank": "2083"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4441",
   "Closing_Rank": "4441"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4744",
   "Closing_Rank": "5527"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2219",
   "Closing_Rank": "2770"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3751",
   "Closing_Rank": "3751"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Economics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Science)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1489",
   "Closing_Rank": "1489"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5868",
   "Closing_Rank": "6873"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12395",
   "Closing_Rank": "14153"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "291P",
   "Closing_Rank": "291P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "998",
   "Closing_Rank": "1132"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2304",
   "Closing_Rank": "2304"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2086",
   "Closing_Rank": "2695"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5822",
   "Closing_Rank": "6649"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1417",
   "Closing_Rank": "1689"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2763",
   "Closing_Rank": "2763"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "805",
   "Closing_Rank": "876"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1446",
   "Closing_Rank": "1446"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4119",
   "Closing_Rank": "5732"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11192",
   "Closing_Rank": "12036"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "290P",
   "Closing_Rank": "290P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "725",
   "Closing_Rank": "910"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2145",
   "Closing_Rank": "2145"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1895",
   "Closing_Rank": "2197"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4835",
   "Closing_Rank": "5603"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1209",
   "Closing_Rank": "1312"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2041",
   "Closing_Rank": "2041"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "672",
   "Closing_Rank": "803"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1372",
   "Closing_Rank": "1372"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10781",
   "Closing_Rank": "12031"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19654",
   "Closing_Rank": "21964"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2030",
   "Closing_Rank": "2195"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3768",
   "Closing_Rank": "3768"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4351",
   "Closing_Rank": "5206"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9150",
   "Closing_Rank": "9150"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2433",
   "Closing_Rank": "2806"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4290",
   "Closing_Rank": "4290"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1248",
   "Closing_Rank": "1482"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "824P",
   "Closing_Rank": "824P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3252",
   "Closing_Rank": "4952"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9830",
   "Closing_Rank": "10651"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "180",
   "Closing_Rank": "180"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "630",
   "Closing_Rank": "697"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1874",
   "Closing_Rank": "1874"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1136",
   "Closing_Rank": "1939"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3657",
   "Closing_Rank": "4568"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "805",
   "Closing_Rank": "1187"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2161",
   "Closing_Rank": "2161"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "625",
   "Closing_Rank": "849"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1361",
   "Closing_Rank": "1361"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7745",
   "Closing_Rank": "10647"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15112",
   "Closing_Rank": "21121"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "378P",
   "Closing_Rank": "378P"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1592",
   "Closing_Rank": "1700"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3362",
   "Closing_Rank": "3386"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3217",
   "Closing_Rank": "4099"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6356",
   "Closing_Rank": "8469"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1994",
   "Closing_Rank": "2380"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3713",
   "Closing_Rank": "3905"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "953",
   "Closing_Rank": "1114"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1770",
   "Closing_Rank": "1770"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11553",
   "Closing_Rank": "15323"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "23452",
   "Closing_Rank": "24442"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2146",
   "Closing_Rank": "2216"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4022",
   "Closing_Rank": "4022"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5451",
   "Closing_Rank": "5706"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2848",
   "Closing_Rank": "3071"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4504",
   "Closing_Rank": "4504"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1287",
   "Closing_Rank": "1324"
 },
 {
   "Institute": "Indian Institute  of Technology Patna",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "953P",
   "Closing_Rank": "953P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3105",
   "Closing_Rank": "19885"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "17302",
   "Closing_Rank": "19354"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2916",
   "Closing_Rank": "2926"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4599",
   "Closing_Rank": "4599"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5379",
   "Closing_Rank": "6973"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3473",
   "Closing_Rank": "3792"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1739",
   "Closing_Rank": "1739"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "76P",
   "Closing_Rank": "315P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5871",
   "Closing_Rank": "7286"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13107",
   "Closing_Rank": "14009"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1389",
   "Closing_Rank": "1398"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2617",
   "Closing_Rank": "2617"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3266",
   "Closing_Rank": "3549"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7685",
   "Closing_Rank": "8022"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1876",
   "Closing_Rank": "2148"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3393",
   "Closing_Rank": "3393"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1184",
   "Closing_Rank": "1229"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "144P",
   "Closing_Rank": "144P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3531",
   "Closing_Rank": "4689"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6799",
   "Closing_Rank": "10941"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "199",
   "Closing_Rank": "199"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "327P",
   "Closing_Rank": "327P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "701",
   "Closing_Rank": "836"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1588",
   "Closing_Rank": "2059"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1745",
   "Closing_Rank": "2386"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5014",
   "Closing_Rank": "5629"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "902",
   "Closing_Rank": "1264"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1630",
   "Closing_Rank": "2665"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "594",
   "Closing_Rank": "748"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1253",
   "Closing_Rank": "1532"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8062",
   "Closing_Rank": "11647"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11950",
   "Closing_Rank": "18537"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1647",
   "Closing_Rank": "1840"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3547",
   "Closing_Rank": "3547"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3438",
   "Closing_Rank": "4818"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8781",
   "Closing_Rank": "8990"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2042",
   "Closing_Rank": "2581"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4418",
   "Closing_Rank": "4418"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Chemical Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1003",
   "Closing_Rank": "1137"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4202",
   "Closing_Rank": "6293"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10785",
   "Closing_Rank": "14749"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "338P",
   "Closing_Rank": "338P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "314P",
   "Closing_Rank": "314P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "863",
   "Closing_Rank": "1154"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2362",
   "Closing_Rank": "2537"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2210",
   "Closing_Rank": "2899"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6018",
   "Closing_Rank": "7570"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "374",
   "Closing_Rank": "1478"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2007",
   "Closing_Rank": "2823"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "358",
   "Closing_Rank": "457"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1029",
   "Closing_Rank": "1122"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "277",
   "Closing_Rank": "481"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1270",
   "Closing_Rank": "1870"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13",
   "Closing_Rank": "18"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "93",
   "Closing_Rank": "93"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "56",
   "Closing_Rank": "90"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "339",
   "Closing_Rank": "395"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "165",
   "Closing_Rank": "236"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "556",
   "Closing_Rank": "783"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8",
   "Closing_Rank": "8"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "41",
   "Closing_Rank": "144"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "460",
   "Closing_Rank": "474"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10",
   "Closing_Rank": "10"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "56",
   "Closing_Rank": "75"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "192",
   "Closing_Rank": "229"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "503",
   "Closing_Rank": "680"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2000",
   "Closing_Rank": "2114"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "49",
   "Closing_Rank": "49"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "99",
   "Closing_Rank": "132"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "477",
   "Closing_Rank": "477"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "238",
   "Closing_Rank": "421"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "936",
   "Closing_Rank": "1105"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "195",
   "Closing_Rank": "226"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "694",
   "Closing_Rank": "694"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "101",
   "Closing_Rank": "103"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "326",
   "Closing_Rank": "326"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3233",
   "Closing_Rank": "5312"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10154",
   "Closing_Rank": "10847"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "877",
   "Closing_Rank": "931"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2084",
   "Closing_Rank": "2084"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1747",
   "Closing_Rank": "2552"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4637",
   "Closing_Rank": "4949"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1268",
   "Closing_Rank": "1879"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3203",
   "Closing_Rank": "3203"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Economics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "992",
   "Closing_Rank": "1009"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1495",
   "Closing_Rank": "2033"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3631",
   "Closing_Rank": "6111"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "96",
   "Closing_Rank": "148"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "191",
   "Closing_Rank": "191"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "287",
   "Closing_Rank": "385"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "788",
   "Closing_Rank": "1042"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15P",
   "Closing_Rank": "15P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "768",
   "Closing_Rank": "1012"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2182",
   "Closing_Rank": "3072"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "84P",
   "Closing_Rank": "84P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "358",
   "Closing_Rank": "560"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1079",
   "Closing_Rank": "1402"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "124",
   "Closing_Rank": "298"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "446",
   "Closing_Rank": "654"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7P",
   "Closing_Rank": "7P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "987",
   "Closing_Rank": "1424"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2653",
   "Closing_Rank": "3590"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "64",
   "Closing_Rank": "69"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "181",
   "Closing_Rank": "265"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "617",
   "Closing_Rank": "781"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "514",
   "Closing_Rank": "732"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1247",
   "Closing_Rank": "1709"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "29",
   "Closing_Rank": "29"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "174",
   "Closing_Rank": "398"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "784",
   "Closing_Rank": "932"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "17P",
   "Closing_Rank": "17P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "109",
   "Closing_Rank": "217"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "474",
   "Closing_Rank": "484"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3834",
   "Closing_Rank": "4818"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11253",
   "Closing_Rank": "11253"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "909",
   "Closing_Rank": "1061"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2302",
   "Closing_Rank": "2302"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2018",
   "Closing_Rank": "2521"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6329",
   "Closing_Rank": "6329"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1242",
   "Closing_Rank": "1416"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2831",
   "Closing_Rank": "2831"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "835",
   "Closing_Rank": "835"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2456",
   "Closing_Rank": "3951"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6739",
   "Closing_Rank": "10019"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "280P",
   "Closing_Rank": "280P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "762",
   "Closing_Rank": "1208"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2298",
   "Closing_Rank": "2298"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1908",
   "Closing_Rank": "2690"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6528",
   "Closing_Rank": "6911"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1122",
   "Closing_Rank": "1642"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3074",
   "Closing_Rank": "3315"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1025",
   "Closing_Rank": "1118"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7985",
   "Closing_Rank": "9977"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15494",
   "Closing_Rank": "17322"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1505",
   "Closing_Rank": "1530"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3367",
   "Closing_Rank": "3367"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3931",
   "Closing_Rank": "4209"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8427",
   "Closing_Rank": "8470"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1082",
   "Closing_Rank": "2491"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3707",
   "Closing_Rank": "3707"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1233",
   "Closing_Rank": "1332"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geological Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "699P",
   "Closing_Rank": "699P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6436",
   "Closing_Rank": "9210"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16073",
   "Closing_Rank": "18299"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1233",
   "Closing_Rank": "1496"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3489",
   "Closing_Rank": "3489"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3487",
   "Closing_Rank": "3834"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8044",
   "Closing_Rank": "8416"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2254",
   "Closing_Rank": "2505"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3833",
   "Closing_Rank": "3833"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1162",
   "Closing_Rank": "1371"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Geophysical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "928P",
   "Closing_Rank": "928P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1089",
   "Closing_Rank": "1512"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4010",
   "Closing_Rank": "4814"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "137",
   "Closing_Rank": "137"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "255",
   "Closing_Rank": "270"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "876",
   "Closing_Rank": "876"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "511",
   "Closing_Rank": "857"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2178",
   "Closing_Rank": "2529"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "70P",
   "Closing_Rank": "70P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "266",
   "Closing_Rank": "611"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "912",
   "Closing_Rank": "912"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "261",
   "Closing_Rank": "338"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "823",
   "Closing_Rank": "823"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2526",
   "Closing_Rank": "3672"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7668",
   "Closing_Rank": "10517"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "366P",
   "Closing_Rank": "366P"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "513",
   "Closing_Rank": "686"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1879",
   "Closing_Rank": "2091"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1097",
   "Closing_Rank": "1784"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3784",
   "Closing_Rank": "5050"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "813",
   "Closing_Rank": "1010"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1604",
   "Closing_Rank": "2287"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "397",
   "Closing_Rank": "515"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "659",
   "Closing_Rank": "860"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6173",
   "Closing_Rank": "7051"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13244",
   "Closing_Rank": "15488"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1185",
   "Closing_Rank": "1290"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2711",
   "Closing_Rank": "2838"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2849",
   "Closing_Rank": "3349"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7420",
   "Closing_Rank": "7844"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1569",
   "Closing_Rank": "2001"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2927",
   "Closing_Rank": "3119"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "885",
   "Closing_Rank": "958"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1609",
   "Closing_Rank": "1609"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6021",
   "Closing_Rank": "9079"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12854",
   "Closing_Rank": "15922"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1575",
   "Closing_Rank": "1619"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3729",
   "Closing_Rank": "3729"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2560",
   "Closing_Rank": "4243"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8317",
   "Closing_Rank": "8466"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1898",
   "Closing_Rank": "2327"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3836",
   "Closing_Rank": "3836"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1426",
   "Closing_Rank": "1426"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4457",
   "Closing_Rank": "5365"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11018",
   "Closing_Rank": "11912"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "891",
   "Closing_Rank": "1048"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2505",
   "Closing_Rank": "2505"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2540",
   "Closing_Rank": "2936"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5993",
   "Closing_Rank": "6543"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1568",
   "Closing_Rank": "1749"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3168",
   "Closing_Rank": "3317"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "864",
   "Closing_Rank": "904"
 },
 {
   "Institute": "Indian Institute  of Technology Roorkee",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1588",
   "Closing_Rank": "1588"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13369",
   "Closing_Rank": "16011"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24275",
   "Closing_Rank": "24275"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2434",
   "Closing_Rank": "2459"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4733",
   "Closing_Rank": "4733"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3996",
   "Closing_Rank": "5756"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3132",
   "Closing_Rank": "3162"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4671",
   "Closing_Rank": "4671"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geology ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1458",
   "Closing_Rank": "1458"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15220",
   "Closing_Rank": "15591"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "25416",
   "Closing_Rank": "25416"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2392",
   "Closing_Rank": "2502"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4591",
   "Closing_Rank": "4591"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5779",
   "Closing_Rank": "5890"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2794",
   "Closing_Rank": "2996"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4789",
   "Closing_Rank": "4789"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Applied Geophysics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1451",
   "Closing_Rank": "1451"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7882",
   "Closing_Rank": "11398"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16313",
   "Closing_Rank": "19432"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1515",
   "Closing_Rank": "1674"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3300",
   "Closing_Rank": "3300"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3961",
   "Closing_Rank": "4316"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7539",
   "Closing_Rank": "8205"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2118",
   "Closing_Rank": "2384"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3770",
   "Closing_Rank": "3770"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1081",
   "Closing_Rank": "1152"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1513",
   "Closing_Rank": "1513"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9898",
   "Closing_Rank": "12996"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20786",
   "Closing_Rank": "23485"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1797",
   "Closing_Rank": "1950"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4263",
   "Closing_Rank": "4263"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3690",
   "Closing_Rank": "4547"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7843",
   "Closing_Rank": "8938"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2030",
   "Closing_Rank": "2412"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2857",
   "Closing_Rank": "4115"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "765",
   "Closing_Rank": "912"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1548",
   "Closing_Rank": "1548"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2167",
   "Closing_Rank": "3846"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5560",
   "Closing_Rank": "8682"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "85",
   "Closing_Rank": "125"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "349P",
   "Closing_Rank": "349P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "407",
   "Closing_Rank": "528"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1172",
   "Closing_Rank": "1361"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13P",
   "Closing_Rank": "13P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "758",
   "Closing_Rank": "1273"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1892",
   "Closing_Rank": "3220"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "47",
   "Closing_Rank": "39P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "427",
   "Closing_Rank": "699"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "754",
   "Closing_Rank": "1552"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "197",
   "Closing_Rank": "304"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "394",
   "Closing_Rank": "439"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4868",
   "Closing_Rank": "7567"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "13093",
   "Closing_Rank": "14711"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "970",
   "Closing_Rank": "1121"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2276",
   "Closing_Rank": "2283"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2127",
   "Closing_Rank": "2752"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4661",
   "Closing_Rank": "6161"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1062",
   "Closing_Rank": "1595"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2366",
   "Closing_Rank": "2870"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "550",
   "Closing_Rank": "801"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1308",
   "Closing_Rank": "1459"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4743",
   "Closing_Rank": "5982"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9327",
   "Closing_Rank": "12788"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "77P",
   "Closing_Rank": "323P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "759",
   "Closing_Rank": "880"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1855",
   "Closing_Rank": "2003"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1257",
   "Closing_Rank": "2102"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3161",
   "Closing_Rank": "4377"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "707",
   "Closing_Rank": "1214"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2091",
   "Closing_Rank": "2421"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "486",
   "Closing_Rank": "673"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "758",
   "Closing_Rank": "1020"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8392",
   "Closing_Rank": "10778"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16923",
   "Closing_Rank": "17730"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1518",
   "Closing_Rank": "1557"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3510",
   "Closing_Rank": "3510"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4049",
   "Closing_Rank": "4206"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8343",
   "Closing_Rank": "9217"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2077",
   "Closing_Rank": "2547"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4420",
   "Closing_Rank": "4420"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1270",
   "Closing_Rank": "1360"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9606",
   "Closing_Rank": "15291"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21683",
   "Closing_Rank": "23835"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "352P",
   "Closing_Rank": "352P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2184",
   "Closing_Rank": "2355"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3917",
   "Closing_Rank": "3917"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3755",
   "Closing_Rank": "5681"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2925",
   "Closing_Rank": "3113"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4628",
   "Closing_Rank": "4628"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1351",
   "Closing_Rank": "1441"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Environmental Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "390P",
   "Closing_Rank": "390P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4100",
   "Closing_Rank": "4956"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9026",
   "Closing_Rank": "10248"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "73P",
   "Closing_Rank": "73P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "560",
   "Closing_Rank": "677"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2016",
   "Closing_Rank": "2016"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1280",
   "Closing_Rank": "1663"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3565",
   "Closing_Rank": "4170"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "153P",
   "Closing_Rank": "153P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "759",
   "Closing_Rank": "1000"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1999",
   "Closing_Rank": "1999"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "340",
   "Closing_Rank": "603"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "642",
   "Closing_Rank": "642"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7675",
   "Closing_Rank": "9875"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16262",
   "Closing_Rank": "19099"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1354",
   "Closing_Rank": "1462"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2977",
   "Closing_Rank": "3036"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3021",
   "Closing_Rank": "3698"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6948",
   "Closing_Rank": "7762"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1711",
   "Closing_Rank": "2117"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3410",
   "Closing_Rank": "3695"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "674",
   "Closing_Rank": "1064"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1322",
   "Closing_Rank": "1639"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12446",
   "Closing_Rank": "13599"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21216",
   "Closing_Rank": "23512"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1992",
   "Closing_Rank": "2186"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3961",
   "Closing_Rank": "3961"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4736",
   "Closing_Rank": "5445"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2466",
   "Closing_Rank": "2960"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4110",
   "Closing_Rank": "4110"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1243",
   "Closing_Rank": "1340"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mineral and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "489P",
   "Closing_Rank": "489P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8439",
   "Closing_Rank": "14762"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19181",
   "Closing_Rank": "25824"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2094",
   "Closing_Rank": "2282"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4285",
   "Closing_Rank": "4393"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4352",
   "Closing_Rank": "5446"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8429",
   "Closing_Rank": "9262"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1787",
   "Closing_Rank": "2977"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3779",
   "Closing_Rank": "4552"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1082",
   "Closing_Rank": "1171"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "552P",
   "Closing_Rank": "687P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13690",
   "Closing_Rank": "15321"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21592",
   "Closing_Rank": "21592"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2177",
   "Closing_Rank": "2368"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4598",
   "Closing_Rank": "4598"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4644",
   "Closing_Rank": "5717"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2534",
   "Closing_Rank": "3102"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4730",
   "Closing_Rank": "4792"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1174",
   "Closing_Rank": "1338"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Mining Machinery Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "540P",
   "Closing_Rank": "540P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7795",
   "Closing_Rank": "12310"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16543",
   "Closing_Rank": "20817"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "107P",
   "Closing_Rank": "107P"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1721",
   "Closing_Rank": "1919"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3342",
   "Closing_Rank": "3763"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3586",
   "Closing_Rank": "4582"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7219",
   "Closing_Rank": "8736"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1976",
   "Closing_Rank": "2559"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4001",
   "Closing_Rank": "4266"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "716",
   "Closing_Rank": "1112"
 },
 {
   "Institute": "Indian Institute  of Technology (ISM) Dhanbad",
   "Academic_Program_Name": "Petroleum Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1543",
   "Closing_Rank": "1543"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2254",
   "Closing_Rank": "2656"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7074",
   "Closing_Rank": "8412"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "122",
   "Closing_Rank": "122"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "499",
   "Closing_Rank": "563"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1116",
   "Closing_Rank": "1308"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3785",
   "Closing_Rank": "3785"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "738",
   "Closing_Rank": "781"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1888",
   "Closing_Rank": "1888"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "440",
   "Closing_Rank": "449"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8597",
   "Closing_Rank": "10097"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14701",
   "Closing_Rank": "15740"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1527",
   "Closing_Rank": "1631"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2873",
   "Closing_Rank": "2873"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3836",
   "Closing_Rank": "4203"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8316",
   "Closing_Rank": "8382"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1955",
   "Closing_Rank": "2227"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3910",
   "Closing_Rank": "3910"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1127",
   "Closing_Rank": "1238"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1703",
   "Closing_Rank": "1703"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10126",
   "Closing_Rank": "11365"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "17886",
   "Closing_Rank": "18966"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1902",
   "Closing_Rank": "1949"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3714",
   "Closing_Rank": "3714"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4186",
   "Closing_Rank": "4371"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8232",
   "Closing_Rank": "8904"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2309",
   "Closing_Rank": "2419"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3778",
   "Closing_Rank": "3778"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "706",
   "Closing_Rank": "744"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1699",
   "Closing_Rank": "1699"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1158",
   "Closing_Rank": "2379"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3461",
   "Closing_Rank": "6044"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "126",
   "Closing_Rank": "136"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "234",
   "Closing_Rank": "291"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "818",
   "Closing_Rank": "1108"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "43P",
   "Closing_Rank": "43P"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "450",
   "Closing_Rank": "1084"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1492",
   "Closing_Rank": "2227"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "63",
   "Closing_Rank": "63"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "388",
   "Closing_Rank": "548"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1389",
   "Closing_Rank": "1641"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "242",
   "Closing_Rank": "419"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "773",
   "Closing_Rank": "773"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3406",
   "Closing_Rank": "5528"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6205",
   "Closing_Rank": "12038"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "795",
   "Closing_Rank": "925"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2150",
   "Closing_Rank": "2438"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1936",
   "Closing_Rank": "2590"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4632",
   "Closing_Rank": "6437"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1099",
   "Closing_Rank": "1593"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2172",
   "Closing_Rank": "2382"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "554",
   "Closing_Rank": "764"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1427",
   "Closing_Rank": "1427"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8158",
   "Closing_Rank": "9142"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16348",
   "Closing_Rank": "18747"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1543",
   "Closing_Rank": "1790"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3886",
   "Closing_Rank": "4310"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9270",
   "Closing_Rank": "9270"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2143",
   "Closing_Rank": "2573"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4477",
   "Closing_Rank": "4477"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1368",
   "Closing_Rank": "1391"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1755",
   "Closing_Rank": "3518"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8734",
   "Closing_Rank": "9494"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "411",
   "Closing_Rank": "552"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1912",
   "Closing_Rank": "1912"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1267",
   "Closing_Rank": "1724"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2514",
   "Closing_Rank": "2514"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "807",
   "Closing_Rank": "930"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1451",
   "Closing_Rank": "1451"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "599",
   "Closing_Rank": "620"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1316",
   "Closing_Rank": "1316"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6919",
   "Closing_Rank": "8636"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15057",
   "Closing_Rank": "17603"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1241",
   "Closing_Rank": "1494"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3095",
   "Closing_Rank": "3095"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3113",
   "Closing_Rank": "3810"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7008",
   "Closing_Rank": "8395"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1825",
   "Closing_Rank": "2128"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3154",
   "Closing_Rank": "3800"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "782",
   "Closing_Rank": "935"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "71P",
   "Closing_Rank": "71P"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11211",
   "Closing_Rank": "13009"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20595",
   "Closing_Rank": "21582"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2134",
   "Closing_Rank": "2208"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4611",
   "Closing_Rank": "4968"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8970",
   "Closing_Rank": "9018"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2652",
   "Closing_Rank": "2662"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4385",
   "Closing_Rank": "4385"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1366",
   "Closing_Rank": "1366"
 },
 {
   "Institute": "Indian Institute  of Technology Ropar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "936P",
   "Closing_Rank": "936P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "20507",
   "Closing_Rank": "22390"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "25278",
   "Closing_Rank": "25278"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3439",
   "Closing_Rank": "3467"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7644",
   "Closing_Rank": "8177"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3540",
   "Closing_Rank": "4321"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4991",
   "Closing_Rank": "4991"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "616P",
   "Closing_Rank": "764P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10717",
   "Closing_Rank": "12761"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20653",
   "Closing_Rank": "20966"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1976",
   "Closing_Rank": "2090"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5377",
   "Closing_Rank": "5680"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2686",
   "Closing_Rank": "2954"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4522",
   "Closing_Rank": "4522"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1350",
   "Closing_Rank": "1465"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11279",
   "Closing_Rank": "14001"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "17189",
   "Closing_Rank": "18700"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2061",
   "Closing_Rank": "2081"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4763",
   "Closing_Rank": "5361"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8573",
   "Closing_Rank": "8573"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2635",
   "Closing_Rank": "3125"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4763",
   "Closing_Rank": "4763"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Bioengineering with M.Tech in Biomedical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1461",
   "Closing_Rank": "1461"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10504",
   "Closing_Rank": "12154"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18654",
   "Closing_Rank": "22716"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1838",
   "Closing_Rank": "2044"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3629",
   "Closing_Rank": "3629"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3543",
   "Closing_Rank": "4895"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2245",
   "Closing_Rank": "2976"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4619",
   "Closing_Rank": "4675"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1297",
   "Closing_Rank": "1430"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "788P",
   "Closing_Rank": "788P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12260",
   "Closing_Rank": "13931"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24628",
   "Closing_Rank": "24753"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2222",
   "Closing_Rank": "2254"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4991",
   "Closing_Rank": "5501"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3005",
   "Closing_Rank": "3020"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4860",
   "Closing_Rank": "4860"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1456",
   "Closing_Rank": "1456"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5715",
   "Closing_Rank": "7757"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12307",
   "Closing_Rank": "16188"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1078",
   "Closing_Rank": "1314"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2608",
   "Closing_Rank": "2615"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2957",
   "Closing_Rank": "3404"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6033",
   "Closing_Rank": "7414"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1637",
   "Closing_Rank": "2078"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3129",
   "Closing_Rank": "3940"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "828",
   "Closing_Rank": "973"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1802",
   "Closing_Rank": "44P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7809",
   "Closing_Rank": "9339"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15552",
   "Closing_Rank": "18970"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1327",
   "Closing_Rank": "1426"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2829",
   "Closing_Rank": "2866"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3186",
   "Closing_Rank": "3819"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7465",
   "Closing_Rank": "8011"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1733",
   "Closing_Rank": "2064"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3128",
   "Closing_Rank": "3721"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "491",
   "Closing_Rank": "633"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1355",
   "Closing_Rank": "1472"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8533",
   "Closing_Rank": "10583"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18871",
   "Closing_Rank": "19360"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1646",
   "Closing_Rank": "1683"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3587",
   "Closing_Rank": "3587"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3545",
   "Closing_Rank": "4123"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7141",
   "Closing_Rank": "8759"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1956",
   "Closing_Rank": "2144"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3888",
   "Closing_Rank": "3888"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "712",
   "Closing_Rank": "800"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "667",
   "Closing_Rank": "1071"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2562",
   "Closing_Rank": "4130"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "27",
   "Closing_Rank": "40"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "142",
   "Closing_Rank": "187"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "507",
   "Closing_Rank": "517"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "226",
   "Closing_Rank": "524"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "880",
   "Closing_Rank": "1592"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14",
   "Closing_Rank": "14"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "192",
   "Closing_Rank": "262"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "527",
   "Closing_Rank": "851"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "19P",
   "Closing_Rank": "19P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "77",
   "Closing_Rank": "163"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "254",
   "Closing_Rank": "254"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1120",
   "Closing_Rank": "1510"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4165",
   "Closing_Rank": "4593"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "246",
   "Closing_Rank": "247"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1073",
   "Closing_Rank": "1073"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "553",
   "Closing_Rank": "727"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2055",
   "Closing_Rank": "2237"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "45",
   "Closing_Rank": "45"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "300",
   "Closing_Rank": "340"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1453",
   "Closing_Rank": "1453"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "210",
   "Closing_Rank": "279"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "278",
   "Closing_Rank": "278"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2852",
   "Closing_Rank": "3608"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6391",
   "Closing_Rank": "10177"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "121P",
   "Closing_Rank": "256P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "505",
   "Closing_Rank": "604"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1411",
   "Closing_Rank": "1658"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1107",
   "Closing_Rank": "1544"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3285",
   "Closing_Rank": "4181"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "123P",
   "Closing_Rank": "149P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "642",
   "Closing_Rank": "916"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1013",
   "Closing_Rank": "2086"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "353",
   "Closing_Rank": "497"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "754",
   "Closing_Rank": "920"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3826",
   "Closing_Rank": "4619"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10337",
   "Closing_Rank": "10830"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "637",
   "Closing_Rank": "675"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2153",
   "Closing_Rank": "2153"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1607",
   "Closing_Rank": "1896"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4355",
   "Closing_Rank": "4433"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1157",
   "Closing_Rank": "1350"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2830",
   "Closing_Rank": "2830"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "468",
   "Closing_Rank": "468"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electrical Engineering with M.Tech. in Power Electronics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1307",
   "Closing_Rank": "1307"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2110",
   "Closing_Rank": "2826"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5767",
   "Closing_Rank": "6986"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "150",
   "Closing_Rank": "163"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "179",
   "Closing_Rank": "179"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "401",
   "Closing_Rank": "492"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1119",
   "Closing_Rank": "1262"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "18P",
   "Closing_Rank": "18P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1027",
   "Closing_Rank": "1351"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2395",
   "Closing_Rank": "3268"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "524",
   "Closing_Rank": "808"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1683",
   "Closing_Rank": "2050"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "324",
   "Closing_Rank": "421"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "917",
   "Closing_Rank": "981"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6196",
   "Closing_Rank": "7764"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16241",
   "Closing_Rank": "16996"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1692",
   "Closing_Rank": "1777"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4076",
   "Closing_Rank": "4076"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3447",
   "Closing_Rank": "3874"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8040",
   "Closing_Rank": "8040"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2087",
   "Closing_Rank": "2255"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4467",
   "Closing_Rank": "4467"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1256",
   "Closing_Rank": "1271"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10813",
   "Closing_Rank": "12317"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21633",
   "Closing_Rank": "23182"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2077",
   "Closing_Rank": "2300"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4645",
   "Closing_Rank": "5474"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8455",
   "Closing_Rank": "8455"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2983",
   "Closing_Rank": "3046"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4487",
   "Closing_Rank": "4487"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1380",
   "Closing_Rank": "1476"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8540",
   "Closing_Rank": "12524"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19448",
   "Closing_Rank": "19700"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1827",
   "Closing_Rank": "1889"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3310",
   "Closing_Rank": "3310"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4849",
   "Closing_Rank": "5324"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2637",
   "Closing_Rank": "2797"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4376",
   "Closing_Rank": "4439"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Materials Science and Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1448",
   "Closing_Rank": "1448"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1538",
   "Closing_Rank": "2154"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4954",
   "Closing_Rank": "6186"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "121",
   "Closing_Rank": "121"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "310",
   "Closing_Rank": "346"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1105",
   "Closing_Rank": "1105"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "780",
   "Closing_Rank": "1028"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2578",
   "Closing_Rank": "2726"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "501",
   "Closing_Rank": "672"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "675",
   "Closing_Rank": "675"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "316",
   "Closing_Rank": "385"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "831",
   "Closing_Rank": "831"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4029",
   "Closing_Rank": "6596"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11998",
   "Closing_Rank": "15157"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "865",
   "Closing_Rank": "1152"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2275",
   "Closing_Rank": "2594"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2060",
   "Closing_Rank": "2829"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5585",
   "Closing_Rank": "6731"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1141",
   "Closing_Rank": "1654"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2993",
   "Closing_Rank": "3610"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "600",
   "Closing_Rank": "763"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1409",
   "Closing_Rank": "1585"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6689",
   "Closing_Rank": "7497"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14319",
   "Closing_Rank": "16564"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1244",
   "Closing_Rank": "1248"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3151",
   "Closing_Rank": "3151"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2746",
   "Closing_Rank": "3138"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6603",
   "Closing_Rank": "6772"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1671",
   "Closing_Rank": "1790"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "970",
   "Closing_Rank": "1006"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "267P",
   "Closing_Rank": "267P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7717",
   "Closing_Rank": "10740"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16644",
   "Closing_Rank": "19931"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1473",
   "Closing_Rank": "1714"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3003",
   "Closing_Rank": "3491"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3739",
   "Closing_Rank": "4494"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8302",
   "Closing_Rank": "8551"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2177",
   "Closing_Rank": "2477"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3929",
   "Closing_Rank": "4295"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1115",
   "Closing_Rank": "1296"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "358P",
   "Closing_Rank": "386P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10791",
   "Closing_Rank": "11530"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16837",
   "Closing_Rank": "22750"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1888",
   "Closing_Rank": "1947"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4080",
   "Closing_Rank": "4080"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4471",
   "Closing_Rank": "4945"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9190",
   "Closing_Rank": "9190"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2721",
   "Closing_Rank": "2744"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4086",
   "Closing_Rank": "4086"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Metallurgical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1352",
   "Closing_Rank": "1356"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10803",
   "Closing_Rank": "12876"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20895",
   "Closing_Rank": "24616"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1814",
   "Closing_Rank": "2014"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3647",
   "Closing_Rank": "3873"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4081",
   "Closing_Rank": "4990"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8101",
   "Closing_Rank": "9144"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2435",
   "Closing_Rank": "2924"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4563",
   "Closing_Rank": "4767"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1186",
   "Closing_Rank": "1300"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "496P",
   "Closing_Rank": "728P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12958",
   "Closing_Rank": "14557"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24523",
   "Closing_Rank": "25614"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2257",
   "Closing_Rank": "2351"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5004",
   "Closing_Rank": "5454"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2850",
   "Closing_Rank": "3054"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4884",
   "Closing_Rank": "4884"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1140",
   "Closing_Rank": "1140"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "905P",
   "Closing_Rank": "905P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11311",
   "Closing_Rank": "13562"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20701",
   "Closing_Rank": "22879"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1900",
   "Closing_Rank": "2139"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3700",
   "Closing_Rank": "3994"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4175",
   "Closing_Rank": "5464"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8658",
   "Closing_Rank": "9257"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2748",
   "Closing_Rank": "3118"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4816",
   "Closing_Rank": "4827"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1241",
   "Closing_Rank": "1449"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "500P",
   "Closing_Rank": "500P"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13669",
   "Closing_Rank": "14463"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21830",
   "Closing_Rank": "24870"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2229",
   "Closing_Rank": "2363"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5488",
   "Closing_Rank": "5622"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3135",
   "Closing_Rank": "3141"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4524",
   "Closing_Rank": "4524"
 },
 {
   "Institute": "Indian Institute  of Technology (BHU) Varanasi",
   "Academic_Program_Name": "Pharmaceutical Engineering &amp; Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1478",
   "Closing_Rank": "1515"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7410",
   "Closing_Rank": "9178"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15505",
   "Closing_Rank": "17156"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1224",
   "Closing_Rank": "1522"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3055",
   "Closing_Rank": "3292"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3576",
   "Closing_Rank": "4051"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7828",
   "Closing_Rank": "8486"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2139",
   "Closing_Rank": "2616"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3345",
   "Closing_Rank": "4053"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1198",
   "Closing_Rank": "1318"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Biosciences and Bioengineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1766",
   "Closing_Rank": "1766"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4618",
   "Closing_Rank": "5363"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11635",
   "Closing_Rank": "12557"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "867",
   "Closing_Rank": "1046"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2203",
   "Closing_Rank": "2270"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2413",
   "Closing_Rank": "2630"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5621",
   "Closing_Rank": "6011"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1224",
   "Closing_Rank": "1442"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2450",
   "Closing_Rank": "2736"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "743",
   "Closing_Rank": "811"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1317",
   "Closing_Rank": "1398"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5443",
   "Closing_Rank": "6834"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11731",
   "Closing_Rank": "14720"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1082",
   "Closing_Rank": "1198"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2349",
   "Closing_Rank": "2349"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2271",
   "Closing_Rank": "3307"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6121",
   "Closing_Rank": "6918"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1554",
   "Closing_Rank": "1868"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3483",
   "Closing_Rank": "3516"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "869",
   "Closing_Rank": "993"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Chemical Science and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "916",
   "Closing_Rank": "916"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5869",
   "Closing_Rank": "7670"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15033",
   "Closing_Rank": "16581"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1178",
   "Closing_Rank": "1300"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2545",
   "Closing_Rank": "2598"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2946",
   "Closing_Rank": "3258"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6204",
   "Closing_Rank": "7488"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1577",
   "Closing_Rank": "1761"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3091",
   "Closing_Rank": "3290"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "361",
   "Closing_Rank": "624"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "670",
   "Closing_Rank": "1246"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "466",
   "Closing_Rank": "623"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1788",
   "Closing_Rank": "2332"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "26",
   "Closing_Rank": "26"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "119",
   "Closing_Rank": "119"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "87",
   "Closing_Rank": "128"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "419",
   "Closing_Rank": "419"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6",
   "Closing_Rank": "6"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "31",
   "Closing_Rank": "31"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "212",
   "Closing_Rank": "364"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "862",
   "Closing_Rank": "950"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13",
   "Closing_Rank": "13"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "134",
   "Closing_Rank": "181"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "481",
   "Closing_Rank": "677"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4P",
   "Closing_Rank": "4P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "74",
   "Closing_Rank": "100"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "231",
   "Closing_Rank": "263"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "829",
   "Closing_Rank": "995"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2228",
   "Closing_Rank": "2748"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "134",
   "Closing_Rank": "157"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "493",
   "Closing_Rank": "493"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "360",
   "Closing_Rank": "479"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1151",
   "Closing_Rank": "1186"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "228",
   "Closing_Rank": "293"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "847",
   "Closing_Rank": "847"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "140",
   "Closing_Rank": "160"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "379",
   "Closing_Rank": "379"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1462",
   "Closing_Rank": "1658"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3688",
   "Closing_Rank": "4679"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "74",
   "Closing_Rank": "92"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "283P",
   "Closing_Rank": "283P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "267",
   "Closing_Rank": "347"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "915",
   "Closing_Rank": "990"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6P",
   "Closing_Rank": "6P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "682",
   "Closing_Rank": "869"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2114",
   "Closing_Rank": "2462"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "22P",
   "Closing_Rank": "22P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "351",
   "Closing_Rank": "485"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1230",
   "Closing_Rank": "1422"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "13P",
   "Closing_Rank": "13P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "204",
   "Closing_Rank": "282"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "556",
   "Closing_Rank": "556"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1686",
   "Closing_Rank": "2217"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5193",
   "Closing_Rank": "6141"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "157",
   "Closing_Rank": "157"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "368",
   "Closing_Rank": "412"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1131",
   "Closing_Rank": "1131"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "926",
   "Closing_Rank": "1040"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2364",
   "Closing_Rank": "3045"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "81P",
   "Closing_Rank": "81P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "556",
   "Closing_Rank": "585"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1423",
   "Closing_Rank": "1429"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "108",
   "Closing_Rank": "328"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Electronics and Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "663",
   "Closing_Rank": "663"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4960",
   "Closing_Rank": "5920"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9754",
   "Closing_Rank": "11615"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1076",
   "Closing_Rank": "1109"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2328",
   "Closing_Rank": "2802"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6588",
   "Closing_Rank": "6588"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1495",
   "Closing_Rank": "1682"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2698",
   "Closing_Rank": "2698"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Energy Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "720",
   "Closing_Rank": "720"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4302",
   "Closing_Rank": "5686"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10217",
   "Closing_Rank": "14111"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1007",
   "Closing_Rank": "1287"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2192",
   "Closing_Rank": "2192"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2648",
   "Closing_Rank": "3222"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4962",
   "Closing_Rank": "7221"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1839",
   "Closing_Rank": "2061"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3264",
   "Closing_Rank": "3913"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1130",
   "Closing_Rank": "1216"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1719",
   "Closing_Rank": "1719"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "722",
   "Closing_Rank": "1021"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2578",
   "Closing_Rank": "3350"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "67",
   "Closing_Rank": "67"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "161",
   "Closing_Rank": "180"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "649",
   "Closing_Rank": "743"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "372",
   "Closing_Rank": "614"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1179",
   "Closing_Rank": "1649"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "49P",
   "Closing_Rank": "49P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "272",
   "Closing_Rank": "393"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "952",
   "Closing_Rank": "1164"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "195",
   "Closing_Rank": "227"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "533",
   "Closing_Rank": "533"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3130",
   "Closing_Rank": "4523"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7018",
   "Closing_Rank": "12046"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "261P",
   "Closing_Rank": "261P"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "639",
   "Closing_Rank": "793"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1794",
   "Closing_Rank": "2141"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1778",
   "Closing_Rank": "2139"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4122",
   "Closing_Rank": "5376"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "613",
   "Closing_Rank": "1126"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2687",
   "Closing_Rank": "2978"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "453",
   "Closing_Rank": "579"
 },
 {
   "Institute": "Indian Institute  of Technology Guwahati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "947",
   "Closing_Rank": "1436"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3471",
   "Closing_Rank": "6516"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10240",
   "Closing_Rank": "11544"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "124P",
   "Closing_Rank": "124P"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "914",
   "Closing_Rank": "1018"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2023",
   "Closing_Rank": "2023"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2173",
   "Closing_Rank": "2470"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3453",
   "Closing_Rank": "5333"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "879",
   "Closing_Rank": "1021"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2083",
   "Closing_Rank": "2194"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "209",
   "Closing_Rank": "612"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6043",
   "Closing_Rank": "7570"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12695",
   "Closing_Rank": "14348"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "126P",
   "Closing_Rank": "126P"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1086",
   "Closing_Rank": "1201"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2172",
   "Closing_Rank": "2172"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2482",
   "Closing_Rank": "2811"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5797",
   "Closing_Rank": "5994"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1191",
   "Closing_Rank": "1332"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2443",
   "Closing_Rank": "2443"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Data Science and Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "613",
   "Closing_Rank": "687"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7987",
   "Closing_Rank": "10709"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16917",
   "Closing_Rank": "17772"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1516",
   "Closing_Rank": "1648"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2940",
   "Closing_Rank": "2940"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3003",
   "Closing_Rank": "3999"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7109",
   "Closing_Rank": "8020"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1803",
   "Closing_Rank": "2207"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3134",
   "Closing_Rank": "3408"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "826",
   "Closing_Rank": "1068"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1521",
   "Closing_Rank": "1521"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8375",
   "Closing_Rank": "9472"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15017",
   "Closing_Rank": "15657"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "343P",
   "Closing_Rank": "343P"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1265",
   "Closing_Rank": "1286"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2335",
   "Closing_Rank": "2335"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2982",
   "Closing_Rank": "3539"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6070",
   "Closing_Rank": "7148"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1814",
   "Closing_Rank": "1904"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2952",
   "Closing_Rank": "2952"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "777",
   "Closing_Rank": "902"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1506",
   "Closing_Rank": "1506"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "15066",
   "Closing_Rank": "15871"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "25106",
   "Closing_Rank": "25107"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2338",
   "Closing_Rank": "2378"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5647",
   "Closing_Rank": "5810"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3057",
   "Closing_Rank": "3089"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4676",
   "Closing_Rank": "4676"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1379",
   "Closing_Rank": "1379"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11065",
   "Closing_Rank": "13137"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21738",
   "Closing_Rank": "23440"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1787",
   "Closing_Rank": "2017"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3827",
   "Closing_Rank": "3827"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4449",
   "Closing_Rank": "4803"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8617",
   "Closing_Rank": "8930"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2360",
   "Closing_Rank": "2609"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4142",
   "Closing_Rank": "4142"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1169",
   "Closing_Rank": "1230"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "306P",
   "Closing_Rank": "306P"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8535",
   "Closing_Rank": "10843"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15399",
   "Closing_Rank": "15399"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1739",
   "Closing_Rank": "1739"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3412",
   "Closing_Rank": "3412"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3016",
   "Closing_Rank": "4511"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7479",
   "Closing_Rank": "7479"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1872",
   "Closing_Rank": "2189"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1204",
   "Closing_Rank": "1327"
 },
 {
   "Institute": "Indian Institute of Technology Bhilai",
   "Academic_Program_Name": "Mechatronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "622P",
   "Closing_Rank": "622P"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4070",
   "Closing_Rank": "5371"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10256",
   "Closing_Rank": "11151"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "647",
   "Closing_Rank": "776"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2155",
   "Closing_Rank": "2155"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1756",
   "Closing_Rank": "2002"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4318",
   "Closing_Rank": "5336"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "120P",
   "Closing_Rank": "120P"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "732",
   "Closing_Rank": "1024"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "968",
   "Closing_Rank": "968"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "399",
   "Closing_Rank": "519"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1272",
   "Closing_Rank": "1272"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7765",
   "Closing_Rank": "9603"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15164",
   "Closing_Rank": "16560"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1366",
   "Closing_Rank": "1634"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2597",
   "Closing_Rank": "2597"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2992",
   "Closing_Rank": "3573"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7365",
   "Closing_Rank": "7435"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1771",
   "Closing_Rank": "1889"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2935",
   "Closing_Rank": "2935"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "926",
   "Closing_Rank": "961"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1660",
   "Closing_Rank": "1660"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4928",
   "Closing_Rank": "6685"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12515",
   "Closing_Rank": "13381"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "950",
   "Closing_Rank": "1138"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2103",
   "Closing_Rank": "2593"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5327",
   "Closing_Rank": "5977"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1352",
   "Closing_Rank": "1613"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "787",
   "Closing_Rank": "814"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10277",
   "Closing_Rank": "12066"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19611",
   "Closing_Rank": "20054"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1682",
   "Closing_Rank": "1781"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3178",
   "Closing_Rank": "3178"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4133",
   "Closing_Rank": "4696"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9232",
   "Closing_Rank": "9256"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2175",
   "Closing_Rank": "2427"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4019",
   "Closing_Rank": "4169"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1065",
   "Closing_Rank": "1151"
 },
 {
   "Institute": "Indian Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "544P",
   "Closing_Rank": "544P"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12874",
   "Closing_Rank": "15812"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19444",
   "Closing_Rank": "23384"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2242",
   "Closing_Rank": "2408"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4351",
   "Closing_Rank": "4351"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4276",
   "Closing_Rank": "5565"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9281",
   "Closing_Rank": "9281"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2633",
   "Closing_Rank": "2845"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4304",
   "Closing_Rank": "4304"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1153",
   "Closing_Rank": "1153"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "275P",
   "Closing_Rank": "275P"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4837",
   "Closing_Rank": "6199"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8303",
   "Closing_Rank": "9127"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "371P",
   "Closing_Rank": "371P"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "794",
   "Closing_Rank": "819"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1841",
   "Closing_Rank": "1841"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1567",
   "Closing_Rank": "2069"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3591",
   "Closing_Rank": "3962"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "41P",
   "Closing_Rank": "41P"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "475",
   "Closing_Rank": "1097"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1821",
   "Closing_Rank": "1821"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "537",
   "Closing_Rank": "564"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "834",
   "Closing_Rank": "834"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6267",
   "Closing_Rank": "8084"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12138",
   "Closing_Rank": "13283"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "214P",
   "Closing_Rank": "214P"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1054",
   "Closing_Rank": "1176"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2033",
   "Closing_Rank": "2033"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2412",
   "Closing_Rank": "2788"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4822",
   "Closing_Rank": "5639"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1371",
   "Closing_Rank": "1602"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2742",
   "Closing_Rank": "2742"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "718",
   "Closing_Rank": "722"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1275",
   "Closing_Rank": "1275"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "8381",
   "Closing_Rank": "9625"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12222",
   "Closing_Rank": "15014"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1268",
   "Closing_Rank": "1498"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2247",
   "Closing_Rank": "2247"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3180",
   "Closing_Rank": "3764"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6969",
   "Closing_Rank": "7181"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1886",
   "Closing_Rank": "2040"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2488",
   "Closing_Rank": "2488"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "896",
   "Closing_Rank": "965"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1552",
   "Closing_Rank": "1552"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10901",
   "Closing_Rank": "12540"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15990",
   "Closing_Rank": "21578"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1710",
   "Closing_Rank": "1897"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3701",
   "Closing_Rank": "3701"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3458",
   "Closing_Rank": "4625"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7669",
   "Closing_Rank": "8130"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2018",
   "Closing_Rank": "2440"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3546",
   "Closing_Rank": "3546"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1119",
   "Closing_Rank": "1187"
 },
 {
   "Institute": "Indian Institute  of Technology Palakkad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1786",
   "Closing_Rank": "1786"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12624",
   "Closing_Rank": "14341"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "16379",
   "Closing_Rank": "21110"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2041",
   "Closing_Rank": "2324"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3426",
   "Closing_Rank": "3426"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4996",
   "Closing_Rank": "5277"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6944",
   "Closing_Rank": "8251"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2406",
   "Closing_Rank": "2979"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4007",
   "Closing_Rank": "4007"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1263",
   "Closing_Rank": "1291"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1789",
   "Closing_Rank": "1789"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11383",
   "Closing_Rank": "15429"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21139",
   "Closing_Rank": "23179"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2271",
   "Closing_Rank": "2374"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3528",
   "Closing_Rank": "3528"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4477",
   "Closing_Rank": "5432"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7602",
   "Closing_Rank": "8332"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2565",
   "Closing_Rank": "2754"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4324",
   "Closing_Rank": "4324"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "994",
   "Closing_Rank": "994"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "236P",
   "Closing_Rank": "236P"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1915",
   "Closing_Rank": "5024"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5737",
   "Closing_Rank": "6324"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14P",
   "Closing_Rank": "37P"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "589",
   "Closing_Rank": "727"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "913",
   "Closing_Rank": "913"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1242",
   "Closing_Rank": "1727"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2907",
   "Closing_Rank": "3342"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "51P",
   "Closing_Rank": "51P"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "343",
   "Closing_Rank": "1057"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1419",
   "Closing_Rank": "1419"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "24P",
   "Closing_Rank": "24P"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "129",
   "Closing_Rank": "492"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "750",
   "Closing_Rank": "750"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6236",
   "Closing_Rank": "8816"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7711",
   "Closing_Rank": "12402"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1271",
   "Closing_Rank": "1406"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2441",
   "Closing_Rank": "2441"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2177",
   "Closing_Rank": "3389"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4823",
   "Closing_Rank": "6912"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1508",
   "Closing_Rank": "1851"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2499",
   "Closing_Rank": "2809"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "845",
   "Closing_Rank": "951"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1364",
   "Closing_Rank": "1364"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9589",
   "Closing_Rank": "12605"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "17187",
   "Closing_Rank": "17187"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2123",
   "Closing_Rank": "2123"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4535",
   "Closing_Rank": "4808"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "9033",
   "Closing_Rank": "9033"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3026",
   "Closing_Rank": "3026"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1190",
   "Closing_Rank": "1190"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9340",
   "Closing_Rank": "11509"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "14969",
   "Closing_Rank": "20361"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1677",
   "Closing_Rank": "1758"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3045",
   "Closing_Rank": "3045"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3807",
   "Closing_Rank": "4295"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7373",
   "Closing_Rank": "7833"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2322",
   "Closing_Rank": "2558"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2923",
   "Closing_Rank": "3875"
 },
 {
   "Institute": "Indian Institute  of Technology Tirupati",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1000",
   "Closing_Rank": "1117"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "12943",
   "Closing_Rank": "15048"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "18930",
   "Closing_Rank": "21759"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2104",
   "Closing_Rank": "2376"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3588",
   "Closing_Rank": "3588"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5259",
   "Closing_Rank": "5802"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2939",
   "Closing_Rank": "3043"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4151",
   "Closing_Rank": "4151"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1257",
   "Closing_Rank": "1330"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "787P",
   "Closing_Rank": "787P"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "11454",
   "Closing_Rank": "15583"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "23902",
   "Closing_Rank": "25172"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2391",
   "Closing_Rank": "2428"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4404",
   "Closing_Rank": "4404"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5386",
   "Closing_Rank": "5807"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2843",
   "Closing_Rank": "3015"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4510",
   "Closing_Rank": "4510"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "914",
   "Closing_Rank": "1073"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "480P",
   "Closing_Rank": "480P"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3412",
   "Closing_Rank": "6310"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "8353",
   "Closing_Rank": "12108"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "831",
   "Closing_Rank": "945"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2119",
   "Closing_Rank": "2119"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1584",
   "Closing_Rank": "2341"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5389",
   "Closing_Rank": "6685"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "923",
   "Closing_Rank": "1093"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2147",
   "Closing_Rank": "2147"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "525",
   "Closing_Rank": "635"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7727",
   "Closing_Rank": "10005"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15039",
   "Closing_Rank": "18177"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1510",
   "Closing_Rank": "1745"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2830",
   "Closing_Rank": "2830"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3333",
   "Closing_Rank": "4237"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7429",
   "Closing_Rank": "7775"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1824",
   "Closing_Rank": "2208"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3716",
   "Closing_Rank": "3716"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1039",
   "Closing_Rank": "1155"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14903",
   "Closing_Rank": "16439"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24005",
   "Closing_Rank": "25778"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2443",
   "Closing_Rank": "2472"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4659",
   "Closing_Rank": "4659"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5856",
   "Closing_Rank": "6032"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2674",
   "Closing_Rank": "3164"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4851",
   "Closing_Rank": "4851"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1390",
   "Closing_Rank": "1512"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "941P",
   "Closing_Rank": "941P"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "6555",
   "Closing_Rank": "7956"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "12130",
   "Closing_Rank": "14105"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "17P",
   "Closing_Rank": "17P"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1095",
   "Closing_Rank": "1191"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2610",
   "Closing_Rank": "3141"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7179",
   "Closing_Rank": "7240"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1273",
   "Closing_Rank": "1566"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2239",
   "Closing_Rank": "2750"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "892",
   "Closing_Rank": "1087"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10912",
   "Closing_Rank": "12483"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "21564",
   "Closing_Rank": "23887"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1925",
   "Closing_Rank": "1967"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3921",
   "Closing_Rank": "3921"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4518",
   "Closing_Rank": "5048"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2470",
   "Closing_Rank": "2650"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3839",
   "Closing_Rank": "3839"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1208",
   "Closing_Rank": "1236"
 },
 {
   "Institute": "Indian Institute of Technology Jammu",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "733P",
   "Closing_Rank": "733P"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14948",
   "Closing_Rank": "15739"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24344",
   "Closing_Rank": "24506"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2306",
   "Closing_Rank": "2463"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4558",
   "Closing_Rank": "4558"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5243",
   "Closing_Rank": "5830"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3058",
   "Closing_Rank": "3121"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4059",
   "Closing_Rank": "4059"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Chemical and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1301",
   "Closing_Rank": "1337"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "14375",
   "Closing_Rank": "15945"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "24560",
   "Closing_Rank": "25079"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2417",
   "Closing_Rank": "2422"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3922",
   "Closing_Rank": "3922"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5268",
   "Closing_Rank": "5549"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2627",
   "Closing_Rank": "2856"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4513",
   "Closing_Rank": "4513"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Civil and Infrastructure Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1017",
   "Closing_Rank": "1147"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3482",
   "Closing_Rank": "6375"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4989",
   "Closing_Rank": "11138"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "66P",
   "Closing_Rank": "66P"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "771",
   "Closing_Rank": "906"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1652",
   "Closing_Rank": "1652"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1732",
   "Closing_Rank": "2225"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4785",
   "Closing_Rank": "5409"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "148P",
   "Closing_Rank": "148P"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "975",
   "Closing_Rank": "1295"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1716",
   "Closing_Rank": "2129"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "422",
   "Closing_Rank": "607"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1044",
   "Closing_Rank": "1044"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "7796",
   "Closing_Rank": "9778"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "15007",
   "Closing_Rank": "15299"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1464",
   "Closing_Rank": "1567"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2840",
   "Closing_Rank": "2840"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3470",
   "Closing_Rank": "3823"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7268",
   "Closing_Rank": "7297"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1828",
   "Closing_Rank": "2080"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2964",
   "Closing_Rank": "2964"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "872",
   "Closing_Rank": "906"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4752",
   "Closing_Rank": "8772"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "10983",
   "Closing_Rank": "14717"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "106P",
   "Closing_Rank": "106P"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1336",
   "Closing_Rank": "1458"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2122",
   "Closing_Rank": "2122"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2762",
   "Closing_Rank": "3368"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "5820",
   "Closing_Rank": "6982"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1443",
   "Closing_Rank": "1712"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2734",
   "Closing_Rank": "2734"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "732",
   "Closing_Rank": "850"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1347",
   "Closing_Rank": "1347"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10655",
   "Closing_Rank": "12851"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "20242",
   "Closing_Rank": "20440"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2265",
   "Closing_Rank": "2339"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3749",
   "Closing_Rank": "3749"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5100",
   "Closing_Rank": "5258"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2948",
   "Closing_Rank": "3133"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4066",
   "Closing_Rank": "4066"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1405",
   "Closing_Rank": "1442"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "9258",
   "Closing_Rank": "16968"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "23467",
   "Closing_Rank": "25762"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2473",
   "Closing_Rank": "2524"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4489",
   "Closing_Rank": "4489"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "5925",
   "Closing_Rank": "6185"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "3183",
   "Closing_Rank": "3302"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4768",
   "Closing_Rank": "4859"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1503",
   "Closing_Rank": "1549"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Interdisciplinary Sciences ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree)",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1036P",
   "Closing_Rank": "1036P"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4677",
   "Closing_Rank": "7381"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "11879",
   "Closing_Rank": "12205"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "885",
   "Closing_Rank": "1167"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2046",
   "Closing_Rank": "2046"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2613",
   "Closing_Rank": "2819"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "6616",
   "Closing_Rank": "6867"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1090",
   "Closing_Rank": "1479"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "2799",
   "Closing_Rank": "2799"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "596",
   "Closing_Rank": "788"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "10317",
   "Closing_Rank": "13435"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "19074",
   "Closing_Rank": "21591"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "2024",
   "Closing_Rank": "2196"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "3563",
   "Closing_Rank": "3563"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "4218",
   "Closing_Rank": "5082"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "7628",
   "Closing_Rank": "9014"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "1327",
   "Closing_Rank": "2629"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "4302",
   "Closing_Rank": "4303"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": "664",
   "Closing_Rank": "1213"
 },
 {
   "Institute": "Indian Institute of Technology Dharwad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "AI",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": "1687",
   "Closing_Rank": "1687"
 }
]
