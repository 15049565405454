export const BITSClg = [
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Chemical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "252",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Civil",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "242",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Electrical & Electronics",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "296",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Mechanical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "244",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "331",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Electronics & Instrumentation",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "285",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Electronics & Communication",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "318",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Manufacturing",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "251",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Pharm",
    duration: "4 Years",
    Degree_Type: "B.Pharm",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "178",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Biological Sciences",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "239",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Chemistry",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "243",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Economics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "274",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Mathematics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "259",
  },
  {
    Institute: "BITS-Pilani ",
    Academic_Program_Name: "Physics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "255",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Chemical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "242",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Electrical & Electronics",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "278",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Mechanical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "258",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "304",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Electronics & Instrumentation",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "271",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Electronics & Communication",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "289",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Biological Sciences",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "236",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Chemistry",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "238",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Economics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "265",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Mathematics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "246",
  },
  {
    Institute: "BITS-Goa ",
    Academic_Program_Name: "Physics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "250",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Chemical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "241",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Civil",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "237",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Electrical & Electronics",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "275",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Mechanical",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "252",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "298",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Electronics & Instrumentation",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "270",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Electronics & Communication",
    duration: "4 Years",
    Degree_Type: "BE",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "285",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Pharm",
    duration: "4 Years",
    Degree_Type: "B.Pharm",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "186",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Biological Sciences",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "234",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Chemistry",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "237",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Economics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "262",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Mathematics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "248",
  },
  {
    Institute: "BITS-Hyderabad ",
    Academic_Program_Name: "Physics",
    duration: "5 Years",
    Degree_Type: "BE + M.Sc.",
    Quota: "AI",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Closing_Rank: "245",
  },
  
];
