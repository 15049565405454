export const NIT = [
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56402,
   "Closing_Rank": 67216
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52325,
   "Closing_Rank": 52325
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13529,
   "Closing_Rank": 14091
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13481,
   "Closing_Rank": 13481
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52003,
   "Closing_Rank": 81089
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41323,
   "Closing_Rank": 41323
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11387,
   "Closing_Rank": 12966
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12329,
   "Closing_Rank": 12329
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39435,
   "Closing_Rank": 47967
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 54448,
   "Closing_Rank": 57854
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7793,
   "Closing_Rank": 7839
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15038,
   "Closing_Rank": 16245
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18751,
   "Closing_Rank": 18751
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7472,
   "Closing_Rank": 7723
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9772,
   "Closing_Rank": 9772
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2675,
   "Closing_Rank": 2875
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36773,
   "Closing_Rank": 56585
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38712,
   "Closing_Rank": 51020
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 979,
   "Closing_Rank": 979
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10871,
   "Closing_Rank": 11340
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10402,
   "Closing_Rank": 10402
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48968,
   "Closing_Rank": 64555
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44955,
   "Closing_Rank": 62792
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7565,
   "Closing_Rank": 10304
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10115,
   "Closing_Rank": 10115
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31059,
   "Closing_Rank": 37272
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41493,
   "Closing_Rank": 42080
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2013,
   "Closing_Rank": 2013
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5981,
   "Closing_Rank": 5981
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8350,
   "Closing_Rank": 8350
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10761,
   "Closing_Rank": 12648
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15841,
   "Closing_Rank": 16448
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5270,
   "Closing_Rank": 6743
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7303,
   "Closing_Rank": 7303
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2359,
   "Closing_Rank": 2493
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3849,
   "Closing_Rank": 3849
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55135,
   "Closing_Rank": 66693
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55142,
   "Closing_Rank": 67182
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10955,
   "Closing_Rank": 12304
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11219,
   "Closing_Rank": 11219
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40504,
   "Closing_Rank": 62329
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52917,
   "Closing_Rank": 58441
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8777,
   "Closing_Rank": 11809
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22842,
   "Closing_Rank": 22842
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37743,
   "Closing_Rank": 44274
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50776,
   "Closing_Rank": 54742
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7142,
   "Closing_Rank": 7181
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9339,
   "Closing_Rank": 9339
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13014,
   "Closing_Rank": 14293
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19773,
   "Closing_Rank": 20397
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4999,
   "Closing_Rank": 6228
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7576,
   "Closing_Rank": 7576
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1297,
   "Closing_Rank": 1307
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2735,
   "Closing_Rank": 2735
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10292,
   "Closing_Rank": 16940
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14911,
   "Closing_Rank": 20475
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 657,
   "Closing_Rank": 657
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2938,
   "Closing_Rank": 4051
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3239,
   "Closing_Rank": 3239
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 464,
   "Closing_Rank": 464
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5814,
   "Closing_Rank": 12760
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6128,
   "Closing_Rank": 15646
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2346,
   "Closing_Rank": 4220
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4131,
   "Closing_Rank": 5763
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 198,
   "Closing_Rank": 198
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2480,
   "Closing_Rank": 6283
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12636,
   "Closing_Rank": 12636
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6330,
   "Closing_Rank": 10957
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14367,
   "Closing_Rank": 17115
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 360,
   "Closing_Rank": 360
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 271,
   "Closing_Rank": 271
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1576,
   "Closing_Rank": 1854
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2658,
   "Closing_Rank": 2658
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2752,
   "Closing_Rank": 3834
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6499,
   "Closing_Rank": 7675
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 198,
   "Closing_Rank": 198
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1461,
   "Closing_Rank": 2109
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2606,
   "Closing_Rank": 3012
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 462,
   "Closing_Rank": 812
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1485,
   "Closing_Rank": 1485
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37,
   "Closing_Rank": 37
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16983,
   "Closing_Rank": 20116
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21470,
   "Closing_Rank": 21470
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4823,
   "Closing_Rank": 4903
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12902,
   "Closing_Rank": 21147
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31609,
   "Closing_Rank": 40718
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4622,
   "Closing_Rank": 5140
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6741,
   "Closing_Rank": 6741
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6872,
   "Closing_Rank": 6872
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11121,
   "Closing_Rank": 14016
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20893,
   "Closing_Rank": 22062
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 575,
   "Closing_Rank": 575
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2129,
   "Closing_Rank": 2239
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5049,
   "Closing_Rank": 5293
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7871,
   "Closing_Rank": 7871
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2501,
   "Closing_Rank": 2827
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4676,
   "Closing_Rank": 4676
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1140,
   "Closing_Rank": 1140
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Data Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45,
   "Closing_Rank": 45
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28005,
   "Closing_Rank": 33719
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34911,
   "Closing_Rank": 35126
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6845,
   "Closing_Rank": 7109
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8759,
   "Closing_Rank": 8759
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17886,
   "Closing_Rank": 38019
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41167,
   "Closing_Rank": 47050
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6375,
   "Closing_Rank": 7741
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8258,
   "Closing_Rank": 8258
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20623,
   "Closing_Rank": 20623
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11802,
   "Closing_Rank": 21545
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27336,
   "Closing_Rank": 30087
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1193,
   "Closing_Rank": 1193
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3422,
   "Closing_Rank": 3474
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5200,
   "Closing_Rank": 5200
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6597,
   "Closing_Rank": 7231
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10974,
   "Closing_Rank": 10974
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3909,
   "Closing_Rank": 4170
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5036,
   "Closing_Rank": 5036
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1336,
   "Closing_Rank": 1355
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2598,
   "Closing_Rank": 2598
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14933,
   "Closing_Rank": 26835
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28204,
   "Closing_Rank": 32778
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2409,
   "Closing_Rank": 2409
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4315,
   "Closing_Rank": 6149
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7307,
   "Closing_Rank": 7307
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13511,
   "Closing_Rank": 33738
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21898,
   "Closing_Rank": 40885
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4550,
   "Closing_Rank": 6638
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7387,
   "Closing_Rank": 7387
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6588,
   "Closing_Rank": 9295
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19836,
   "Closing_Rank": 19836
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11652,
   "Closing_Rank": 15270
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22206,
   "Closing_Rank": 23262
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 848,
   "Closing_Rank": 941
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2388,
   "Closing_Rank": 2412
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3811,
   "Closing_Rank": 3811
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 152,
   "Closing_Rank": 152
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5133,
   "Closing_Rank": 5653
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8211,
   "Closing_Rank": 8408
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3120,
   "Closing_Rank": 3552
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4615,
   "Closing_Rank": 4615
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1101,
   "Closing_Rank": 1167
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1569,
   "Closing_Rank": 1569
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26375,
   "Closing_Rank": 28308
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33893,
   "Closing_Rank": 33893
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6966,
   "Closing_Rank": 6966
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31100,
   "Closing_Rank": 35696
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41484,
   "Closing_Rank": 41484
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6134,
   "Closing_Rank": 6917
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8161,
   "Closing_Rank": 8161
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29341,
   "Closing_Rank": 29341
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15751,
   "Closing_Rank": 16973
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25894,
   "Closing_Rank": 25894
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1184,
   "Closing_Rank": 1184
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2595,
   "Closing_Rank": 2636
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5925,
   "Closing_Rank": 6347
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10301,
   "Closing_Rank": 10301
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3928,
   "Closing_Rank": 3928
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5579,
   "Closing_Rank": 5579
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1884,
   "Closing_Rank": 1884
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51820,
   "Closing_Rank": 67982
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36750,
   "Closing_Rank": 63028
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12054,
   "Closing_Rank": 13177
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13447,
   "Closing_Rank": 13447
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60779,
   "Closing_Rank": 74512
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56040,
   "Closing_Rank": 80485
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9709,
   "Closing_Rank": 14319
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14955,
   "Closing_Rank": 14955
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37487,
   "Closing_Rank": 46905
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50826,
   "Closing_Rank": 55941
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3491,
   "Closing_Rank": 3491
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7709,
   "Closing_Rank": 7763
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13878,
   "Closing_Rank": 16102
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16630,
   "Closing_Rank": 16630
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6956,
   "Closing_Rank": 7383
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10263,
   "Closing_Rank": 10263
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2470,
   "Closing_Rank": 2929
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Industrial and Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2420,
   "Closing_Rank": 2420
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17521,
   "Closing_Rank": 26077
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21818,
   "Closing_Rank": 25376
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4504,
   "Closing_Rank": 5397
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4297,
   "Closing_Rank": 4297
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12957,
   "Closing_Rank": 20809
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23552,
   "Closing_Rank": 30554
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4090,
   "Closing_Rank": 6508
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6389,
   "Closing_Rank": 7016
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6338,
   "Closing_Rank": 19832
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10765,
   "Closing_Rank": 14120
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16421,
   "Closing_Rank": 21173
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 573,
   "Closing_Rank": 573
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2033,
   "Closing_Rank": 2033
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2110,
   "Closing_Rank": 2220
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3841,
   "Closing_Rank": 3841
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3941,
   "Closing_Rank": 4985
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7827,
   "Closing_Rank": 8296
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 340,
   "Closing_Rank": 340
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2805,
   "Closing_Rank": 3107
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3427,
   "Closing_Rank": 3427
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 978,
   "Closing_Rank": 1173
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35090,
   "Closing_Rank": 48219
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36352,
   "Closing_Rank": 42712
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8030,
   "Closing_Rank": 10288
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8794,
   "Closing_Rank": 8794
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36232,
   "Closing_Rank": 48231
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48210,
   "Closing_Rank": 53998
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7747,
   "Closing_Rank": 9775
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9647,
   "Closing_Rank": 9647
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23302,
   "Closing_Rank": 30740
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34421,
   "Closing_Rank": 39256
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2481,
   "Closing_Rank": 2481
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5449,
   "Closing_Rank": 5542
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6363,
   "Closing_Rank": 6363
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8431,
   "Closing_Rank": 11353
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14216,
   "Closing_Rank": 17410
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1486,
   "Closing_Rank": 1486
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4708,
   "Closing_Rank": 6410
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5683,
   "Closing_Rank": 5683
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2605,
   "Closing_Rank": 2869
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3248,
   "Closing_Rank": 3248
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21758,
   "Closing_Rank": 26506
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27011,
   "Closing_Rank": 27011
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4979,
   "Closing_Rank": 5775
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15075,
   "Closing_Rank": 31705
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30730,
   "Closing_Rank": 30730
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7257,
   "Closing_Rank": 7725
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7233,
   "Closing_Rank": 7233
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6525,
   "Closing_Rank": 6525
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11793,
   "Closing_Rank": 14559
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22482,
   "Closing_Rank": 23411
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1541,
   "Closing_Rank": 1541
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2270,
   "Closing_Rank": 2365
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4690,
   "Closing_Rank": 5314
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8651,
   "Closing_Rank": 8651
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2982,
   "Closing_Rank": 3466
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4855,
   "Closing_Rank": 4855
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1088,
   "Closing_Rank": 1088
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29363,
   "Closing_Rank": 48460
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45677,
   "Closing_Rank": 49462
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8135,
   "Closing_Rank": 10528
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25038,
   "Closing_Rank": 47293
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41011,
   "Closing_Rank": 56439
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8442,
   "Closing_Rank": 10075
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12658,
   "Closing_Rank": 13333
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23454,
   "Closing_Rank": 30878
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41224,
   "Closing_Rank": 43899
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1513,
   "Closing_Rank": 1513
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4953,
   "Closing_Rank": 5067
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7808,
   "Closing_Rank": 7808
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9064,
   "Closing_Rank": 10702
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16501,
   "Closing_Rank": 17625
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1751,
   "Closing_Rank": 1751
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5017,
   "Closing_Rank": 5733
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8464,
   "Closing_Rank": 8626
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2143,
   "Closing_Rank": 2293
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3699,
   "Closing_Rank": 3699
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 67538,
   "Closing_Rank": 80933
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 68128,
   "Closing_Rank": 76737
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14138,
   "Closing_Rank": 14966
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14493,
   "Closing_Rank": 14493
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66171,
   "Closing_Rank": 81118
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 78061,
   "Closing_Rank": 83770
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12817,
   "Closing_Rank": 15261
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15499,
   "Closing_Rank": 15499
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46988,
   "Closing_Rank": 50863
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56931,
   "Closing_Rank": 58773
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8611,
   "Closing_Rank": 8679
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9710,
   "Closing_Rank": 9710
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16322,
   "Closing_Rank": 17382
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21107,
   "Closing_Rank": 21114
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7929,
   "Closing_Rank": 8911
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10819,
   "Closing_Rank": 10831
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3388,
   "Closing_Rank": 3462
 },
 {
   "Institute": "Dr. B R Ambedkar National Institute of Technolog Jalandhar",
   "Academic_Program_Name": "Textile Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3592,
   "Closing_Rank": 3592
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 757,
   "Closing_Rank": 2524
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 712,
   "Closing_Rank": 1296
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 314,
   "Closing_Rank": 751
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1134,
   "Closing_Rank": 2298
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1476,
   "Closing_Rank": 1493
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 424,
   "Closing_Rank": 1669
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 368,
   "Closing_Rank": 368
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 433,
   "Closing_Rank": 650
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1545,
   "Closing_Rank": 1545
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 497,
   "Closing_Rank": 926
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 316,
   "Closing_Rank": 631
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 270,
   "Closing_Rank": 417
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 165,
   "Closing_Rank": 165
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 348,
   "Closing_Rank": 700
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 703,
   "Closing_Rank": 722
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 159,
   "Closing_Rank": 321
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 143,
   "Closing_Rank": 143
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 78,
   "Closing_Rank": 192
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 65,
   "Closing_Rank": 65
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5228,
   "Closing_Rank": 6022
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1009,
   "Closing_Rank": 1009
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1865,
   "Closing_Rank": 1865
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2333,
   "Closing_Rank": 2804
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2209,
   "Closing_Rank": 2209
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1261,
   "Closing_Rank": 1968
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3147,
   "Closing_Rank": 3147
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 430,
   "Closing_Rank": 430
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1168,
   "Closing_Rank": 1168
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4505,
   "Closing_Rank": 5514
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7880,
   "Closing_Rank": 7880
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 937,
   "Closing_Rank": 951
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1598,
   "Closing_Rank": 1598
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1831,
   "Closing_Rank": 2173
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4883,
   "Closing_Rank": 4883
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1275,
   "Closing_Rank": 1349
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3122,
   "Closing_Rank": 3122
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Artificial Intelligence and Data Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 521,
   "Closing_Rank": 521
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19731,
   "Closing_Rank": 27394
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17887,
   "Closing_Rank": 31162
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1615,
   "Closing_Rank": 1615
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4427,
   "Closing_Rank": 4727
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6051,
   "Closing_Rank": 6051
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7598,
   "Closing_Rank": 9223
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10549,
   "Closing_Rank": 17034
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1795,
   "Closing_Rank": 5265
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7515,
   "Closing_Rank": 7563
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 586,
   "Closing_Rank": 1729
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3006,
   "Closing_Rank": 3006
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21226,
   "Closing_Rank": 25049
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26719,
   "Closing_Rank": 32066
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2039,
   "Closing_Rank": 2039
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3958,
   "Closing_Rank": 4344
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6406,
   "Closing_Rank": 6406
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8299,
   "Closing_Rank": 9283
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13155,
   "Closing_Rank": 15790
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 905,
   "Closing_Rank": 905
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4408,
   "Closing_Rank": 5396
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4796,
   "Closing_Rank": 7023
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2007,
   "Closing_Rank": 2399
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3379,
   "Closing_Rank": 3379
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16373,
   "Closing_Rank": 30509
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25667,
   "Closing_Rank": 35785
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2078,
   "Closing_Rank": 3096
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4842,
   "Closing_Rank": 5361
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6104,
   "Closing_Rank": 6104
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8841,
   "Closing_Rank": 10919
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10828,
   "Closing_Rank": 17360
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2660,
   "Closing_Rank": 4735
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6036,
   "Closing_Rank": 6609
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 209,
   "Closing_Rank": 261
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 453,
   "Closing_Rank": 453
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15273,
   "Closing_Rank": 32608
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27994,
   "Closing_Rank": 44371
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 958,
   "Closing_Rank": 958
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5307,
   "Closing_Rank": 5733
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7448,
   "Closing_Rank": 7448
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 447,
   "Closing_Rank": 447
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9542,
   "Closing_Rank": 11936
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14832,
   "Closing_Rank": 16471
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1196,
   "Closing_Rank": 5549
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6992,
   "Closing_Rank": 7033
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 862,
   "Closing_Rank": 1480
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2202,
   "Closing_Rank": 2202
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3099,
   "Closing_Rank": 5435
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5725,
   "Closing_Rank": 8836
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68,
   "Closing_Rank": 68
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 785,
   "Closing_Rank": 957
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1718,
   "Closing_Rank": 1718
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 117,
   "Closing_Rank": 117
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1669,
   "Closing_Rank": 2184
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2908,
   "Closing_Rank": 3086
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 97,
   "Closing_Rank": 97
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 604,
   "Closing_Rank": 1182
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1697,
   "Closing_Rank": 2821
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 170,
   "Closing_Rank": 203
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 864,
   "Closing_Rank": 864
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1501,
   "Closing_Rank": 4711
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5097,
   "Closing_Rank": 7220
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 179,
   "Closing_Rank": 179
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 345,
   "Closing_Rank": 345
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 644,
   "Closing_Rank": 736
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1374,
   "Closing_Rank": 1374
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1023,
   "Closing_Rank": 1781
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2576,
   "Closing_Rank": 4063
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 121,
   "Closing_Rank": 121
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 557,
   "Closing_Rank": 1015
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1315,
   "Closing_Rank": 1315
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30,
   "Closing_Rank": 30
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 253,
   "Closing_Rank": 433
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 708,
   "Closing_Rank": 708
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11057,
   "Closing_Rank": 14001
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17031,
   "Closing_Rank": 21644
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2014,
   "Closing_Rank": 2014
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2186,
   "Closing_Rank": 2322
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3638,
   "Closing_Rank": 3638
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4062,
   "Closing_Rank": 5155
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8380,
   "Closing_Rank": 8807
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 181,
   "Closing_Rank": 181
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 718,
   "Closing_Rank": 2981
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4446,
   "Closing_Rank": 4446
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 234,
   "Closing_Rank": 674
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 824,
   "Closing_Rank": 824
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9332,
   "Closing_Rank": 13170
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15637,
   "Closing_Rank": 19755
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 787,
   "Closing_Rank": 787
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2058,
   "Closing_Rank": 2190
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3441,
   "Closing_Rank": 3441
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4480,
   "Closing_Rank": 5425
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8772,
   "Closing_Rank": 9480
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 400,
   "Closing_Rank": 400
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2527,
   "Closing_Rank": 3053
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4310,
   "Closing_Rank": 4824
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1006,
   "Closing_Rank": 1125
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 716,
   "Closing_Rank": 716
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6149,
   "Closing_Rank": 10321
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10829,
   "Closing_Rank": 15405
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2118,
   "Closing_Rank": 2118
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1473,
   "Closing_Rank": 1700
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2941,
   "Closing_Rank": 2941
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2892,
   "Closing_Rank": 3768
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5587,
   "Closing_Rank": 7318
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 331,
   "Closing_Rank": 331
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2016,
   "Closing_Rank": 2472
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4307,
   "Closing_Rank": 4307
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 352,
   "Closing_Rank": 546
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1675,
   "Closing_Rank": 1675
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6929,
   "Closing_Rank": 8437
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5851,
   "Closing_Rank": 15765
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 858,
   "Closing_Rank": 858
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1514,
   "Closing_Rank": 1578
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2489,
   "Closing_Rank": 2489
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75,
   "Closing_Rank": 75
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2471,
   "Closing_Rank": 3879
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5381,
   "Closing_Rank": 6307
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 270,
   "Closing_Rank": 270
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2071,
   "Closing_Rank": 2297
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3182,
   "Closing_Rank": 3182
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 685,
   "Closing_Rank": 925
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1422,
   "Closing_Rank": 1422
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11382,
   "Closing_Rank": 20618
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24624,
   "Closing_Rank": 31631
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 999,
   "Closing_Rank": 999
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3246,
   "Closing_Rank": 3472
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5312,
   "Closing_Rank": 5312
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5289,
   "Closing_Rank": 7466
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10823,
   "Closing_Rank": 11963
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3339,
   "Closing_Rank": 4206
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4548,
   "Closing_Rank": 4552
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 744,
   "Closing_Rank": 1105
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1718,
   "Closing_Rank": 1718
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5685,
   "Closing_Rank": 20436
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30023,
   "Closing_Rank": 33594
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1308,
   "Closing_Rank": 1308
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3237,
   "Closing_Rank": 3611
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5976,
   "Closing_Rank": 5976
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 259,
   "Closing_Rank": 259
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6792,
   "Closing_Rank": 8361
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13799,
   "Closing_Rank": 15498
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3432,
   "Closing_Rank": 4770
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6372,
   "Closing_Rank": 6372
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1205,
   "Closing_Rank": 1756
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2452,
   "Closing_Rank": 2452
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28556,
   "Closing_Rank": 36368
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37292,
   "Closing_Rank": 44634
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1846,
   "Closing_Rank": 1846
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5925,
   "Closing_Rank": 5993
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7335,
   "Closing_Rank": 7335
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11468,
   "Closing_Rank": 13199
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18962,
   "Closing_Rank": 19523
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5297,
   "Closing_Rank": 6530
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1938,
   "Closing_Rank": 1938
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3329,
   "Closing_Rank": 3329
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32851,
   "Closing_Rank": 36965
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39308,
   "Closing_Rank": 48668
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2574,
   "Closing_Rank": 2574
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6076,
   "Closing_Rank": 6267
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9625,
   "Closing_Rank": 9625
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11740,
   "Closing_Rank": 13480
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17202,
   "Closing_Rank": 19038
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1647,
   "Closing_Rank": 1647
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6206,
   "Closing_Rank": 6813
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8536,
   "Closing_Rank": 8536
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2571,
   "Closing_Rank": 2607
 },
 {
   "Institute": "Malaviya National Institute of Technology Jaipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4286,
   "Closing_Rank": 4286
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 796,
   "Closing_Rank": 2945
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 843,
   "Closing_Rank": 1269
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 419,
   "Closing_Rank": 949
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 456,
   "Closing_Rank": 456
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1416,
   "Closing_Rank": 2919
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 949,
   "Closing_Rank": 1724
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 462,
   "Closing_Rank": 1024
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 746,
   "Closing_Rank": 746
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 290,
   "Closing_Rank": 437
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 226,
   "Closing_Rank": 226
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 947,
   "Closing_Rank": 1469
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 849,
   "Closing_Rank": 1080
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 264,
   "Closing_Rank": 616
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 326,
   "Closing_Rank": 326
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 735,
   "Closing_Rank": 863
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 709,
   "Closing_Rank": 716
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 123,
   "Closing_Rank": 414
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 378,
   "Closing_Rank": 394
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 163,
   "Closing_Rank": 211
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 130,
   "Closing_Rank": 130
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27303,
   "Closing_Rank": 38413
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25924,
   "Closing_Rank": 37635
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6229,
   "Closing_Rank": 6432
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6607,
   "Closing_Rank": 6607
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11056,
   "Closing_Rank": 13782
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17944,
   "Closing_Rank": 18673
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4393,
   "Closing_Rank": 6650
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10066,
   "Closing_Rank": 10066
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3508,
   "Closing_Rank": 4113
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4783,
   "Closing_Rank": 4783
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30761,
   "Closing_Rank": 34554
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39181,
   "Closing_Rank": 41894
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2400,
   "Closing_Rank": 2718
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5629,
   "Closing_Rank": 5705
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7483,
   "Closing_Rank": 7483
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10697,
   "Closing_Rank": 12168
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18460,
   "Closing_Rank": 18558
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1403,
   "Closing_Rank": 1403
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6121,
   "Closing_Rank": 6723
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8374,
   "Closing_Rank": 8374
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2441,
   "Closing_Rank": 2455
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3802,
   "Closing_Rank": 3802
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29755,
   "Closing_Rank": 42663
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47043,
   "Closing_Rank": 56610
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 907,
   "Closing_Rank": 907
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6941,
   "Closing_Rank": 7386
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9439,
   "Closing_Rank": 9439
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13635,
   "Closing_Rank": 15351
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19990,
   "Closing_Rank": 22491
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4326,
   "Closing_Rank": 7405
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9142,
   "Closing_Rank": 9524
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2315,
   "Closing_Rank": 3300
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3840,
   "Closing_Rank": 3840
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24066,
   "Closing_Rank": 39973
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41996,
   "Closing_Rank": 51012
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1606,
   "Closing_Rank": 1606
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6765,
   "Closing_Rank": 6908
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8760,
   "Closing_Rank": 8760
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 455,
   "Closing_Rank": 455
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11465,
   "Closing_Rank": 13911
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19837,
   "Closing_Rank": 20228
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1282,
   "Closing_Rank": 1282
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5301,
   "Closing_Rank": 6915
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9368,
   "Closing_Rank": 9394
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1344,
   "Closing_Rank": 1693
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2745,
   "Closing_Rank": 2745
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6025,
   "Closing_Rank": 10832
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7156,
   "Closing_Rank": 15768
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 582,
   "Closing_Rank": 692
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1686,
   "Closing_Rank": 2088
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2603,
   "Closing_Rank": 2652
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2698,
   "Closing_Rank": 4066
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4903,
   "Closing_Rank": 9576
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 293,
   "Closing_Rank": 301
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 992,
   "Closing_Rank": 2294
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1403,
   "Closing_Rank": 2884
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 330,
   "Closing_Rank": 330
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 367,
   "Closing_Rank": 1710
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2617,
   "Closing_Rank": 2623
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6217,
   "Closing_Rank": 8830
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9487,
   "Closing_Rank": 14804
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 311,
   "Closing_Rank": 313
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1310,
   "Closing_Rank": 1440
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2378,
   "Closing_Rank": 2485
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93,
   "Closing_Rank": 93
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2535,
   "Closing_Rank": 3521
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4222,
   "Closing_Rank": 6632
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 162,
   "Closing_Rank": 162
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1244,
   "Closing_Rank": 1993
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2737,
   "Closing_Rank": 3262
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52,
   "Closing_Rank": 52
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 635,
   "Closing_Rank": 738
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1161,
   "Closing_Rank": 1328
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39,
   "Closing_Rank": 39
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11659,
   "Closing_Rank": 21323
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25923,
   "Closing_Rank": 30690
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 515,
   "Closing_Rank": 2476
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3343,
   "Closing_Rank": 3635
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5313,
   "Closing_Rank": 5313
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5738,
   "Closing_Rank": 8153
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11703,
   "Closing_Rank": 13895
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3881,
   "Closing_Rank": 5006
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6364,
   "Closing_Rank": 7121
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2074,
   "Closing_Rank": 2661
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4312,
   "Closing_Rank": 4312
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15886,
   "Closing_Rank": 20334
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24069,
   "Closing_Rank": 28138
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1452,
   "Closing_Rank": 1452
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3240,
   "Closing_Rank": 3513
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4671,
   "Closing_Rank": 4671
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5919,
   "Closing_Rank": 6910
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10361,
   "Closing_Rank": 12303
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 645,
   "Closing_Rank": 645
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2961,
   "Closing_Rank": 4425
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4619,
   "Closing_Rank": 6905
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1153,
   "Closing_Rank": 1561
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2345,
   "Closing_Rank": 2345
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11814,
   "Closing_Rank": 17869
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18855,
   "Closing_Rank": 25392
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2080,
   "Closing_Rank": 2201
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2736,
   "Closing_Rank": 2916
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4001,
   "Closing_Rank": 4362
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4547,
   "Closing_Rank": 6801
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11564,
   "Closing_Rank": 13773
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2299,
   "Closing_Rank": 3825
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3785,
   "Closing_Rank": 5867
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1311,
   "Closing_Rank": 2318
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3085,
   "Closing_Rank": 3085
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11457,
   "Closing_Rank": 14955
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19586,
   "Closing_Rank": 22084
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 495,
   "Closing_Rank": 551
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2349,
   "Closing_Rank": 2517
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3530,
   "Closing_Rank": 4068
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4277,
   "Closing_Rank": 5524
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8158,
   "Closing_Rank": 9991
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 378,
   "Closing_Rank": 378
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2535,
   "Closing_Rank": 3260
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4070,
   "Closing_Rank": 5350
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 898,
   "Closing_Rank": 1293
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1606,
   "Closing_Rank": 1606
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49,
   "Closing_Rank": 49
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43281,
   "Closing_Rank": 47044
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51659,
   "Closing_Rank": 53087
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7658,
   "Closing_Rank": 8077
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10880,
   "Closing_Rank": 10880
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14506,
   "Closing_Rank": 17158
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22862,
   "Closing_Rank": 23582
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7227,
   "Closing_Rank": 8410
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11362,
   "Closing_Rank": 11362
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4503,
   "Closing_Rank": 4645
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3850,
   "Closing_Rank": 3850
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35097,
   "Closing_Rank": 44668
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52836,
   "Closing_Rank": 53135
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7235,
   "Closing_Rank": 7347
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8858,
   "Closing_Rank": 8858
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14640,
   "Closing_Rank": 15097
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19139,
   "Closing_Rank": 19678
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1240,
   "Closing_Rank": 1240
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7000,
   "Closing_Rank": 7626
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8278,
   "Closing_Rank": 8278
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2880,
   "Closing_Rank": 2966
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Materials Science and Metallurgical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4432,
   "Closing_Rank": 4432
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11498,
   "Closing_Rank": 16047
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21932,
   "Closing_Rank": 21932
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2646,
   "Closing_Rank": 2646
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4444,
   "Closing_Rank": 4444
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5141,
   "Closing_Rank": 6033
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11562,
   "Closing_Rank": 11562
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4311,
   "Closing_Rank": 5514
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1899,
   "Closing_Rank": 1899
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12973,
   "Closing_Rank": 14740
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21302,
   "Closing_Rank": 21302
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2268,
   "Closing_Rank": 2268
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3728,
   "Closing_Rank": 5360
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10583,
   "Closing_Rank": 10583
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3521,
   "Closing_Rank": 3655
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5794,
   "Closing_Rank": 5794
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mathematics and Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1120,
   "Closing_Rank": 1120
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22235,
   "Closing_Rank": 34634
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41538,
   "Closing_Rank": 49166
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3330,
   "Closing_Rank": 3919
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5561,
   "Closing_Rank": 6141
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8032,
   "Closing_Rank": 8159
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9694,
   "Closing_Rank": 11952
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17659,
   "Closing_Rank": 19844
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5074,
   "Closing_Rank": 6634
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7952,
   "Closing_Rank": 9483
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1833,
   "Closing_Rank": 3335
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5168,
   "Closing_Rank": 5168
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22699,
   "Closing_Rank": 29993
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36154,
   "Closing_Rank": 42003
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 949,
   "Closing_Rank": 2499
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2018,
   "Closing_Rank": 2018
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4897,
   "Closing_Rank": 5280
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7353,
   "Closing_Rank": 7837
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 446,
   "Closing_Rank": 446
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9182,
   "Closing_Rank": 10546
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16582,
   "Closing_Rank": 18396
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1392,
   "Closing_Rank": 1392
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4906,
   "Closing_Rank": 6108
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7422,
   "Closing_Rank": 8986
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1728,
   "Closing_Rank": 2147
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2730,
   "Closing_Rank": 2730
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 726,
   "Closing_Rank": 3194
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 540,
   "Closing_Rank": 696
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 678,
   "Closing_Rank": 1316
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2443,
   "Closing_Rank": 3671
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1658,
   "Closing_Rank": 1658
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 381,
   "Closing_Rank": 457
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 221,
   "Closing_Rank": 221
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 357,
   "Closing_Rank": 515
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 339,
   "Closing_Rank": 781
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 555,
   "Closing_Rank": 640
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 141,
   "Closing_Rank": 181
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 281,
   "Closing_Rank": 391
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 318,
   "Closing_Rank": 340
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 94,
   "Closing_Rank": 111
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 280,
   "Closing_Rank": 280
 },
 {
   "Institute": "Maulana Azad National Institute of Technology Bhopal",
   "Academic_Program_Name": "Planning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Planning)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52,
   "Closing_Rank": 54
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27136,
   "Closing_Rank": 34919
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39598,
   "Closing_Rank": 42103
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5746,
   "Closing_Rank": 5760
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9332,
   "Closing_Rank": 9332
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10858,
   "Closing_Rank": 14261
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5208,
   "Closing_Rank": 6025
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7368,
   "Closing_Rank": 7368
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 258,
   "Closing_Rank": 258
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2979,
   "Closing_Rank": 5286
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32231,
   "Closing_Rank": 35422
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34506,
   "Closing_Rank": 38879
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6178,
   "Closing_Rank": 6220
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8121,
   "Closing_Rank": 8121
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11784,
   "Closing_Rank": 13918
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17845,
   "Closing_Rank": 17845
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1487,
   "Closing_Rank": 1487
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7112,
   "Closing_Rank": 7750
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8654,
   "Closing_Rank": 8654
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2616,
   "Closing_Rank": 2672
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3734,
   "Closing_Rank": 3734
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20435,
   "Closing_Rank": 22101
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31499,
   "Closing_Rank": 32646
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1450,
   "Closing_Rank": 1450
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3629,
   "Closing_Rank": 3652
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6114,
   "Closing_Rank": 6114
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6240,
   "Closing_Rank": 7973
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12241,
   "Closing_Rank": 12241
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3305,
   "Closing_Rank": 3750
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5740,
   "Closing_Rank": 6579
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2951,
   "Closing_Rank": 3724
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18237,
   "Closing_Rank": 21061
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20419,
   "Closing_Rank": 34050
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2273,
   "Closing_Rank": 2273
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3752,
   "Closing_Rank": 3752
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5973,
   "Closing_Rank": 5973
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7491,
   "Closing_Rank": 8348
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14313,
   "Closing_Rank": 14313
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4254,
   "Closing_Rank": 4922
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4654,
   "Closing_Rank": 4654
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1799,
   "Closing_Rank": 1839
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18477,
   "Closing_Rank": 26362
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41257,
   "Closing_Rank": 42910
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 696,
   "Closing_Rank": 696
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4163,
   "Closing_Rank": 4528
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7060,
   "Closing_Rank": 7060
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7788,
   "Closing_Rank": 9043
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14771,
   "Closing_Rank": 17586
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 600,
   "Closing_Rank": 600
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2337,
   "Closing_Rank": 4509
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4816,
   "Closing_Rank": 4816
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3273,
   "Closing_Rank": 3551
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2207,
   "Closing_Rank": 2207
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15324,
   "Closing_Rank": 29422
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29464,
   "Closing_Rank": 41013
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 668,
   "Closing_Rank": 668
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2191,
   "Closing_Rank": 2191
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4853,
   "Closing_Rank": 5018
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7244,
   "Closing_Rank": 7244
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 167,
   "Closing_Rank": 167
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8930,
   "Closing_Rank": 10495
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12963,
   "Closing_Rank": 15463
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4590,
   "Closing_Rank": 5314
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6075,
   "Closing_Rank": 6828
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 460,
   "Closing_Rank": 1232
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2082,
   "Closing_Rank": 2082
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2401,
   "Closing_Rank": 5943
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4497,
   "Closing_Rank": 9834
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 74,
   "Closing_Rank": 117
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 267,
   "Closing_Rank": 267
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 857,
   "Closing_Rank": 1044
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1685,
   "Closing_Rank": 1770
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1312,
   "Closing_Rank": 2802
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2775,
   "Closing_Rank": 5075
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75,
   "Closing_Rank": 80
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 481,
   "Closing_Rank": 481
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 213,
   "Closing_Rank": 1136
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 890,
   "Closing_Rank": 1286
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16,
   "Closing_Rank": 16
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 155,
   "Closing_Rank": 927
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2425,
   "Closing_Rank": 3286
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26,
   "Closing_Rank": 26
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2417,
   "Closing_Rank": 4191
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5177,
   "Closing_Rank": 8838
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59,
   "Closing_Rank": 134
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 584,
   "Closing_Rank": 709
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1372,
   "Closing_Rank": 1373
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 157,
   "Closing_Rank": 157
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 982,
   "Closing_Rank": 1605
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2836,
   "Closing_Rank": 4113
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 91,
   "Closing_Rank": 120
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 173,
   "Closing_Rank": 1072
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 942,
   "Closing_Rank": 1873
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22,
   "Closing_Rank": 22
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 84,
   "Closing_Rank": 84
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 256,
   "Closing_Rank": 428
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 614,
   "Closing_Rank": 657
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15,
   "Closing_Rank": 15
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9340,
   "Closing_Rank": 11921
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18796,
   "Closing_Rank": 23004
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 476,
   "Closing_Rank": 476
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1799,
   "Closing_Rank": 1894
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3944,
   "Closing_Rank": 3944
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3867,
   "Closing_Rank": 4534
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8604,
   "Closing_Rank": 8929
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 262,
   "Closing_Rank": 262
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1542,
   "Closing_Rank": 2528
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4107,
   "Closing_Rank": 4290
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1554,
   "Closing_Rank": 1785
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3324,
   "Closing_Rank": 3324
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9008,
   "Closing_Rank": 10928
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15078,
   "Closing_Rank": 16066
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 148,
   "Closing_Rank": 148
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2103,
   "Closing_Rank": 2103
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1651,
   "Closing_Rank": 1904
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2921,
   "Closing_Rank": 2921
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3613,
   "Closing_Rank": 4927
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6371,
   "Closing_Rank": 8223
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 542,
   "Closing_Rank": 542
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2148,
   "Closing_Rank": 2605
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4803,
   "Closing_Rank": 4803
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 604,
   "Closing_Rank": 870
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2215,
   "Closing_Rank": 2215
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6010,
   "Closing_Rank": 9231
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12293,
   "Closing_Rank": 18170
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 217,
   "Closing_Rank": 217
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1469,
   "Closing_Rank": 1469
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1335,
   "Closing_Rank": 1580
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3126,
   "Closing_Rank": 3164
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2662,
   "Closing_Rank": 3859
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5248,
   "Closing_Rank": 6445
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 183,
   "Closing_Rank": 183
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1111,
   "Closing_Rank": 2050
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3461,
   "Closing_Rank": 3883
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 986,
   "Closing_Rank": 1546
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3457,
   "Closing_Rank": 3457
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5805,
   "Closing_Rank": 7707
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10646,
   "Closing_Rank": 14134
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 307,
   "Closing_Rank": 307
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1282,
   "Closing_Rank": 1282
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1059,
   "Closing_Rank": 1402
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2368,
   "Closing_Rank": 2368
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1823,
   "Closing_Rank": 3043
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4773,
   "Closing_Rank": 6250
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 266,
   "Closing_Rank": 266
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1321,
   "Closing_Rank": 2062
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2365,
   "Closing_Rank": 2365
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 199,
   "Closing_Rank": 199
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 513,
   "Closing_Rank": 823
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1578,
   "Closing_Rank": 1665
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11365,
   "Closing_Rank": 11794
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19712,
   "Closing_Rank": 19712
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 524,
   "Closing_Rank": 524
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2056,
   "Closing_Rank": 2056
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 266,
   "Closing_Rank": 266
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4931,
   "Closing_Rank": 5531
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9447,
   "Closing_Rank": 9447
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2587,
   "Closing_Rank": 2891
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2347,
   "Closing_Rank": 2347
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11306,
   "Closing_Rank": 11650
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17028,
   "Closing_Rank": 17028
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2177,
   "Closing_Rank": 2177
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3958,
   "Closing_Rank": 5233
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7500,
   "Closing_Rank": 7500
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2879,
   "Closing_Rank": 3141
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5623,
   "Closing_Rank": 5623
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1374,
   "Closing_Rank": 1374
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26249,
   "Closing_Rank": 30318
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36170,
   "Closing_Rank": 36170
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1863,
   "Closing_Rank": 1863
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5411,
   "Closing_Rank": 5411
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9718,
   "Closing_Rank": 13882
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18280,
   "Closing_Rank": 18280
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3838,
   "Closing_Rank": 5162
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5733,
   "Closing_Rank": 5733
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36256,
   "Closing_Rank": 38008
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48623,
   "Closing_Rank": 48623
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6475,
   "Closing_Rank": 6475
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12778,
   "Closing_Rank": 13589
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18572,
   "Closing_Rank": 18572
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5366,
   "Closing_Rank": 7293
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9817,
   "Closing_Rank": 9817
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2091,
   "Closing_Rank": 2091
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10836,
   "Closing_Rank": 19615
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29041,
   "Closing_Rank": 33524
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 590,
   "Closing_Rank": 590
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3185,
   "Closing_Rank": 3435
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5806,
   "Closing_Rank": 6001
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 170,
   "Closing_Rank": 170
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5592,
   "Closing_Rank": 7622
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9530,
   "Closing_Rank": 13762
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 477,
   "Closing_Rank": 477
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3197,
   "Closing_Rank": 3587
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5984,
   "Closing_Rank": 6972
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2376,
   "Closing_Rank": 2560
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3534,
   "Closing_Rank": 3534
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14116,
   "Closing_Rank": 19748
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27632,
   "Closing_Rank": 33989
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 512,
   "Closing_Rank": 512
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3555,
   "Closing_Rank": 3555
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3105,
   "Closing_Rank": 3486
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6246,
   "Closing_Rank": 6258
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5652,
   "Closing_Rank": 7823
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11708,
   "Closing_Rank": 15527
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 568,
   "Closing_Rank": 568
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3566,
   "Closing_Rank": 4759
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5887,
   "Closing_Rank": 6522
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1531,
   "Closing_Rank": 1643
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2450,
   "Closing_Rank": 2450
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23990,
   "Closing_Rank": 32872
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43130,
   "Closing_Rank": 50847
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2019,
   "Closing_Rank": 2019
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5442,
   "Closing_Rank": 5612
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8813,
   "Closing_Rank": 8813
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9663,
   "Closing_Rank": 12822
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4647,
   "Closing_Rank": 5828
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8962,
   "Closing_Rank": 8962
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3228,
   "Closing_Rank": 4689
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26669,
   "Closing_Rank": 35559
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34608,
   "Closing_Rank": 42691
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3306,
   "Closing_Rank": 3306
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5763,
   "Closing_Rank": 5770
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7540,
   "Closing_Rank": 7540
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10877,
   "Closing_Rank": 13268
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16190,
   "Closing_Rank": 16190
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5737,
   "Closing_Rank": 6676
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9814,
   "Closing_Rank": 9814
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2824,
   "Closing_Rank": 2824
 },
 {
   "Institute": "Motilal Nehru National Institute of Technology Allahabad",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4455,
   "Closing_Rank": 4455
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 106936,
   "Closing_Rank": 247596
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 129019,
   "Closing_Rank": 151489
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25408,
   "Closing_Rank": 27811
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30280,
   "Closing_Rank": 30280
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16005,
   "Closing_Rank": 40112
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5057,
   "Closing_Rank": 12047
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50675,
   "Closing_Rank": 55914
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60642,
   "Closing_Rank": 60830
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4279,
   "Closing_Rank": 4279
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9128,
   "Closing_Rank": 9404
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11132,
   "Closing_Rank": 11132
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17206,
   "Closing_Rank": 18438
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22242,
   "Closing_Rank": 22576
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7903,
   "Closing_Rank": 8743
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12234,
   "Closing_Rank": 12234
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Biotechnology and Biochemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3665,
   "Closing_Rank": 3705
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 173466,
   "Closing_Rank": 231324
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 161018,
   "Closing_Rank": 206719
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 98955,
   "Closing_Rank": 98955
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14023,
   "Closing_Rank": 24924
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30885,
   "Closing_Rank": 30885
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10761,
   "Closing_Rank": 32970
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25473,
   "Closing_Rank": 30949
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42401,
   "Closing_Rank": 50320
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51123,
   "Closing_Rank": 56710
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8140,
   "Closing_Rank": 8333
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9633,
   "Closing_Rank": 9633
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15180,
   "Closing_Rank": 16505
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19905,
   "Closing_Rank": 21312
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7195,
   "Closing_Rank": 7798
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10661,
   "Closing_Rank": 10661
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2896,
   "Closing_Rank": 3003
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4823,
   "Closing_Rank": 4823
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 246115,
   "Closing_Rank": 299281
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35119,
   "Closing_Rank": 36730
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12083,
   "Closing_Rank": 37412
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55119,
   "Closing_Rank": 57997
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9708,
   "Closing_Rank": 9708
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17943,
   "Closing_Rank": 18999
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9573,
   "Closing_Rank": 9614
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3851,
   "Closing_Rank": 3851
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 153525,
   "Closing_Rank": 217289
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 175593,
   "Closing_Rank": 231406
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15017,
   "Closing_Rank": 24520
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18921,
   "Closing_Rank": 23676
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8900,
   "Closing_Rank": 19732
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6867,
   "Closing_Rank": 12901
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41899,
   "Closing_Rank": 53657
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 64979,
   "Closing_Rank": 66493
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8726,
   "Closing_Rank": 8852
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11028,
   "Closing_Rank": 11028
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16363,
   "Closing_Rank": 17896
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23256,
   "Closing_Rank": 23792
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7730,
   "Closing_Rank": 8072
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11300,
   "Closing_Rank": 11687
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2579,
   "Closing_Rank": 2654
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3123,
   "Closing_Rank": 3123
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 100545,
   "Closing_Rank": 174131
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16797,
   "Closing_Rank": 19043
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27913,
   "Closing_Rank": 34842
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6188,
   "Closing_Rank": 6188
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13665,
   "Closing_Rank": 14458
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5013,
   "Closing_Rank": 6296
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computational Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2162,
   "Closing_Rank": 2162
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9028,
   "Closing_Rank": 75987
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29647,
   "Closing_Rank": 62001
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2276,
   "Closing_Rank": 2276
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14754,
   "Closing_Rank": 25178
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18530,
   "Closing_Rank": 25228
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3000,
   "Closing_Rank": 6092
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5038,
   "Closing_Rank": 8848
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 221,
   "Closing_Rank": 221
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 755,
   "Closing_Rank": 14266
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2326,
   "Closing_Rank": 7155
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8121,
   "Closing_Rank": 20298
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27900,
   "Closing_Rank": 35092
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3288,
   "Closing_Rank": 3310
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5774,
   "Closing_Rank": 5997
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 185,
   "Closing_Rank": 204
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6076,
   "Closing_Rank": 7198
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10241,
   "Closing_Rank": 13401
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 577,
   "Closing_Rank": 577
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2461,
   "Closing_Rank": 3907
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4935,
   "Closing_Rank": 6133
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 77,
   "Closing_Rank": 77
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1113,
   "Closing_Rank": 1464
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2510,
   "Closing_Rank": 2510
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57501,
   "Closing_Rank": 121194
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 101741,
   "Closing_Rank": 114900
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40805,
   "Closing_Rank": 90195
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39400,
   "Closing_Rank": 39400
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11357,
   "Closing_Rank": 12676
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15030,
   "Closing_Rank": 18979
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9409,
   "Closing_Rank": 41930
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13838,
   "Closing_Rank": 28459
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27996,
   "Closing_Rank": 38044
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42555,
   "Closing_Rank": 47349
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2132,
   "Closing_Rank": 2132
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6235,
   "Closing_Rank": 6685
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7789,
   "Closing_Rank": 7789
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10820,
   "Closing_Rank": 13148
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16383,
   "Closing_Rank": 17517
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5431,
   "Closing_Rank": 6214
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8287,
   "Closing_Rank": 8596
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2221,
   "Closing_Rank": 2779
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4451,
   "Closing_Rank": 4451
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36570,
   "Closing_Rank": 96256
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 79447,
   "Closing_Rank": 101333
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22888,
   "Closing_Rank": 32467
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36354,
   "Closing_Rank": 36354
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5922,
   "Closing_Rank": 10847
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9882,
   "Closing_Rank": 14348
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1338,
   "Closing_Rank": 28056
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16884,
   "Closing_Rank": 24537
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22067,
   "Closing_Rank": 27158
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35635,
   "Closing_Rank": 39625
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 957,
   "Closing_Rank": 957
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4383,
   "Closing_Rank": 4584
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6371,
   "Closing_Rank": 6371
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7535,
   "Closing_Rank": 8982
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13844,
   "Closing_Rank": 14694
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 689,
   "Closing_Rank": 689
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3784,
   "Closing_Rank": 4937
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6370,
   "Closing_Rank": 6717
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1656,
   "Closing_Rank": 1942
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2795,
   "Closing_Rank": 2795
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96471,
   "Closing_Rank": 142075
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 118749,
   "Closing_Rank": 131661
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40078,
   "Closing_Rank": 57351
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12724,
   "Closing_Rank": 14041
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18821,
   "Closing_Rank": 19995
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22986,
   "Closing_Rank": 37244
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42990,
   "Closing_Rank": 45959
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1434,
   "Closing_Rank": 2441
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6116,
   "Closing_Rank": 6304
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8940,
   "Closing_Rank": 8940
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11040,
   "Closing_Rank": 13512
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19032,
   "Closing_Rank": 19679
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5414,
   "Closing_Rank": 6686
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9526,
   "Closing_Rank": 9526
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2716,
   "Closing_Rank": 2716
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3979,
   "Closing_Rank": 3979
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 179536,
   "Closing_Rank": 226647
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25867,
   "Closing_Rank": 32523
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43591,
   "Closing_Rank": 45559
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7624,
   "Closing_Rank": 7624
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13855,
   "Closing_Rank": 16543
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7485,
   "Closing_Rank": 9252
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3509,
   "Closing_Rank": 3509
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56381,
   "Closing_Rank": 265899
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62273,
   "Closing_Rank": 62273
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19129,
   "Closing_Rank": 19129
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8498,
   "Closing_Rank": 29783
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26272,
   "Closing_Rank": 31639
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5223,
   "Closing_Rank": 5223
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 226,
   "Closing_Rank": 226
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12265,
   "Closing_Rank": 13839
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6072,
   "Closing_Rank": 6702
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mathematics &amp; Computing ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2728,
   "Closing_Rank": 2728
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 92131,
   "Closing_Rank": 173117
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 144503,
   "Closing_Rank": 184292
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36207,
   "Closing_Rank": 36207
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 100431,
   "Closing_Rank": 100431
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14230,
   "Closing_Rank": 21527
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20185,
   "Closing_Rank": 26256
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2657,
   "Closing_Rank": 45933
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35554,
   "Closing_Rank": 46675
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49305,
   "Closing_Rank": 58864
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2981,
   "Closing_Rank": 2981
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7516,
   "Closing_Rank": 7830
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10115,
   "Closing_Rank": 10115
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13657,
   "Closing_Rank": 15431
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20713,
   "Closing_Rank": 21142
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6404,
   "Closing_Rank": 7660
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11044,
   "Closing_Rank": 11080
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2457,
   "Closing_Rank": 2928
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4034,
   "Closing_Rank": 4034
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 127978,
   "Closing_Rank": 236979
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25507,
   "Closing_Rank": 25507
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21009,
   "Closing_Rank": 21009
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18136,
   "Closing_Rank": 51420
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16888,
   "Closing_Rank": 18714
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9053,
   "Closing_Rank": 9441
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Science and Master of Science (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3126,
   "Closing_Rank": 3126
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 227666,
   "Closing_Rank": 307180
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 229506,
   "Closing_Rank": 261203
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24331,
   "Closing_Rank": 32881
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21619,
   "Closing_Rank": 34040
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21673,
   "Closing_Rank": 21673
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46759,
   "Closing_Rank": 56865
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59503,
   "Closing_Rank": 65780
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9248,
   "Closing_Rank": 9382
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10987,
   "Closing_Rank": 10987
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17430,
   "Closing_Rank": 18933
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22896,
   "Closing_Rank": 24115
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8204,
   "Closing_Rank": 9301
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11860,
   "Closing_Rank": 12376
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3017,
   "Closing_Rank": 3622
 },
 {
   "Institute": "National Institute of Technology  Agartala",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4762,
   "Closing_Rank": 4762
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68,
   "Closing_Rank": 271
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 83,
   "Closing_Rank": 138
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62,
   "Closing_Rank": 62
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 211,
   "Closing_Rank": 595
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 479,
   "Closing_Rank": 479
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 78,
   "Closing_Rank": 133
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 96,
   "Closing_Rank": 105
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13,
   "Closing_Rank": 289
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 298,
   "Closing_Rank": 298
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51,
   "Closing_Rank": 415
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 239,
   "Closing_Rank": 466
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 256,
   "Closing_Rank": 292
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38,
   "Closing_Rank": 38
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 121,
   "Closing_Rank": 136
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 135,
   "Closing_Rank": 299
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 217,
   "Closing_Rank": 217
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 86,
   "Closing_Rank": 104
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52,
   "Closing_Rank": 52
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85,
   "Closing_Rank": 85
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55,
   "Closing_Rank": 55
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30858,
   "Closing_Rank": 32869
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18789,
   "Closing_Rank": 18789
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1696,
   "Closing_Rank": 3033
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59839,
   "Closing_Rank": 59839
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16197,
   "Closing_Rank": 16197
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11012,
   "Closing_Rank": 11278
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9267,
   "Closing_Rank": 9267
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1440,
   "Closing_Rank": 1440
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3526,
   "Closing_Rank": 9508
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20287,
   "Closing_Rank": 20287
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23927,
   "Closing_Rank": 35922
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27660,
   "Closing_Rank": 32481
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6209,
   "Closing_Rank": 6217
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11113,
   "Closing_Rank": 12280
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12604,
   "Closing_Rank": 12604
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4956,
   "Closing_Rank": 5691
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8909,
   "Closing_Rank": 8909
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1867,
   "Closing_Rank": 1867
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19284,
   "Closing_Rank": 30934
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21455,
   "Closing_Rank": 27393
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26082,
   "Closing_Rank": 43883
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10755,
   "Closing_Rank": 10755
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8945,
   "Closing_Rank": 10457
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9567,
   "Closing_Rank": 10150
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12039,
   "Closing_Rank": 14849
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10671,
   "Closing_Rank": 10671
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19037,
   "Closing_Rank": 29399
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12872,
   "Closing_Rank": 12872
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20381,
   "Closing_Rank": 26194
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25156,
   "Closing_Rank": 34601
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1016,
   "Closing_Rank": 1016
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4434,
   "Closing_Rank": 4781
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5876,
   "Closing_Rank": 5876
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7395,
   "Closing_Rank": 9306
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10490,
   "Closing_Rank": 11908
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 573,
   "Closing_Rank": 573
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3732,
   "Closing_Rank": 5176
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6099,
   "Closing_Rank": 6197
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1628,
   "Closing_Rank": 1689
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1887,
   "Closing_Rank": 1887
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26868,
   "Closing_Rank": 40264
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22602,
   "Closing_Rank": 35697
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2250,
   "Closing_Rank": 2250
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3628,
   "Closing_Rank": 3628
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51312,
   "Closing_Rank": 79174
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26099,
   "Closing_Rank": 57585
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11567,
   "Closing_Rank": 14202
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11488,
   "Closing_Rank": 11853
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1558,
   "Closing_Rank": 1558
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7322,
   "Closing_Rank": 16371
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11136,
   "Closing_Rank": 14575
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8191,
   "Closing_Rank": 34138
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12142,
   "Closing_Rank": 21106
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25679,
   "Closing_Rank": 33650
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40047,
   "Closing_Rank": 43260
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1743,
   "Closing_Rank": 1743
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5455,
   "Closing_Rank": 5714
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7051,
   "Closing_Rank": 7051
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9754,
   "Closing_Rank": 11102
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13508,
   "Closing_Rank": 14612
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 885,
   "Closing_Rank": 885
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 881,
   "Closing_Rank": 881
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4832,
   "Closing_Rank": 5615
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6352,
   "Closing_Rank": 7665
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1230,
   "Closing_Rank": 1515
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1967,
   "Closing_Rank": 1967
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4488,
   "Closing_Rank": 9271
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5110,
   "Closing_Rank": 9330
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 170,
   "Closing_Rank": 170
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 193,
   "Closing_Rank": 193
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4562,
   "Closing_Rank": 20059
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14720,
   "Closing_Rank": 14720
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2084,
   "Closing_Rank": 3220
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2709,
   "Closing_Rank": 4350
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 165,
   "Closing_Rank": 165
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 515,
   "Closing_Rank": 3043
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5952,
   "Closing_Rank": 7468
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 784,
   "Closing_Rank": 4056
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2705,
   "Closing_Rank": 2705
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2614,
   "Closing_Rank": 4482
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4989,
   "Closing_Rank": 8068
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 94,
   "Closing_Rank": 94
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 606,
   "Closing_Rank": 645
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1232,
   "Closing_Rank": 1309
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22,
   "Closing_Rank": 22
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1103,
   "Closing_Rank": 1457
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2056,
   "Closing_Rank": 3125
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 98,
   "Closing_Rank": 98
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 482,
   "Closing_Rank": 843
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1084,
   "Closing_Rank": 1131
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18,
   "Closing_Rank": 18
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 229,
   "Closing_Rank": 269
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 555,
   "Closing_Rank": 555
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25,
   "Closing_Rank": 25
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12281,
   "Closing_Rank": 15954
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14981,
   "Closing_Rank": 19402
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 413,
   "Closing_Rank": 521
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15206,
   "Closing_Rank": 26831
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25910,
   "Closing_Rank": 25910
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4117,
   "Closing_Rank": 5301
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5869,
   "Closing_Rank": 6507
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 260,
   "Closing_Rank": 260
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 475,
   "Closing_Rank": 475
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7084,
   "Closing_Rank": 9899
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4532,
   "Closing_Rank": 7981
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2835,
   "Closing_Rank": 16825
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16680,
   "Closing_Rank": 16680
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7989,
   "Closing_Rank": 11415
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13966,
   "Closing_Rank": 15780
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 371,
   "Closing_Rank": 371
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 902,
   "Closing_Rank": 902
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1712,
   "Closing_Rank": 2011
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2679,
   "Closing_Rank": 2753
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 132,
   "Closing_Rank": 132
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2877,
   "Closing_Rank": 4310
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5370,
   "Closing_Rank": 7253
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 328,
   "Closing_Rank": 328
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1837,
   "Closing_Rank": 2686
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2512,
   "Closing_Rank": 2919
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 468,
   "Closing_Rank": 869
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1446,
   "Closing_Rank": 1446
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8055,
   "Closing_Rank": 12264
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9897,
   "Closing_Rank": 13963
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58,
   "Closing_Rank": 58
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13380,
   "Closing_Rank": 22874
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13421,
   "Closing_Rank": 13421
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3028,
   "Closing_Rank": 4082
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4401,
   "Closing_Rank": 5134
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 279,
   "Closing_Rank": 279
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1152,
   "Closing_Rank": 6719
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7748,
   "Closing_Rank": 9031
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1387,
   "Closing_Rank": 6198
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5467,
   "Closing_Rank": 5467
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4721,
   "Closing_Rank": 7433
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9361,
   "Closing_Rank": 10983
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 707,
   "Closing_Rank": 707
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1034,
   "Closing_Rank": 1260
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1589,
   "Closing_Rank": 1589
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56,
   "Closing_Rank": 91
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1845,
   "Closing_Rank": 2574
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3889,
   "Closing_Rank": 4372
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 133,
   "Closing_Rank": 133
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1009,
   "Closing_Rank": 1665
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2631,
   "Closing_Rank": 3773
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 320,
   "Closing_Rank": 411
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 906,
   "Closing_Rank": 1220
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15963,
   "Closing_Rank": 21437
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24186,
   "Closing_Rank": 24186
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33778,
   "Closing_Rank": 33778
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 88460,
   "Closing_Rank": 88460
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8388,
   "Closing_Rank": 9819
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9539,
   "Closing_Rank": 9539
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5321,
   "Closing_Rank": 5321
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10926,
   "Closing_Rank": 10926
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1685,
   "Closing_Rank": 1685
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8191,
   "Closing_Rank": 16218
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25618,
   "Closing_Rank": 26715
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1671,
   "Closing_Rank": 1671
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3153,
   "Closing_Rank": 3333
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5434,
   "Closing_Rank": 6978
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12361,
   "Closing_Rank": 12361
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3752,
   "Closing_Rank": 4856
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1258,
   "Closing_Rank": 1258
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4205,
   "Closing_Rank": 4205
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30870,
   "Closing_Rank": 36082
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32439,
   "Closing_Rank": 32439
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1128,
   "Closing_Rank": 1128
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33781,
   "Closing_Rank": 33781
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10999,
   "Closing_Rank": 12355
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10680,
   "Closing_Rank": 10680
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15399,
   "Closing_Rank": 16049
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18073,
   "Closing_Rank": 18073
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36888,
   "Closing_Rank": 36888
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20228,
   "Closing_Rank": 33674
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44098,
   "Closing_Rank": 44098
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6173,
   "Closing_Rank": 6173
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8786,
   "Closing_Rank": 8786
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11558,
   "Closing_Rank": 12440
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14486,
   "Closing_Rank": 14486
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6324,
   "Closing_Rank": 6885
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1940,
   "Closing_Rank": 1940
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16058,
   "Closing_Rank": 22280
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20145,
   "Closing_Rank": 27770
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 917,
   "Closing_Rank": 1321
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16862,
   "Closing_Rank": 36039
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31528,
   "Closing_Rank": 38676
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5850,
   "Closing_Rank": 7893
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8509,
   "Closing_Rank": 9916
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1872,
   "Closing_Rank": 1872
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1928,
   "Closing_Rank": 1928
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4815,
   "Closing_Rank": 13856
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9900,
   "Closing_Rank": 10192
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5369,
   "Closing_Rank": 15322
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15174,
   "Closing_Rank": 20611
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26987,
   "Closing_Rank": 30239
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 963,
   "Closing_Rank": 963
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1683,
   "Closing_Rank": 1683
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3225,
   "Closing_Rank": 3546
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5707,
   "Closing_Rank": 5911
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5475,
   "Closing_Rank": 6889
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10373,
   "Closing_Rank": 11749
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 998,
   "Closing_Rank": 998
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3436,
   "Closing_Rank": 4556
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6362,
   "Closing_Rank": 6662
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1090,
   "Closing_Rank": 1466
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2063,
   "Closing_Rank": 2253
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34977,
   "Closing_Rank": 41705
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35414,
   "Closing_Rank": 38975
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48505,
   "Closing_Rank": 59113
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 76781,
   "Closing_Rank": 76781
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13040,
   "Closing_Rank": 14647
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12663,
   "Closing_Rank": 12726
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17614,
   "Closing_Rank": 18762
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18532,
   "Closing_Rank": 18532
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27217,
   "Closing_Rank": 33433
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39536,
   "Closing_Rank": 41373
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2272,
   "Closing_Rank": 2272
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5964,
   "Closing_Rank": 6564
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9971,
   "Closing_Rank": 12253
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14652,
   "Closing_Rank": 14652
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1002,
   "Closing_Rank": 1002
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5488,
   "Closing_Rank": 5806
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8081,
   "Closing_Rank": 8081
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1889,
   "Closing_Rank": 1889
 },
 {
   "Institute": "National Institute of Technology Calicut",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3414,
   "Closing_Rank": 3414
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17286,
   "Closing_Rank": 19407
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32479,
   "Closing_Rank": 32479
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7633,
   "Closing_Rank": 7633
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10416,
   "Closing_Rank": 13550
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2853,
   "Closing_Rank": 2853
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6929,
   "Closing_Rank": 6929
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3567,
   "Closing_Rank": 3567
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5998,
   "Closing_Rank": 14243
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6370,
   "Closing_Rank": 6370
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5142,
   "Closing_Rank": 5142
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 442,
   "Closing_Rank": 442
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3899,
   "Closing_Rank": 4743
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2236,
   "Closing_Rank": 2236
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 785,
   "Closing_Rank": 785
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9112,
   "Closing_Rank": 12510
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16573,
   "Closing_Rank": 16573
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2280,
   "Closing_Rank": 2280
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5695,
   "Closing_Rank": 6267
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10865,
   "Closing_Rank": 10865
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2684,
   "Closing_Rank": 2684
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2525,
   "Closing_Rank": 2525
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8339,
   "Closing_Rank": 8572
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13111,
   "Closing_Rank": 13111
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 194,
   "Closing_Rank": 194
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1318,
   "Closing_Rank": 1318
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2012,
   "Closing_Rank": 2105
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5621,
   "Closing_Rank": 5621
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1172,
   "Closing_Rank": 1621
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32836,
   "Closing_Rank": 36329
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23962,
   "Closing_Rank": 23962
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7683,
   "Closing_Rank": 7683
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17807,
   "Closing_Rank": 18429
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27213,
   "Closing_Rank": 27213
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5498,
   "Closing_Rank": 5498
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7381,
   "Closing_Rank": 7381
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3188,
   "Closing_Rank": 3188
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22416,
   "Closing_Rank": 27045
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36193,
   "Closing_Rank": 36193
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7874,
   "Closing_Rank": 7874
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9017,
   "Closing_Rank": 9096
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3317,
   "Closing_Rank": 3471
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 379,
   "Closing_Rank": 379
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5545,
   "Closing_Rank": 12455
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10306,
   "Closing_Rank": 16787
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 579,
   "Closing_Rank": 579
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2181,
   "Closing_Rank": 3311
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2971,
   "Closing_Rank": 2971
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3664,
   "Closing_Rank": 5688
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4950,
   "Closing_Rank": 7187
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 247,
   "Closing_Rank": 247
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1543,
   "Closing_Rank": 2641
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4528,
   "Closing_Rank": 4640
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96,
   "Closing_Rank": 96
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 149,
   "Closing_Rank": 617
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2135,
   "Closing_Rank": 2135
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2438,
   "Closing_Rank": 8018
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11811,
   "Closing_Rank": 13868
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 403,
   "Closing_Rank": 403
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1168,
   "Closing_Rank": 1419
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2128,
   "Closing_Rank": 2128
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 57,
   "Closing_Rank": 57
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2900,
   "Closing_Rank": 3444
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3484,
   "Closing_Rank": 3595
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 498,
   "Closing_Rank": 498
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 681,
   "Closing_Rank": 1584
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2305,
   "Closing_Rank": 2872
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 334,
   "Closing_Rank": 443
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 913,
   "Closing_Rank": 913
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17542,
   "Closing_Rank": 25757
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28805,
   "Closing_Rank": 34150
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4099,
   "Closing_Rank": 4769
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11505,
   "Closing_Rank": 12598
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18717,
   "Closing_Rank": 19632
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3065,
   "Closing_Rank": 3586
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6912,
   "Closing_Rank": 6912
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2634,
   "Closing_Rank": 3938
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12167,
   "Closing_Rank": 16739
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25168,
   "Closing_Rank": 25855
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 602,
   "Closing_Rank": 602
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2731,
   "Closing_Rank": 2875
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4125,
   "Closing_Rank": 4125
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5354,
   "Closing_Rank": 6190
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10436,
   "Closing_Rank": 10436
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 371,
   "Closing_Rank": 371
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2114,
   "Closing_Rank": 2935
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4063,
   "Closing_Rank": 4063
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1337,
   "Closing_Rank": 1448
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1595,
   "Closing_Rank": 1595
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13130,
   "Closing_Rank": 16179
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19091,
   "Closing_Rank": 22726
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3314,
   "Closing_Rank": 3977
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6613,
   "Closing_Rank": 10568
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11232,
   "Closing_Rank": 17102
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2692,
   "Closing_Rank": 3671
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5450,
   "Closing_Rank": 5450
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1619,
   "Closing_Rank": 2529
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8844,
   "Closing_Rank": 12080
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16788,
   "Closing_Rank": 17923
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 459,
   "Closing_Rank": 459
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1912,
   "Closing_Rank": 1919
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3074,
   "Closing_Rank": 3074
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3977,
   "Closing_Rank": 4713
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 628,
   "Closing_Rank": 628
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2168,
   "Closing_Rank": 2536
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1752,
   "Closing_Rank": 1752
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 905,
   "Closing_Rank": 991
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1518,
   "Closing_Rank": 1518
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17066,
   "Closing_Rank": 30040
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34334,
   "Closing_Rank": 36212
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6376,
   "Closing_Rank": 6684
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14699,
   "Closing_Rank": 16253
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22258,
   "Closing_Rank": 22258
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5448,
   "Closing_Rank": 6093
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4069,
   "Closing_Rank": 4069
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8873,
   "Closing_Rank": 8873
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1737,
   "Closing_Rank": 22208
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26420,
   "Closing_Rank": 26420
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 803,
   "Closing_Rank": 803
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3563,
   "Closing_Rank": 3772
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6616,
   "Closing_Rank": 8710
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10275,
   "Closing_Rank": 16086
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2369,
   "Closing_Rank": 3934
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6847,
   "Closing_Rank": 6847
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1423,
   "Closing_Rank": 1423
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17771,
   "Closing_Rank": 18485
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24228,
   "Closing_Rank": 24228
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 615,
   "Closing_Rank": 615
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4822,
   "Closing_Rank": 5478
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11436,
   "Closing_Rank": 11925
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21378,
   "Closing_Rank": 21378
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3763,
   "Closing_Rank": 3763
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7775,
   "Closing_Rank": 7775
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10688,
   "Closing_Rank": 15438
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22510,
   "Closing_Rank": 22510
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4598,
   "Closing_Rank": 4598
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5195,
   "Closing_Rank": 5708
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3799,
   "Closing_Rank": 3930
 },
 {
   "Institute": "National Institute of Technology Delhi",
   "Academic_Program_Name": "VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1730,
   "Closing_Rank": 1730
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12811,
   "Closing_Rank": 19108
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21013,
   "Closing_Rank": 26580
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2391,
   "Closing_Rank": 2391
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4484,
   "Closing_Rank": 4901
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10441,
   "Closing_Rank": 10441
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6095,
   "Closing_Rank": 11771
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16503,
   "Closing_Rank": 21124
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1659,
   "Closing_Rank": 2715
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6640,
   "Closing_Rank": 6640
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4615,
   "Closing_Rank": 4615
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5506,
   "Closing_Rank": 5506
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9154,
   "Closing_Rank": 12077
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16354,
   "Closing_Rank": 18005
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1816,
   "Closing_Rank": 1907
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3610,
   "Closing_Rank": 3610
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3645,
   "Closing_Rank": 4234
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8392,
   "Closing_Rank": 8885
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 448,
   "Closing_Rank": 448
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2191,
   "Closing_Rank": 2746
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 847,
   "Closing_Rank": 847
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "B.Tech in Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2125,
   "Closing_Rank": 2125
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44728,
   "Closing_Rank": 52643
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25088,
   "Closing_Rank": 41918
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2200,
   "Closing_Rank": 2200
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15786,
   "Closing_Rank": 16724
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35087,
   "Closing_Rank": 35087
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19544,
   "Closing_Rank": 37828
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30378,
   "Closing_Rank": 32414
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6513,
   "Closing_Rank": 8588
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4342,
   "Closing_Rank": 4342
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7590,
   "Closing_Rank": 8845
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15221,
   "Closing_Rank": 15221
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35794,
   "Closing_Rank": 47888
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45243,
   "Closing_Rank": 52107
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1296,
   "Closing_Rank": 1296
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7756,
   "Closing_Rank": 8113
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8502,
   "Closing_Rank": 8502
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14215,
   "Closing_Rank": 15137
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20041,
   "Closing_Rank": 20311
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1073,
   "Closing_Rank": 1073
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4583,
   "Closing_Rank": 7130
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9530,
   "Closing_Rank": 9530
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2439,
   "Closing_Rank": 2906
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4401,
   "Closing_Rank": 4401
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39332,
   "Closing_Rank": 39332
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36277,
   "Closing_Rank": 36277
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38653,
   "Closing_Rank": 38653
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9379,
   "Closing_Rank": 9379
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41093,
   "Closing_Rank": 47996
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Biotechnology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2920,
   "Closing_Rank": 2920
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33720,
   "Closing_Rank": 38799
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33919,
   "Closing_Rank": 35209
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1164,
   "Closing_Rank": 1164
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9875,
   "Closing_Rank": 11999
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16315,
   "Closing_Rank": 16315
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22063,
   "Closing_Rank": 25528
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28881,
   "Closing_Rank": 29454
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4097,
   "Closing_Rank": 5637
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9137,
   "Closing_Rank": 9137
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9475,
   "Closing_Rank": 9928
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13528,
   "Closing_Rank": 13528
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28000,
   "Closing_Rank": 33696
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38597,
   "Closing_Rank": 40727
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1813,
   "Closing_Rank": 1813
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5615,
   "Closing_Rank": 6165
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7214,
   "Closing_Rank": 7214
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10183,
   "Closing_Rank": 11747
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15496,
   "Closing_Rank": 16013
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1674,
   "Closing_Rank": 1674
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6037,
   "Closing_Rank": 6367
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7267,
   "Closing_Rank": 7267
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2030,
   "Closing_Rank": 2084
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3390,
   "Closing_Rank": 3390
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34347,
   "Closing_Rank": 39810
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27262,
   "Closing_Rank": 27262
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6450,
   "Closing_Rank": 6450
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34405,
   "Closing_Rank": 34405
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44280,
   "Closing_Rank": 44280
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12673,
   "Closing_Rank": 12673
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53000,
   "Closing_Rank": 55579
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55746,
   "Closing_Rank": 55746
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17155,
   "Closing_Rank": 17155
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40381,
   "Closing_Rank": 40766
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10026,
   "Closing_Rank": 10026
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52850,
   "Closing_Rank": 54181
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 62067,
   "Closing_Rank": 62067
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9327,
   "Closing_Rank": 9327
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16447,
   "Closing_Rank": 18415
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20371,
   "Closing_Rank": 20371
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9454,
   "Closing_Rank": 9454
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3238,
   "Closing_Rank": 3238
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30900,
   "Closing_Rank": 44468
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48151,
   "Closing_Rank": 53154
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2552,
   "Closing_Rank": 2552
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14606,
   "Closing_Rank": 15717
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22652,
   "Closing_Rank": 22652
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20325,
   "Closing_Rank": 29500
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38758,
   "Closing_Rank": 41915
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6952,
   "Closing_Rank": 8362
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10541,
   "Closing_Rank": 10736
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5669,
   "Closing_Rank": 6811
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9528,
   "Closing_Rank": 9528
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33236,
   "Closing_Rank": 40215
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49272,
   "Closing_Rank": 50702
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6519,
   "Closing_Rank": 6556
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8293,
   "Closing_Rank": 8293
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12376,
   "Closing_Rank": 12892
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18934,
   "Closing_Rank": 20111
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 792,
   "Closing_Rank": 792
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4276,
   "Closing_Rank": 5957
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8736,
   "Closing_Rank": 9038
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1543,
   "Closing_Rank": 1585
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3107,
   "Closing_Rank": 3107
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5446,
   "Closing_Rank": 14090
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10671,
   "Closing_Rank": 18785
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 107,
   "Closing_Rank": 107
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2182,
   "Closing_Rank": 4275
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3454,
   "Closing_Rank": 7651
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 481,
   "Closing_Rank": 481
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3463,
   "Closing_Rank": 8278
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11890,
   "Closing_Rank": 15435
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 226,
   "Closing_Rank": 226
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 618,
   "Closing_Rank": 1466
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1173,
   "Closing_Rank": 2262
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21,
   "Closing_Rank": 21
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 251,
   "Closing_Rank": 2160
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2653,
   "Closing_Rank": 3203
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5973,
   "Closing_Rank": 8765
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9300,
   "Closing_Rank": 11623
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 288,
   "Closing_Rank": 288
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1224,
   "Closing_Rank": 1397
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1892,
   "Closing_Rank": 2151
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2054,
   "Closing_Rank": 3182
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4212,
   "Closing_Rank": 5182
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 180,
   "Closing_Rank": 180
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1449,
   "Closing_Rank": 1934
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1334,
   "Closing_Rank": 3009
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 393,
   "Closing_Rank": 458
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 736,
   "Closing_Rank": 1188
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20004,
   "Closing_Rank": 23223
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27454,
   "Closing_Rank": 32123
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1171,
   "Closing_Rank": 1171
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6409,
   "Closing_Rank": 6620
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13464,
   "Closing_Rank": 13464
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13028,
   "Closing_Rank": 17401
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19734,
   "Closing_Rank": 28372
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3159,
   "Closing_Rank": 3940
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4390,
   "Closing_Rank": 6515
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2644,
   "Closing_Rank": 3373
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1808,
   "Closing_Rank": 1808
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14715,
   "Closing_Rank": 19559
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23914,
   "Closing_Rank": 31268
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1410,
   "Closing_Rank": 1410
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3033,
   "Closing_Rank": 3226
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5302,
   "Closing_Rank": 5302
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5783,
   "Closing_Rank": 6475
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9082,
   "Closing_Rank": 9504
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 646,
   "Closing_Rank": 737
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3207,
   "Closing_Rank": 3955
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5518,
   "Closing_Rank": 5717
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1093,
   "Closing_Rank": 1139
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2100,
   "Closing_Rank": 2100
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12069,
   "Closing_Rank": 19252
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22279,
   "Closing_Rank": 23007
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 414,
   "Closing_Rank": 414
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5951,
   "Closing_Rank": 6124
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9393,
   "Closing_Rank": 9393
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7273,
   "Closing_Rank": 12936
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15608,
   "Closing_Rank": 18159
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 177,
   "Closing_Rank": 177
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1739,
   "Closing_Rank": 2901
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4056,
   "Closing_Rank": 4056
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1478,
   "Closing_Rank": 3002
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4938,
   "Closing_Rank": 4938
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9270,
   "Closing_Rank": 13608
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17085,
   "Closing_Rank": 20568
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 593,
   "Closing_Rank": 593
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2057,
   "Closing_Rank": 2149
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3292,
   "Closing_Rank": 3292
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3555,
   "Closing_Rank": 4631
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7282,
   "Closing_Rank": 7588
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2052,
   "Closing_Rank": 2851
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4032,
   "Closing_Rank": 4059
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 603,
   "Closing_Rank": 757
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1885,
   "Closing_Rank": 1885
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24328,
   "Closing_Rank": 32976
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36478,
   "Closing_Rank": 49258
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 775,
   "Closing_Rank": 775
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6923,
   "Closing_Rank": 10116
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15078,
   "Closing_Rank": 16616
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13392,
   "Closing_Rank": 23991
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29317,
   "Closing_Rank": 38269
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1635,
   "Closing_Rank": 1635
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3057,
   "Closing_Rank": 5700
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6668,
   "Closing_Rank": 8236
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4083,
   "Closing_Rank": 6067
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7859,
   "Closing_Rank": 12899
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22071,
   "Closing_Rank": 27957
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32026,
   "Closing_Rank": 34829
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1584,
   "Closing_Rank": 1584
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4799,
   "Closing_Rank": 4949
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6113,
   "Closing_Rank": 6290
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7689,
   "Closing_Rank": 9834
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14257,
   "Closing_Rank": 16603
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 952,
   "Closing_Rank": 952
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4568,
   "Closing_Rank": 5360
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7434,
   "Closing_Rank": 8350
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1242,
   "Closing_Rank": 1915
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3161,
   "Closing_Rank": 3291
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39831,
   "Closing_Rank": 48076
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49336,
   "Closing_Rank": 51939
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1701,
   "Closing_Rank": 1701
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10527,
   "Closing_Rank": 15542
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26789,
   "Closing_Rank": 26789
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26579,
   "Closing_Rank": 34536
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30120,
   "Closing_Rank": 30120
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6380,
   "Closing_Rank": 8419
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9734,
   "Closing_Rank": 9734
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9580,
   "Closing_Rank": 9580
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15725,
   "Closing_Rank": 15725
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31491,
   "Closing_Rank": 44399
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52069,
   "Closing_Rank": 56452
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2586,
   "Closing_Rank": 2586
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7376,
   "Closing_Rank": 7413
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10053,
   "Closing_Rank": 10053
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12908,
   "Closing_Rank": 14964
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19737,
   "Closing_Rank": 19737
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1364,
   "Closing_Rank": 1364
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7072,
   "Closing_Rank": 7887
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7234,
   "Closing_Rank": 7234
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2660,
   "Closing_Rank": 2844
 },
 {
   "Institute": "National Institute of Technology Durgapur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4353,
   "Closing_Rank": 4353
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50452,
   "Closing_Rank": 83278
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 104222,
   "Closing_Rank": 104222
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 105446,
   "Closing_Rank": 138934
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 221362,
   "Closing_Rank": 221362
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10577,
   "Closing_Rank": 55000
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13278,
   "Closing_Rank": 13278
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 89452,
   "Closing_Rank": 89452
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 674299,
   "Closing_Rank": 674299
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39186,
   "Closing_Rank": 39186
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50206,
   "Closing_Rank": 50206
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40468,
   "Closing_Rank": 44014
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48672,
   "Closing_Rank": 52955
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7434,
   "Closing_Rank": 7475
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12995,
   "Closing_Rank": 14663
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16071,
   "Closing_Rank": 16071
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3758,
   "Closing_Rank": 3758
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8058,
   "Closing_Rank": 8058
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2138,
   "Closing_Rank": 2138
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7705,
   "Closing_Rank": 27417
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37084,
   "Closing_Rank": 38936
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4481,
   "Closing_Rank": 4481
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12370,
   "Closing_Rank": 22662
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24550,
   "Closing_Rank": 24550
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4853,
   "Closing_Rank": 9679
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2738,
   "Closing_Rank": 2738
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2941,
   "Closing_Rank": 4273
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22250,
   "Closing_Rank": 34858
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8961,
   "Closing_Rank": 8961
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23454,
   "Closing_Rank": 23454
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7914,
   "Closing_Rank": 12511
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13339,
   "Closing_Rank": 13339
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 420,
   "Closing_Rank": 420
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1924,
   "Closing_Rank": 1929
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3143,
   "Closing_Rank": 3143
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3783,
   "Closing_Rank": 4746
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5554,
   "Closing_Rank": 5554
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2234,
   "Closing_Rank": 2782
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3243,
   "Closing_Rank": 3243
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 626,
   "Closing_Rank": 626
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47017,
   "Closing_Rank": 64325
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 99933,
   "Closing_Rank": 99933
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25843,
   "Closing_Rank": 25843
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26608,
   "Closing_Rank": 65730
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63399,
   "Closing_Rank": 63399
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18622,
   "Closing_Rank": 34483
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42641,
   "Closing_Rank": 42641
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9418,
   "Closing_Rank": 9418
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46614,
   "Closing_Rank": 46614
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 67170,
   "Closing_Rank": 67170
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23815,
   "Closing_Rank": 23815
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31653,
   "Closing_Rank": 31653
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3139,
   "Closing_Rank": 3139
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17714,
   "Closing_Rank": 23326
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26478,
   "Closing_Rank": 26968
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4013,
   "Closing_Rank": 4154
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6954,
   "Closing_Rank": 8251
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11162,
   "Closing_Rank": 14902
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 766,
   "Closing_Rank": 766
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4164,
   "Closing_Rank": 4601
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6334,
   "Closing_Rank": 6334
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 83,
   "Closing_Rank": 83
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22186,
   "Closing_Rank": 37814
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47556,
   "Closing_Rank": 77244
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21983,
   "Closing_Rank": 24335
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30818,
   "Closing_Rank": 54193
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12472,
   "Closing_Rank": 16039
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32521,
   "Closing_Rank": 32521
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5463,
   "Closing_Rank": 5463
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35548,
   "Closing_Rank": 41968
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34941,
   "Closing_Rank": 34941
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14472,
   "Closing_Rank": 14472
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14870,
   "Closing_Rank": 16935
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24488,
   "Closing_Rank": 25449
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 747,
   "Closing_Rank": 747
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2654,
   "Closing_Rank": 2877
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6094,
   "Closing_Rank": 6777
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10317,
   "Closing_Rank": 10317
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3332,
   "Closing_Rank": 4114
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5823,
   "Closing_Rank": 5823
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1085,
   "Closing_Rank": 1474
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2121,
   "Closing_Rank": 2121
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36144,
   "Closing_Rank": 79077
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 75251,
   "Closing_Rank": 75251
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40543,
   "Closing_Rank": 40543
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70874,
   "Closing_Rank": 88700
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 104473,
   "Closing_Rank": 104473
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36358,
   "Closing_Rank": 36358
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "GO",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4625,
   "Closing_Rank": 4625
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58547,
   "Closing_Rank": 58547
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 95332,
   "Closing_Rank": 95332
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22654,
   "Closing_Rank": 22654
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25708,
   "Closing_Rank": 31124
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43643,
   "Closing_Rank": 43643
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7750,
   "Closing_Rank": 7750
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 440,
   "Closing_Rank": 440
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9934,
   "Closing_Rank": 11719
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18160,
   "Closing_Rank": 18160
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5009,
   "Closing_Rank": 6034
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2319,
   "Closing_Rank": 2319
 },
 {
   "Institute": "National Institute of Technology Goa",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3189,
   "Closing_Rank": 3189
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1781,
   "Closing_Rank": 5973
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1860,
   "Closing_Rank": 3126
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2971,
   "Closing_Rank": 2971
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2863,
   "Closing_Rank": 12832
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4222,
   "Closing_Rank": 4222
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 526,
   "Closing_Rank": 657
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1933,
   "Closing_Rank": 1933
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 264,
   "Closing_Rank": 264
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 414,
   "Closing_Rank": 414
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 903,
   "Closing_Rank": 1616
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1096,
   "Closing_Rank": 1268
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 637,
   "Closing_Rank": 701
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 478,
   "Closing_Rank": 478
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 562,
   "Closing_Rank": 1051
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 891,
   "Closing_Rank": 975
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 212,
   "Closing_Rank": 286
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 167,
   "Closing_Rank": 167
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 120,
   "Closing_Rank": 161
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 71215,
   "Closing_Rank": 105917
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 70239,
   "Closing_Rank": 100330
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17800,
   "Closing_Rank": 22831
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21652,
   "Closing_Rank": 21652
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96220,
   "Closing_Rank": 132676
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 78812,
   "Closing_Rank": 98660
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6583,
   "Closing_Rank": 11880
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13144,
   "Closing_Rank": 13144
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4918,
   "Closing_Rank": 5033
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25381,
   "Closing_Rank": 39188
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37417,
   "Closing_Rank": 48228
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6502,
   "Closing_Rank": 6577
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8469,
   "Closing_Rank": 8469
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11504,
   "Closing_Rank": 13273
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19659,
   "Closing_Rank": 20010
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1796,
   "Closing_Rank": 1796
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5392,
   "Closing_Rank": 6695
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7505,
   "Closing_Rank": 7505
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2369,
   "Closing_Rank": 2886
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4031,
   "Closing_Rank": 4031
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62609,
   "Closing_Rank": 111536
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 81065,
   "Closing_Rank": 106671
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20356,
   "Closing_Rank": 23418
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23266,
   "Closing_Rank": 23266
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36141,
   "Closing_Rank": 102398
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 108289,
   "Closing_Rank": 132760
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8681,
   "Closing_Rank": 11140
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6852,
   "Closing_Rank": 12843
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2421,
   "Closing_Rank": 4169
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3891,
   "Closing_Rank": 3891
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37193,
   "Closing_Rank": 45502
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50581,
   "Closing_Rank": 54283
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3228,
   "Closing_Rank": 3228
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7447,
   "Closing_Rank": 7618
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9378,
   "Closing_Rank": 9378
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14078,
   "Closing_Rank": 15449
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20726,
   "Closing_Rank": 21019
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6040,
   "Closing_Rank": 7373
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7205,
   "Closing_Rank": 8067
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1715,
   "Closing_Rank": 1911
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3152,
   "Closing_Rank": 3152
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12871,
   "Closing_Rank": 28278
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21216,
   "Closing_Rank": 34884
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2685,
   "Closing_Rank": 2685
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5073,
   "Closing_Rank": 9221
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11326,
   "Closing_Rank": 11326
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7944,
   "Closing_Rank": 21983
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11888,
   "Closing_Rank": 23531
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2246,
   "Closing_Rank": 3299
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2358,
   "Closing_Rank": 2778
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 768,
   "Closing_Rank": 998
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2158,
   "Closing_Rank": 2158
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 94,
   "Closing_Rank": 94
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8376,
   "Closing_Rank": 12236
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15238,
   "Closing_Rank": 19951
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 456,
   "Closing_Rank": 456
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1852,
   "Closing_Rank": 2092
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3193,
   "Closing_Rank": 3193
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3487,
   "Closing_Rank": 4356
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5896,
   "Closing_Rank": 7593
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 289,
   "Closing_Rank": 289
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2311,
   "Closing_Rank": 2773
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3882,
   "Closing_Rank": 4181
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 137,
   "Closing_Rank": 137
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 676,
   "Closing_Rank": 1084
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1779,
   "Closing_Rank": 1779
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28887,
   "Closing_Rank": 35206
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44148,
   "Closing_Rank": 44148
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2279,
   "Closing_Rank": 2279
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10912,
   "Closing_Rank": 10912
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17592,
   "Closing_Rank": 28385
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24565,
   "Closing_Rank": 24565
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3478,
   "Closing_Rank": 3478
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5545,
   "Closing_Rank": 5545
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1941,
   "Closing_Rank": 1941
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12499,
   "Closing_Rank": 14106
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22553,
   "Closing_Rank": 22553
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2169,
   "Closing_Rank": 2248
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3680,
   "Closing_Rank": 4747
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11001,
   "Closing_Rank": 12130
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3037,
   "Closing_Rank": 3056
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1227,
   "Closing_Rank": 1227
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53347,
   "Closing_Rank": 70818
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 61297,
   "Closing_Rank": 72389
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1798,
   "Closing_Rank": 1798
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12637,
   "Closing_Rank": 14926
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20626,
   "Closing_Rank": 20626
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53591,
   "Closing_Rank": 75819
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 57021,
   "Closing_Rank": 65846
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3901,
   "Closing_Rank": 7585
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10579,
   "Closing_Rank": 10625
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2507,
   "Closing_Rank": 3584
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2859,
   "Closing_Rank": 2859
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20800,
   "Closing_Rank": 25782
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23756,
   "Closing_Rank": 32832
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1936,
   "Closing_Rank": 1936
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3219,
   "Closing_Rank": 3219
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4215,
   "Closing_Rank": 4309
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6110,
   "Closing_Rank": 6110
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7286,
   "Closing_Rank": 8894
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12602,
   "Closing_Rank": 14267
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4018,
   "Closing_Rank": 5040
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7291,
   "Closing_Rank": 7291
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1568,
   "Closing_Rank": 2029
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3135,
   "Closing_Rank": 3135
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32762,
   "Closing_Rank": 52872
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35733,
   "Closing_Rank": 53435
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10884,
   "Closing_Rank": 11470
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12916,
   "Closing_Rank": 12916
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30073,
   "Closing_Rank": 52383
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44071,
   "Closing_Rank": 52306
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3644,
   "Closing_Rank": 6504
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9097,
   "Closing_Rank": 9097
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1165,
   "Closing_Rank": 2120
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2371,
   "Closing_Rank": 2857
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13651,
   "Closing_Rank": 18421
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26899,
   "Closing_Rank": 28062
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1129,
   "Closing_Rank": 1129
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2856,
   "Closing_Rank": 3042
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4488,
   "Closing_Rank": 4488
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5300,
   "Closing_Rank": 6647
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9832,
   "Closing_Rank": 11973
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 699,
   "Closing_Rank": 699
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3098,
   "Closing_Rank": 4219
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5147,
   "Closing_Rank": 5620
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1281,
   "Closing_Rank": 1416
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1986,
   "Closing_Rank": 1986
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37997,
   "Closing_Rank": 55371
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52712,
   "Closing_Rank": 52712
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12702,
   "Closing_Rank": 14253
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53867,
   "Closing_Rank": 58802
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56098,
   "Closing_Rank": 76922
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7116,
   "Closing_Rank": 8096
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2630,
   "Closing_Rank": 2630
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18631,
   "Closing_Rank": 19942
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28452,
   "Closing_Rank": 28452
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1035,
   "Closing_Rank": 1035
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3408,
   "Closing_Rank": 3408
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5615,
   "Closing_Rank": 6794
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13927,
   "Closing_Rank": 13927
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4553,
   "Closing_Rank": 4553
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7393,
   "Closing_Rank": 7393
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2164,
   "Closing_Rank": 2164
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68752,
   "Closing_Rank": 111665
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 90040,
   "Closing_Rank": 98630
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23439,
   "Closing_Rank": 23850
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 101593,
   "Closing_Rank": 139038
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 157849,
   "Closing_Rank": 208815
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9989,
   "Closing_Rank": 12227
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2845,
   "Closing_Rank": 2845
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5965,
   "Closing_Rank": 5965
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21249,
   "Closing_Rank": 35666
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39193,
   "Closing_Rank": 39672
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3298,
   "Closing_Rank": 3298
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6820,
   "Closing_Rank": 7008
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7178,
   "Closing_Rank": 7178
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10874,
   "Closing_Rank": 13008
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18296,
   "Closing_Rank": 18296
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1646,
   "Closing_Rank": 1646
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4508,
   "Closing_Rank": 6164
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9741,
   "Closing_Rank": 9741
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3096,
   "Closing_Rank": 3096
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 97104,
   "Closing_Rank": 114907
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 98224,
   "Closing_Rank": 98224
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26318,
   "Closing_Rank": 26318
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24137,
   "Closing_Rank": 24137
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 100303,
   "Closing_Rank": 147929
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 104062,
   "Closing_Rank": 104062
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12420,
   "Closing_Rank": 13248
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5182,
   "Closing_Rank": 5182
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5627,
   "Closing_Rank": 5627
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45178,
   "Closing_Rank": 46742
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50743,
   "Closing_Rank": 54073
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7769,
   "Closing_Rank": 8023
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15579,
   "Closing_Rank": 15755
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19476,
   "Closing_Rank": 19476
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1778,
   "Closing_Rank": 1778
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7673,
   "Closing_Rank": 7872
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10675,
   "Closing_Rank": 10675
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Materials Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3110,
   "Closing_Rank": 3191
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29739,
   "Closing_Rank": 41618
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49236,
   "Closing_Rank": 52970
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1898,
   "Closing_Rank": 1898
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9810,
   "Closing_Rank": 10749
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13339,
   "Closing_Rank": 13339
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26275,
   "Closing_Rank": 41279
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44142,
   "Closing_Rank": 44142
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3826,
   "Closing_Rank": 3906
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12279,
   "Closing_Rank": 12279
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1682,
   "Closing_Rank": 1682
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13016,
   "Closing_Rank": 16502
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23682,
   "Closing_Rank": 23719
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2575,
   "Closing_Rank": 2579
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4765,
   "Closing_Rank": 5800
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7692,
   "Closing_Rank": 10138
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1973,
   "Closing_Rank": 3642
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1521,
   "Closing_Rank": 1521
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3812,
   "Closing_Rank": 3812
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48580,
   "Closing_Rank": 79041
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 77720,
   "Closing_Rank": 111152
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2227,
   "Closing_Rank": 2227
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15307,
   "Closing_Rank": 16560
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23856,
   "Closing_Rank": 23856
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 82799,
   "Closing_Rank": 127894
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 109967,
   "Closing_Rank": 181980
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7344,
   "Closing_Rank": 10457
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13393,
   "Closing_Rank": 13393
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3397,
   "Closing_Rank": 4073
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5206,
   "Closing_Rank": 5206
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27676,
   "Closing_Rank": 35236
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43377,
   "Closing_Rank": 48988
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2213,
   "Closing_Rank": 2213
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5771,
   "Closing_Rank": 5902
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8074,
   "Closing_Rank": 8074
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 477,
   "Closing_Rank": 477
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10868,
   "Closing_Rank": 12148
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16906,
   "Closing_Rank": 19232
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1784,
   "Closing_Rank": 1784
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5896,
   "Closing_Rank": 6233
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9296,
   "Closing_Rank": 9422
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2095,
   "Closing_Rank": 2365
 },
 {
   "Institute": "National Institute of Technology Hamirpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4020,
   "Closing_Rank": 4020
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3114,
   "Closing_Rank": 3717
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3948,
   "Closing_Rank": 6233
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1321,
   "Closing_Rank": 1321
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 897,
   "Closing_Rank": 2383
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7052,
   "Closing_Rank": 7052
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 144,
   "Closing_Rank": 144
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 976,
   "Closing_Rank": 1555
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 314,
   "Closing_Rank": 314
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 810,
   "Closing_Rank": 810
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2138,
   "Closing_Rank": 2594
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4354,
   "Closing_Rank": 4354
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 109,
   "Closing_Rank": 109
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 356,
   "Closing_Rank": 398
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 732,
   "Closing_Rank": 732
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 586,
   "Closing_Rank": 740
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1664,
   "Closing_Rank": 1664
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 348,
   "Closing_Rank": 586
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 774,
   "Closing_Rank": 774
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 224,
   "Closing_Rank": 224
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9458,
   "Closing_Rank": 19080
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17900,
   "Closing_Rank": 22991
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1036,
   "Closing_Rank": 1036
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3109,
   "Closing_Rank": 4447
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6977,
   "Closing_Rank": 12125
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13595,
   "Closing_Rank": 13595
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4237,
   "Closing_Rank": 5478
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5142,
   "Closing_Rank": 5142
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1223,
   "Closing_Rank": 1223
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1566,
   "Closing_Rank": 1566
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11762,
   "Closing_Rank": 15195
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20811,
   "Closing_Rank": 21059
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 458,
   "Closing_Rank": 458
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2717,
   "Closing_Rank": 2732
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4191,
   "Closing_Rank": 4191
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5079,
   "Closing_Rank": 5917
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6937,
   "Closing_Rank": 8267
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 495,
   "Closing_Rank": 495
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2271,
   "Closing_Rank": 3857
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4926,
   "Closing_Rank": 4926
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1206,
   "Closing_Rank": 1229
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10933,
   "Closing_Rank": 32691
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29523,
   "Closing_Rank": 39258
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 955,
   "Closing_Rank": 955
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5793,
   "Closing_Rank": 7099
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10601,
   "Closing_Rank": 10601
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10291,
   "Closing_Rank": 15103
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20322,
   "Closing_Rank": 21170
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 957,
   "Closing_Rank": 957
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2116,
   "Closing_Rank": 7168
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6070,
   "Closing_Rank": 6070
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1395,
   "Closing_Rank": 1807
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1909,
   "Closing_Rank": 1909
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14301,
   "Closing_Rank": 22939
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27846,
   "Closing_Rank": 31390
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1194,
   "Closing_Rank": 1367
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3603,
   "Closing_Rank": 3680
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5487,
   "Closing_Rank": 5487
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 251,
   "Closing_Rank": 251
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6789,
   "Closing_Rank": 8050
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9118,
   "Closing_Rank": 10217
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 547,
   "Closing_Rank": 547
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3291,
   "Closing_Rank": 4134
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5709,
   "Closing_Rank": 5921
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 571,
   "Closing_Rank": 1010
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1841,
   "Closing_Rank": 1841
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3437,
   "Closing_Rank": 4786
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7008,
   "Closing_Rank": 7008
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 181,
   "Closing_Rank": 181
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1424,
   "Closing_Rank": 1424
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2466,
   "Closing_Rank": 3272
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10866,
   "Closing_Rank": 10866
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1819,
   "Closing_Rank": 2096
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2439,
   "Closing_Rank": 2439
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 578,
   "Closing_Rank": 578
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2299,
   "Closing_Rank": 3348
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4443,
   "Closing_Rank": 4443
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 556,
   "Closing_Rank": 556
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1064,
   "Closing_Rank": 1064
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 765,
   "Closing_Rank": 1110
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1878,
   "Closing_Rank": 1878
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 970,
   "Closing_Rank": 979
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computational and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 232,
   "Closing_Rank": 232
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1607,
   "Closing_Rank": 2933
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2636,
   "Closing_Rank": 3358
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93,
   "Closing_Rank": 93
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 757,
   "Closing_Rank": 1163
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1369,
   "Closing_Rank": 1369
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66,
   "Closing_Rank": 66
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 593,
   "Closing_Rank": 2099
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2053,
   "Closing_Rank": 4021
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 151,
   "Closing_Rank": 151
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 296,
   "Closing_Rank": 1223
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 837,
   "Closing_Rank": 837
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2,
   "Closing_Rank": 2
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58,
   "Closing_Rank": 146
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 213,
   "Closing_Rank": 213
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14,
   "Closing_Rank": 1615
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 729,
   "Closing_Rank": 2433
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 297,
   "Closing_Rank": 297
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 222,
   "Closing_Rank": 309
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 526,
   "Closing_Rank": 526
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 391,
   "Closing_Rank": 532
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1059,
   "Closing_Rank": 1627
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 74,
   "Closing_Rank": 74
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 225,
   "Closing_Rank": 324
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 542,
   "Closing_Rank": 679
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 107,
   "Closing_Rank": 153
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 174,
   "Closing_Rank": 174
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5905,
   "Closing_Rank": 7809
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10016,
   "Closing_Rank": 11553
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 509,
   "Closing_Rank": 509
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2036,
   "Closing_Rank": 2660
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5650,
   "Closing_Rank": 5650
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4606,
   "Closing_Rank": 5845
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5921,
   "Closing_Rank": 11224
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 388,
   "Closing_Rank": 388
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2124,
   "Closing_Rank": 2598
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4271,
   "Closing_Rank": 5153
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 643,
   "Closing_Rank": 982
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1235,
   "Closing_Rank": 1235
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5368,
   "Closing_Rank": 7132
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10100,
   "Closing_Rank": 10912
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 616,
   "Closing_Rank": 616
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1042,
   "Closing_Rank": 1314
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2260,
   "Closing_Rank": 2260
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1639,
   "Closing_Rank": 2495
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2782,
   "Closing_Rank": 3968
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 86,
   "Closing_Rank": 86
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 384,
   "Closing_Rank": 1710
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1910,
   "Closing_Rank": 1910
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 80,
   "Closing_Rank": 80
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 143,
   "Closing_Rank": 366
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 879,
   "Closing_Rank": 879
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 77,
   "Closing_Rank": 77
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3410,
   "Closing_Rank": 5662
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5537,
   "Closing_Rank": 7934
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 119,
   "Closing_Rank": 119
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1198,
   "Closing_Rank": 1792
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4098,
   "Closing_Rank": 4098
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 138,
   "Closing_Rank": 138
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3018,
   "Closing_Rank": 4406
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9596,
   "Closing_Rank": 10889
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 132,
   "Closing_Rank": 132
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1069,
   "Closing_Rank": 1959
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2409,
   "Closing_Rank": 2438
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 705,
   "Closing_Rank": 707
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 484,
   "Closing_Rank": 484
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20,
   "Closing_Rank": 20
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1991,
   "Closing_Rank": 4227
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5959,
   "Closing_Rank": 7035
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 382,
   "Closing_Rank": 382
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 647,
   "Closing_Rank": 725
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1225,
   "Closing_Rank": 1225
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1252,
   "Closing_Rank": 1580
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2253,
   "Closing_Rank": 2632
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 667,
   "Closing_Rank": 986
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1580,
   "Closing_Rank": 1580
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 65,
   "Closing_Rank": 65
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 271,
   "Closing_Rank": 309
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 345,
   "Closing_Rank": 345
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3173,
   "Closing_Rank": 4452
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3814,
   "Closing_Rank": 5617
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 164,
   "Closing_Rank": 164
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1293,
   "Closing_Rank": 1333
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5570,
   "Closing_Rank": 5570
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2122,
   "Closing_Rank": 3268
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6129,
   "Closing_Rank": 7201
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 500,
   "Closing_Rank": 500
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1253,
   "Closing_Rank": 1860
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2572,
   "Closing_Rank": 2572
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 506,
   "Closing_Rank": 613
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 958,
   "Closing_Rank": 958
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1697,
   "Closing_Rank": 2944
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2850,
   "Closing_Rank": 4876
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 198,
   "Closing_Rank": 198
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 382,
   "Closing_Rank": 478
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 720,
   "Closing_Rank": 720
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 662,
   "Closing_Rank": 1038
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1783,
   "Closing_Rank": 2180
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 639,
   "Closing_Rank": 852
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 877,
   "Closing_Rank": 877
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 61,
   "Closing_Rank": 61
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 158,
   "Closing_Rank": 158
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11,
   "Closing_Rank": 11
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3340,
   "Closing_Rank": 14011
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14820,
   "Closing_Rank": 27829
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1166,
   "Closing_Rank": 1166
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2994,
   "Closing_Rank": 3965
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4440,
   "Closing_Rank": 7630
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 197,
   "Closing_Rank": 197
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3853,
   "Closing_Rank": 10194
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14408,
   "Closing_Rank": 19383
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 680,
   "Closing_Rank": 680
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 926,
   "Closing_Rank": 4424
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5809,
   "Closing_Rank": 6192
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 333,
   "Closing_Rank": 333
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1221,
   "Closing_Rank": 1618
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3183,
   "Closing_Rank": 3183
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4101,
   "Closing_Rank": 12380
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20398,
   "Closing_Rank": 22425
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 423,
   "Closing_Rank": 478
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1305,
   "Closing_Rank": 1305
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1921,
   "Closing_Rank": 2080
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3973,
   "Closing_Rank": 4458
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3286,
   "Closing_Rank": 4994
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7829,
   "Closing_Rank": 8557
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 377,
   "Closing_Rank": 377
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 566,
   "Closing_Rank": 2633
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4513,
   "Closing_Rank": 4897
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 127,
   "Closing_Rank": 127
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 955,
   "Closing_Rank": 1162
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1705,
   "Closing_Rank": 1765
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11386,
   "Closing_Rank": 26584
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28212,
   "Closing_Rank": 32742
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2028,
   "Closing_Rank": 2028
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4647,
   "Closing_Rank": 5369
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13264,
   "Closing_Rank": 15553
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19899,
   "Closing_Rank": 21498
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7582,
   "Closing_Rank": 9437
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9288,
   "Closing_Rank": 9288
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1965,
   "Closing_Rank": 1965
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2990,
   "Closing_Rank": 2990
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14034,
   "Closing_Rank": 23412
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31977,
   "Closing_Rank": 35840
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 922,
   "Closing_Rank": 922
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3997,
   "Closing_Rank": 4418
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6862,
   "Closing_Rank": 6862
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8792,
   "Closing_Rank": 9052
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13323,
   "Closing_Rank": 13901
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 492,
   "Closing_Rank": 492
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4966,
   "Closing_Rank": 5257
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5721,
   "Closing_Rank": 5721
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1958,
   "Closing_Rank": 2069
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27486,
   "Closing_Rank": 41318
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41413,
   "Closing_Rank": 60709
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7555,
   "Closing_Rank": 7757
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15715,
   "Closing_Rank": 17436
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20090,
   "Closing_Rank": 30036
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7324,
   "Closing_Rank": 9388
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9554,
   "Closing_Rank": 9554
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1861,
   "Closing_Rank": 1861
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21500,
   "Closing_Rank": 27766
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38204,
   "Closing_Rank": 48048
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4482,
   "Closing_Rank": 4517
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7776,
   "Closing_Rank": 7776
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9288,
   "Closing_Rank": 10611
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15377,
   "Closing_Rank": 15377
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5051,
   "Closing_Rank": 5712
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6118,
   "Closing_Rank": 6118
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1465,
   "Closing_Rank": 1538
 },
 {
   "Institute": "National Institute of Technology Karnataka Surathkal",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3223,
   "Closing_Rank": 3223
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 169930,
   "Closing_Rank": 169930
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 296304,
   "Closing_Rank": 296304
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2211,
   "Closing_Rank": 12084
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7985,
   "Closing_Rank": 8698
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45085,
   "Closing_Rank": 49836
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60488,
   "Closing_Rank": 65404
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8643,
   "Closing_Rank": 8643
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17008,
   "Closing_Rank": 18001
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23119,
   "Closing_Rank": 23119
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7619,
   "Closing_Rank": 7662
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2156,
   "Closing_Rank": 2156
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31545,
   "Closing_Rank": 31545
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 198857,
   "Closing_Rank": 198857
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30856,
   "Closing_Rank": 30856
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17255,
   "Closing_Rank": 19897
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2763,
   "Closing_Rank": 7348
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4705,
   "Closing_Rank": 4705
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14656,
   "Closing_Rank": 20039
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35153,
   "Closing_Rank": 35153
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3386,
   "Closing_Rank": 3386
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 323,
   "Closing_Rank": 323
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5251,
   "Closing_Rank": 7086
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13146,
   "Closing_Rank": 13146
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3059,
   "Closing_Rank": 3059
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5288,
   "Closing_Rank": 5288
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1031,
   "Closing_Rank": 1031
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 188231,
   "Closing_Rank": 188231
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 459245,
   "Closing_Rank": 459245
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39020,
   "Closing_Rank": 39020
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27941,
   "Closing_Rank": 27941
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3797,
   "Closing_Rank": 18350
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18898,
   "Closing_Rank": 19966
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29518,
   "Closing_Rank": 36106
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43307,
   "Closing_Rank": 43307
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6271,
   "Closing_Rank": 6309
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11675,
   "Closing_Rank": 12834
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18540,
   "Closing_Rank": 18540
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1040,
   "Closing_Rank": 1040
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7111,
   "Closing_Rank": 7193
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3815,
   "Closing_Rank": 3815
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55668,
   "Closing_Rank": 102988
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 365786,
   "Closing_Rank": 365786
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2172,
   "Closing_Rank": 18329
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8248,
   "Closing_Rank": 21839
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27786,
   "Closing_Rank": 29484
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43057,
   "Closing_Rank": 43057
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1802,
   "Closing_Rank": 1802
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4726,
   "Closing_Rank": 4726
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7028,
   "Closing_Rank": 7028
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9316,
   "Closing_Rank": 9487
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18059,
   "Closing_Rank": 18059
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4878,
   "Closing_Rank": 4878
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6961,
   "Closing_Rank": 6961
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1463,
   "Closing_Rank": 1463
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 162491,
   "Closing_Rank": 162491
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 458027,
   "Closing_Rank": 458027
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39038,
   "Closing_Rank": 39038
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11165,
   "Closing_Rank": 18638
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16864,
   "Closing_Rank": 16864
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42899,
   "Closing_Rank": 48332
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53708,
   "Closing_Rank": 53708
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7819,
   "Closing_Rank": 7819
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14581,
   "Closing_Rank": 14596
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19920,
   "Closing_Rank": 19920
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7521,
   "Closing_Rank": 7688
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11251,
   "Closing_Rank": 11251
 },
 {
   "Institute": "National Institute of Technology Meghalaya",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2944,
   "Closing_Rank": 2952
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3299,
   "Closing_Rank": 11973
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5162,
   "Closing_Rank": 7255
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51582,
   "Closing_Rank": 57171
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 67166,
   "Closing_Rank": 68939
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9413,
   "Closing_Rank": 9480
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17567,
   "Closing_Rank": 18671
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24688,
   "Closing_Rank": 24688
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8435,
   "Closing_Rank": 8804
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11364,
   "Closing_Rank": 11364
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5615,
   "Closing_Rank": 10791
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6603,
   "Closing_Rank": 12883
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22429,
   "Closing_Rank": 31391
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38249,
   "Closing_Rank": 38249
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1770,
   "Closing_Rank": 1770
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5204,
   "Closing_Rank": 5233
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 465,
   "Closing_Rank": 465
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9073,
   "Closing_Rank": 10561
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1748,
   "Closing_Rank": 1748
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4460,
   "Closing_Rank": 5063
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11211,
   "Closing_Rank": 21638
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19133,
   "Closing_Rank": 20361
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44822,
   "Closing_Rank": 47335
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52564,
   "Closing_Rank": 54153
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3249,
   "Closing_Rank": 3249
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7865,
   "Closing_Rank": 7966
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14309,
   "Closing_Rank": 15609
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19496,
   "Closing_Rank": 19496
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6738,
   "Closing_Rank": 6738
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9576,
   "Closing_Rank": 9576
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13098,
   "Closing_Rank": 29330
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6132,
   "Closing_Rank": 13947
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33367,
   "Closing_Rank": 42889
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44560,
   "Closing_Rank": 51406
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6950,
   "Closing_Rank": 7052
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13248,
   "Closing_Rank": 13259
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17431,
   "Closing_Rank": 17431
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6353,
   "Closing_Rank": 6439
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19650,
   "Closing_Rank": 32284
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13872,
   "Closing_Rank": 13872
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45710,
   "Closing_Rank": 47676
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 54484,
   "Closing_Rank": 55653
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3597,
   "Closing_Rank": 3597
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8044,
   "Closing_Rank": 8393
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15835,
   "Closing_Rank": 16457
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20988,
   "Closing_Rank": 20988
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7728,
   "Closing_Rank": 8353
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6358,
   "Closing_Rank": 19275
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25400,
   "Closing_Rank": 25400
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48153,
   "Closing_Rank": 51973
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63079,
   "Closing_Rank": 68738
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8706,
   "Closing_Rank": 8968
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17479,
   "Closing_Rank": 17683
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23737,
   "Closing_Rank": 24205
 },
 {
   "Institute": "National Institute of Technology Nagaland",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6658,
   "Closing_Rank": 7715
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2451,
   "Closing_Rank": 6575
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3357,
   "Closing_Rank": 3720
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1130,
   "Closing_Rank": 1276
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1075,
   "Closing_Rank": 1075
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2509,
   "Closing_Rank": 4069
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2224,
   "Closing_Rank": 2224
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 491,
   "Closing_Rank": 765
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 547,
   "Closing_Rank": 547
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 954,
   "Closing_Rank": 954
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1069,
   "Closing_Rank": 1687
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 932,
   "Closing_Rank": 1065
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 723,
   "Closing_Rank": 723
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 609,
   "Closing_Rank": 609
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 793,
   "Closing_Rank": 915
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 907,
   "Closing_Rank": 907
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 138,
   "Closing_Rank": 203
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 336,
   "Closing_Rank": 336
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 167,
   "Closing_Rank": 167
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45827,
   "Closing_Rank": 45827
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52806,
   "Closing_Rank": 52806
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8799,
   "Closing_Rank": 8799
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16172,
   "Closing_Rank": 16172
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9246,
   "Closing_Rank": 9246
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36034,
   "Closing_Rank": 36034
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50778,
   "Closing_Rank": 50778
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3410,
   "Closing_Rank": 3410
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Chemical Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14774,
   "Closing_Rank": 16182
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35673,
   "Closing_Rank": 49025
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53789,
   "Closing_Rank": 64356
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1510,
   "Closing_Rank": 1510
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7944,
   "Closing_Rank": 8226
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10876,
   "Closing_Rank": 10876
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14520,
   "Closing_Rank": 16024
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20889,
   "Closing_Rank": 22692
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1016,
   "Closing_Rank": 1016
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6426,
   "Closing_Rank": 7940
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9955,
   "Closing_Rank": 10400
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3386,
   "Closing_Rank": 4153
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9682,
   "Closing_Rank": 9682
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42153,
   "Closing_Rank": 46400
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49493,
   "Closing_Rank": 58458
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1530,
   "Closing_Rank": 1530
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7508,
   "Closing_Rank": 7697
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9832,
   "Closing_Rank": 9832
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14398,
   "Closing_Rank": 15943
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19337,
   "Closing_Rank": 20721
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1397,
   "Closing_Rank": 1397
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6090,
   "Closing_Rank": 7302
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9430,
   "Closing_Rank": 9447
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1766,
   "Closing_Rank": 2105
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3382,
   "Closing_Rank": 3382
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49139,
   "Closing_Rank": 49139
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60312,
   "Closing_Rank": 60312
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16735,
   "Closing_Rank": 16735
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8408,
   "Closing_Rank": 8408
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42114,
   "Closing_Rank": 42411
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58957,
   "Closing_Rank": 58957
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15967,
   "Closing_Rank": 15967
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Civil Engineering with Specialization in Construction Technology and Management ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7321,
   "Closing_Rank": 7321
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8776,
   "Closing_Rank": 18320
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16772,
   "Closing_Rank": 29874
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 407,
   "Closing_Rank": 407
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1865,
   "Closing_Rank": 1865
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2825,
   "Closing_Rank": 3099
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4878,
   "Closing_Rank": 4878
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 160,
   "Closing_Rank": 160
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4640,
   "Closing_Rank": 6254
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8641,
   "Closing_Rank": 12189
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 197,
   "Closing_Rank": 197
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2255,
   "Closing_Rank": 3557
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4872,
   "Closing_Rank": 5792
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 163,
   "Closing_Rank": 163
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1530,
   "Closing_Rank": 2476
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2449,
   "Closing_Rank": 4917
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9288,
   "Closing_Rank": 14442
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18483,
   "Closing_Rank": 21664
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 381,
   "Closing_Rank": 497
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 622,
   "Closing_Rank": 622
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2197,
   "Closing_Rank": 2363
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3850,
   "Closing_Rank": 3850
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 74,
   "Closing_Rank": 74
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3932,
   "Closing_Rank": 4872
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7873,
   "Closing_Rank": 9771
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 329,
   "Closing_Rank": 329
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2357,
   "Closing_Rank": 3026
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2147,
   "Closing_Rank": 3680
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55,
   "Closing_Rank": 55
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 688,
   "Closing_Rank": 963
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1793,
   "Closing_Rank": 1962
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32,
   "Closing_Rank": 32
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22226,
   "Closing_Rank": 23853
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43731,
   "Closing_Rank": 43731
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 705,
   "Closing_Rank": 705
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3830,
   "Closing_Rank": 3830
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7434,
   "Closing_Rank": 7539
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15504,
   "Closing_Rank": 15504
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4670,
   "Closing_Rank": 4670
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2945,
   "Closing_Rank": 2945
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16249,
   "Closing_Rank": 16444
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26014,
   "Closing_Rank": 26014
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2665,
   "Closing_Rank": 2665
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4330,
   "Closing_Rank": 5641
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11259,
   "Closing_Rank": 11259
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 431,
   "Closing_Rank": 431
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3626,
   "Closing_Rank": 3626
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Cyber Security ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1436,
   "Closing_Rank": 1436
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19041,
   "Closing_Rank": 20655
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38069,
   "Closing_Rank": 38069
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1048,
   "Closing_Rank": 1048
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3261,
   "Closing_Rank": 3261
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7261,
   "Closing_Rank": 7406
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13334,
   "Closing_Rank": 13334
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4050,
   "Closing_Rank": 4050
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8379,
   "Closing_Rank": 8379
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2504,
   "Closing_Rank": 2504
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11908,
   "Closing_Rank": 15659
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23901,
   "Closing_Rank": 23901
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2558,
   "Closing_Rank": 2558
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 218,
   "Closing_Rank": 218
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5583,
   "Closing_Rank": 5654
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10950,
   "Closing_Rank": 10950
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2766,
   "Closing_Rank": 2766
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Computer Science and Engineering with Specialization in Data Science ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1461,
   "Closing_Rank": 1461
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26553,
   "Closing_Rank": 32823
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40594,
   "Closing_Rank": 47916
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1739,
   "Closing_Rank": 1739
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5241,
   "Closing_Rank": 5601
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8356,
   "Closing_Rank": 8356
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9066,
   "Closing_Rank": 10334
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13992,
   "Closing_Rank": 16714
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 772,
   "Closing_Rank": 772
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5449,
   "Closing_Rank": 6901
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8548,
   "Closing_Rank": 9747
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3446,
   "Closing_Rank": 4246
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6131,
   "Closing_Rank": 6131
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22524,
   "Closing_Rank": 26872
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34034,
   "Closing_Rank": 37159
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1209,
   "Closing_Rank": 1209
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4266,
   "Closing_Rank": 4530
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6511,
   "Closing_Rank": 6511
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7480,
   "Closing_Rank": 8664
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13534,
   "Closing_Rank": 15507
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 807,
   "Closing_Rank": 807
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4791,
   "Closing_Rank": 5351
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7217,
   "Closing_Rank": 7457
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1928,
   "Closing_Rank": 2117
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3093,
   "Closing_Rank": 3093
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35581,
   "Closing_Rank": 36942
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43898,
   "Closing_Rank": 43898
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1783,
   "Closing_Rank": 1783
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6449,
   "Closing_Rank": 6449
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10551,
   "Closing_Rank": 10870
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17824,
   "Closing_Rank": 17824
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1013,
   "Closing_Rank": 1013
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6996,
   "Closing_Rank": 6996
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8812,
   "Closing_Rank": 8812
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21904,
   "Closing_Rank": 27448
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43415,
   "Closing_Rank": 43415
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4656,
   "Closing_Rank": 4656
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9044,
   "Closing_Rank": 9100
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13962,
   "Closing_Rank": 13962
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5378,
   "Closing_Rank": 5378
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electrical Engineering with Specialization In Power System Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2218,
   "Closing_Rank": 2218
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20750,
   "Closing_Rank": 25962
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25779,
   "Closing_Rank": 35980
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 980,
   "Closing_Rank": 980
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4137,
   "Closing_Rank": 4459
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6437,
   "Closing_Rank": 6495
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7031,
   "Closing_Rank": 8288
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10362,
   "Closing_Rank": 13019
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 432,
   "Closing_Rank": 432
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3742,
   "Closing_Rank": 5041
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7493,
   "Closing_Rank": 9692
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2723,
   "Closing_Rank": 3405
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7125,
   "Closing_Rank": 7125
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15389,
   "Closing_Rank": 20286
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27115,
   "Closing_Rank": 31021
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1251,
   "Closing_Rank": 1251
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3208,
   "Closing_Rank": 3281
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4955,
   "Closing_Rank": 5176
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5490,
   "Closing_Rank": 6882
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10177,
   "Closing_Rank": 10976
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 684,
   "Closing_Rank": 684
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3415,
   "Closing_Rank": 4340
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5228,
   "Closing_Rank": 5940
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 232,
   "Closing_Rank": 232
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1431,
   "Closing_Rank": 1540
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2287,
   "Closing_Rank": 2287
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26094,
   "Closing_Rank": 32786
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42145,
   "Closing_Rank": 42145
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5852,
   "Closing_Rank": 5852
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9578,
   "Closing_Rank": 9933
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15569,
   "Closing_Rank": 15569
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5433,
   "Closing_Rank": 5433
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3448,
   "Closing_Rank": 3448
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21967,
   "Closing_Rank": 22769
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22579,
   "Closing_Rank": 22579
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3590,
   "Closing_Rank": 3590
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6633,
   "Closing_Rank": 6633
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14291,
   "Closing_Rank": 14291
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 655,
   "Closing_Rank": 655
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4542,
   "Closing_Rank": 4542
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1553,
   "Closing_Rank": 1553
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50323,
   "Closing_Rank": 50323
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8610,
   "Closing_Rank": 8610
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12248,
   "Closing_Rank": 12248
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17463,
   "Closing_Rank": 17463
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10034,
   "Closing_Rank": 10034
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37241,
   "Closing_Rank": 37241
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59996,
   "Closing_Rank": 59996
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6442,
   "Closing_Rank": 6442
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Material Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10700,
   "Closing_Rank": 13860
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23529,
   "Closing_Rank": 23529
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40217,
   "Closing_Rank": 40217
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 727,
   "Closing_Rank": 727
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7178,
   "Closing_Rank": 7178
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4836,
   "Closing_Rank": 4836
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15362,
   "Closing_Rank": 15362
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26231,
   "Closing_Rank": 26231
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mathematics and Computing Technology ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5777,
   "Closing_Rank": 5804
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32089,
   "Closing_Rank": 42589
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39769,
   "Closing_Rank": 58835
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2780,
   "Closing_Rank": 2780
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6843,
   "Closing_Rank": 7165
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9951,
   "Closing_Rank": 9951
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12342,
   "Closing_Rank": 13452
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18387,
   "Closing_Rank": 21209
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1566,
   "Closing_Rank": 1566
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6138,
   "Closing_Rank": 7785
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10349,
   "Closing_Rank": 11177
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3633,
   "Closing_Rank": 4569
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7871,
   "Closing_Rank": 7871
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29366,
   "Closing_Rank": 36958
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43090,
   "Closing_Rank": 48516
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3082,
   "Closing_Rank": 3082
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5928,
   "Closing_Rank": 6140
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8035,
   "Closing_Rank": 8035
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11227,
   "Closing_Rank": 12299
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17499,
   "Closing_Rank": 19192
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1713,
   "Closing_Rank": 1713
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5948,
   "Closing_Rank": 6674
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8906,
   "Closing_Rank": 9003
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2296,
   "Closing_Rank": 2334
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3782,
   "Closing_Rank": 3782
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43864,
   "Closing_Rank": 48315
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 64005,
   "Closing_Rank": 64005
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8134,
   "Closing_Rank": 8134
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14289,
   "Closing_Rank": 14533
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22747,
   "Closing_Rank": 22747
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8318,
   "Closing_Rank": 8318
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11415,
   "Closing_Rank": 11415
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4946,
   "Closing_Rank": 4946
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37408,
   "Closing_Rank": 39044
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53845,
   "Closing_Rank": 53845
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1563,
   "Closing_Rank": 1563
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6990,
   "Closing_Rank": 6990
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11327,
   "Closing_Rank": 12515
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20027,
   "Closing_Rank": 20027
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6805,
   "Closing_Rank": 6805
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2620,
   "Closing_Rank": 2620
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32615,
   "Closing_Rank": 40085
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49479,
   "Closing_Rank": 49479
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7036,
   "Closing_Rank": 7036
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12229,
   "Closing_Rank": 12693
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17402,
   "Closing_Rank": 17402
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7703,
   "Closing_Rank": 7703
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11754,
   "Closing_Rank": 11754
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4513,
   "Closing_Rank": 4513
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20681,
   "Closing_Rank": 24053
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45543,
   "Closing_Rank": 45543
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4450,
   "Closing_Rank": 4450
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7970,
   "Closing_Rank": 8711
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15141,
   "Closing_Rank": 15141
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5183,
   "Closing_Rank": 5641
 },
 {
   "Institute": "National Institute of Technology Patna",
   "Academic_Program_Name": "Mechatronics and Automation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8820,
   "Closing_Rank": 8820
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 138928,
   "Closing_Rank": 206045
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 244840,
   "Closing_Rank": 257167
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 87784,
   "Closing_Rank": 87784
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75745,
   "Closing_Rank": 102063
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 95480,
   "Closing_Rank": 95480
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38366,
   "Closing_Rank": 39489
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44970,
   "Closing_Rank": 47854
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41684,
   "Closing_Rank": 44659
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8080,
   "Closing_Rank": 8214
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14205,
   "Closing_Rank": 15806
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 997,
   "Closing_Rank": 997
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6029,
   "Closing_Rank": 7386
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7476,
   "Closing_Rank": 7476
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2265,
   "Closing_Rank": 2265
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3768,
   "Closing_Rank": 3768
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27384,
   "Closing_Rank": 63717
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60539,
   "Closing_Rank": 71250
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51905,
   "Closing_Rank": 66844
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20214,
   "Closing_Rank": 26198
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22808,
   "Closing_Rank": 38465
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1300,
   "Closing_Rank": 1300
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7964,
   "Closing_Rank": 11782
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8926,
   "Closing_Rank": 17166
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9489,
   "Closing_Rank": 9489
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6318,
   "Closing_Rank": 18515
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9484,
   "Closing_Rank": 18657
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1161,
   "Closing_Rank": 1161
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2876,
   "Closing_Rank": 3108
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3870,
   "Closing_Rank": 3870
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5281,
   "Closing_Rank": 6550
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6144,
   "Closing_Rank": 6144
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1551,
   "Closing_Rank": 3195
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3782,
   "Closing_Rank": 3782
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 788,
   "Closing_Rank": 792
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 102541,
   "Closing_Rank": 120719
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 152553,
   "Closing_Rank": 195851
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40202,
   "Closing_Rank": 45980
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 70795,
   "Closing_Rank": 72797
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13938,
   "Closing_Rank": 36217
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41593,
   "Closing_Rank": 41593
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20487,
   "Closing_Rank": 29032
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33318,
   "Closing_Rank": 37184
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1472,
   "Closing_Rank": 1472
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5184,
   "Closing_Rank": 5488
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7007,
   "Closing_Rank": 7007
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8540,
   "Closing_Rank": 11164
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13738,
   "Closing_Rank": 13738
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5216,
   "Closing_Rank": 5873
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2681,
   "Closing_Rank": 2681
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1858,
   "Closing_Rank": 1858
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3260,
   "Closing_Rank": 3260
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50168,
   "Closing_Rank": 90244
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 83677,
   "Closing_Rank": 95611
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40008,
   "Closing_Rank": 40008
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 66874,
   "Closing_Rank": 66874
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28910,
   "Closing_Rank": 31554
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40490,
   "Closing_Rank": 47439
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13100,
   "Closing_Rank": 30924
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11866,
   "Closing_Rank": 11866
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16370,
   "Closing_Rank": 24073
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25204,
   "Closing_Rank": 28650
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 674,
   "Closing_Rank": 674
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3933,
   "Closing_Rank": 3964
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4826,
   "Closing_Rank": 4826
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6745,
   "Closing_Rank": 8478
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8536,
   "Closing_Rank": 8536
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4577,
   "Closing_Rank": 4902
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3837,
   "Closing_Rank": 3837
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1228,
   "Closing_Rank": 1296
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2684,
   "Closing_Rank": 2684
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55842,
   "Closing_Rank": 160684
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 199749,
   "Closing_Rank": 228923
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 55290,
   "Closing_Rank": 58051
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 84572,
   "Closing_Rank": 88661
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9712,
   "Closing_Rank": 25648
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20988,
   "Closing_Rank": 20988
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34874,
   "Closing_Rank": 42247
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46509,
   "Closing_Rank": 47344
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7232,
   "Closing_Rank": 7249
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9059,
   "Closing_Rank": 9059
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12632,
   "Closing_Rank": 13373
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15865,
   "Closing_Rank": 15865
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5853,
   "Closing_Rank": 6980
 },
 {
   "Institute": "National Institute of Technology Puducherry",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4032,
   "Closing_Rank": 4032
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2104,
   "Closing_Rank": 4948
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1333,
   "Closing_Rank": 1333
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 611,
   "Closing_Rank": 611
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2351,
   "Closing_Rank": 4642
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3118,
   "Closing_Rank": 3118
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1045,
   "Closing_Rank": 1391
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1174,
   "Closing_Rank": 1174
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1250,
   "Closing_Rank": 1250
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 988,
   "Closing_Rank": 1538
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 815,
   "Closing_Rank": 826
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 650,
   "Closing_Rank": 712
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 636,
   "Closing_Rank": 836
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 972,
   "Closing_Rank": 972
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 310,
   "Closing_Rank": 369
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 416,
   "Closing_Rank": 416
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 201,
   "Closing_Rank": 201
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75374,
   "Closing_Rank": 111467
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 87615,
   "Closing_Rank": 106148
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20910,
   "Closing_Rank": 23501
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38985,
   "Closing_Rank": 41367
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42802,
   "Closing_Rank": 42827
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14791,
   "Closing_Rank": 20294
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17416,
   "Closing_Rank": 17416
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6239,
   "Closing_Rank": 6341
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43187,
   "Closing_Rank": 52916
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46758,
   "Closing_Rank": 59352
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8800,
   "Closing_Rank": 9086
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10583,
   "Closing_Rank": 10583
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17406,
   "Closing_Rank": 18155
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20877,
   "Closing_Rank": 21223
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7779,
   "Closing_Rank": 8114
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10322,
   "Closing_Rank": 10696
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3330,
   "Closing_Rank": 3472
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46734,
   "Closing_Rank": 97437
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 69571,
   "Closing_Rank": 105430
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17982,
   "Closing_Rank": 20159
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21309,
   "Closing_Rank": 21309
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34905,
   "Closing_Rank": 38955
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39905,
   "Closing_Rank": 40955
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13875,
   "Closing_Rank": 19748
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9840,
   "Closing_Rank": 9840
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4085,
   "Closing_Rank": 4220
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6916,
   "Closing_Rank": 6916
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42017,
   "Closing_Rank": 52108
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44205,
   "Closing_Rank": 59252
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8501,
   "Closing_Rank": 8912
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15441,
   "Closing_Rank": 17373
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19178,
   "Closing_Rank": 19331
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7520,
   "Closing_Rank": 8412
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8796,
   "Closing_Rank": 8796
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3210,
   "Closing_Rank": 3250
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3950,
   "Closing_Rank": 3950
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 52136,
   "Closing_Rank": 69308
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 65822,
   "Closing_Rank": 70635
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11438,
   "Closing_Rank": 13973
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15006,
   "Closing_Rank": 15006
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23067,
   "Closing_Rank": 26468
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26078,
   "Closing_Rank": 35379
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9915,
   "Closing_Rank": 13113
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14268,
   "Closing_Rank": 14268
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4603,
   "Closing_Rank": 4784
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34623,
   "Closing_Rank": 39335
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40344,
   "Closing_Rank": 44955
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2241,
   "Closing_Rank": 2241
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6331,
   "Closing_Rank": 6687
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7945,
   "Closing_Rank": 7945
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12563,
   "Closing_Rank": 13880
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17352,
   "Closing_Rank": 18792
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1931,
   "Closing_Rank": 1931
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6938,
   "Closing_Rank": 7361
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8443,
   "Closing_Rank": 8443
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2404,
   "Closing_Rank": 2543
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3946,
   "Closing_Rank": 3946
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44737,
   "Closing_Rank": 71977
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 72607,
   "Closing_Rank": 84881
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14315,
   "Closing_Rank": 16769
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16790,
   "Closing_Rank": 16790
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25272,
   "Closing_Rank": 31024
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29642,
   "Closing_Rank": 35384
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6970,
   "Closing_Rank": 12893
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15080,
   "Closing_Rank": 15080
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1741,
   "Closing_Rank": 4119
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3899,
   "Closing_Rank": 3899
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41341,
   "Closing_Rank": 44960
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52932,
   "Closing_Rank": 54393
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1197,
   "Closing_Rank": 1197
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7256,
   "Closing_Rank": 7615
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9466,
   "Closing_Rank": 9466
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14217,
   "Closing_Rank": 15396
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20026,
   "Closing_Rank": 20429
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1233,
   "Closing_Rank": 1233
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6412,
   "Closing_Rank": 7286
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9993,
   "Closing_Rank": 9994
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1926,
   "Closing_Rank": 2353
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3105,
   "Closing_Rank": 3105
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11194,
   "Closing_Rank": 21328
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20758,
   "Closing_Rank": 33617
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 538,
   "Closing_Rank": 538
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3587,
   "Closing_Rank": 5343
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6805,
   "Closing_Rank": 6805
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5565,
   "Closing_Rank": 8819
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11701,
   "Closing_Rank": 18437
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 622,
   "Closing_Rank": 622
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4189,
   "Closing_Rank": 5847
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1814,
   "Closing_Rank": 5804
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36,
   "Closing_Rank": 36
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1124,
   "Closing_Rank": 1490
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2332,
   "Closing_Rank": 2332
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10323,
   "Closing_Rank": 13177
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16367,
   "Closing_Rank": 19455
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 449,
   "Closing_Rank": 449
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2012,
   "Closing_Rank": 2095
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3218,
   "Closing_Rank": 3218
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 463,
   "Closing_Rank": 463
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3578,
   "Closing_Rank": 4263
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5146,
   "Closing_Rank": 6019
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 268,
   "Closing_Rank": 268
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2278,
   "Closing_Rank": 2895
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3036,
   "Closing_Rank": 3036
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48,
   "Closing_Rank": 48
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 540,
   "Closing_Rank": 974
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32187,
   "Closing_Rank": 44565
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51171,
   "Closing_Rank": 62262
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8809,
   "Closing_Rank": 9379
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12008,
   "Closing_Rank": 12008
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17044,
   "Closing_Rank": 19578
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21791,
   "Closing_Rank": 22028
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9436,
   "Closing_Rank": 10632
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9484,
   "Closing_Rank": 14432
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2224,
   "Closing_Rank": 3720
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6000,
   "Closing_Rank": 6000
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20355,
   "Closing_Rank": 26898
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28398,
   "Closing_Rank": 37242
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 698,
   "Closing_Rank": 698
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4265,
   "Closing_Rank": 4360
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6167,
   "Closing_Rank": 6474
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7292,
   "Closing_Rank": 8569
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11677,
   "Closing_Rank": 14207
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3582,
   "Closing_Rank": 5118
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7473,
   "Closing_Rank": 7764
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1767,
   "Closing_Rank": 1937
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2606,
   "Closing_Rank": 2606
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27680,
   "Closing_Rank": 35860
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48104,
   "Closing_Rank": 50840
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1000,
   "Closing_Rank": 1000
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7397,
   "Closing_Rank": 8766
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10767,
   "Closing_Rank": 10767
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10520,
   "Closing_Rank": 16842
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17614,
   "Closing_Rank": 20795
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6153,
   "Closing_Rank": 9322
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8963,
   "Closing_Rank": 8963
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1780,
   "Closing_Rank": 2740
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3168,
   "Closing_Rank": 3168
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15698,
   "Closing_Rank": 18823
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24169,
   "Closing_Rank": 26938
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1133,
   "Closing_Rank": 1133
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1223,
   "Closing_Rank": 1223
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2968,
   "Closing_Rank": 3216
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4335,
   "Closing_Rank": 4335
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 200,
   "Closing_Rank": 200
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5109,
   "Closing_Rank": 6657
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9033,
   "Closing_Rank": 9606
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 638,
   "Closing_Rank": 638
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4066,
   "Closing_Rank": 4457
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5428,
   "Closing_Rank": 5925
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 762,
   "Closing_Rank": 1520
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21427,
   "Closing_Rank": 27785
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35732,
   "Closing_Rank": 47018
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1685,
   "Closing_Rank": 1685
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4559,
   "Closing_Rank": 6319
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10500,
   "Closing_Rank": 10500
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8877,
   "Closing_Rank": 12333
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19890,
   "Closing_Rank": 21020
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5492,
   "Closing_Rank": 7144
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8232,
   "Closing_Rank": 8232
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1680,
   "Closing_Rank": 2090
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2589,
   "Closing_Rank": 2589
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13256,
   "Closing_Rank": 16070
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20109,
   "Closing_Rank": 22715
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 731,
   "Closing_Rank": 731
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2437,
   "Closing_Rank": 2453
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3580,
   "Closing_Rank": 3580
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 112,
   "Closing_Rank": 112
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4381,
   "Closing_Rank": 5511
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6845,
   "Closing_Rank": 8233
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3136,
   "Closing_Rank": 3569
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4165,
   "Closing_Rank": 4423
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1233,
   "Closing_Rank": 1357
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30103,
   "Closing_Rank": 55150
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63339,
   "Closing_Rank": 69123
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11980,
   "Closing_Rank": 13459
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14394,
   "Closing_Rank": 14394
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17598,
   "Closing_Rank": 22783
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23619,
   "Closing_Rank": 29463
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9879,
   "Closing_Rank": 11961
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13374,
   "Closing_Rank": 14539
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3801,
   "Closing_Rank": 4161
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5184,
   "Closing_Rank": 5184
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28086,
   "Closing_Rank": 35628
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39551,
   "Closing_Rank": 45711
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1858,
   "Closing_Rank": 1858
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5813,
   "Closing_Rank": 6249
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7993,
   "Closing_Rank": 7993
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10782,
   "Closing_Rank": 11705
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16421,
   "Closing_Rank": 19238
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6401,
   "Closing_Rank": 6631
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8373,
   "Closing_Rank": 8373
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1999,
   "Closing_Rank": 2392
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3418,
   "Closing_Rank": 3418
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58711,
   "Closing_Rank": 76780
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 88804,
   "Closing_Rank": 95943
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13095,
   "Closing_Rank": 20092
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17481,
   "Closing_Rank": 17481
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24336,
   "Closing_Rank": 34688
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37602,
   "Closing_Rank": 41044
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13523,
   "Closing_Rank": 15652
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13462,
   "Closing_Rank": 16169
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5112,
   "Closing_Rank": 5596
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6847,
   "Closing_Rank": 6847
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42536,
   "Closing_Rank": 49080
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53602,
   "Closing_Rank": 58540
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2483,
   "Closing_Rank": 2483
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8262,
   "Closing_Rank": 8407
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9956,
   "Closing_Rank": 9956
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16123,
   "Closing_Rank": 17275
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20775,
   "Closing_Rank": 21240
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7686,
   "Closing_Rank": 8079
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10292,
   "Closing_Rank": 10292
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2848,
   "Closing_Rank": 3070
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4388,
   "Closing_Rank": 4495
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49174,
   "Closing_Rank": 82515
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 96021,
   "Closing_Rank": 101515
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14313,
   "Closing_Rank": 17486
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20986,
   "Closing_Rank": 20986
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28479,
   "Closing_Rank": 35058
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44835,
   "Closing_Rank": 50062
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11340,
   "Closing_Rank": 14577
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16205,
   "Closing_Rank": 16711
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4082,
   "Closing_Rank": 5082
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41692,
   "Closing_Rank": 51623
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60652,
   "Closing_Rank": 62417
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8388,
   "Closing_Rank": 8702
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10311,
   "Closing_Rank": 10311
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15344,
   "Closing_Rank": 16882
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22589,
   "Closing_Rank": 23453
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6247,
   "Closing_Rank": 8387
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11591,
   "Closing_Rank": 11591
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2428,
   "Closing_Rank": 3156
 },
 {
   "Institute": "National Institute of Technology Raipur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2212,
   "Closing_Rank": 2212
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 118100,
   "Closing_Rank": 305662
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 102401,
   "Closing_Rank": 940500
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 200817,
   "Closing_Rank": 354738
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6322,
   "Closing_Rank": 6322
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30037,
   "Closing_Rank": 33955
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31349,
   "Closing_Rank": 31349
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9842,
   "Closing_Rank": 11033
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17291,
   "Closing_Rank": 17291
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2843,
   "Closing_Rank": 2843
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6483,
   "Closing_Rank": 6483
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1424,
   "Closing_Rank": 1424
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 564098,
   "Closing_Rank": 1368129
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 506019,
   "Closing_Rank": 1094961
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48432,
   "Closing_Rank": 54266
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 66468,
   "Closing_Rank": 66468
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16109,
   "Closing_Rank": 17851
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23479,
   "Closing_Rank": 23479
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8011,
   "Closing_Rank": 8222
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11614,
   "Closing_Rank": 11614
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54736,
   "Closing_Rank": 143919
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 268637,
   "Closing_Rank": 399819
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69229,
   "Closing_Rank": 141737
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 218072,
   "Closing_Rank": 218072
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27032,
   "Closing_Rank": 113186
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63611,
   "Closing_Rank": 63611
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6688,
   "Closing_Rank": 9743
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23245,
   "Closing_Rank": 28284
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34107,
   "Closing_Rank": 35891
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1195,
   "Closing_Rank": 1195
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8076,
   "Closing_Rank": 9886
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10642,
   "Closing_Rank": 10642
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3458,
   "Closing_Rank": 3803
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5977,
   "Closing_Rank": 5977
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1764,
   "Closing_Rank": 1764
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 194239,
   "Closing_Rank": 377626
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 914560,
   "Closing_Rank": 914560
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 213608,
   "Closing_Rank": 455479
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 71174,
   "Closing_Rank": 114675
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30150,
   "Closing_Rank": 30150
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33265,
   "Closing_Rank": 42538
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51162,
   "Closing_Rank": 51162
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12203,
   "Closing_Rank": 13343
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19267,
   "Closing_Rank": 19267
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5979,
   "Closing_Rank": 6005
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9132,
   "Closing_Rank": 9132
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2325,
   "Closing_Rank": 2325
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 83257,
   "Closing_Rank": 209475
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 523629,
   "Closing_Rank": 683058
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 199997,
   "Closing_Rank": 283915
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 322677,
   "Closing_Rank": 322677
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11512,
   "Closing_Rank": 70749
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27450,
   "Closing_Rank": 27450
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28551,
   "Closing_Rank": 37004
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44971,
   "Closing_Rank": 44971
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11382,
   "Closing_Rank": 12628
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18298,
   "Closing_Rank": 18298
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1288,
   "Closing_Rank": 1314
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5244,
   "Closing_Rank": 5705
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8495,
   "Closing_Rank": 8495
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2000,
   "Closing_Rank": 2000
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 507133,
   "Closing_Rank": 663130
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32334,
   "Closing_Rank": 32334
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41613,
   "Closing_Rank": 47070
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51398,
   "Closing_Rank": 54473
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13716,
   "Closing_Rank": 14850
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23130,
   "Closing_Rank": 23130
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7347,
   "Closing_Rank": 7347
 },
 {
   "Institute": "National Institute of Technology Sikkim",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2798,
   "Closing_Rank": 2798
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 189756,
   "Closing_Rank": 189756
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 172311,
   "Closing_Rank": 172311
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93275,
   "Closing_Rank": 93275
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2778,
   "Closing_Rank": 6345
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4092,
   "Closing_Rank": 9273
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51179,
   "Closing_Rank": 55692
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 65179,
   "Closing_Rank": 65179
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9112,
   "Closing_Rank": 9112
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17184,
   "Closing_Rank": 18279
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24201,
   "Closing_Rank": 24201
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1738,
   "Closing_Rank": 1738
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8389,
   "Closing_Rank": 8598
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2694,
   "Closing_Rank": 2694
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68434,
   "Closing_Rank": 71299
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11663,
   "Closing_Rank": 11663
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35104,
   "Closing_Rank": 35104
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3207,
   "Closing_Rank": 8735
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12486,
   "Closing_Rank": 20835
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22046,
   "Closing_Rank": 28514
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40714,
   "Closing_Rank": 40714
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4830,
   "Closing_Rank": 4986
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10648,
   "Closing_Rank": 11122
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17556,
   "Closing_Rank": 17556
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4295,
   "Closing_Rank": 4890
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1794,
   "Closing_Rank": 1794
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 99436,
   "Closing_Rank": 182035
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45740,
   "Closing_Rank": 45740
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 96720,
   "Closing_Rank": 96720
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9152,
   "Closing_Rank": 12717
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5598,
   "Closing_Rank": 17190
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39935,
   "Closing_Rank": 45212
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50543,
   "Closing_Rank": 50543
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7662,
   "Closing_Rank": 7662
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9036,
   "Closing_Rank": 9036
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13627,
   "Closing_Rank": 14504
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6456,
   "Closing_Rank": 7062
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2588,
   "Closing_Rank": 2588
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 73439,
   "Closing_Rank": 73439
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24212,
   "Closing_Rank": 24212
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59209,
   "Closing_Rank": 59209
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29426,
   "Closing_Rank": 29426
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8120,
   "Closing_Rank": 27924
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8951,
   "Closing_Rank": 26671
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32471,
   "Closing_Rank": 40722
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43507,
   "Closing_Rank": 43507
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1613,
   "Closing_Rank": 1963
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6830,
   "Closing_Rank": 6914
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12222,
   "Closing_Rank": 12655
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20251,
   "Closing_Rank": 20251
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5980,
   "Closing_Rank": 5980
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2196,
   "Closing_Rank": 2196
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 156476,
   "Closing_Rank": 156476
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 105947,
   "Closing_Rank": 105947
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44024,
   "Closing_Rank": 44024
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12991,
   "Closing_Rank": 35049
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6747,
   "Closing_Rank": 19495
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46036,
   "Closing_Rank": 50666
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 64642,
   "Closing_Rank": 64642
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8525,
   "Closing_Rank": 8525
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16062,
   "Closing_Rank": 16754
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23261,
   "Closing_Rank": 23261
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7631,
   "Closing_Rank": 8019
 },
 {
   "Institute": "National Institute of Technology Arunachal Pradesh ",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4308,
   "Closing_Rank": 4308
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36595,
   "Closing_Rank": 47939
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 65323,
   "Closing_Rank": 74915
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2088,
   "Closing_Rank": 2088
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7814,
   "Closing_Rank": 9163
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14009,
   "Closing_Rank": 16278
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26561,
   "Closing_Rank": 29807
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7290,
   "Closing_Rank": 9062
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13185,
   "Closing_Rank": 13299
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2402,
   "Closing_Rank": 2577
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4263,
   "Closing_Rank": 4263
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33533,
   "Closing_Rank": 40903
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52147,
   "Closing_Rank": 52986
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1601,
   "Closing_Rank": 1601
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6593,
   "Closing_Rank": 6921
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9519,
   "Closing_Rank": 9519
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12247,
   "Closing_Rank": 13542
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19683,
   "Closing_Rank": 20034
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5622,
   "Closing_Rank": 6443
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9312,
   "Closing_Rank": 9577
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1479,
   "Closing_Rank": 2060
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2788,
   "Closing_Rank": 2788
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6449,
   "Closing_Rank": 13775
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14057,
   "Closing_Rank": 22346
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1107,
   "Closing_Rank": 1107
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2399,
   "Closing_Rank": 2723
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4697,
   "Closing_Rank": 4697
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3455,
   "Closing_Rank": 5016
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6780,
   "Closing_Rank": 9790
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 549,
   "Closing_Rank": 549
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1999,
   "Closing_Rank": 3382
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7075,
   "Closing_Rank": 9173
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 138,
   "Closing_Rank": 138
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 699,
   "Closing_Rank": 1268
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1792,
   "Closing_Rank": 1792
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4935,
   "Closing_Rank": 7944
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13055,
   "Closing_Rank": 15201
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 467,
   "Closing_Rank": 467
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1096,
   "Closing_Rank": 1147
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2641,
   "Closing_Rank": 2641
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45,
   "Closing_Rank": 45
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2220,
   "Closing_Rank": 2879
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6335,
   "Closing_Rank": 6747
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 178,
   "Closing_Rank": 178
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1627,
   "Closing_Rank": 1784
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2652,
   "Closing_Rank": 2875
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 47,
   "Closing_Rank": 47
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 524,
   "Closing_Rank": 748
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1203,
   "Closing_Rank": 1203
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19024,
   "Closing_Rank": 24481
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32218,
   "Closing_Rank": 42038
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1535,
   "Closing_Rank": 2050
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2433,
   "Closing_Rank": 2433
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4089,
   "Closing_Rank": 4429
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7422,
   "Closing_Rank": 7422
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6492,
   "Closing_Rank": 7797
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13162,
   "Closing_Rank": 16019
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4811,
   "Closing_Rank": 5874
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12046,
   "Closing_Rank": 12046
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1324,
   "Closing_Rank": 1733
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13550,
   "Closing_Rank": 17387
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25280,
   "Closing_Rank": 28759
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 791,
   "Closing_Rank": 945
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2687,
   "Closing_Rank": 3000
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6058,
   "Closing_Rank": 6058
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5593,
   "Closing_Rank": 6623
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10794,
   "Closing_Rank": 12583
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 613,
   "Closing_Rank": 613
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3151,
   "Closing_Rank": 3839
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5184,
   "Closing_Rank": 5184
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1303,
   "Closing_Rank": 1763
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14414,
   "Closing_Rank": 18723
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22528,
   "Closing_Rank": 30387
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1365,
   "Closing_Rank": 2510
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3047,
   "Closing_Rank": 3758
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6348,
   "Closing_Rank": 6410
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5083,
   "Closing_Rank": 6088
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10349,
   "Closing_Rank": 12380
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 697,
   "Closing_Rank": 697
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2685,
   "Closing_Rank": 4714
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10639,
   "Closing_Rank": 10714
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1442,
   "Closing_Rank": 1593
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2309,
   "Closing_Rank": 2309
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9974,
   "Closing_Rank": 13080
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17422,
   "Closing_Rank": 21844
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1386,
   "Closing_Rank": 1386
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1956,
   "Closing_Rank": 2062
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3419,
   "Closing_Rank": 3419
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 176,
   "Closing_Rank": 176
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3748,
   "Closing_Rank": 4567
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6844,
   "Closing_Rank": 7410
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 461,
   "Closing_Rank": 461
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1888,
   "Closing_Rank": 2955
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4756,
   "Closing_Rank": 4909
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 256,
   "Closing_Rank": 256
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 677,
   "Closing_Rank": 970
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1995,
   "Closing_Rank": 1995
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24960,
   "Closing_Rank": 27104
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45456,
   "Closing_Rank": 45456
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2262,
   "Closing_Rank": 2262
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5740,
   "Closing_Rank": 5740
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7421,
   "Closing_Rank": 7421
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8354,
   "Closing_Rank": 8866
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16453,
   "Closing_Rank": 16453
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7664,
   "Closing_Rank": 9453
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2442,
   "Closing_Rank": 2948
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15063,
   "Closing_Rank": 19206
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30105,
   "Closing_Rank": 30105
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2998,
   "Closing_Rank": 3269
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5138,
   "Closing_Rank": 6409
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11433,
   "Closing_Rank": 11433
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4519,
   "Closing_Rank": 4580
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7432,
   "Closing_Rank": 7432
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Engineering and Computational Mechanics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2002,
   "Closing_Rank": 2173
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26403,
   "Closing_Rank": 35978
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47884,
   "Closing_Rank": 55289
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6241,
   "Closing_Rank": 7020
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9085,
   "Closing_Rank": 9085
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10131,
   "Closing_Rank": 12897
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19506,
   "Closing_Rank": 23877
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4842,
   "Closing_Rank": 7252
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12107,
   "Closing_Rank": 12111
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1356,
   "Closing_Rank": 2191
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2943,
   "Closing_Rank": 2943
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22450,
   "Closing_Rank": 29147
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34790,
   "Closing_Rank": 40459
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4655,
   "Closing_Rank": 4972
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7130,
   "Closing_Rank": 7130
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8051,
   "Closing_Rank": 9551
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16340,
   "Closing_Rank": 17361
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 853,
   "Closing_Rank": 853
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4754,
   "Closing_Rank": 5718
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8127,
   "Closing_Rank": 8628
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1961,
   "Closing_Rank": 2251
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3520,
   "Closing_Rank": 3520
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36583,
   "Closing_Rank": 52186
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37802,
   "Closing_Rank": 71436
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2170,
   "Closing_Rank": 2170
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8601,
   "Closing_Rank": 8632
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13258,
   "Closing_Rank": 13258
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15442,
   "Closing_Rank": 18521
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22381,
   "Closing_Rank": 22867
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8077,
   "Closing_Rank": 9972
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12285,
   "Closing_Rank": 12285
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2971,
   "Closing_Rank": 3935
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4373,
   "Closing_Rank": 4373
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36801,
   "Closing_Rank": 46367
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53079,
   "Closing_Rank": 56449
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7465,
   "Closing_Rank": 7608
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13847,
   "Closing_Rank": 15272
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20161,
   "Closing_Rank": 20727
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6879,
   "Closing_Rank": 7737
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9262,
   "Closing_Rank": 9262
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2984,
   "Closing_Rank": 3215
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4556,
   "Closing_Rank": 4556
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41425,
   "Closing_Rank": 49001
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50962,
   "Closing_Rank": 58520
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8583,
   "Closing_Rank": 9476
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12256,
   "Closing_Rank": 12256
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15656,
   "Closing_Rank": 18746
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27894,
   "Closing_Rank": 28840
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10441,
   "Closing_Rank": 10886
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15523,
   "Closing_Rank": 15523
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4505,
   "Closing_Rank": 4680
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36326,
   "Closing_Rank": 39744
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47504,
   "Closing_Rank": 50396
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2573,
   "Closing_Rank": 2573
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6368,
   "Closing_Rank": 6816
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9396,
   "Closing_Rank": 9396
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13590,
   "Closing_Rank": 14759
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20485,
   "Closing_Rank": 20958
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7071,
   "Closing_Rank": 7597
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10368,
   "Closing_Rank": 10368
 },
 {
   "Institute": "National Institute of Technolog Jamshedpur",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2871,
   "Closing_Rank": 3073
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1161,
   "Closing_Rank": 2741
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1580,
   "Closing_Rank": 2003
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 961,
   "Closing_Rank": 1940
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 963,
   "Closing_Rank": 4408
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1059,
   "Closing_Rank": 1059
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 493,
   "Closing_Rank": 493
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1010,
   "Closing_Rank": 1010
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 645,
   "Closing_Rank": 1605
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 741,
   "Closing_Rank": 741
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 450,
   "Closing_Rank": 450
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 556,
   "Closing_Rank": 556
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 741,
   "Closing_Rank": 1086
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 615,
   "Closing_Rank": 615
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 337,
   "Closing_Rank": 363
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 283,
   "Closing_Rank": 283
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Architecture and Planning ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 174,
   "Closing_Rank": 195
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9571,
   "Closing_Rank": 11951
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15257,
   "Closing_Rank": 20264
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2071,
   "Closing_Rank": 2074
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 209,
   "Closing_Rank": 209
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5111,
   "Closing_Rank": 6447
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8839,
   "Closing_Rank": 9398
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 780,
   "Closing_Rank": 780
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2654,
   "Closing_Rank": 3180
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4324,
   "Closing_Rank": 4324
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3337,
   "Closing_Rank": 3337
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14451,
   "Closing_Rank": 14451
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6431,
   "Closing_Rank": 7485
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10327,
   "Closing_Rank": 13103
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1179,
   "Closing_Rank": 1415
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2477,
   "Closing_Rank": 3026
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5515,
   "Closing_Rank": 5515
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1742,
   "Closing_Rank": 1807
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3181,
   "Closing_Rank": 3181
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 543,
   "Closing_Rank": 588
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Data Science ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1234,
   "Closing_Rank": 1234
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5056,
   "Closing_Rank": 10215
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17769,
   "Closing_Rank": 19534
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1889,
   "Closing_Rank": 1935
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3417,
   "Closing_Rank": 3417
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4111,
   "Closing_Rank": 6336
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7100,
   "Closing_Rank": 7848
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 416,
   "Closing_Rank": 416
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2567,
   "Closing_Rank": 2689
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2861,
   "Closing_Rank": 2861
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4115,
   "Closing_Rank": 4115
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6202,
   "Closing_Rank": 8432
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12877,
   "Closing_Rank": 13139
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 363,
   "Closing_Rank": 363
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1199,
   "Closing_Rank": 1343
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2973,
   "Closing_Rank": 3395
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4733,
   "Closing_Rank": 5522
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 546,
   "Closing_Rank": 1685
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3214,
   "Closing_Rank": 3214
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 519,
   "Closing_Rank": 519
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Artificial Intelligence and Machine Learning ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1452,
   "Closing_Rank": 1452
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9853,
   "Closing_Rank": 41698
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36990,
   "Closing_Rank": 48635
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6888,
   "Closing_Rank": 7067
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8795,
   "Closing_Rank": 8795
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12990,
   "Closing_Rank": 17154
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17405,
   "Closing_Rank": 19972
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3488,
   "Closing_Rank": 5909
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5719,
   "Closing_Rank": 5719
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12145,
   "Closing_Rank": 16510
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13062,
   "Closing_Rank": 13062
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26282,
   "Closing_Rank": 34647
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37470,
   "Closing_Rank": 48121
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 269,
   "Closing_Rank": 269
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5720,
   "Closing_Rank": 5826
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8297,
   "Closing_Rank": 8297
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10214,
   "Closing_Rank": 12000
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19239,
   "Closing_Rank": 19522
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2560,
   "Closing_Rank": 5161
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8354,
   "Closing_Rank": 8532
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 966,
   "Closing_Rank": 1146
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2312,
   "Closing_Rank": 2312
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44751,
   "Closing_Rank": 44751
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 54115,
   "Closing_Rank": 54115
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17037,
   "Closing_Rank": 17037
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5908,
   "Closing_Rank": 5908
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34173,
   "Closing_Rank": 34173
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46156,
   "Closing_Rank": 46156
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6716,
   "Closing_Rank": 6716
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13769,
   "Closing_Rank": 13789
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6042,
   "Closing_Rank": 6042
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5078,
   "Closing_Rank": 8475
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8593,
   "Closing_Rank": 11320
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 378,
   "Closing_Rank": 378
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1228,
   "Closing_Rank": 1551
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2010,
   "Closing_Rank": 2010
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1847,
   "Closing_Rank": 4101
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3498,
   "Closing_Rank": 4661
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 325,
   "Closing_Rank": 325
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1373,
   "Closing_Rank": 2332
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1020,
   "Closing_Rank": 3515
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 485,
   "Closing_Rank": 3421
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3029,
   "Closing_Rank": 3029
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4472,
   "Closing_Rank": 6000
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5961,
   "Closing_Rank": 9179
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 147,
   "Closing_Rank": 147
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 879,
   "Closing_Rank": 942
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1604,
   "Closing_Rank": 1604
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50,
   "Closing_Rank": 50
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1835,
   "Closing_Rank": 2281
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3441,
   "Closing_Rank": 4331
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 258,
   "Closing_Rank": 258
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1310,
   "Closing_Rank": 1479
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1482,
   "Closing_Rank": 1618
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 212,
   "Closing_Rank": 515
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1052,
   "Closing_Rank": 1052
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9956,
   "Closing_Rank": 11043
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 581,
   "Closing_Rank": 581
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5393,
   "Closing_Rank": 5393
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8395,
   "Closing_Rank": 8395
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12079,
   "Closing_Rank": 12079
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7717,
   "Closing_Rank": 7717
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13385,
   "Closing_Rank": 13385
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1515,
   "Closing_Rank": 1515
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2635,
   "Closing_Rank": 2635
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1446,
   "Closing_Rank": 2129
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17154,
   "Closing_Rank": 21795
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26539,
   "Closing_Rank": 28566
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3703,
   "Closing_Rank": 4167
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5632,
   "Closing_Rank": 5632
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7697,
   "Closing_Rank": 10693
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10515,
   "Closing_Rank": 13393
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4349,
   "Closing_Rank": 5338
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6776,
   "Closing_Rank": 6776
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7156,
   "Closing_Rank": 22089
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7600,
   "Closing_Rank": 7600
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14406,
   "Closing_Rank": 17104
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24057,
   "Closing_Rank": 25788
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2301,
   "Closing_Rank": 2301
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2692,
   "Closing_Rank": 2831
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4357,
   "Closing_Rank": 4357
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4583,
   "Closing_Rank": 5903
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8673,
   "Closing_Rank": 10509
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 383,
   "Closing_Rank": 383
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2917,
   "Closing_Rank": 3342
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4939,
   "Closing_Rank": 4939
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1201,
   "Closing_Rank": 1282
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22845,
   "Closing_Rank": 23037
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6514,
   "Closing_Rank": 6514
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11628,
   "Closing_Rank": 11628
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4766,
   "Closing_Rank": 4766
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15897,
   "Closing_Rank": 15897
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26833,
   "Closing_Rank": 26833
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1151,
   "Closing_Rank": 1151
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6472,
   "Closing_Rank": 7088
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2990,
   "Closing_Rank": 2990
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11097,
   "Closing_Rank": 15830
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17185,
   "Closing_Rank": 22498
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2522,
   "Closing_Rank": 2522
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2509,
   "Closing_Rank": 2682
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4024,
   "Closing_Rank": 4024
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5264,
   "Closing_Rank": 7634
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9990,
   "Closing_Rank": 11869
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3050,
   "Closing_Rank": 3767
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4636,
   "Closing_Rank": 4767
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6956,
   "Closing_Rank": 14426
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9042,
   "Closing_Rank": 11489
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15085,
   "Closing_Rank": 17577
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 564,
   "Closing_Rank": 564
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1713,
   "Closing_Rank": 1838
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2926,
   "Closing_Rank": 2926
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 110,
   "Closing_Rank": 110
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3540,
   "Closing_Rank": 4418
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7657,
   "Closing_Rank": 8102
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 393,
   "Closing_Rank": 393
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2287,
   "Closing_Rank": 2540
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3910,
   "Closing_Rank": 3910
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 724,
   "Closing_Rank": 959
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1692,
   "Closing_Rank": 1692
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14593,
   "Closing_Rank": 14593
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24499,
   "Closing_Rank": 24499
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8542,
   "Closing_Rank": 8542
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3174,
   "Closing_Rank": 3174
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12066,
   "Closing_Rank": 12066
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22141,
   "Closing_Rank": 22141
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2327,
   "Closing_Rank": 2327
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4080,
   "Closing_Rank": 5026
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3146,
   "Closing_Rank": 3146
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17163,
   "Closing_Rank": 19912
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31643,
   "Closing_Rank": 35620
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4324,
   "Closing_Rank": 4848
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8458,
   "Closing_Rank": 12802
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17224,
   "Closing_Rank": 17224
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3277,
   "Closing_Rank": 5997
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8977,
   "Closing_Rank": 8977
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9817,
   "Closing_Rank": 16603
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23443,
   "Closing_Rank": 24040
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 722,
   "Closing_Rank": 722
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2534,
   "Closing_Rank": 2725
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4233,
   "Closing_Rank": 4233
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6255,
   "Closing_Rank": 7568
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9194,
   "Closing_Rank": 9194
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 653,
   "Closing_Rank": 653
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3873,
   "Closing_Rank": 4124
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6149,
   "Closing_Rank": 6149
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1611,
   "Closing_Rank": 1611
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Industrial Internet of Things ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3745,
   "Closing_Rank": 3745
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8871,
   "Closing_Rank": 11454
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12969,
   "Closing_Rank": 13652
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1499,
   "Closing_Rank": 1499
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1805,
   "Closing_Rank": 2060
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2423,
   "Closing_Rank": 2423
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3840,
   "Closing_Rank": 5042
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6439,
   "Closing_Rank": 7064
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2333,
   "Closing_Rank": 2467
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4148,
   "Closing_Rank": 4148
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4444,
   "Closing_Rank": 5957
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6109,
   "Closing_Rank": 7512
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9440,
   "Closing_Rank": 12090
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1087,
   "Closing_Rank": 1188
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2375,
   "Closing_Rank": 3010
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5665,
   "Closing_Rank": 5665
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1598,
   "Closing_Rank": 1830
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3383,
   "Closing_Rank": 3383
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25,
   "Closing_Rank": 25
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 636,
   "Closing_Rank": 786
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1269,
   "Closing_Rank": 1269
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10686,
   "Closing_Rank": 13410
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18969,
   "Closing_Rank": 21684
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2075,
   "Closing_Rank": 2075
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2245,
   "Closing_Rank": 2415
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5480,
   "Closing_Rank": 8366
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11668,
   "Closing_Rank": 11668
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3334,
   "Closing_Rank": 3854
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5656,
   "Closing_Rank": 5656
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23484,
   "Closing_Rank": 23484
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6603,
   "Closing_Rank": 9556
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12313,
   "Closing_Rank": 14332
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1447,
   "Closing_Rank": 1497
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2547,
   "Closing_Rank": 2547
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3200,
   "Closing_Rank": 3778
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6389,
   "Closing_Rank": 6389
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 202,
   "Closing_Rank": 202
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1840,
   "Closing_Rank": 1893
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4789,
   "Closing_Rank": 4789
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 171,
   "Closing_Rank": 171
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 872,
   "Closing_Rank": 872
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2348,
   "Closing_Rank": 2348
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11141,
   "Closing_Rank": 28538
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31800,
   "Closing_Rank": 37214
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4591,
   "Closing_Rank": 5413
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6692,
   "Closing_Rank": 6692
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11146,
   "Closing_Rank": 15278
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14072,
   "Closing_Rank": 17815
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5167,
   "Closing_Rank": 6722
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8246,
   "Closing_Rank": 8246
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11941,
   "Closing_Rank": 27243
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11252,
   "Closing_Rank": 11252
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17691,
   "Closing_Rank": 23310
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34479,
   "Closing_Rank": 36213
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3391,
   "Closing_Rank": 3391
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3660,
   "Closing_Rank": 3963
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6947,
   "Closing_Rank": 6947
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7962,
   "Closing_Rank": 8695
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14570,
   "Closing_Rank": 15856
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 480,
   "Closing_Rank": 480
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3385,
   "Closing_Rank": 4874
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7851,
   "Closing_Rank": 7851
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1735,
   "Closing_Rank": 2004
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22052,
   "Closing_Rank": 30337
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8314,
   "Closing_Rank": 8314
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13571,
   "Closing_Rank": 13571
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6716,
   "Closing_Rank": 6716
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18205,
   "Closing_Rank": 18205
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38832,
   "Closing_Rank": 38832
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2265,
   "Closing_Rank": 2265
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9210,
   "Closing_Rank": 9821
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5408,
   "Closing_Rank": 5408
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16175,
   "Closing_Rank": 22127
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23981,
   "Closing_Rank": 29509
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3670,
   "Closing_Rank": 3670
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6813,
   "Closing_Rank": 6813
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8937,
   "Closing_Rank": 12067
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13723,
   "Closing_Rank": 13971
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4574,
   "Closing_Rank": 5300
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6932,
   "Closing_Rank": 6932
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9391,
   "Closing_Rank": 9391
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11638,
   "Closing_Rank": 14399
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18323,
   "Closing_Rank": 20440
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2178,
   "Closing_Rank": 2377
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3612,
   "Closing_Rank": 3612
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4427,
   "Closing_Rank": 5868
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10732,
   "Closing_Rank": 10732
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3517,
   "Closing_Rank": 3947
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5049,
   "Closing_Rank": 5049
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1410,
   "Closing_Rank": 1410
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Microelectronics &amp; VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2569,
   "Closing_Rank": 2569
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35564,
   "Closing_Rank": 43737
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44341,
   "Closing_Rank": 52632
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7191,
   "Closing_Rank": 7229
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16450,
   "Closing_Rank": 16848
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19600,
   "Closing_Rank": 19600
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6886,
   "Closing_Rank": 7229
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10222,
   "Closing_Rank": 10222
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30321,
   "Closing_Rank": 39314
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43809,
   "Closing_Rank": 43809
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2410,
   "Closing_Rank": 2410
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6375,
   "Closing_Rank": 6375
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8991,
   "Closing_Rank": 8991
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12501,
   "Closing_Rank": 13788
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16531,
   "Closing_Rank": 16531
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5981,
   "Closing_Rank": 6730
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9098,
   "Closing_Rank": 9098
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "Production and Industrial Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2851,
   "Closing_Rank": 2885
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13571,
   "Closing_Rank": 16498
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23755,
   "Closing_Rank": 24033
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2846,
   "Closing_Rank": 3457
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6454,
   "Closing_Rank": 6454
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7753,
   "Closing_Rank": 11903
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12757,
   "Closing_Rank": 12757
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4035,
   "Closing_Rank": 4554
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5267,
   "Closing_Rank": 5267
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6529,
   "Closing_Rank": 11581
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14850,
   "Closing_Rank": 14850
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 916,
   "Closing_Rank": 1040
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1843,
   "Closing_Rank": 1944
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3454,
   "Closing_Rank": 5021
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8030,
   "Closing_Rank": 8035
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 483,
   "Closing_Rank": 483
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 947,
   "Closing_Rank": 2089
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4775,
   "Closing_Rank": 5402
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "ROBOTICS &amp; AUTOMATION ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 369,
   "Closing_Rank": 1468
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31305,
   "Closing_Rank": 45275
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41130,
   "Closing_Rank": 46270
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8172,
   "Closing_Rank": 8202
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15481,
   "Closing_Rank": 17859
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21996,
   "Closing_Rank": 21996
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7500,
   "Closing_Rank": 9124
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9126,
   "Closing_Rank": 9126
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15875,
   "Closing_Rank": 32019
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44034,
   "Closing_Rank": 47032
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5376,
   "Closing_Rank": 6673
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8366,
   "Closing_Rank": 8366
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11494,
   "Closing_Rank": 13485
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16117,
   "Closing_Rank": 16117
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4923,
   "Closing_Rank": 6718
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10137,
   "Closing_Rank": 10137
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2764,
   "Closing_Rank": 3224
 },
 {
   "Institute": "National Institute of Technolog Kurukshetra",
   "Academic_Program_Name": "SUSTAINABLE ENERGY TECHNOLOGIES ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4371,
   "Closing_Rank": 4371
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 120347,
   "Closing_Rank": 230962
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 131071,
   "Closing_Rank": 131071
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 82211,
   "Closing_Rank": 125402
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 134344,
   "Closing_Rank": 134344
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31415,
   "Closing_Rank": 35941
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 71782,
   "Closing_Rank": 71782
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4464,
   "Closing_Rank": 7357
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54655,
   "Closing_Rank": 56358
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 68676,
   "Closing_Rank": 68676
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3669,
   "Closing_Rank": 3669
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9246,
   "Closing_Rank": 9246
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11245,
   "Closing_Rank": 11245
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17384,
   "Closing_Rank": 18425
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21724,
   "Closing_Rank": 21724
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8397,
   "Closing_Rank": 8678
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4453,
   "Closing_Rank": 4453
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62074,
   "Closing_Rank": 214958
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 83760,
   "Closing_Rank": 163308
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 76480,
   "Closing_Rank": 105233
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 122335,
   "Closing_Rank": 148070
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17281,
   "Closing_Rank": 27870
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15596,
   "Closing_Rank": 15596
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3800,
   "Closing_Rank": 3800
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5900,
   "Closing_Rank": 5900
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15616,
   "Closing_Rank": 30611
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38759,
   "Closing_Rank": 43862
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4985,
   "Closing_Rank": 5178
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7058,
   "Closing_Rank": 7058
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8750,
   "Closing_Rank": 11118
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15757,
   "Closing_Rank": 15757
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 675,
   "Closing_Rank": 675
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4503,
   "Closing_Rank": 4921
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6541,
   "Closing_Rank": 6541
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1864,
   "Closing_Rank": 1922
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 216734,
   "Closing_Rank": 461282
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 354850,
   "Closing_Rank": 363608
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 180226,
   "Closing_Rank": 426716
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 241931,
   "Closing_Rank": 241931
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39056,
   "Closing_Rank": 42632
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13459,
   "Closing_Rank": 13459
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20333,
   "Closing_Rank": 20333
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38450,
   "Closing_Rank": 47728
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52138,
   "Closing_Rank": 55730
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7726,
   "Closing_Rank": 7857
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15054,
   "Closing_Rank": 15926
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20363,
   "Closing_Rank": 20363
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7137,
   "Closing_Rank": 7497
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2546,
   "Closing_Rank": 2546
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 251398,
   "Closing_Rank": 476458
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 425686,
   "Closing_Rank": 571950
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 177663,
   "Closing_Rank": 299067
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 271955,
   "Closing_Rank": 271955
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46778,
   "Closing_Rank": 123813
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15068,
   "Closing_Rank": 16654
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35204,
   "Closing_Rank": 40126
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44558,
   "Closing_Rank": 49722
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6532,
   "Closing_Rank": 6532
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8183,
   "Closing_Rank": 8183
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11634,
   "Closing_Rank": 12615
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19120,
   "Closing_Rank": 19120
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6935,
   "Closing_Rank": 7814
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2521,
   "Closing_Rank": 2521
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 317560,
   "Closing_Rank": 476682
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 194433,
   "Closing_Rank": 716081
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 190456,
   "Closing_Rank": 453798
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 379896,
   "Closing_Rank": 379896
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 89902,
   "Closing_Rank": 89902
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10607,
   "Closing_Rank": 10607
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46956,
   "Closing_Rank": 50535
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 63193,
   "Closing_Rank": 66426
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8375,
   "Closing_Rank": 8375
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11067,
   "Closing_Rank": 11067
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16235,
   "Closing_Rank": 17474
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22708,
   "Closing_Rank": 22708
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8152,
   "Closing_Rank": 8264
 },
 {
   "Institute": "National Institute of Technolog Manipur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3076,
   "Closing_Rank": 3076
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 373312,
   "Closing_Rank": 574304
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 846887,
   "Closing_Rank": 846887
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14578,
   "Closing_Rank": 14578
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43818,
   "Closing_Rank": 56579
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 69070,
   "Closing_Rank": 69524
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9265,
   "Closing_Rank": 9265
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11447,
   "Closing_Rank": 11447
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18847,
   "Closing_Rank": 18857
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25124,
   "Closing_Rank": 25124
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8462,
   "Closing_Rank": 8515
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11990,
   "Closing_Rank": 11990
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2710,
   "Closing_Rank": 2710
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4066,
   "Closing_Rank": 4066
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 309946,
   "Closing_Rank": 712487
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 370684,
   "Closing_Rank": 544345
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62026,
   "Closing_Rank": 62026
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18700,
   "Closing_Rank": 18700
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31598,
   "Closing_Rank": 33181
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35200,
   "Closing_Rank": 43362
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1542,
   "Closing_Rank": 1542
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5469,
   "Closing_Rank": 5469
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7883,
   "Closing_Rank": 7883
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11236,
   "Closing_Rank": 11783
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17814,
   "Closing_Rank": 17814
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5066,
   "Closing_Rank": 5269
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5473,
   "Closing_Rank": 5473
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1943,
   "Closing_Rank": 1943
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 713287,
   "Closing_Rank": 1187663
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 500521,
   "Closing_Rank": 500521
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43594,
   "Closing_Rank": 47514
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55169,
   "Closing_Rank": 56224
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7822,
   "Closing_Rank": 7822
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9190,
   "Closing_Rank": 9190
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14818,
   "Closing_Rank": 16394
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21993,
   "Closing_Rank": 21993
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7708,
   "Closing_Rank": 7896
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2726,
   "Closing_Rank": 2801
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 293110,
   "Closing_Rank": 1187082
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 772989,
   "Closing_Rank": 772989
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40683,
   "Closing_Rank": 43128
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49188,
   "Closing_Rank": 53289
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2312,
   "Closing_Rank": 2312
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7124,
   "Closing_Rank": 7124
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8871,
   "Closing_Rank": 8871
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12786,
   "Closing_Rank": 13285
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20366,
   "Closing_Rank": 20366
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6523,
   "Closing_Rank": 6735
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6888,
   "Closing_Rank": 6888
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2166,
   "Closing_Rank": 2166
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 596054,
   "Closing_Rank": 743618
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36273,
   "Closing_Rank": 36482
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45505,
   "Closing_Rank": 45505
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6120,
   "Closing_Rank": 6120
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13693,
   "Closing_Rank": 13693
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 313293,
   "Closing_Rank": 1294100
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49167,
   "Closing_Rank": 52165
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60713,
   "Closing_Rank": 60713
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4235,
   "Closing_Rank": 4235
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8552,
   "Closing_Rank": 8552
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11353,
   "Closing_Rank": 11353
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16441,
   "Closing_Rank": 17686
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23426,
   "Closing_Rank": 23426
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7668,
   "Closing_Rank": 8299
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3078,
   "Closing_Rank": 3078
 },
 {
   "Institute": "National Institute of Technolog Mizoram",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4396,
   "Closing_Rank": 4396
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 552,
   "Closing_Rank": 1334
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 458,
   "Closing_Rank": 458
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1600,
   "Closing_Rank": 1600
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1173,
   "Closing_Rank": 1546
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 468,
   "Closing_Rank": 468
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 593,
   "Closing_Rank": 593
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 98,
   "Closing_Rank": 98
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 464,
   "Closing_Rank": 716
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 255,
   "Closing_Rank": 255
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 147,
   "Closing_Rank": 147
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 286,
   "Closing_Rank": 290
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 236,
   "Closing_Rank": 236
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 91,
   "Closing_Rank": 91
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56,
   "Closing_Rank": 56
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7885,
   "Closing_Rank": 13848
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17069,
   "Closing_Rank": 18539
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 226,
   "Closing_Rank": 226
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3532,
   "Closing_Rank": 4658
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5374,
   "Closing_Rank": 7567
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8069,
   "Closing_Rank": 12507
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2446,
   "Closing_Rank": 2941
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1151,
   "Closing_Rank": 1283
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3125,
   "Closing_Rank": 3983
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6548,
   "Closing_Rank": 7563
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 547,
   "Closing_Rank": 578
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1115,
   "Closing_Rank": 1308
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2794,
   "Closing_Rank": 3658
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 595,
   "Closing_Rank": 821
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1728,
   "Closing_Rank": 1728
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 315,
   "Closing_Rank": 315
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48464,
   "Closing_Rank": 54280
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52327,
   "Closing_Rank": 55431
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10173,
   "Closing_Rank": 12449
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17875,
   "Closing_Rank": 23527
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22506,
   "Closing_Rank": 22506
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5546,
   "Closing_Rank": 5632
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2769,
   "Closing_Rank": 2769
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28053,
   "Closing_Rank": 37811
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34783,
   "Closing_Rank": 37497
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6582,
   "Closing_Rank": 6793
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11852,
   "Closing_Rank": 12921
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15879,
   "Closing_Rank": 15879
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6552,
   "Closing_Rank": 6559
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8262,
   "Closing_Rank": 8262
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Medical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2534,
   "Closing_Rank": 2534
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31466,
   "Closing_Rank": 48211
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43472,
   "Closing_Rank": 47049
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 422,
   "Closing_Rank": 422
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9784,
   "Closing_Rank": 9784
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18310,
   "Closing_Rank": 21917
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26367,
   "Closing_Rank": 26367
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11514,
   "Closing_Rank": 12212
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3045,
   "Closing_Rank": 3045
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21536,
   "Closing_Rank": 29495
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32656,
   "Closing_Rank": 33148
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5658,
   "Closing_Rank": 5658
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10652,
   "Closing_Rank": 12323
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15165,
   "Closing_Rank": 15165
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 871,
   "Closing_Rank": 4251
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7844,
   "Closing_Rank": 7844
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2276,
   "Closing_Rank": 2276
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44990,
   "Closing_Rank": 47298
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 65597,
   "Closing_Rank": 66664
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10459,
   "Closing_Rank": 11488
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17612,
   "Closing_Rank": 20623
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28898,
   "Closing_Rank": 28898
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10204,
   "Closing_Rank": 10726
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19210,
   "Closing_Rank": 19210
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4563,
   "Closing_Rank": 4563
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30050,
   "Closing_Rank": 36000
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42737,
   "Closing_Rank": 51113
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3036,
   "Closing_Rank": 3036
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6338,
   "Closing_Rank": 6344
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11253,
   "Closing_Rank": 12506
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16871,
   "Closing_Rank": 16871
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5610,
   "Closing_Rank": 6396
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1860,
   "Closing_Rank": 1860
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4390,
   "Closing_Rank": 4390
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54658,
   "Closing_Rank": 55726
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 67684,
   "Closing_Rank": 67684
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22349,
   "Closing_Rank": 24049
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12621,
   "Closing_Rank": 12621
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36588,
   "Closing_Rank": 39253
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52689,
   "Closing_Rank": 52689
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14651,
   "Closing_Rank": 14845
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Ceramic Engineering and M.Tech Industrial Ceramic ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6666,
   "Closing_Rank": 6666
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23194,
   "Closing_Rank": 29496
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23380,
   "Closing_Rank": 32945
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 724,
   "Closing_Rank": 724
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8528,
   "Closing_Rank": 8939
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10186,
   "Closing_Rank": 13451
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17103,
   "Closing_Rank": 19453
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5934,
   "Closing_Rank": 8952
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6815,
   "Closing_Rank": 6815
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3541,
   "Closing_Rank": 4305
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6330,
   "Closing_Rank": 6330
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15345,
   "Closing_Rank": 18958
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23211,
   "Closing_Rank": 28865
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1575,
   "Closing_Rank": 1575
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3131,
   "Closing_Rank": 3466
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6899,
   "Closing_Rank": 7172
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7822,
   "Closing_Rank": 9983
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3829,
   "Closing_Rank": 4709
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6361,
   "Closing_Rank": 6361
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1126,
   "Closing_Rank": 1252
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46142,
   "Closing_Rank": 47227
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15601,
   "Closing_Rank": 15601
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21598,
   "Closing_Rank": 21598
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7149,
   "Closing_Rank": 7149
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21024,
   "Closing_Rank": 22091
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30894,
   "Closing_Rank": 30894
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4431,
   "Closing_Rank": 4431
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8043,
   "Closing_Rank": 8043
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4787,
   "Closing_Rank": 4787
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 70542,
   "Closing_Rank": 73400
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 73003,
   "Closing_Rank": 73003
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15883,
   "Closing_Rank": 15883
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30245,
   "Closing_Rank": 32789
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38799,
   "Closing_Rank": 38799
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13809,
   "Closing_Rank": 13948
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5582,
   "Closing_Rank": 5582
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42967,
   "Closing_Rank": 48289
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38846,
   "Closing_Rank": 38846
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8076,
   "Closing_Rank": 8076
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14349,
   "Closing_Rank": 15838
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19783,
   "Closing_Rank": 19783
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6903,
   "Closing_Rank": 8428
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2819,
   "Closing_Rank": 2819
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28165,
   "Closing_Rank": 40516
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28606,
   "Closing_Rank": 40193
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 178,
   "Closing_Rank": 178
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9565,
   "Closing_Rank": 10254
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14324,
   "Closing_Rank": 15882
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21022,
   "Closing_Rank": 25964
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 212,
   "Closing_Rank": 212
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5115,
   "Closing_Rank": 7966
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10126,
   "Closing_Rank": 10126
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3482,
   "Closing_Rank": 3609
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17880,
   "Closing_Rank": 25893
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31009,
   "Closing_Rank": 38826
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 462,
   "Closing_Rank": 462
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4320,
   "Closing_Rank": 4465
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7005,
   "Closing_Rank": 8742
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11024,
   "Closing_Rank": 11289
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 982,
   "Closing_Rank": 982
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3675,
   "Closing_Rank": 4447
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4781,
   "Closing_Rank": 4781
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 987,
   "Closing_Rank": 1129
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2379,
   "Closing_Rank": 7807
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5554,
   "Closing_Rank": 12843
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 91,
   "Closing_Rank": 91
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46,
   "Closing_Rank": 46
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1977,
   "Closing_Rank": 3098
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23,
   "Closing_Rank": 23
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1685,
   "Closing_Rank": 4455
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3757,
   "Closing_Rank": 8061
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 111,
   "Closing_Rank": 111
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 716,
   "Closing_Rank": 2401
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1510,
   "Closing_Rank": 1510
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23,
   "Closing_Rank": 23
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 667,
   "Closing_Rank": 1359
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4033,
   "Closing_Rank": 4033
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 591,
   "Closing_Rank": 2940
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3724,
   "Closing_Rank": 6112
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 137,
   "Closing_Rank": 137
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 396,
   "Closing_Rank": 454
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 996,
   "Closing_Rank": 996
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32,
   "Closing_Rank": 32
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 816,
   "Closing_Rank": 1081
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1778,
   "Closing_Rank": 1977
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48,
   "Closing_Rank": 48
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 346,
   "Closing_Rank": 523
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 722,
   "Closing_Rank": 809
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7,
   "Closing_Rank": 7
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32,
   "Closing_Rank": 32
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 76,
   "Closing_Rank": 202
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 177,
   "Closing_Rank": 177
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6,
   "Closing_Rank": 6
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14003,
   "Closing_Rank": 19279
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21948,
   "Closing_Rank": 25181
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 641,
   "Closing_Rank": 641
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 671,
   "Closing_Rank": 671
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4958,
   "Closing_Rank": 7657
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8685,
   "Closing_Rank": 8685
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 290,
   "Closing_Rank": 290
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7814,
   "Closing_Rank": 11759
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11770,
   "Closing_Rank": 14143
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 457,
   "Closing_Rank": 457
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 515,
   "Closing_Rank": 515
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3297,
   "Closing_Rank": 5584
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10101,
   "Closing_Rank": 10101
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1491,
   "Closing_Rank": 2299
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3925,
   "Closing_Rank": 3925
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6573,
   "Closing_Rank": 10462
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11102,
   "Closing_Rank": 16658
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 402,
   "Closing_Rank": 402
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 993,
   "Closing_Rank": 993
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1566,
   "Closing_Rank": 1780
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2822,
   "Closing_Rank": 2822
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 104,
   "Closing_Rank": 104
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2902,
   "Closing_Rank": 3839
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4531,
   "Closing_Rank": 4635
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 173,
   "Closing_Rank": 173
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 718,
   "Closing_Rank": 718
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1229,
   "Closing_Rank": 2112
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2615,
   "Closing_Rank": 2615
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 245,
   "Closing_Rank": 245
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 402,
   "Closing_Rank": 414
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1399,
   "Closing_Rank": 1399
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8119,
   "Closing_Rank": 13280
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18168,
   "Closing_Rank": 19473
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 125,
   "Closing_Rank": 125
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3800,
   "Closing_Rank": 5169
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5815,
   "Closing_Rank": 5815
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4592,
   "Closing_Rank": 6723
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9665,
   "Closing_Rank": 9665
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1491,
   "Closing_Rank": 3055
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7369,
   "Closing_Rank": 7369
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1412,
   "Closing_Rank": 1462
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4352,
   "Closing_Rank": 4352
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3958,
   "Closing_Rank": 5721
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8260,
   "Closing_Rank": 9496
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 136,
   "Closing_Rank": 136
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 794,
   "Closing_Rank": 816
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1395,
   "Closing_Rank": 1395
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1653,
   "Closing_Rank": 1827
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3226,
   "Closing_Rank": 3226
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 239,
   "Closing_Rank": 706
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1713,
   "Closing_Rank": 1713
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 343,
   "Closing_Rank": 356
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 928,
   "Closing_Rank": 928
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13463,
   "Closing_Rank": 16937
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19966,
   "Closing_Rank": 20728
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 335,
   "Closing_Rank": 335
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5102,
   "Closing_Rank": 5242
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7388,
   "Closing_Rank": 7388
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8078,
   "Closing_Rank": 10265
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11525,
   "Closing_Rank": 11525
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3400,
   "Closing_Rank": 5232
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8088,
   "Closing_Rank": 8088
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1582,
   "Closing_Rank": 1582
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5517,
   "Closing_Rank": 5517
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7450,
   "Closing_Rank": 10785
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16629,
   "Closing_Rank": 17092
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1659,
   "Closing_Rank": 1710
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3127,
   "Closing_Rank": 3127
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2890,
   "Closing_Rank": 4328
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5758,
   "Closing_Rank": 5967
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 631,
   "Closing_Rank": 2055
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 566,
   "Closing_Rank": 735
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48950,
   "Closing_Rank": 53926
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 70560,
   "Closing_Rank": 70560
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11767,
   "Closing_Rank": 12657
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23462,
   "Closing_Rank": 23683
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35880,
   "Closing_Rank": 35880
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12506,
   "Closing_Rank": 12506
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12930,
   "Closing_Rank": 12930
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5108,
   "Closing_Rank": 5108
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34557,
   "Closing_Rank": 41605
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29089,
   "Closing_Rank": 29089
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6874,
   "Closing_Rank": 6970
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13476,
   "Closing_Rank": 13687
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16573,
   "Closing_Rank": 16573
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6525,
   "Closing_Rank": 6525
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9774,
   "Closing_Rank": 9774
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Food Process Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2123,
   "Closing_Rank": 2123
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26976,
   "Closing_Rank": 42172
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43549,
   "Closing_Rank": 64092
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9165,
   "Closing_Rank": 9165
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13346,
   "Closing_Rank": 19627
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21530,
   "Closing_Rank": 21530
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6485,
   "Closing_Rank": 10169
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3948,
   "Closing_Rank": 3948
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27601,
   "Closing_Rank": 29601
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24803,
   "Closing_Rank": 34264
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4981,
   "Closing_Rank": 4981
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8854,
   "Closing_Rank": 10689
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11685,
   "Closing_Rank": 11685
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5357,
   "Closing_Rank": 5760
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Industrial Design ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2397,
   "Closing_Rank": 2397
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66885,
   "Closing_Rank": 74550
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 73791,
   "Closing_Rank": 73791
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15993,
   "Closing_Rank": 15993
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33867,
   "Closing_Rank": 34452
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37056,
   "Closing_Rank": 37056
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17118,
   "Closing_Rank": 17118
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7682,
   "Closing_Rank": 7682
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38622,
   "Closing_Rank": 45442
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59030,
   "Closing_Rank": 59030
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7534,
   "Closing_Rank": 7534
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13448,
   "Closing_Rank": 15138
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18597,
   "Closing_Rank": 18597
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6210,
   "Closing_Rank": 6210
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Life Science ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2820,
   "Closing_Rank": 2820
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23934,
   "Closing_Rank": 62446
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58403,
   "Closing_Rank": 58403
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13756,
   "Closing_Rank": 13756
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25070,
   "Closing_Rank": 29231
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12218,
   "Closing_Rank": 14237
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5925,
   "Closing_Rank": 5925
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21341,
   "Closing_Rank": 28659
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36666,
   "Closing_Rank": 36666
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5486,
   "Closing_Rank": 5486
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10650,
   "Closing_Rank": 13475
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2135,
   "Closing_Rank": 6775
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2717,
   "Closing_Rank": 2717
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9600,
   "Closing_Rank": 25338
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23912,
   "Closing_Rank": 29689
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 647,
   "Closing_Rank": 647
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6553,
   "Closing_Rank": 8394
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10313,
   "Closing_Rank": 10313
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8027,
   "Closing_Rank": 12979
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14120,
   "Closing_Rank": 16389
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 526,
   "Closing_Rank": 526
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4666,
   "Closing_Rank": 6616
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5994,
   "Closing_Rank": 9825
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2037,
   "Closing_Rank": 2516
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5889,
   "Closing_Rank": 5889
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12405,
   "Closing_Rank": 15037
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19501,
   "Closing_Rank": 25029
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 636,
   "Closing_Rank": 636
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2323,
   "Closing_Rank": 2586
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4702,
   "Closing_Rank": 4702
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4649,
   "Closing_Rank": 5904
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9774,
   "Closing_Rank": 10173
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 489,
   "Closing_Rank": 489
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1154,
   "Closing_Rank": 1154
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2450,
   "Closing_Rank": 3371
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4951,
   "Closing_Rank": 5221
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 933,
   "Closing_Rank": 1365
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1830,
   "Closing_Rank": 1830
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34,
   "Closing_Rank": 34
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23197,
   "Closing_Rank": 41491
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35681,
   "Closing_Rank": 56122
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1830,
   "Closing_Rank": 1830
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9222,
   "Closing_Rank": 9871
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13552,
   "Closing_Rank": 17210
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21868,
   "Closing_Rank": 26622
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1084,
   "Closing_Rank": 1084
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7512,
   "Closing_Rank": 9908
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10695,
   "Closing_Rank": 10695
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4621,
   "Closing_Rank": 4780
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24831,
   "Closing_Rank": 28678
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38375,
   "Closing_Rank": 43900
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2254,
   "Closing_Rank": 2254
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5202,
   "Closing_Rank": 5517
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8840,
   "Closing_Rank": 9899
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15017,
   "Closing_Rank": 15170
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1030,
   "Closing_Rank": 1030
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6001,
   "Closing_Rank": 6294
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6683,
   "Closing_Rank": 6683
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1972,
   "Closing_Rank": 2275
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2054,
   "Closing_Rank": 2054
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41527,
   "Closing_Rank": 54330
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21857,
   "Closing_Rank": 21857
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35598,
   "Closing_Rank": 35598
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9307,
   "Closing_Rank": 9307
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25209,
   "Closing_Rank": 35955
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48201,
   "Closing_Rank": 48201
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13024,
   "Closing_Rank": 13680
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6647,
   "Closing_Rank": 6647
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38674,
   "Closing_Rank": 43406
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52364,
   "Closing_Rank": 57296
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11140,
   "Closing_Rank": 11288
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23397,
   "Closing_Rank": 23397
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15631,
   "Closing_Rank": 18903
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24766,
   "Closing_Rank": 24766
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8579,
   "Closing_Rank": 9204
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11717,
   "Closing_Rank": 11717
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3701,
   "Closing_Rank": 3701
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8501,
   "Closing_Rank": 8501
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29168,
   "Closing_Rank": 31895
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 46295,
   "Closing_Rank": 49630
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5425,
   "Closing_Rank": 5941
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8374,
   "Closing_Rank": 8374
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11714,
   "Closing_Rank": 12811
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18631,
   "Closing_Rank": 18981
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5935,
   "Closing_Rank": 5969
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7101,
   "Closing_Rank": 7101
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2076,
   "Closing_Rank": 2076
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39054,
   "Closing_Rank": 49516
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19581,
   "Closing_Rank": 19581
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37927,
   "Closing_Rank": 37927
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10423,
   "Closing_Rank": 10423
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4409,
   "Closing_Rank": 4409
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30720,
   "Closing_Rank": 32461
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6521,
   "Closing_Rank": 6521
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19779,
   "Closing_Rank": 19779
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5775,
   "Closing_Rank": 5775
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Mining Engineering ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor and Master of Technology (Dual Degree))",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2462,
   "Closing_Rank": 2462
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58952,
   "Closing_Rank": 71897
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 79497,
   "Closing_Rank": 79497
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19690,
   "Closing_Rank": 19690
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22073,
   "Closing_Rank": 23392
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36120,
   "Closing_Rank": 36120
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12055,
   "Closing_Rank": 15681
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4052,
   "Closing_Rank": 4052
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17440,
   "Closing_Rank": 22008
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33147,
   "Closing_Rank": 33147
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5838,
   "Closing_Rank": 5838
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9015,
   "Closing_Rank": 11503
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1277,
   "Closing_Rank": 1771
 },
 {
   "Institute": "National Institute of Technolog Rourkela",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1869,
   "Closing_Rank": 1869
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25665,
   "Closing_Rank": 84759
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37500,
   "Closing_Rank": 102786
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 685,
   "Closing_Rank": 685
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15780,
   "Closing_Rank": 18040
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17686,
   "Closing_Rank": 17686
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28731,
   "Closing_Rank": 49818
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50295,
   "Closing_Rank": 74407
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 735,
   "Closing_Rank": 735
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6721,
   "Closing_Rank": 13614
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11003,
   "Closing_Rank": 14254
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2011,
   "Closing_Rank": 3012
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3024,
   "Closing_Rank": 3024
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33067,
   "Closing_Rank": 45391
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47132,
   "Closing_Rank": 57074
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3048,
   "Closing_Rank": 3048
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7351,
   "Closing_Rank": 7661
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9318,
   "Closing_Rank": 9433
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13204,
   "Closing_Rank": 15129
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19861,
   "Closing_Rank": 21034
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5999,
   "Closing_Rank": 7283
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9770,
   "Closing_Rank": 10133
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1750,
   "Closing_Rank": 2038
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2222,
   "Closing_Rank": 2222
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6492,
   "Closing_Rank": 26892
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30006,
   "Closing_Rank": 39836
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 325,
   "Closing_Rank": 325
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4937,
   "Closing_Rank": 7075
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6435,
   "Closing_Rank": 12329
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8089,
   "Closing_Rank": 15380
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21277,
   "Closing_Rank": 41348
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 521,
   "Closing_Rank": 676
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1197,
   "Closing_Rank": 3094
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7828,
   "Closing_Rank": 10088
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 139,
   "Closing_Rank": 139
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 373,
   "Closing_Rank": 1331
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2460,
   "Closing_Rank": 2460
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5736,
   "Closing_Rank": 11930
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11300,
   "Closing_Rank": 18846
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 390,
   "Closing_Rank": 390
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1765,
   "Closing_Rank": 1910
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3195,
   "Closing_Rank": 3203
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 97,
   "Closing_Rank": 97
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2779,
   "Closing_Rank": 3848
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6788,
   "Closing_Rank": 8489
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 229,
   "Closing_Rank": 229
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1444,
   "Closing_Rank": 2425
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3840,
   "Closing_Rank": 3862
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 276,
   "Closing_Rank": 276
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 318,
   "Closing_Rank": 806
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1186,
   "Closing_Rank": 1186
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33991,
   "Closing_Rank": 62022
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60072,
   "Closing_Rank": 76071
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2566,
   "Closing_Rank": 2980
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10414,
   "Closing_Rank": 12556
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19039,
   "Closing_Rank": 19384
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22680,
   "Closing_Rank": 37149
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41851,
   "Closing_Rank": 60194
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3350,
   "Closing_Rank": 9836
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12622,
   "Closing_Rank": 12958
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1427,
   "Closing_Rank": 2197
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3429,
   "Closing_Rank": 3429
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19075,
   "Closing_Rank": 24365
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34545,
   "Closing_Rank": 36438
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1733,
   "Closing_Rank": 1733
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2542,
   "Closing_Rank": 2542
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3853,
   "Closing_Rank": 3994
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6069,
   "Closing_Rank": 6562
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6520,
   "Closing_Rank": 8343
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13966,
   "Closing_Rank": 14969
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 943,
   "Closing_Rank": 943
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3981,
   "Closing_Rank": 5339
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6263,
   "Closing_Rank": 6542
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1473,
   "Closing_Rank": 1991
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2364,
   "Closing_Rank": 2364
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26983,
   "Closing_Rank": 42983
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41560,
   "Closing_Rank": 57804
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7131,
   "Closing_Rank": 7991
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14039,
   "Closing_Rank": 17866
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15953,
   "Closing_Rank": 22375
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32424,
   "Closing_Rank": 46520
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3406,
   "Closing_Rank": 6107
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10440,
   "Closing_Rank": 12406
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1095,
   "Closing_Rank": 1371
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2208,
   "Closing_Rank": 2208
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12183,
   "Closing_Rank": 17339
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19580,
   "Closing_Rank": 26263
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 493,
   "Closing_Rank": 493
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1208,
   "Closing_Rank": 1208
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2743,
   "Closing_Rank": 2820
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4775,
   "Closing_Rank": 5367
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 148,
   "Closing_Rank": 234
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4970,
   "Closing_Rank": 6082
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9250,
   "Closing_Rank": 10135
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 406,
   "Closing_Rank": 406
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3027,
   "Closing_Rank": 4139
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3786,
   "Closing_Rank": 6174
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 808,
   "Closing_Rank": 1404
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1557,
   "Closing_Rank": 1557
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33,
   "Closing_Rank": 33
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43847,
   "Closing_Rank": 51593
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59443,
   "Closing_Rank": 71031
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8621,
   "Closing_Rank": 10728
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18602,
   "Closing_Rank": 18602
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22567,
   "Closing_Rank": 33187
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33975,
   "Closing_Rank": 55215
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5517,
   "Closing_Rank": 6580
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12300,
   "Closing_Rank": 12300
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1768,
   "Closing_Rank": 2204
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3887,
   "Closing_Rank": 3887
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17669,
   "Closing_Rank": 21926
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27237,
   "Closing_Rank": 32943
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3426,
   "Closing_Rank": 3523
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6201,
   "Closing_Rank": 6201
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6079,
   "Closing_Rank": 7994
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11750,
   "Closing_Rank": 14278
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4415,
   "Closing_Rank": 4635
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6219,
   "Closing_Rank": 6219
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1786,
   "Closing_Rank": 2118
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Electronics and Instrumentation Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2300,
   "Closing_Rank": 2300
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49948,
   "Closing_Rank": 72011
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 54213,
   "Closing_Rank": 91301
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12566,
   "Closing_Rank": 14891
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18752,
   "Closing_Rank": 19656
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23829,
   "Closing_Rank": 48118
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30905,
   "Closing_Rank": 77859
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2711,
   "Closing_Rank": 12259
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13290,
   "Closing_Rank": 13810
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2522,
   "Closing_Rank": 3258
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4274,
   "Closing_Rank": 4274
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22243,
   "Closing_Rank": 32251
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43284,
   "Closing_Rank": 47054
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1779,
   "Closing_Rank": 1779
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3276,
   "Closing_Rank": 3276
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5396,
   "Closing_Rank": 5789
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7912,
   "Closing_Rank": 8228
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8959,
   "Closing_Rank": 11217
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17509,
   "Closing_Rank": 19533
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 920,
   "Closing_Rank": 1393
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4881,
   "Closing_Rank": 6013
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9190,
   "Closing_Rank": 10046
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2126,
   "Closing_Rank": 2297
 },
 {
   "Institute": "National Institute of Technolog Silchar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3192,
   "Closing_Rank": 3192
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 75874,
   "Closing_Rank": 120403
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 100839,
   "Closing_Rank": 137443
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14867,
   "Closing_Rank": 29182
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58707,
   "Closing_Rank": 128053
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4527,
   "Closing_Rank": 7916
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 349784,
   "Closing_Rank": 349784
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41898,
   "Closing_Rank": 51760
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47392,
   "Closing_Rank": 58828
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3694,
   "Closing_Rank": 3694
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8419,
   "Closing_Rank": 8540
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10368,
   "Closing_Rank": 10368
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16090,
   "Closing_Rank": 17471
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21213,
   "Closing_Rank": 22281
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2995,
   "Closing_Rank": 8253
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10611,
   "Closing_Rank": 10635
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3007,
   "Closing_Rank": 3088
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4497,
   "Closing_Rank": 4497
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38474,
   "Closing_Rank": 101648
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 80405,
   "Closing_Rank": 125416
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2421,
   "Closing_Rank": 3224
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36118,
   "Closing_Rank": 86607
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38798,
   "Closing_Rank": 38798
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 58549,
   "Closing_Rank": 295468
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6131,
   "Closing_Rank": 26278
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26694,
   "Closing_Rank": 32911
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1647,
   "Closing_Rank": 4023
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14206,
   "Closing_Rank": 14206
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 252507,
   "Closing_Rank": 252507
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 266792,
   "Closing_Rank": 266792
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45564,
   "Closing_Rank": 55070
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 61452,
   "Closing_Rank": 68202
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4326,
   "Closing_Rank": 4326
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8962,
   "Closing_Rank": 9150
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11262,
   "Closing_Rank": 11339
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16843,
   "Closing_Rank": 18246
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22886,
   "Closing_Rank": 24363
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7449,
   "Closing_Rank": 8448
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12021,
   "Closing_Rank": 12370
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1567,
   "Closing_Rank": 2648
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3963,
   "Closing_Rank": 3963
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9106,
   "Closing_Rank": 31827
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31019,
   "Closing_Rank": 49390
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 549,
   "Closing_Rank": 549
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6666,
   "Closing_Rank": 12613
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15337,
   "Closing_Rank": 15337
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13609,
   "Closing_Rank": 42105
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 73424,
   "Closing_Rank": 136454
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6048,
   "Closing_Rank": 9094
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8015,
   "Closing_Rank": 8015
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1934,
   "Closing_Rank": 3240
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6857,
   "Closing_Rank": 6857
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 162986,
   "Closing_Rank": 162986
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12751,
   "Closing_Rank": 21839
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24950,
   "Closing_Rank": 36322
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 543,
   "Closing_Rank": 543
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3481,
   "Closing_Rank": 3485
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6061,
   "Closing_Rank": 6061
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6808,
   "Closing_Rank": 7619
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12251,
   "Closing_Rank": 12735
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 415,
   "Closing_Rank": 415
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3164,
   "Closing_Rank": 3843
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5762,
   "Closing_Rank": 5782
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1083,
   "Closing_Rank": 1630
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 53003,
   "Closing_Rank": 73846
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 82369,
   "Closing_Rank": 114629
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3145,
   "Closing_Rank": 3145
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 78238,
   "Closing_Rank": 79879
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17441,
   "Closing_Rank": 22882
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18558,
   "Closing_Rank": 24398
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5178,
   "Closing_Rank": 6383
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3265,
   "Closing_Rank": 3265
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 248474,
   "Closing_Rank": 248474
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 377420,
   "Closing_Rank": 377420
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31608,
   "Closing_Rank": 40442
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44720,
   "Closing_Rank": 49672
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2384,
   "Closing_Rank": 2928
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6566,
   "Closing_Rank": 6703
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8965,
   "Closing_Rank": 8965
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12561,
   "Closing_Rank": 13744
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17933,
   "Closing_Rank": 18270
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1454,
   "Closing_Rank": 1454
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5181,
   "Closing_Rank": 6347
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8660,
   "Closing_Rank": 9203
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2468,
   "Closing_Rank": 2473
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4144,
   "Closing_Rank": 4144
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40070,
   "Closing_Rank": 52959
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58543,
   "Closing_Rank": 82834
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2860,
   "Closing_Rank": 2860
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36904,
   "Closing_Rank": 62342
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38885,
   "Closing_Rank": 437563
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 332267,
   "Closing_Rank": 332267
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13712,
   "Closing_Rank": 16732
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23581,
   "Closing_Rank": 30121
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5087,
   "Closing_Rank": 6277
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20780,
   "Closing_Rank": 20780
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 328365,
   "Closing_Rank": 328365
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 383654,
   "Closing_Rank": 383654
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24337,
   "Closing_Rank": 33605
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41464,
   "Closing_Rank": 43622
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2399,
   "Closing_Rank": 2399
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5470,
   "Closing_Rank": 5685
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7026,
   "Closing_Rank": 7026
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9925,
   "Closing_Rank": 11753
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17096,
   "Closing_Rank": 17726
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1321,
   "Closing_Rank": 1321
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4936,
   "Closing_Rank": 5756
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8219,
   "Closing_Rank": 8510
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1923,
   "Closing_Rank": 2322
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3205,
   "Closing_Rank": 3205
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32584,
   "Closing_Rank": 41053
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55449,
   "Closing_Rank": 77972
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1809,
   "Closing_Rank": 1809
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19715,
   "Closing_Rank": 41203
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 43934,
   "Closing_Rank": 220082
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 112797,
   "Closing_Rank": 322491
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9408,
   "Closing_Rank": 13526
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11932,
   "Closing_Rank": 11932
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4275,
   "Closing_Rank": 4528
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9005,
   "Closing_Rank": 9005
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 210829,
   "Closing_Rank": 210829
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22938,
   "Closing_Rank": 26370
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 37177,
   "Closing_Rank": 37980
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 865,
   "Closing_Rank": 865
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4202,
   "Closing_Rank": 4466
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6789,
   "Closing_Rank": 6789
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7156,
   "Closing_Rank": 9167
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13694,
   "Closing_Rank": 16141
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4004,
   "Closing_Rank": 4843
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6540,
   "Closing_Rank": 8065
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1745,
   "Closing_Rank": 2261
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2486,
   "Closing_Rank": 2486
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 56935,
   "Closing_Rank": 89013
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 116427,
   "Closing_Rank": 122218
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1808,
   "Closing_Rank": 1808
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 69126,
   "Closing_Rank": 100279
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49600,
   "Closing_Rank": 249274
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17498,
   "Closing_Rank": 27315
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 40216,
   "Closing_Rank": 76887
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5650,
   "Closing_Rank": 6550
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 304164,
   "Closing_Rank": 304164
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 388057,
   "Closing_Rank": 388057
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40623,
   "Closing_Rank": 47482
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56374,
   "Closing_Rank": 62389
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3181,
   "Closing_Rank": 3181
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7806,
   "Closing_Rank": 8070
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10446,
   "Closing_Rank": 10446
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13937,
   "Closing_Rank": 15893
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19406,
   "Closing_Rank": 22568
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6677,
   "Closing_Rank": 7579
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10737,
   "Closing_Rank": 11334
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2686,
   "Closing_Rank": 2707
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4491,
   "Closing_Rank": 4491
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 102741,
   "Closing_Rank": 133070
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 142540,
   "Closing_Rank": 158691
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31349,
   "Closing_Rank": 35343
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 132222,
   "Closing_Rank": 132222
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "JK",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8141,
   "Closing_Rank": 8695
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "LA",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 367592,
   "Closing_Rank": 367592
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44051,
   "Closing_Rank": 55447
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 66054,
   "Closing_Rank": 66731
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3284,
   "Closing_Rank": 3284
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9115,
   "Closing_Rank": 9273
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11522,
   "Closing_Rank": 11522
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16916,
   "Closing_Rank": 18785
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24089,
   "Closing_Rank": 25142
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1581,
   "Closing_Rank": 1581
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8842,
   "Closing_Rank": 9242
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12132,
   "Closing_Rank": 12519
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2961,
   "Closing_Rank": 3556
 },
 {
   "Institute": "National Institute of Technolog Srinagar",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4136,
   "Closing_Rank": 4136
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2,
   "Closing_Rank": 486
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49,
   "Closing_Rank": 50
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 115,
   "Closing_Rank": 115
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1767,
   "Closing_Rank": 1802
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2356,
   "Closing_Rank": 2356
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 144,
   "Closing_Rank": 311
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 169,
   "Closing_Rank": 300
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 186,
   "Closing_Rank": 299
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17,
   "Closing_Rank": 17
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 302,
   "Closing_Rank": 492
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29,
   "Closing_Rank": 404
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 240,
   "Closing_Rank": 240
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23,
   "Closing_Rank": 23
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59,
   "Closing_Rank": 102
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 156,
   "Closing_Rank": 168
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 152,
   "Closing_Rank": 152
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16,
   "Closing_Rank": 50
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 84,
   "Closing_Rank": 84
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41,
   "Closing_Rank": 41
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39,
   "Closing_Rank": 39
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12770,
   "Closing_Rank": 20789
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9835,
   "Closing_Rank": 25656
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11147,
   "Closing_Rank": 15371
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23612,
   "Closing_Rank": 23612
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5350,
   "Closing_Rank": 6345
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8169,
   "Closing_Rank": 9868
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 451,
   "Closing_Rank": 451
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3608,
   "Closing_Rank": 4436
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3808,
   "Closing_Rank": 3808
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2903,
   "Closing_Rank": 6910
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5802,
   "Closing_Rank": 5802
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7175,
   "Closing_Rank": 13950
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13588,
   "Closing_Rank": 20366
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 689,
   "Closing_Rank": 689
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2494,
   "Closing_Rank": 2572
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3710,
   "Closing_Rank": 3710
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5002,
   "Closing_Rank": 5625
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6386,
   "Closing_Rank": 6386
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 576,
   "Closing_Rank": 576
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3015,
   "Closing_Rank": 3704
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3485,
   "Closing_Rank": 3485
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 815,
   "Closing_Rank": 1001
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 87,
   "Closing_Rank": 87
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19131,
   "Closing_Rank": 36104
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26597,
   "Closing_Rank": 43172
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15751,
   "Closing_Rank": 31894
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26143,
   "Closing_Rank": 26143
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10090,
   "Closing_Rank": 11601
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12876,
   "Closing_Rank": 13119
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 486,
   "Closing_Rank": 486
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 685,
   "Closing_Rank": 685
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3622,
   "Closing_Rank": 4957
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3234,
   "Closing_Rank": 4154
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 353,
   "Closing_Rank": 353
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6880,
   "Closing_Rank": 8901
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1851,
   "Closing_Rank": 1851
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6660,
   "Closing_Rank": 18965
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20469,
   "Closing_Rank": 27581
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 118,
   "Closing_Rank": 118
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3034,
   "Closing_Rank": 3742
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4696,
   "Closing_Rank": 4696
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 342,
   "Closing_Rank": 342
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5137,
   "Closing_Rank": 6991
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10303,
   "Closing_Rank": 11348
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 436,
   "Closing_Rank": 436
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 810,
   "Closing_Rank": 2819
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1393,
   "Closing_Rank": 3524
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 339,
   "Closing_Rank": 712
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1432,
   "Closing_Rank": 1432
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1196,
   "Closing_Rank": 5066
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3258,
   "Closing_Rank": 9721
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 331,
   "Closing_Rank": 331
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 366,
   "Closing_Rank": 366
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1094,
   "Closing_Rank": 4239
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4052,
   "Closing_Rank": 4052
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 231,
   "Closing_Rank": 231
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1157,
   "Closing_Rank": 1633
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2267,
   "Closing_Rank": 2814
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 490,
   "Closing_Rank": 1284
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 493,
   "Closing_Rank": 804
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 167,
   "Closing_Rank": 167
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 350,
   "Closing_Rank": 2538
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1657,
   "Closing_Rank": 1657
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 299,
   "Closing_Rank": 1224
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1426,
   "Closing_Rank": 2172
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60,
   "Closing_Rank": 60
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 144,
   "Closing_Rank": 201
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 448,
   "Closing_Rank": 448
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 215,
   "Closing_Rank": 369
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 426,
   "Closing_Rank": 939
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26,
   "Closing_Rank": 26
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 100,
   "Closing_Rank": 204
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 160,
   "Closing_Rank": 467
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45,
   "Closing_Rank": 123
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 115,
   "Closing_Rank": 115
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6688,
   "Closing_Rank": 9828
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16092,
   "Closing_Rank": 18393
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 359,
   "Closing_Rank": 359
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8013,
   "Closing_Rank": 10773
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8917,
   "Closing_Rank": 8917
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2440,
   "Closing_Rank": 2905
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4677,
   "Closing_Rank": 5746
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1780,
   "Closing_Rank": 2230
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1736,
   "Closing_Rank": 2318
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 169,
   "Closing_Rank": 169
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1740,
   "Closing_Rank": 3253
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3464,
   "Closing_Rank": 3464
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3029,
   "Closing_Rank": 6040
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9351,
   "Closing_Rank": 10054
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 384,
   "Closing_Rank": 384
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 851,
   "Closing_Rank": 1155
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1909,
   "Closing_Rank": 1909
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1365,
   "Closing_Rank": 2175
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3194,
   "Closing_Rank": 3377
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 283,
   "Closing_Rank": 283
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 999,
   "Closing_Rank": 1242
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2209,
   "Closing_Rank": 2511
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 118,
   "Closing_Rank": 123
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 127,
   "Closing_Rank": 329
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 517,
   "Closing_Rank": 517
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2712,
   "Closing_Rank": 7036
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11473,
   "Closing_Rank": 15587
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 256,
   "Closing_Rank": 256
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4778,
   "Closing_Rank": 8222
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1654,
   "Closing_Rank": 2294
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3900,
   "Closing_Rank": 4264
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1255,
   "Closing_Rank": 1688
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1460,
   "Closing_Rank": 1513
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 158,
   "Closing_Rank": 158
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1747,
   "Closing_Rank": 2873
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3686,
   "Closing_Rank": 3686
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 917,
   "Closing_Rank": 3546
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3874,
   "Closing_Rank": 5481
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 72,
   "Closing_Rank": 72
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 461,
   "Closing_Rank": 498
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 849,
   "Closing_Rank": 849
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 775,
   "Closing_Rank": 1350
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1641,
   "Closing_Rank": 2035
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54,
   "Closing_Rank": 54
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 394,
   "Closing_Rank": 633
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 268,
   "Closing_Rank": 1563
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35,
   "Closing_Rank": 35
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 171,
   "Closing_Rank": 223
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 222,
   "Closing_Rank": 222
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10149,
   "Closing_Rank": 14697
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18611,
   "Closing_Rank": 23662
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 662,
   "Closing_Rank": 662
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10690,
   "Closing_Rank": 11696
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3690,
   "Closing_Rank": 4495
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7162,
   "Closing_Rank": 8805
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2155,
   "Closing_Rank": 3464
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3033,
   "Closing_Rank": 3424
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 231,
   "Closing_Rank": 231
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4494,
   "Closing_Rank": 5524
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5317,
   "Closing_Rank": 5317
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7435,
   "Closing_Rank": 11001
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11048,
   "Closing_Rank": 15619
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 797,
   "Closing_Rank": 797
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1591,
   "Closing_Rank": 1851
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2818,
   "Closing_Rank": 2818
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3399,
   "Closing_Rank": 4306
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4382,
   "Closing_Rank": 7952
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 438,
   "Closing_Rank": 438
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2159,
   "Closing_Rank": 2646
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4620,
   "Closing_Rank": 4786
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 269,
   "Closing_Rank": 269
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1081,
   "Closing_Rank": 1166
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Instrumentation and Control Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1890,
   "Closing_Rank": 1890
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9999,
   "Closing_Rank": 16002
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21318,
   "Closing_Rank": 27827
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6326,
   "Closing_Rank": 13658
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11154,
   "Closing_Rank": 11154
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3956,
   "Closing_Rank": 4930
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8269,
   "Closing_Rank": 9181
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 334,
   "Closing_Rank": 334
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 682,
   "Closing_Rank": 682
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2645,
   "Closing_Rank": 2969
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2283,
   "Closing_Rank": 3664
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 301,
   "Closing_Rank": 301
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3444,
   "Closing_Rank": 5423
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6741,
   "Closing_Rank": 6741
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5023,
   "Closing_Rank": 9305
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14067,
   "Closing_Rank": 16912
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 391,
   "Closing_Rank": 391
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1417,
   "Closing_Rank": 1817
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3036,
   "Closing_Rank": 3036
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 109,
   "Closing_Rank": 109
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2114,
   "Closing_Rank": 3737
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5267,
   "Closing_Rank": 6831
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 224,
   "Closing_Rank": 224
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1029,
   "Closing_Rank": 2390
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2534,
   "Closing_Rank": 4749
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 556,
   "Closing_Rank": 818
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1488,
   "Closing_Rank": 1488
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18454,
   "Closing_Rank": 30319
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29482,
   "Closing_Rank": 43127
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16300,
   "Closing_Rank": 29781
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15905,
   "Closing_Rank": 15905
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8332,
   "Closing_Rank": 10279
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12679,
   "Closing_Rank": 13641
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 314,
   "Closing_Rank": 314
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5130,
   "Closing_Rank": 5275
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4484,
   "Closing_Rank": 4484
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7232,
   "Closing_Rank": 8185
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8128,
   "Closing_Rank": 8128
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15466,
   "Closing_Rank": 22521
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24127,
   "Closing_Rank": 28329
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 898,
   "Closing_Rank": 898
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3588,
   "Closing_Rank": 3796
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6254,
   "Closing_Rank": 6254
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6712,
   "Closing_Rank": 8593
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12688,
   "Closing_Rank": 12688
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1540,
   "Closing_Rank": 1540
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4003,
   "Closing_Rank": 4931
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4673,
   "Closing_Rank": 4673
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1100,
   "Closing_Rank": 1607
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19582,
   "Closing_Rank": 24973
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32476,
   "Closing_Rank": 35594
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15910,
   "Closing_Rank": 21395
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31256,
   "Closing_Rank": 31256
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7025,
   "Closing_Rank": 8074
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11207,
   "Closing_Rank": 12315
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 449,
   "Closing_Rank": 449
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 629,
   "Closing_Rank": 629
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3467,
   "Closing_Rank": 4426
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4265,
   "Closing_Rank": 4794
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 370,
   "Closing_Rank": 370
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7363,
   "Closing_Rank": 8872
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7106,
   "Closing_Rank": 7106
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14668,
   "Closing_Rank": 24286
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29750,
   "Closing_Rank": 36782
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1552,
   "Closing_Rank": 1552
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3887,
   "Closing_Rank": 4264
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6560,
   "Closing_Rank": 6560
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 264,
   "Closing_Rank": 264
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7307,
   "Closing_Rank": 8996
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15736,
   "Closing_Rank": 16122
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1115,
   "Closing_Rank": 1115
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3904,
   "Closing_Rank": 5186
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6159,
   "Closing_Rank": 6291
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1475,
   "Closing_Rank": 1681
 },
 {
   "Institute": "National Institute of Technolog Tiruchirappalli",
   "Academic_Program_Name": "Production Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1723,
   "Closing_Rank": 1723
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48084,
   "Closing_Rank": 59638
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60895,
   "Closing_Rank": 60895
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10532,
   "Closing_Rank": 10532
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19005,
   "Closing_Rank": 19005
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27867,
   "Closing_Rank": 32031
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41426,
   "Closing_Rank": 41999
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8395,
   "Closing_Rank": 8424
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4812,
   "Closing_Rank": 4812
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37276,
   "Closing_Rank": 48366
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47116,
   "Closing_Rank": 47116
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8126,
   "Closing_Rank": 8155
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9904,
   "Closing_Rank": 9904
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15771,
   "Closing_Rank": 16069
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7294,
   "Closing_Rank": 7295
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8778,
   "Closing_Rank": 8778
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2133,
   "Closing_Rank": 2133
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4021,
   "Closing_Rank": 4021
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18865,
   "Closing_Rank": 31282
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39753,
   "Closing_Rank": 41844
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 213,
   "Closing_Rank": 213
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6379,
   "Closing_Rank": 6464
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7399,
   "Closing_Rank": 7399
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13151,
   "Closing_Rank": 18467
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5854,
   "Closing_Rank": 7260
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8036,
   "Closing_Rank": 8036
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3460,
   "Closing_Rank": 5729
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14598,
   "Closing_Rank": 18039
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22891,
   "Closing_Rank": 28529
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 726,
   "Closing_Rank": 726
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2752,
   "Closing_Rank": 2752
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4587,
   "Closing_Rank": 4587
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 120,
   "Closing_Rank": 120
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6134,
   "Closing_Rank": 6645
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7982,
   "Closing_Rank": 10525
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2971,
   "Closing_Rank": 3720
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1516,
   "Closing_Rank": 1516
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45575,
   "Closing_Rank": 50715
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53705,
   "Closing_Rank": 53705
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8254,
   "Closing_Rank": 8254
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24682,
   "Closing_Rank": 26776
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32277,
   "Closing_Rank": 32277
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8557,
   "Closing_Rank": 9809
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14979,
   "Closing_Rank": 14979
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5795,
   "Closing_Rank": 6368
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30197,
   "Closing_Rank": 32287
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33741,
   "Closing_Rank": 33741
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5154,
   "Closing_Rank": 5154
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9256,
   "Closing_Rank": 10001
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15597,
   "Closing_Rank": 16265
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5439,
   "Closing_Rank": 5885
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7399,
   "Closing_Rank": 7399
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1857,
   "Closing_Rank": 1857
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 40057,
   "Closing_Rank": 45096
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 50662,
   "Closing_Rank": 50662
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8053,
   "Closing_Rank": 8053
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15429,
   "Closing_Rank": 24485
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31540,
   "Closing_Rank": 31540
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7696,
   "Closing_Rank": 7772
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9326,
   "Closing_Rank": 13877
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4016,
   "Closing_Rank": 4016
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7228,
   "Closing_Rank": 7228
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 22097,
   "Closing_Rank": 26226
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30330,
   "Closing_Rank": 33827
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1932,
   "Closing_Rank": 1932
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4208,
   "Closing_Rank": 4451
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7577,
   "Closing_Rank": 8855
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14145,
   "Closing_Rank": 14145
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 912,
   "Closing_Rank": 912
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4405,
   "Closing_Rank": 4958
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1844,
   "Closing_Rank": 1844
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 54064,
   "Closing_Rank": 59119
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55951,
   "Closing_Rank": 55951
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9912,
   "Closing_Rank": 10456
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26911,
   "Closing_Rank": 30113
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 41960,
   "Closing_Rank": 41960
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10097,
   "Closing_Rank": 10489
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6247,
   "Closing_Rank": 6247
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 37781,
   "Closing_Rank": 40565
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51410,
   "Closing_Rank": 51410
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6705,
   "Closing_Rank": 6705
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11885,
   "Closing_Rank": 13637
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19455,
   "Closing_Rank": 19455
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5742,
   "Closing_Rank": 6770
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9074,
   "Closing_Rank": 9074
 },
 {
   "Institute": "National Institute of Technolog Uttarakhand",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2395,
   "Closing_Rank": 2395
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27094,
   "Closing_Rank": 39862
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29664,
   "Closing_Rank": 35190
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1316,
   "Closing_Rank": 1316
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6826,
   "Closing_Rank": 6933
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6430,
   "Closing_Rank": 6430
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11597,
   "Closing_Rank": 13402
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11613,
   "Closing_Rank": 11613
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6298,
   "Closing_Rank": 6988
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5210,
   "Closing_Rank": 5210
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1471,
   "Closing_Rank": 1471
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2574,
   "Closing_Rank": 2574
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15149,
   "Closing_Rank": 30707
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15370,
   "Closing_Rank": 22944
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4999,
   "Closing_Rank": 5332
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9626,
   "Closing_Rank": 12024
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12136,
   "Closing_Rank": 12177
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 369,
   "Closing_Rank": 369
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5989,
   "Closing_Rank": 6168
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7126,
   "Closing_Rank": 7126
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2314,
   "Closing_Rank": 2432
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4307,
   "Closing_Rank": 4307
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10578,
   "Closing_Rank": 26477
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23428,
   "Closing_Rank": 26391
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1303,
   "Closing_Rank": 1303
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2116,
   "Closing_Rank": 2116
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4184,
   "Closing_Rank": 4411
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4825,
   "Closing_Rank": 4825
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7065,
   "Closing_Rank": 9036
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9620,
   "Closing_Rank": 11061
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4706,
   "Closing_Rank": 5723
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5286,
   "Closing_Rank": 6200
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1178,
   "Closing_Rank": 1433
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1973,
   "Closing_Rank": 1973
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15610,
   "Closing_Rank": 18833
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20825,
   "Closing_Rank": 22134
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1252,
   "Closing_Rank": 1252
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3115,
   "Closing_Rank": 3453
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4281,
   "Closing_Rank": 4281
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5011,
   "Closing_Rank": 6696
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7319,
   "Closing_Rank": 9341
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 569,
   "Closing_Rank": 569
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1578,
   "Closing_Rank": 4538
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3687,
   "Closing_Rank": 4470
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1440,
   "Closing_Rank": 1498
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1925,
   "Closing_Rank": 1925
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44550,
   "Closing_Rank": 45516
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42267,
   "Closing_Rank": 42267
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2645,
   "Closing_Rank": 2645
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8801,
   "Closing_Rank": 8801
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17628,
   "Closing_Rank": 17955
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19635,
   "Closing_Rank": 19635
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9017,
   "Closing_Rank": 9017
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2946,
   "Closing_Rank": 2946
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25299,
   "Closing_Rank": 31513
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42773,
   "Closing_Rank": 42773
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7340,
   "Closing_Rank": 7340
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13490,
   "Closing_Rank": 14365
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7127,
   "Closing_Rank": 7127
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10523,
   "Closing_Rank": 10523
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1862,
   "Closing_Rank": 1862
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23188,
   "Closing_Rank": 29824
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 31822,
   "Closing_Rank": 36132
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 755,
   "Closing_Rank": 755
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4943,
   "Closing_Rank": 5137
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6071,
   "Closing_Rank": 6071
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8801,
   "Closing_Rank": 11042
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11070,
   "Closing_Rank": 11376
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3384,
   "Closing_Rank": 4052
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4168,
   "Closing_Rank": 4565
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 143,
   "Closing_Rank": 143
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 720,
   "Closing_Rank": 1080
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1469,
   "Closing_Rank": 1469
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19905,
   "Closing_Rank": 25857
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32645,
   "Closing_Rank": 36778
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 634,
   "Closing_Rank": 1112
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4107,
   "Closing_Rank": 4223
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6347,
   "Closing_Rank": 6347
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 268,
   "Closing_Rank": 268
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7161,
   "Closing_Rank": 8896
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11958,
   "Closing_Rank": 12950
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4245,
   "Closing_Rank": 4698
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4650,
   "Closing_Rank": 5422
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 793,
   "Closing_Rank": 1014
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1299,
   "Closing_Rank": 1299
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 787,
   "Closing_Rank": 3387
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4672,
   "Closing_Rank": 4695
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 202,
   "Closing_Rank": 202
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 561,
   "Closing_Rank": 592
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 778,
   "Closing_Rank": 778
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 659,
   "Closing_Rank": 985
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1612,
   "Closing_Rank": 1612
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 572,
   "Closing_Rank": 715
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 433,
   "Closing_Rank": 433
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 45,
   "Closing_Rank": 45
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 71,
   "Closing_Rank": 71
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 548,
   "Closing_Rank": 548
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2419,
   "Closing_Rank": 2948
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3262,
   "Closing_Rank": 3621
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 87,
   "Closing_Rank": 87
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 374,
   "Closing_Rank": 416
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 539,
   "Closing_Rank": 539
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 778,
   "Closing_Rank": 1066
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1322,
   "Closing_Rank": 1322
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85,
   "Closing_Rank": 85
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 360,
   "Closing_Rank": 484
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 743,
   "Closing_Rank": 743
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 176,
   "Closing_Rank": 176
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ( Artificial Intelligence &amp; Data Science) ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 435,
   "Closing_Rank": 435
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 755,
   "Closing_Rank": 2804
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1921,
   "Closing_Rank": 4683
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85,
   "Closing_Rank": 85
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 380,
   "Closing_Rank": 507
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 872,
   "Closing_Rank": 872
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 531,
   "Closing_Rank": 824
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1117,
   "Closing_Rank": 1389
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66,
   "Closing_Rank": 66
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 295,
   "Closing_Rank": 625
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 411,
   "Closing_Rank": 707
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 110,
   "Closing_Rank": 159
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 504,
   "Closing_Rank": 504
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5,
   "Closing_Rank": 5
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1043,
   "Closing_Rank": 2186
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3296,
   "Closing_Rank": 3609
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24,
   "Closing_Rank": 24
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 300,
   "Closing_Rank": 329
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 523,
   "Closing_Rank": 523
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 58,
   "Closing_Rank": 58
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 452,
   "Closing_Rank": 679
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 975,
   "Closing_Rank": 1257
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 68,
   "Closing_Rank": 68
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 255,
   "Closing_Rank": 409
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 668,
   "Closing_Rank": 683
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17,
   "Closing_Rank": 201
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 649,
   "Closing_Rank": 649
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6469,
   "Closing_Rank": 9887
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9310,
   "Closing_Rank": 12542
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 314,
   "Closing_Rank": 314
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1433,
   "Closing_Rank": 1681
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1930,
   "Closing_Rank": 1930
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2360,
   "Closing_Rank": 3669
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4235,
   "Closing_Rank": 4573
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 139,
   "Closing_Rank": 139
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 870,
   "Closing_Rank": 2348
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2509,
   "Closing_Rank": 2509
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 201,
   "Closing_Rank": 201
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 420,
   "Closing_Rank": 628
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 899,
   "Closing_Rank": 899
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6030,
   "Closing_Rank": 7851
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11501,
   "Closing_Rank": 13388
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 560,
   "Closing_Rank": 560
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1229,
   "Closing_Rank": 1435
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2500,
   "Closing_Rank": 2500
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1926,
   "Closing_Rank": 2805
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4364,
   "Closing_Rank": 4494
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 366,
   "Closing_Rank": 366
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1361,
   "Closing_Rank": 1835
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2951,
   "Closing_Rank": 3640
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 85,
   "Closing_Rank": 89
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 501,
   "Closing_Rank": 660
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1690,
   "Closing_Rank": 1690
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3393,
   "Closing_Rank": 5463
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5724,
   "Closing_Rank": 7241
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 780,
   "Closing_Rank": 836
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1122,
   "Closing_Rank": 1122
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27,
   "Closing_Rank": 27
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1359,
   "Closing_Rank": 2134
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2733,
   "Closing_Rank": 3143
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 84,
   "Closing_Rank": 84
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 727,
   "Closing_Rank": 1599
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 782,
   "Closing_Rank": 1544
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 276,
   "Closing_Rank": 311
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3637,
   "Closing_Rank": 5014
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7357,
   "Closing_Rank": 9308
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 165,
   "Closing_Rank": 165
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 700,
   "Closing_Rank": 734
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1391,
   "Closing_Rank": 1391
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1185,
   "Closing_Rank": 1736
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2334,
   "Closing_Rank": 3708
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 745,
   "Closing_Rank": 1025
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2218,
   "Closing_Rank": 2218
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 60,
   "Closing_Rank": 60
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 233,
   "Closing_Rank": 306
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 881,
   "Closing_Rank": 881
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10,
   "Closing_Rank": 10
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4321,
   "Closing_Rank": 6271
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7610,
   "Closing_Rank": 7613
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 208,
   "Closing_Rank": 208
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 876,
   "Closing_Rank": 891
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1789,
   "Closing_Rank": 2189
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2630,
   "Closing_Rank": 2630
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1413,
   "Closing_Rank": 1794
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2004,
   "Closing_Rank": 2004
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 406,
   "Closing_Rank": 406
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2108,
   "Closing_Rank": 5678
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8041,
   "Closing_Rank": 8041
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 303,
   "Closing_Rank": 303
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 840,
   "Closing_Rank": 840
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1434,
   "Closing_Rank": 1434
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1843,
   "Closing_Rank": 1881
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2479,
   "Closing_Rank": 2479
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 140,
   "Closing_Rank": 140
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1133,
   "Closing_Rank": 1250
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 486,
   "Closing_Rank": 486
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Electronics and Communication Engineering (VLSI Design and Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1288,
   "Closing_Rank": 1288
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 30763,
   "Closing_Rank": 44795
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43889,
   "Closing_Rank": 43889
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7359,
   "Closing_Rank": 7359
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14567,
   "Closing_Rank": 14567
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 826,
   "Closing_Rank": 826
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7848,
   "Closing_Rank": 8240
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4243,
   "Closing_Rank": 4243
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19576,
   "Closing_Rank": 23183
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 33298,
   "Closing_Rank": 33298
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4367,
   "Closing_Rank": 4367
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6106,
   "Closing_Rank": 7230
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16026,
   "Closing_Rank": 16026
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6132,
   "Closing_Rank": 6132
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2415,
   "Closing_Rank": 2415
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3403,
   "Closing_Rank": 4102
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6672,
   "Closing_Rank": 6672
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 599,
   "Closing_Rank": 599
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1089,
   "Closing_Rank": 1089
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 935,
   "Closing_Rank": 1223
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2138,
   "Closing_Rank": 2138
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 225,
   "Closing_Rank": 225
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1114,
   "Closing_Rank": 1264
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 381,
   "Closing_Rank": 381
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2617,
   "Closing_Rank": 3436
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4553,
   "Closing_Rank": 4553
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 490,
   "Closing_Rank": 490
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 750,
   "Closing_Rank": 936
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2286,
   "Closing_Rank": 2286
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 748,
   "Closing_Rank": 862
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1427,
   "Closing_Rank": 1427
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 375,
   "Closing_Rank": 375
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10334,
   "Closing_Rank": 17079
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16605,
   "Closing_Rank": 23288
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 393,
   "Closing_Rank": 393
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2631,
   "Closing_Rank": 2962
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4851,
   "Closing_Rank": 4851
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4466,
   "Closing_Rank": 5905
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7449,
   "Closing_Rank": 9828
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 357,
   "Closing_Rank": 357
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2192,
   "Closing_Rank": 3806
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4336,
   "Closing_Rank": 4589
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1176,
   "Closing_Rank": 1456
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 585,
   "Closing_Rank": 585
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11580,
   "Closing_Rank": 14333
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19168,
   "Closing_Rank": 25047
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1214,
   "Closing_Rank": 1214
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2122,
   "Closing_Rank": 2359
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4278,
   "Closing_Rank": 4278
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 213,
   "Closing_Rank": 213
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3904,
   "Closing_Rank": 5307
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6694,
   "Closing_Rank": 10048
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 709,
   "Closing_Rank": 709
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2224,
   "Closing_Rank": 2818
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4065,
   "Closing_Rank": 4294
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1323,
   "Closing_Rank": 1405
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2015,
   "Closing_Rank": 2015
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29668,
   "Closing_Rank": 39273
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30711,
   "Closing_Rank": 34591
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1749,
   "Closing_Rank": 2011
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2277,
   "Closing_Rank": 2277
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6917,
   "Closing_Rank": 7272
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7000,
   "Closing_Rank": 7000
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11171,
   "Closing_Rank": 13164
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11515,
   "Closing_Rank": 12205
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4102,
   "Closing_Rank": 5931
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5727,
   "Closing_Rank": 5727
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1817,
   "Closing_Rank": 1843
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25037,
   "Closing_Rank": 28135
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29050,
   "Closing_Rank": 33978
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1973,
   "Closing_Rank": 1973
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4479,
   "Closing_Rank": 4549
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6890,
   "Closing_Rank": 6890
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9382,
   "Closing_Rank": 10309
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13934,
   "Closing_Rank": 14476
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5334,
   "Closing_Rank": 6154
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5569,
   "Closing_Rank": 5569
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1791,
   "Closing_Rank": 2157
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3356,
   "Closing_Rank": 3356
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31119,
   "Closing_Rank": 37580
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52205,
   "Closing_Rank": 52205
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7682,
   "Closing_Rank": 7682
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12137,
   "Closing_Rank": 14403
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7351,
   "Closing_Rank": 7351
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1658,
   "Closing_Rank": 1658
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4351,
   "Closing_Rank": 4351
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11345,
   "Closing_Rank": 22490
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24447,
   "Closing_Rank": 24447
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5716,
   "Closing_Rank": 5716
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9982,
   "Closing_Rank": 12401
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16120,
   "Closing_Rank": 16120
 },
 {
   "Institute": "National Institute of Technolog Warangal",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4609,
   "Closing_Rank": 4609
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6960,
   "Closing_Rank": 10289
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10282,
   "Closing_Rank": 19691
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 944,
   "Closing_Rank": 944
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1538,
   "Closing_Rank": 1782
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3303,
   "Closing_Rank": 3303
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3888,
   "Closing_Rank": 5521
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9952,
   "Closing_Rank": 17542
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1379,
   "Closing_Rank": 1379
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1285,
   "Closing_Rank": 2777
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3880,
   "Closing_Rank": 3880
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 152,
   "Closing_Rank": 152
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 830,
   "Closing_Rank": 1353
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1364,
   "Closing_Rank": 1364
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6925,
   "Closing_Rank": 9454
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9014,
   "Closing_Rank": 15050
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 529,
   "Closing_Rank": 529
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1405,
   "Closing_Rank": 1599
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2657,
   "Closing_Rank": 2657
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2451,
   "Closing_Rank": 3750
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7190,
   "Closing_Rank": 7364
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 219,
   "Closing_Rank": 219
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2123,
   "Closing_Rank": 2571
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2533,
   "Closing_Rank": 2617
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 72,
   "Closing_Rank": 72
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 431,
   "Closing_Rank": 944
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1482,
   "Closing_Rank": 1482
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10459,
   "Closing_Rank": 12529
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15428,
   "Closing_Rank": 15428
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2098,
   "Closing_Rank": 2098
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5671,
   "Closing_Rank": 6042
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16271,
   "Closing_Rank": 16271
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3380,
   "Closing_Rank": 3633
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8762,
   "Closing_Rank": 8762
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1372,
   "Closing_Rank": 1372
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10624,
   "Closing_Rank": 12267
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19369,
   "Closing_Rank": 19369
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1821,
   "Closing_Rank": 1821
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4055,
   "Closing_Rank": 4524
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9158,
   "Closing_Rank": 9158
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2520,
   "Closing_Rank": 2758
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5217,
   "Closing_Rank": 5217
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Artificial Intelligence ",
   "duration": "5 Years",
   "Degree_Type": " Integrated B. Tech. and M. Tech.)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1200,
   "Closing_Rank": 1200
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27439,
   "Closing_Rank": 38431
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36745,
   "Closing_Rank": 43891
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6187,
   "Closing_Rank": 7002
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8188,
   "Closing_Rank": 8188
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11920,
   "Closing_Rank": 21031
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 28751,
   "Closing_Rank": 36394
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4755,
   "Closing_Rank": 9463
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6613,
   "Closing_Rank": 9402
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1305,
   "Closing_Rank": 2743
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1503,
   "Closing_Rank": 1503
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26451,
   "Closing_Rank": 34327
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32907,
   "Closing_Rank": 39516
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3063,
   "Closing_Rank": 3063
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5527,
   "Closing_Rank": 5939
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6349,
   "Closing_Rank": 6349
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10793,
   "Closing_Rank": 12100
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15296,
   "Closing_Rank": 16830
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1395,
   "Closing_Rank": 1395
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6058,
   "Closing_Rank": 6869
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7516,
   "Closing_Rank": 8295
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2236,
   "Closing_Rank": 2416
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2708,
   "Closing_Rank": 2708
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49977,
   "Closing_Rank": 74869
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14044,
   "Closing_Rank": 14226
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 41509,
   "Closing_Rank": 66368
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17885,
   "Closing_Rank": 23503
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6831,
   "Closing_Rank": 9357
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39815,
   "Closing_Rank": 54901
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9170,
   "Closing_Rank": 9315
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18187,
   "Closing_Rank": 19040
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1649,
   "Closing_Rank": 1649
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8733,
   "Closing_Rank": 9518
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Chemistry ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3495,
   "Closing_Rank": 3572
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17078,
   "Closing_Rank": 44474
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 36264,
   "Closing_Rank": 55500
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1306,
   "Closing_Rank": 1306
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7173,
   "Closing_Rank": 7419
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9595,
   "Closing_Rank": 9595
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21192,
   "Closing_Rank": 28007
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26927,
   "Closing_Rank": 41049
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5845,
   "Closing_Rank": 12306
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13581,
   "Closing_Rank": 14789
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 796,
   "Closing_Rank": 3005
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2817,
   "Closing_Rank": 2817
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33336,
   "Closing_Rank": 42166
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44649,
   "Closing_Rank": 46667
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1381,
   "Closing_Rank": 1381
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1756,
   "Closing_Rank": 1756
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6989,
   "Closing_Rank": 7092
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7622,
   "Closing_Rank": 7622
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 496,
   "Closing_Rank": 496
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12331,
   "Closing_Rank": 13766
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18354,
   "Closing_Rank": 19805
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5686,
   "Closing_Rank": 6942
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9232,
   "Closing_Rank": 9306
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1256,
   "Closing_Rank": 1674
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2373,
   "Closing_Rank": 2373
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3106,
   "Closing_Rank": 6827
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2821,
   "Closing_Rank": 10759
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 225,
   "Closing_Rank": 225
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1002,
   "Closing_Rank": 1164
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1717,
   "Closing_Rank": 1717
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 103,
   "Closing_Rank": 103
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1529,
   "Closing_Rank": 3543
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2684,
   "Closing_Rank": 3871
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 188,
   "Closing_Rank": 188
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 511,
   "Closing_Rank": 1183
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 682,
   "Closing_Rank": 2203
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 295,
   "Closing_Rank": 718
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 625,
   "Closing_Rank": 625
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5587,
   "Closing_Rank": 7113
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10021,
   "Closing_Rank": 11508
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 362,
   "Closing_Rank": 362
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1006,
   "Closing_Rank": 1079
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1810,
   "Closing_Rank": 1810
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1820,
   "Closing_Rank": 2911
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4269,
   "Closing_Rank": 5796
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 175,
   "Closing_Rank": 175
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1670,
   "Closing_Rank": 1926
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1770,
   "Closing_Rank": 1984
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 62,
   "Closing_Rank": 62
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 530,
   "Closing_Rank": 568
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1434,
   "Closing_Rank": 1434
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 23,
   "Closing_Rank": 23
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14510,
   "Closing_Rank": 21713
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23383,
   "Closing_Rank": 29969
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3400,
   "Closing_Rank": 3792
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6480,
   "Closing_Rank": 6480
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8468,
   "Closing_Rank": 11928
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22186,
   "Closing_Rank": 29326
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2544,
   "Closing_Rank": 7791
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4783,
   "Closing_Rank": 7110
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1414,
   "Closing_Rank": 2264
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2520,
   "Closing_Rank": 2520
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15845,
   "Closing_Rank": 19425
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21695,
   "Closing_Rank": 24722
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1134,
   "Closing_Rank": 1134
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3078,
   "Closing_Rank": 3322
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4446,
   "Closing_Rank": 4446
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5614,
   "Closing_Rank": 6864
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9713,
   "Closing_Rank": 12649
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3126,
   "Closing_Rank": 3920
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6110,
   "Closing_Rank": 6792
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1347,
   "Closing_Rank": 1708
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2001,
   "Closing_Rank": 2001
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 59,
   "Closing_Rank": 59
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7702,
   "Closing_Rank": 14376
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16037,
   "Closing_Rank": 20293
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2214,
   "Closing_Rank": 2326
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3863,
   "Closing_Rank": 3863
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4636,
   "Closing_Rank": 6938
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8620,
   "Closing_Rank": 16317
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1765,
   "Closing_Rank": 3496
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3203,
   "Closing_Rank": 3728
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1575,
   "Closing_Rank": 1774
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1556,
   "Closing_Rank": 1556
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9908,
   "Closing_Rank": 13942
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16061,
   "Closing_Rank": 19933
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 406,
   "Closing_Rank": 406
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2116,
   "Closing_Rank": 2338
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3086,
   "Closing_Rank": 3086
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 127,
   "Closing_Rank": 127
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3833,
   "Closing_Rank": 5084
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8002,
   "Closing_Rank": 9282
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 348,
   "Closing_Rank": 348
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2906,
   "Closing_Rank": 3338
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4946,
   "Closing_Rank": 5180
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1034,
   "Closing_Rank": 1159
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1248,
   "Closing_Rank": 1248
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13158,
   "Closing_Rank": 16775
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21745,
   "Closing_Rank": 22994
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2906,
   "Closing_Rank": 2995
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7087,
   "Closing_Rank": 9196
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16486,
   "Closing_Rank": 16486
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4193,
   "Closing_Rank": 5008
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5255,
   "Closing_Rank": 5255
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2343,
   "Closing_Rank": 2343
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4722,
   "Closing_Rank": 4722
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13519,
   "Closing_Rank": 16600
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22633,
   "Closing_Rank": 23295
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1105,
   "Closing_Rank": 1105
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2708,
   "Closing_Rank": 2733
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4756,
   "Closing_Rank": 4756
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5269,
   "Closing_Rank": 6113
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10537,
   "Closing_Rank": 10537
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2193,
   "Closing_Rank": 3701
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5551,
   "Closing_Rank": 5551
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1460,
   "Closing_Rank": 1460
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Electronics and VLSI Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2727,
   "Closing_Rank": 2727
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7284,
   "Closing_Rank": 35992
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 43741,
   "Closing_Rank": 43741
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8641,
   "Closing_Rank": 8737
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25601,
   "Closing_Rank": 29100
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30048,
   "Closing_Rank": 30048
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11975,
   "Closing_Rank": 11975
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12268,
   "Closing_Rank": 12268
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2540,
   "Closing_Rank": 2540
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12447,
   "Closing_Rank": 24363
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 34876,
   "Closing_Rank": 34876
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4928,
   "Closing_Rank": 4928
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11388,
   "Closing_Rank": 12743
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16395,
   "Closing_Rank": 16395
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5029,
   "Closing_Rank": 6222
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8729,
   "Closing_Rank": 8729
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Engineering Physics ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2956,
   "Closing_Rank": 2956
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42069,
   "Closing_Rank": 49761
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44819,
   "Closing_Rank": 44819
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8422,
   "Closing_Rank": 8422
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29419,
   "Closing_Rank": 30775
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59079,
   "Closing_Rank": 59079
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12751,
   "Closing_Rank": 12751
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9601,
   "Closing_Rank": 9601
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5603,
   "Closing_Rank": 5603
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 44273,
   "Closing_Rank": 46870
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52698,
   "Closing_Rank": 52698
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3431,
   "Closing_Rank": 3431
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7761,
   "Closing_Rank": 7980
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14363,
   "Closing_Rank": 14821
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22139,
   "Closing_Rank": 22139
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6971,
   "Closing_Rank": 6971
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11198,
   "Closing_Rank": 11198
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Industrial Chemistry ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3234,
   "Closing_Rank": 3234
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33517,
   "Closing_Rank": 63356
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10431,
   "Closing_Rank": 10702
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31173,
   "Closing_Rank": 64532
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14220,
   "Closing_Rank": 19908
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3741,
   "Closing_Rank": 3992
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 21547,
   "Closing_Rank": 45094
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 246,
   "Closing_Rank": 246
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7648,
   "Closing_Rank": 7963
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14654,
   "Closing_Rank": 17056
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8045,
   "Closing_Rank": 8841
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3323,
   "Closing_Rank": 3402
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7495,
   "Closing_Rank": 9379
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16644,
   "Closing_Rank": 16644
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1326,
   "Closing_Rank": 1352
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2596,
   "Closing_Rank": 5484
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4354,
   "Closing_Rank": 4354
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1416,
   "Closing_Rank": 1416
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2867,
   "Closing_Rank": 2867
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 769,
   "Closing_Rank": 769
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7683,
   "Closing_Rank": 9746
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15060,
   "Closing_Rank": 15060
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1831,
   "Closing_Rank": 1831
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3991,
   "Closing_Rank": 4073
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8145,
   "Closing_Rank": 8145
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 306,
   "Closing_Rank": 306
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2788,
   "Closing_Rank": 2788
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4412,
   "Closing_Rank": 4412
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mathematics and Computing ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 904,
   "Closing_Rank": 904
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11061,
   "Closing_Rank": 28844
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24403,
   "Closing_Rank": 44522
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4625,
   "Closing_Rank": 6013
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8325,
   "Closing_Rank": 8857
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10724,
   "Closing_Rank": 21559
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22168,
   "Closing_Rank": 33138
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8669,
   "Closing_Rank": 11963
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7992,
   "Closing_Rank": 12004
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1709,
   "Closing_Rank": 2898
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1850,
   "Closing_Rank": 3245
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17989,
   "Closing_Rank": 27163
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24826,
   "Closing_Rank": 39305
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2308,
   "Closing_Rank": 2559
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2810,
   "Closing_Rank": 2810
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4326,
   "Closing_Rank": 4854
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6381,
   "Closing_Rank": 6625
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 412,
   "Closing_Rank": 412
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7405,
   "Closing_Rank": 10419
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15425,
   "Closing_Rank": 18189
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1192,
   "Closing_Rank": 1192
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4005,
   "Closing_Rank": 6061
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8730,
   "Closing_Rank": 9112
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 646,
   "Closing_Rank": 2096
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3465,
   "Closing_Rank": 3683
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24904,
   "Closing_Rank": 56712
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11195,
   "Closing_Rank": 12701
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33642,
   "Closing_Rank": 58883
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14555,
   "Closing_Rank": 23150
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10132,
   "Closing_Rank": 10368
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27103,
   "Closing_Rank": 43334
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7325,
   "Closing_Rank": 8014
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 283,
   "Closing_Rank": 283
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13332,
   "Closing_Rank": 18352
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8560,
   "Closing_Rank": 9139
 },
 {
   "Institute": "Sardar Vallabhbhai National Institute of Technolog Surat",
   "Academic_Program_Name": "Physics ",
   "duration": "5 Years",
   "Degree_Type": " Integrated Master of Science)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3230,
   "Closing_Rank": 3230
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 265,
   "Closing_Rank": 1350
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 540,
   "Closing_Rank": 683
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 102,
   "Closing_Rank": 102
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 572,
   "Closing_Rank": 725
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 159,
   "Closing_Rank": 159
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 539,
   "Closing_Rank": 1089
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 552,
   "Closing_Rank": 785
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 338,
   "Closing_Rank": 441
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 190,
   "Closing_Rank": 383
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 191,
   "Closing_Rank": 367
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 537,
   "Closing_Rank": 1002
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 224,
   "Closing_Rank": 495
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 357,
   "Closing_Rank": 398
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 164,
   "Closing_Rank": 164
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 376,
   "Closing_Rank": 659
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 399,
   "Closing_Rank": 489
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 225,
   "Closing_Rank": 319
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 115,
   "Closing_Rank": 166
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 180,
   "Closing_Rank": 217
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 27294,
   "Closing_Rank": 33853
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29914,
   "Closing_Rank": 37856
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1494,
   "Closing_Rank": 1494
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5497,
   "Closing_Rank": 5772
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6302,
   "Closing_Rank": 6302
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9678,
   "Closing_Rank": 12042
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12010,
   "Closing_Rank": 14595
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4312,
   "Closing_Rank": 6564
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7834,
   "Closing_Rank": 7834
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3319,
   "Closing_Rank": 3675
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3042,
   "Closing_Rank": 3042
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 24181,
   "Closing_Rank": 28900
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29901,
   "Closing_Rank": 36394
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2016,
   "Closing_Rank": 2120
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4605,
   "Closing_Rank": 5141
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6828,
   "Closing_Rank": 6828
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9238,
   "Closing_Rank": 10597
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11710,
   "Closing_Rank": 14936
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1179,
   "Closing_Rank": 1179
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5069,
   "Closing_Rank": 5899
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6511,
   "Closing_Rank": 7093
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1742,
   "Closing_Rank": 2086
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3065,
   "Closing_Rank": 3065
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25756,
   "Closing_Rank": 40606
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 27131,
   "Closing_Rank": 49110
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 400,
   "Closing_Rank": 400
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6708,
   "Closing_Rank": 7481
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8463,
   "Closing_Rank": 8463
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 509,
   "Closing_Rank": 509
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12145,
   "Closing_Rank": 15638
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16425,
   "Closing_Rank": 18032
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5307,
   "Closing_Rank": 8211
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10110,
   "Closing_Rank": 10110
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1704,
   "Closing_Rank": 3385
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3780,
   "Closing_Rank": 3780
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29465,
   "Closing_Rank": 36655
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44880,
   "Closing_Rank": 46899
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2286,
   "Closing_Rank": 2286
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5937,
   "Closing_Rank": 6109
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7625,
   "Closing_Rank": 7670
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 392,
   "Closing_Rank": 430
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10706,
   "Closing_Rank": 12302
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15945,
   "Closing_Rank": 18057
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1047,
   "Closing_Rank": 1047
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5119,
   "Closing_Rank": 5784
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6433,
   "Closing_Rank": 7618
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1184,
   "Closing_Rank": 1231
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2755,
   "Closing_Rank": 2755
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5196,
   "Closing_Rank": 7583
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8334,
   "Closing_Rank": 12192
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 108,
   "Closing_Rank": 108
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1153,
   "Closing_Rank": 1520
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3011,
   "Closing_Rank": 3011
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1727,
   "Closing_Rank": 2481
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4414,
   "Closing_Rank": 6084
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 93,
   "Closing_Rank": 93
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 547,
   "Closing_Rank": 1430
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 773,
   "Closing_Rank": 1540
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 618,
   "Closing_Rank": 919
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 921,
   "Closing_Rank": 921
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4555,
   "Closing_Rank": 5795
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7112,
   "Closing_Rank": 9039
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 251,
   "Closing_Rank": 251
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 791,
   "Closing_Rank": 813
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1398,
   "Closing_Rank": 1398
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42,
   "Closing_Rank": 42
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1674,
   "Closing_Rank": 2049
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3179,
   "Closing_Rank": 3594
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 148,
   "Closing_Rank": 148
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 946,
   "Closing_Rank": 1610
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1773,
   "Closing_Rank": 1773
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 66,
   "Closing_Rank": 66
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 353,
   "Closing_Rank": 376
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 565,
   "Closing_Rank": 565
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11915,
   "Closing_Rank": 16773
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19560,
   "Closing_Rank": 23986
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 567,
   "Closing_Rank": 580
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2630,
   "Closing_Rank": 3136
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4423,
   "Closing_Rank": 4423
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 291,
   "Closing_Rank": 291
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5185,
   "Closing_Rank": 6651
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9088,
   "Closing_Rank": 10456
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 565,
   "Closing_Rank": 565
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2979,
   "Closing_Rank": 3974
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3711,
   "Closing_Rank": 3711
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 266,
   "Closing_Rank": 266
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1065,
   "Closing_Rank": 2313
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1738,
   "Closing_Rank": 1738
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10226,
   "Closing_Rank": 14761
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 15851,
   "Closing_Rank": 21015
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 623,
   "Closing_Rank": 623
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2244,
   "Closing_Rank": 2588
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3625,
   "Closing_Rank": 3625
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3914,
   "Closing_Rank": 5476
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6707,
   "Closing_Rank": 8925
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 420,
   "Closing_Rank": 420
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2695,
   "Closing_Rank": 3393
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5424,
   "Closing_Rank": 5424
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 142,
   "Closing_Rank": 142
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 749,
   "Closing_Rank": 1092
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1753,
   "Closing_Rank": 1753
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8178,
   "Closing_Rank": 11804
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13183,
   "Closing_Rank": 17121
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 301,
   "Closing_Rank": 304
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1939,
   "Closing_Rank": 2183
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3087,
   "Closing_Rank": 3087
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2810,
   "Closing_Rank": 5027
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7909,
   "Closing_Rank": 8307
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 257,
   "Closing_Rank": 257
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1755,
   "Closing_Rank": 2731
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2306,
   "Closing_Rank": 3769
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 594,
   "Closing_Rank": 2422
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1030,
   "Closing_Rank": 1030
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6740,
   "Closing_Rank": 9858
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11512,
   "Closing_Rank": 14361
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 608,
   "Closing_Rank": 608
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1414,
   "Closing_Rank": 1684
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2166,
   "Closing_Rank": 2166
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2821,
   "Closing_Rank": 3789
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4817,
   "Closing_Rank": 5043
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 294,
   "Closing_Rank": 294
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1853,
   "Closing_Rank": 2469
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3078,
   "Closing_Rank": 3109
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 398,
   "Closing_Rank": 672
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1247,
   "Closing_Rank": 1247
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16064,
   "Closing_Rank": 23543
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 24414,
   "Closing_Rank": 34428
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1803,
   "Closing_Rank": 1803
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3725,
   "Closing_Rank": 4277
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5524,
   "Closing_Rank": 5524
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6735,
   "Closing_Rank": 8779
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11287,
   "Closing_Rank": 13039
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 840,
   "Closing_Rank": 840
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2874,
   "Closing_Rank": 4885
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8349,
   "Closing_Rank": 8662
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2678,
   "Closing_Rank": 3160
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4408,
   "Closing_Rank": 4408
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20084,
   "Closing_Rank": 23108
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30997,
   "Closing_Rank": 35972
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 426,
   "Closing_Rank": 426
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3616,
   "Closing_Rank": 3727
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6389,
   "Closing_Rank": 6389
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7040,
   "Closing_Rank": 8219
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11514,
   "Closing_Rank": 11696
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 560,
   "Closing_Rank": 560
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3613,
   "Closing_Rank": 5102
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6820,
   "Closing_Rank": 7215
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1492,
   "Closing_Rank": 1584
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2230,
   "Closing_Rank": 2230
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36006,
   "Closing_Rank": 45286
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 39285,
   "Closing_Rank": 50945
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1872,
   "Closing_Rank": 1872
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7826,
   "Closing_Rank": 8120
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9127,
   "Closing_Rank": 9209
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14500,
   "Closing_Rank": 18336
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19268,
   "Closing_Rank": 23269
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1086,
   "Closing_Rank": 1086
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7735,
   "Closing_Rank": 9749
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6065,
   "Closing_Rank": 7624
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3886,
   "Closing_Rank": 3933
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3689,
   "Closing_Rank": 3689
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 35542,
   "Closing_Rank": 42379
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48089,
   "Closing_Rank": 52484
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2702,
   "Closing_Rank": 2706
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6934,
   "Closing_Rank": 7282
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9263,
   "Closing_Rank": 9263
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12270,
   "Closing_Rank": 14017
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16209,
   "Closing_Rank": 19451
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6742,
   "Closing_Rank": 7441
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7803,
   "Closing_Rank": 7803
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2262,
   "Closing_Rank": 2687
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3019,
   "Closing_Rank": 3019
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46460,
   "Closing_Rank": 49561
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 55945,
   "Closing_Rank": 60977
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8193,
   "Closing_Rank": 8271
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15085,
   "Closing_Rank": 18445
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20902,
   "Closing_Rank": 24984
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8512,
   "Closing_Rank": 9930
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10852,
   "Closing_Rank": 10852
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4664,
   "Closing_Rank": 4806
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 29535,
   "Closing_Rank": 44834
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 53308,
   "Closing_Rank": 55854
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7382,
   "Closing_Rank": 7494
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13797,
   "Closing_Rank": 14531
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19925,
   "Closing_Rank": 20165
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6444,
   "Closing_Rank": 7573
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9555,
   "Closing_Rank": 9555
 },
 {
   "Institute": "Visvesvaraya National Institute of Technolog Nagpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2570,
   "Closing_Rank": 2833
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49880,
   "Closing_Rank": 57705
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 47868,
   "Closing_Rank": 50296
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9584,
   "Closing_Rank": 9584
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18458,
   "Closing_Rank": 18882
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20213,
   "Closing_Rank": 20213
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8119,
   "Closing_Rank": 8269
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3340,
   "Closing_Rank": 3340
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49212,
   "Closing_Rank": 51528
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38473,
   "Closing_Rank": 38473
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8861,
   "Closing_Rank": 8861
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10618,
   "Closing_Rank": 10618
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15796,
   "Closing_Rank": 17323
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17484,
   "Closing_Rank": 17484
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 895,
   "Closing_Rank": 1006
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8406,
   "Closing_Rank": 8947
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Bio Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3660,
   "Closing_Rank": 3660
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48170,
   "Closing_Rank": 51854
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 52600,
   "Closing_Rank": 52600
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1944,
   "Closing_Rank": 2165
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8619,
   "Closing_Rank": 8619
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8897,
   "Closing_Rank": 8897
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15701,
   "Closing_Rank": 16203
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19285,
   "Closing_Rank": 19285
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7299,
   "Closing_Rank": 7610
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3190,
   "Closing_Rank": 3190
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39826,
   "Closing_Rank": 45161
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45529,
   "Closing_Rank": 45529
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7535,
   "Closing_Rank": 7535
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9938,
   "Closing_Rank": 9938
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 14323,
   "Closing_Rank": 15529
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18824,
   "Closing_Rank": 18824
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7337,
   "Closing_Rank": 7567
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Chemical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2808,
   "Closing_Rank": 2808
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46504,
   "Closing_Rank": 51738
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 56517,
   "Closing_Rank": 57487
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8541,
   "Closing_Rank": 8842
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9386,
   "Closing_Rank": 9386
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15574,
   "Closing_Rank": 17782
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20277,
   "Closing_Rank": 20277
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5580,
   "Closing_Rank": 7995
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8811,
   "Closing_Rank": 8811
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2864,
   "Closing_Rank": 2970
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 46501,
   "Closing_Rank": 50048
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 54758,
   "Closing_Rank": 59247
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1449,
   "Closing_Rank": 1449
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8081,
   "Closing_Rank": 8129
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9982,
   "Closing_Rank": 9982
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15874,
   "Closing_Rank": 16352
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18239,
   "Closing_Rank": 18239
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1318,
   "Closing_Rank": 1318
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6839,
   "Closing_Rank": 7566
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8016,
   "Closing_Rank": 8016
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2254,
   "Closing_Rank": 2471
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8043,
   "Closing_Rank": 14707
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4033,
   "Closing_Rank": 16697
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 210,
   "Closing_Rank": 210
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2204,
   "Closing_Rank": 2258
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2982,
   "Closing_Rank": 2982
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 164,
   "Closing_Rank": 164
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3898,
   "Closing_Rank": 6509
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6840,
   "Closing_Rank": 9041
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1342,
   "Closing_Rank": 2809
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1019,
   "Closing_Rank": 1019
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 795,
   "Closing_Rank": 1418
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 698,
   "Closing_Rank": 698
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10309,
   "Closing_Rank": 18548
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 20759,
   "Closing_Rank": 24130
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 347,
   "Closing_Rank": 347
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2913,
   "Closing_Rank": 3029
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4106,
   "Closing_Rank": 4106
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4822,
   "Closing_Rank": 6595
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6915,
   "Closing_Rank": 8347
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 158,
   "Closing_Rank": 158
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2721,
   "Closing_Rank": 3468
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2642,
   "Closing_Rank": 2642
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49,
   "Closing_Rank": 49
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 817,
   "Closing_Rank": 940
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 865,
   "Closing_Rank": 865
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26924,
   "Closing_Rank": 37297
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26966,
   "Closing_Rank": 34389
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 936,
   "Closing_Rank": 1330
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6053,
   "Closing_Rank": 6091
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6865,
   "Closing_Rank": 6865
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 293,
   "Closing_Rank": 293
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11147,
   "Closing_Rank": 13239
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12956,
   "Closing_Rank": 13822
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4579,
   "Closing_Rank": 6117
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6399,
   "Closing_Rank": 6399
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1990,
   "Closing_Rank": 2044
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3641,
   "Closing_Rank": 3641
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 28472,
   "Closing_Rank": 34473
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38521,
   "Closing_Rank": 41210
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1379,
   "Closing_Rank": 1379
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5739,
   "Closing_Rank": 6393
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7820,
   "Closing_Rank": 7820
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9928,
   "Closing_Rank": 12197
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14610,
   "Closing_Rank": 15756
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 871,
   "Closing_Rank": 871
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3672,
   "Closing_Rank": 6402
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7463,
   "Closing_Rank": 7463
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1953,
   "Closing_Rank": 1953
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2330,
   "Closing_Rank": 2330
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electrical and Electronics Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 38,
   "Closing_Rank": 52
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18177,
   "Closing_Rank": 24588
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21132,
   "Closing_Rank": 23576
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 576,
   "Closing_Rank": 576
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3942,
   "Closing_Rank": 4081
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3927,
   "Closing_Rank": 3927
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6819,
   "Closing_Rank": 8736
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7202,
   "Closing_Rank": 9548
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 641,
   "Closing_Rank": 641
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3172,
   "Closing_Rank": 4768
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3966,
   "Closing_Rank": 3966
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1801,
   "Closing_Rank": 1876
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2685,
   "Closing_Rank": 2685
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19421,
   "Closing_Rank": 25707
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25501,
   "Closing_Rank": 31426
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 508,
   "Closing_Rank": 508
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4212,
   "Closing_Rank": 4387
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5278,
   "Closing_Rank": 5278
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7495,
   "Closing_Rank": 9042
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9338,
   "Closing_Rank": 9544
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2462,
   "Closing_Rank": 4867
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5100,
   "Closing_Rank": 5100
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 282,
   "Closing_Rank": 282
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1550,
   "Closing_Rank": 1865
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Electronics and Communication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2384,
   "Closing_Rank": 2384
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 31092,
   "Closing_Rank": 42921
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 45416,
   "Closing_Rank": 50311
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1740,
   "Closing_Rank": 1740
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6910,
   "Closing_Rank": 7063
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8328,
   "Closing_Rank": 8328
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13005,
   "Closing_Rank": 14502
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18678,
   "Closing_Rank": 18678
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6843,
   "Closing_Rank": 7418
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9399,
   "Closing_Rank": 9399
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2679,
   "Closing_Rank": 2709
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 34097,
   "Closing_Rank": 41191
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38752,
   "Closing_Rank": 50950
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6899,
   "Closing_Rank": 7248
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8604,
   "Closing_Rank": 8604
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12246,
   "Closing_Rank": 13445
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18463,
   "Closing_Rank": 18463
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1158,
   "Closing_Rank": 1158
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6064,
   "Closing_Rank": 7318
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7827,
   "Closing_Rank": 7827
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2107,
   "Closing_Rank": 2130
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 51861,
   "Closing_Rank": 57008
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59744,
   "Closing_Rank": 59744
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3225,
   "Closing_Rank": 3225
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 9364,
   "Closing_Rank": 9364
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 11306,
   "Closing_Rank": 11306
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17629,
   "Closing_Rank": 18395
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21030,
   "Closing_Rank": 21030
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6750,
   "Closing_Rank": 9382
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3354,
   "Closing_Rank": 3354
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 49440,
   "Closing_Rank": 51428
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 59618,
   "Closing_Rank": 59618
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8708,
   "Closing_Rank": 8708
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15214,
   "Closing_Rank": 17407
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19607,
   "Closing_Rank": 19607
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8164,
   "Closing_Rank": 8341
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10128,
   "Closing_Rank": 10128
 },
 {
   "Institute": "National Institute of Technolog Andhra Pradesh",
   "Academic_Program_Name": "Metallurgical and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3162,
   "Closing_Rank": 3162
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20875,
   "Closing_Rank": 32833
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38974,
   "Closing_Rank": 44531
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1502,
   "Closing_Rank": 1502
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11077,
   "Closing_Rank": 11077
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 19680,
   "Closing_Rank": 25727
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25263,
   "Closing_Rank": 25263
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5240,
   "Closing_Rank": 5724
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8345,
   "Closing_Rank": 8345
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4048,
   "Closing_Rank": 4048
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8917,
   "Closing_Rank": 8917
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10762,
   "Closing_Rank": 21831
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35307,
   "Closing_Rank": 36197
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3770,
   "Closing_Rank": 4535
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7259,
   "Closing_Rank": 7259
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6682,
   "Closing_Rank": 7881
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 14899,
   "Closing_Rank": 14899
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 100,
   "Closing_Rank": 100
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1954,
   "Closing_Rank": 3257
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4137,
   "Closing_Rank": 4137
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Aerospace Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1739,
   "Closing_Rank": 1739
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 194,
   "Closing_Rank": 527
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 219,
   "Closing_Rank": 219
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1654,
   "Closing_Rank": 1654
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2475,
   "Closing_Rank": 2768
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 713,
   "Closing_Rank": 713
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 132,
   "Closing_Rank": 132
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 272,
   "Closing_Rank": 272
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 119,
   "Closing_Rank": 119
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1115,
   "Closing_Rank": 1576
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 999,
   "Closing_Rank": 999
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 505,
   "Closing_Rank": 724
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 533,
   "Closing_Rank": 533
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 259,
   "Closing_Rank": 259
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Architecture  ",
   "duration": "5 Years",
   "Degree_Type": " Bachelor of Architecture)",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 123,
   "Closing_Rank": 123
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 33868,
   "Closing_Rank": 50745
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 48985,
   "Closing_Rank": 56828
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1632,
   "Closing_Rank": 1632
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8321,
   "Closing_Rank": 14721
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 32685,
   "Closing_Rank": 32685
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 18645,
   "Closing_Rank": 37820
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 44475,
   "Closing_Rank": 49449
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6063,
   "Closing_Rank": 9270
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8407,
   "Closing_Rank": 10331
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4356,
   "Closing_Rank": 10635
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13918,
   "Closing_Rank": 13918
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36890,
   "Closing_Rank": 49591
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 51516,
   "Closing_Rank": 59688
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2955,
   "Closing_Rank": 2955
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8018,
   "Closing_Rank": 8213
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9960,
   "Closing_Rank": 9960
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15169,
   "Closing_Rank": 16884
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19244,
   "Closing_Rank": 21112
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7554,
   "Closing_Rank": 8382
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10356,
   "Closing_Rank": 10436
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1787,
   "Closing_Rank": 2056
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Civil Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2787,
   "Closing_Rank": 2787
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8066,
   "Closing_Rank": 15062
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10218,
   "Closing_Rank": 18284
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 124,
   "Closing_Rank": 124
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3089,
   "Closing_Rank": 3832
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6544,
   "Closing_Rank": 6544
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6319,
   "Closing_Rank": 11129
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9880,
   "Closing_Rank": 12616
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1228,
   "Closing_Rank": 2205
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1462,
   "Closing_Rank": 1462
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1483,
   "Closing_Rank": 2024
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3730,
   "Closing_Rank": 3730
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6068,
   "Closing_Rank": 14635
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19472,
   "Closing_Rank": 22547
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 206,
   "Closing_Rank": 206
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2240,
   "Closing_Rank": 2330
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4311,
   "Closing_Rank": 4311
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5106,
   "Closing_Rank": 5770
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 9162,
   "Closing_Rank": 9509
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 975,
   "Closing_Rank": 3155
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3418,
   "Closing_Rank": 3418
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 86,
   "Closing_Rank": 86
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1121,
   "Closing_Rank": 1349
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Computer Science and Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 1818,
   "Closing_Rank": 1818
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6608,
   "Closing_Rank": 27313
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 29625,
   "Closing_Rank": 34425
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 913,
   "Closing_Rank": 913
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6836,
   "Closing_Rank": 7461
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 13649,
   "Closing_Rank": 13649
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 12444,
   "Closing_Rank": 20094
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 25220,
   "Closing_Rank": 27942
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3301,
   "Closing_Rank": 5018
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4391,
   "Closing_Rank": 4391
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4588,
   "Closing_Rank": 6584
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 7149,
   "Closing_Rank": 7149
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 50,
   "Closing_Rank": 50
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20438,
   "Closing_Rank": 28389
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30187,
   "Closing_Rank": 39661
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2195,
   "Closing_Rank": 2195
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4506,
   "Closing_Rank": 4920
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6804,
   "Closing_Rank": 6804
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8437,
   "Closing_Rank": 9816
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 12595,
   "Closing_Rank": 14822
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5225,
   "Closing_Rank": 6023
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8682,
   "Closing_Rank": 8682
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2098,
   "Closing_Rank": 2259
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electrical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3043,
   "Closing_Rank": 3043
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11608,
   "Closing_Rank": 21591
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23856,
   "Closing_Rank": 27739
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5875,
   "Closing_Rank": 6223
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10660,
   "Closing_Rank": 10660
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11269,
   "Closing_Rank": 15577
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 22924,
   "Closing_Rank": 22924
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2553,
   "Closing_Rank": 3637
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 3451,
   "Closing_Rank": 3451
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3130,
   "Closing_Rank": 4179
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5939,
   "Closing_Rank": 5939
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16738,
   "Closing_Rank": 21078
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 30256,
   "Closing_Rank": 31775
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1362,
   "Closing_Rank": 1362
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3833,
   "Closing_Rank": 3881
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6142,
   "Closing_Rank": 6142
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7382,
   "Closing_Rank": 7907
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10504,
   "Closing_Rank": 14579
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 715,
   "Closing_Rank": 715
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4795,
   "Closing_Rank": 5116
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6855,
   "Closing_Rank": 6855
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Electronics and Telecommunication Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1646,
   "Closing_Rank": 1646
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16371,
   "Closing_Rank": 20873
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18799,
   "Closing_Rank": 26441
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 483,
   "Closing_Rank": 483
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5131,
   "Closing_Rank": 5822
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10013,
   "Closing_Rank": 10013
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11699,
   "Closing_Rank": 13803
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 18130,
   "Closing_Rank": 23972
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2451,
   "Closing_Rank": 3112
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4354,
   "Closing_Rank": 4354
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3092,
   "Closing_Rank": 3574
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 5505,
   "Closing_Rank": 5505
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 13607,
   "Closing_Rank": 16840
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 23943,
   "Closing_Rank": 28350
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 823,
   "Closing_Rank": 823
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2625,
   "Closing_Rank": 2697
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4621,
   "Closing_Rank": 4621
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 4415,
   "Closing_Rank": 6398
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10133,
   "Closing_Rank": 12570
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 556,
   "Closing_Rank": 556
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3162,
   "Closing_Rank": 3977
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4845,
   "Closing_Rank": 4845
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1622,
   "Closing_Rank": 1819
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Information Technology ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 2304,
   "Closing_Rank": 2304
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 26830,
   "Closing_Rank": 35986
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38504,
   "Closing_Rank": 48563
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 1804,
   "Closing_Rank": 1908
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7976,
   "Closing_Rank": 9789
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 19038,
   "Closing_Rank": 19038
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10172,
   "Closing_Rank": 24852
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 26403,
   "Closing_Rank": 33900
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5155,
   "Closing_Rank": 6181
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 6861,
   "Closing_Rank": 8062
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 6598,
   "Closing_Rank": 10575
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17215,
   "Closing_Rank": 17215
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 20930,
   "Closing_Rank": 35857
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 42118,
   "Closing_Rank": 45574
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2924,
   "Closing_Rank": 2924
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5765,
   "Closing_Rank": 6094
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8916,
   "Closing_Rank": 8916
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 10392,
   "Closing_Rank": 12482
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 16869,
   "Closing_Rank": 18368
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5283,
   "Closing_Rank": 7266
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 8908,
   "Closing_Rank": 10158
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mechanical Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2323,
   "Closing_Rank": 2461
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 36754,
   "Closing_Rank": 51555
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35082,
   "Closing_Rank": 49799
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2370,
   "Closing_Rank": 2370
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11357,
   "Closing_Rank": 11357
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 35598,
   "Closing_Rank": 35598
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 25728,
   "Closing_Rank": 37857
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 38329,
   "Closing_Rank": 38329
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7325,
   "Closing_Rank": 8621
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10780,
   "Closing_Rank": 10780
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 11528,
   "Closing_Rank": 11528
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 42662,
   "Closing_Rank": 50256
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 62214,
   "Closing_Rank": 63620
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN (PwD)",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 2366,
   "Closing_Rank": 2366
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8169,
   "Closing_Rank": 8296
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 15602,
   "Closing_Rank": 17236
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21448,
   "Closing_Rank": 21448
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 7690,
   "Closing_Rank": 8466
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10247,
   "Closing_Rank": 10247
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3201,
   "Closing_Rank": 3201
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Metallurgy and Materials Engineering ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 4446,
   "Closing_Rank": 4446
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 39453,
   "Closing_Rank": 53757
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 60795,
   "Closing_Rank": 61103
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 16166,
   "Closing_Rank": 16934
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 32594,
   "Closing_Rank": 38510
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 49152,
   "Closing_Rank": 49152
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8279,
   "Closing_Rank": 8915
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "SC",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 10704,
   "Closing_Rank": 10704
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 5955,
   "Closing_Rank": 6846
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "HS",
   "Seat_Type": "ST",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 17297,
   "Closing_Rank": 17297
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 48976,
   "Closing_Rank": 53043
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OPEN",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 62896,
   "Closing_Rank": 65852
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "EWS",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8746,
   "Closing_Rank": 8952
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 17277,
   "Closing_Rank": 17817
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "OBC-NCL",
   "Gender": "Female-only (including Supernumerary)",
   "Opening_Rank": 21607,
   "Closing_Rank": 22321
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "SC",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 8705,
   "Closing_Rank": 9090
 },
 {
   "Institute": "Indian Institute of Engineering Science and Technolog Shibpur",
   "Academic_Program_Name": "Mining Engineering  ",
   "duration": "4 Years",
   "Degree_Type": " Bachelor of Technology",
   "Quota": "OS",
   "Seat_Type": "ST",
   "Gender": "Gender-Neutral",
   "Opening_Rank": 3275,
   "Closing_Rank": 3372
 }
]
