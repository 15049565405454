export const JACClgs = [
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "12948",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "24129",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "49995",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "84936",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "296967",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "16364",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "29278",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "61182",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "106576",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "412902",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "19231",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "33772",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "72135",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "127318",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "500239",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "22071",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "37809",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "85687",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "14866",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "626554",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "27432",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "46787",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "109654",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "164543",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "917669",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "37615",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "55920",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "129975",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "254216",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "1094649",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "52903",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "75179",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "169633",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "327548",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "1075384",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "56083",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "79288",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "173508",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "383468",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "384734",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "54957",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "75767",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "175339",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "432983",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "63692",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "94868",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "199592",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "444420",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "72407",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "99192",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "210896",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "444409",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "682374",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "69236",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "102538",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "219954",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "471134",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "81658",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "111847",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "233970",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "495174",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "80740",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "115740",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "241098",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "HS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "479568",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "4876",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "7658",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "13126",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "37183",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Computer Science",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "96383",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "6424",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "8189",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "15795",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "45086",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Information Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "121909",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "7157",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "9246",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "16970",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "59146",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Software Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "116361",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "7980",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "9555",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "19103",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "71977",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mathematics and Computing",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "143319",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "8677",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "13415",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "21342",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "82652",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electronics and Communication Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "168274",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "11963",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "16236",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "23902",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "112592",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Electrical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "137194",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "16372",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "21326",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "27595",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "124048",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "147571",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "15959",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "17319",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "27596",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "124229",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Mechanical Engineering with specialization in Automotive Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "199399",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "15302",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "24355",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "29463",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "135608",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Engineering Physics",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "252314",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "18459",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "23503",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "31131",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "124665",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Chemical Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "183336",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "21106",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "24754",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "31257",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "126829",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Civil Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "126583",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "21109",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "24825",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "32151",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "131499",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Production and Industrial",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "245112",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "23177",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "25440",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "33674",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "135743",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Environmental Engineering",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "247932",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OPEN",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "23469",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "EWS",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "25703",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "OBC-NCL",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "36181",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "SC",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "132166",
  },
  {
    Institute: "Delhi Technological University ",
    Academic_Program_Name: "Bio Technology",
    duration: "4 Years",
    Degree_Type: "Bachelor of Technology",
    Quota: "OS",
    Seat_Type: "ST",
    Gender: "Gender-Neutral",
    Opening_Rank: "NA",
    Closing_Rank: "240784",
  },
  
  
  





];
